import React from 'react';
import { ThunkDeps } from '@c/ThunkDeps';
import { RemoteFlattenNode } from '@c/domain/entities/QueryScenarioEditor/ScenarioCsv';
import {
  CsvConvertedNodesMap,
  RegisteredCsvNodesMap,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { ScenarioEditorCommonState, ScenarioUsingNamespaces, State } from '@c/state';
import { AsyncActionContextGetter } from '@s/reactHooks';
import { genericError } from '../GenericError';
import { addNewQueryScenario } from '@c/modules/queryScenarioEditor/usecase';
import { json2csv } from 'json-2-csv';
import { dynamciNamespaceIc } from '@c/modules/dynamicNamespaceIC';
import { ProjectType } from '@c/domain/entities/Project';
import { fetchScenarioListSucceeded } from '@c/modules/scenarioCommon/action';
import { CreateInquiryResponse } from '@c/domain/entities/Inquiry';

const invokeNamespaceIc = dynamciNamespaceIc<ScenarioEditorCommonState>();

abstract class BaseCSVImportPass<T = undefined, R = void> {
  public constructor(
    protected csv: string,
    protected getAsyncActionContext: AsyncActionContextGetter<ThunkDeps, State>
  ) {}

  public abstract process(args: T): Promise<R>;
}

class UpdateInquiry extends BaseCSVImportPass<
  { nodeFaqMap: CsvConvertedNodesMap },
  { convertedNodesMap: RegisteredCsvNodesMap; flattenNodes: RemoteFlattenNode[] }
> {
  public async process() {
    const { context, state } = this.getAsyncActionContext();
    const { convertedNodesMap, flattenNodes } =
      await context.scenarioRepository.convertCsvToSceneNodes({
        csv: this.csv,
        inquiryList:
          state.queryScenarioEditor.scenarioList[state.queryScenarioEditor.selectedCategory.id] ??
          [],
        projectType: state.env.type as ProjectType,
      });
    const inquirySet = new Set<string>();
    const failedInquiries = [];

    for (const [faq, { id, params }] of convertedNodesMap) {
      if (!inquirySet.has(faq)) {
        inquirySet.add(faq);
        try {
          const scenarioId = await addNewQueryScenario(this.getAsyncActionContext())({
            inquiry: faq,
          });
          convertedNodesMap.get(faq)!.id = scenarioId;
        } catch (e: any) {
          failedInquiries.push({ inquiry: faq, error: e });
        }
      } else {
        try {
          const scenario = await context.scenarioEditorQuery.find({
            scenarioId: id!,
            projectId: state.env.projectId,
            errors: {},
            projectType: state.env.type,
          });
          for (const p of scenario.parameters ?? []) {
            params.add(p.id);
          }
        } catch (e) {
          failedInquiries.push({ inquiry: faq, error: e });
        }
      }
    }

    if (failedInquiries.length) {
      const message = failedInquiries.reduce((m, f) => {
        return m + `FAQ ${f.inquiry} の追加に失敗しました 原因: ${f.error.message}\n\n`;
      }, '');
      context.reportCrashed({ error: new Error(message), state });
      throw genericError({ message: `CSVからのインポートに失敗しました\n\n${message}` });
    }

    return { convertedNodesMap: convertedNodesMap as RegisteredCsvNodesMap, flattenNodes };
  }
}

class ScenarioInsertion extends BaseCSVImportPass<
  {
    flattenNodes: RemoteFlattenNode[];
    convertedNodesMap: RegisteredCsvNodesMap;
  },
  { scenarioIdSet: Set<string> }
> {
  public async process({
    flattenNodes,
    convertedNodesMap,
  }: {
    flattenNodes: RemoteFlattenNode[];
    convertedNodesMap: RegisteredCsvNodesMap;
  }) {
    const { context, state } = this.getAsyncActionContext();
    let reducedFlattenNodes = flattenNodes;
    const errors: string[] = [];
    const scenarioIdSet = new Set<string>();
    for (const [inquiry, { id, nodes }] of convertedNodesMap.entries()) {
      try {
        let inquiryResponse: CreateInquiryResponse;
        if (id) {
          await context.scenarioEditorRepository.update({
            inquiry,
            projectId: state.env.projectId,
            scenarioId: id,
            current: inquiry,
          });
          inquiryResponse = { scenarioId: id };
        } else {
          inquiryResponse = await context.scenarioEditorRepository.create({
            category: state.queryScenarioEditor.selectedCategory,
            inquiry,
            projectId: state.env.projectId,
          });
        }

        await context.scenarioRepository.update({
          scenarioId: inquiryResponse.scenarioId,
          projectId: state.env.projectId,
          nodes,
        });
        reducedFlattenNodes = reducedFlattenNodes.filter(n => n.質問 !== inquiry);
        scenarioIdSet.add(id);
      } catch (e: any) {
        errors.push(`FAQ: ${inquiry}(${id})`);
        context.reportCrashed({ error: e, state });
      }
    }

    if (errors.length) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob(
        [
          bom,
          await json2csv(reducedFlattenNodes, {
            emptyFieldValue: '',
            trimHeaderFields: true,
            preventCsvInjection: true,
            expandNestedObjects: false,
          }),
        ],
        { type: 'text/csv' }
      );
      const url = URL.createObjectURL(blob);
      throw genericError({
        message: (
          <div>
            <p>CSVからのインポートに失敗しました</p>
            {errors.map(v => (
              <p key={v}>
                {v}
                <br />
              </p>
            ))}
            <br />
            <p>
              失敗したシナリオの差分CSVファイルは
              <a href={url} download="scenario-diff.csv">
                こちら
              </a>
              からダウンロード可能です
            </p>
          </div>
        ),
      });
    }

    return { scenarioIdSet };
  }
}

export interface CSVImportService {
  processCSV({
    namespace,
    csv,
    getAsyncActionContext,
  }: {
    namespace: ScenarioUsingNamespaces;
    csv: string;
    getAsyncActionContext: AsyncActionContextGetter<ThunkDeps, State>;
  }): Promise<void>;
}

export class CSVImportServiceImpl {
  public async processCSV({
    namespace,
    csv,
    getAsyncActionContext,
  }: {
    namespace: ScenarioUsingNamespaces;
    csv: string;
    getAsyncActionContext: AsyncActionContextGetter<ThunkDeps, State>;
  }) {
    const { context, dispatch, state } = getAsyncActionContext();
    const categoryId = state.queryScenarioEditor.selectedCategory.id;
    const updateInquiry = new UpdateInquiry(csv, getAsyncActionContext);
    const { convertedNodesMap, flattenNodes } = await updateInquiry.process();
    const scenarioInsertion = new ScenarioInsertion(csv, getAsyncActionContext);
    await scenarioInsertion.process({ convertedNodesMap, flattenNodes });

    // 新規シナリオを作成した後に再fetchして画面に反映させる
    const scenarioList = await context.scenarioEditorQuery.get({
      projectId: state.env.projectId,
      categoryId,
    });
    dispatch(fetchScenarioListSucceeded(namespace, { categoryId, scenarioList }));
  }
}

import dayjs, { Dayjs, locale, ManipulateType } from 'dayjs';
import 'dayjs/locale/ja';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
locale('ja');
dayjs.extend(relativeTime);

export type DayjsArgType = {
  amount: number;
  unit: 'hour' | 'day' | 'week' | 'month';
};

export type DisplayDateFormat =
  | 'day'
  | 'dayHyphen'
  | 'time'
  | 'minutes'
  | 'seconds'
  | 'minutesFileName';
const DATE_FORMAT_DAY = 'YYYY/MM/DD';
const DATE_FORMAT_DAY_HYPHEN = 'YYYY-MM-DD';
const DATE_FORMAT_TIME = 'HH:mm';
const DATE_FORMAT_MINUTES = 'YYYY/MM/DD HH:mm';
const DATE_FORMAT_MINUTES_FILE_NAME = 'YYYYMMDD_HH_mm';
const DATE_FORMAT_SECONDS = 'YYYY/MM/DD HH:mm:ss';
const RFC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

export class DayjsWrapper {
  public static formatDateToDisplayStr(
    date: Date | null,
    displayDateFormat: DisplayDateFormat
  ): string {
    if (date == null) {
      return '';
    }
    let format;
    if (displayDateFormat === 'day') {
      format = DATE_FORMAT_DAY;
    } else if (displayDateFormat === 'dayHyphen') {
      format = DATE_FORMAT_DAY_HYPHEN;
    } else if (displayDateFormat === 'time') {
      format = DATE_FORMAT_TIME;
    } else if (displayDateFormat === 'minutes') {
      format = DATE_FORMAT_MINUTES;
    } else if (displayDateFormat === 'minutesFileName') {
      format = DATE_FORMAT_MINUTES_FILE_NAME;
    } else {
      format = DATE_FORMAT_SECONDS;
    }
    return dayjs(date).locale('ja').format(format);
  }

  public static formatDateToRfcStr(date: Date | null): string | undefined {
    if (date == null) {
      return undefined;
    }

    return dayjs(date).locale('ja').format(RFC_DATE_FORMAT);
  }

  public static convertJstToISOString(jst: string): string {
    const ts = Date.parse(jst);
    const date = new Date(ts);
    const ret = date.toISOString();
    return ret;
  }

  public static formatUnixTimeToDisplayStr(
    milliSec?: number,
    displayDateFormat: DisplayDateFormat = 'minutes'
  ): string {
    if (milliSec == null) {
      return '';
    }

    let format;
    if (displayDateFormat === 'day') {
      format = DATE_FORMAT_DAY;
    } else if (displayDateFormat === 'minutes') {
      format = DATE_FORMAT_MINUTES;
    } else if (displayDateFormat === 'minutesFileName') {
      format = DATE_FORMAT_MINUTES_FILE_NAME;
    } else {
      format = DATE_FORMAT_SECONDS;
    }

    return dayjs(milliSec).locale('ja').format(format);
  }

  public static isValidDate(dateStr: string, format = 'YYYY-MM-DD'): boolean {
    const formatDate = dayjs(dateStr, format).format(format);
    return dateStr === formatDate;
  }

  private readonly now: Dayjs;
  public constructor(date: string | Date | undefined = undefined) {
    this.now = dayjs(date);
    this.now.locale('ja');
  }
  public calcEndDateBasedOnCurrent(endOffset?: DayjsArgType): string {
    if (endOffset == null) {
      return this.now.format(RFC_DATE_FORMAT);
    }
    const cloneNow = this.now.clone();
    const endDateTime = cloneNow
      .add(endOffset.amount, endOffset.unit)
      .endOf(endOffset.unit)
      .add(1, 'minute')
      .format(RFC_DATE_FORMAT);
    return endDateTime;
  }
  public calcStartDateBasedOnCurrent(startOffset: DayjsArgType): string {
    let startDateTime = '';
    const cloneNow = this.now.clone();

    if (startOffset.unit === 'hour') {
      startDateTime = cloneNow.add(startOffset.amount, startOffset.unit).format(RFC_DATE_FORMAT);
      return startDateTime;
    }

    startDateTime = cloneNow
      .add(startOffset.amount, startOffset.unit)
      .startOf(startOffset.unit)
      .format(RFC_DATE_FORMAT);

    return startDateTime;
  }

  public isSameOrAfter(dateStr: string | undefined = undefined): boolean {
    return this.now.isSameOrAfter(dayjs(dateStr));
  }
  public isSameOrAfterThanCurrent(): boolean {
    return this.isSameOrAfter();
  }
  public isSameOrBefore(dateStr: string | undefined = undefined): boolean {
    return this.now.isSameOrBefore(dayjs(dateStr));
  }
  public isSameOrBeforeThanCurrent(): boolean {
    return this.isSameOrBefore();
  }

  public from(date: Date): string {
    return this.now.from(date);
  }
  public add(amount: number, unit: ManipulateType): Dayjs {
    return this.now.add(amount, unit);
  }
  public subtract(amount: number, unit: ManipulateType): Dayjs {
    return this.now.subtract(amount, unit);
  }
}

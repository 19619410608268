/**
 * @fileOverview
 * @name FacebookAppRepogitory.ts
 * @author Taketoshi Aono
 * @license
 */

import { AppendonlyRepository } from '@s/Repository';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type FacebookPageAppendableRepogitory = AppendonlyRepository<
  {
    projectId: string;
    pageId: string;
    iguserId: string;
    userAccessToken: string;
  },
  {},
  {
    projectId: string;
    pageId: string;
    newPageId?: string;
    iguserId?: string;
    userAccessToken?: string;
  },
  {}
>;

export class FacebookPageRepository implements FacebookPageAppendableRepogitory {
  public async create({
    projectId,
    ...data
  }: Parameters<FacebookPageAppendableRepogitory['create']>[0]): ReturnType<
    FacebookPageAppendableRepogitory['create']
  > {
    return fetchService(apiEndpoint(`/project/${projectId}/instagram/page`), {
      method: 'POST',
      data,
      responseType: 'json',
    });
  }

  public async update({
    projectId,
    pageId,
    ...data
  }: Parameters<FacebookPageAppendableRepogitory['update']>[0]): ReturnType<
    FacebookPageAppendableRepogitory['update']
  > {
    return fetchService(apiEndpoint(`/project/${projectId}/instagram/setting/page/${pageId}`), {
      method: 'PATCH',
      data,
      responseType: 'json',
    });
  }
}

/**
 * @fileOverview
 * @author
 */

import { initialState } from '@c/state';
import produce from 'immer';
import {
  fetchPreviewConfigurationSucceeded,
  reflectPreviewFailed,
  issuePreviewCustomerSucceeded,
  previewStateCleaned,
} from '@c/modules/preview/action';

type ActionType = ReturnType<
  | typeof fetchPreviewConfigurationSucceeded
  | typeof reflectPreviewFailed
  | typeof issuePreviewCustomerSucceeded
  | typeof previewStateCleaned
>;

export default (state = initialState.preview, action: ActionType) => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_PREVIEW_CONFIGURATION_SUCCEEDED':
        state.configuration = action.payload.configuration;
        state.welcomeMessage = action.payload.welcomeMessage;
        (state.configuration.whitelist.acceptDomains ?? []).push(
          process.env.NODE_ENV === 'production' ? '*.ai-messenger.app' : ''
        );
        return;
      case 'REFLECT_PREVIEW_FAILED':
        state.error = action.payload;
        return;
      case 'ISSUE_PREVIEW_CUSTOMER_SUCCEEDED':
        state.error = null;
        state.token = action.payload.token;
        return;
      case 'PREVIEW_STATE_CLEANED':
        state.configuration = null;
        return;
      default:
        return state;
    }
  });
};

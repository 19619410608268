/**
 * @fileOverview
 * @name SceneNodeSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import { SceneErrors } from '../values/PromoteErrors';
import {
  SceneNode,
  RemoteSceneNodes,
  SceneDimensionCache,
  MessageNode,
  IntegrationNode,
  getAllEdgesFromSceneNode,
  MailNode,
  IgCarouselNode,
} from '../entities/QueryScenarioEditor/SceneNode';

export type FullNodeRelationMap = Map<string, [SceneNode, string[], string[]]>;

export const cleanupFallback = (scene: SceneNode) => {
  if (scene.type === 'message' && isTerminalScene(scene)) {
    scene.fallbackView.edge = '';
    scene.shouldUseFallbackView = false;
  }
};

export interface SceneNodeSpecification {
  buildFullRelationMap(nodes: SceneNode[]): FullNodeRelationMap;
  cleanupDimensions(
    dimensions: RemoteSceneNodes['dimensions'],
    fullNodeRelationMap: FullNodeRelationMap
  ): SceneDimensionCache;
  validate(scene: SceneNode): ValueOf<SceneErrors>;
}

export const isOrphanScene = (scene: SceneNode): boolean => {
  return (
    !scene.fromEdges.length &&
    (scene.type === 'message' || scene.type === 'carousel' ? !scene.isInitialScene : true)
  );
};

export const isSceneHasNoMessages = (scenes: MessageNode) => {
  return !scenes.response.messages.length;
};

export const isSceneHasInvalidMessage = (
  scene: MessageNode
): { hasEmptyMessage: boolean; hasEmptyImage: boolean } => {
  let hasEmptyMessage = false;
  let hasEmptyImage = false;
  scene.response.messages.forEach(m => {
    if (m.attachment.mediaType === 'plainText' && !m.attachment.payload) {
      hasEmptyMessage = true;
    } else if (m.attachment.mediaType === 'image' && !m.attachment.payload.url) {
      hasEmptyImage = true;
    }
  });

  return {
    hasEmptyMessage,
    hasEmptyImage,
  };
};

export const isSceneHasEmptyImage = (scene: MessageNode): boolean => {
  return !scene.response.messages.every(m =>
    m.attachment.mediaType === 'image' ? !!m.attachment.payload.url : true
  );
};

export const isSceneHasOrphanButton = (scene: SceneNode): boolean => {
  switch (scene.type) {
    case 'message': {
      return !scene.shouldUseFallbackView
        ? !scene.response.quickReplies.every(({ edge }) => !!edge)
        : !scene.fallbackView.edge;
    }
    case 'businessHour': {
      return !scene.request.results.every(({ edge }) => !!edge);
    }
    case 'integration': {
      return !scene.integration.succeeded.quickReplies?.every(({ edge }) => !!edge);
    }
    case 'carousel': {
      return !scene.carousel.elements
        .flatMap(element => {
          return element.buttonsView;
        })
        .concat(scene.freeInputEdgeView)
        .every(({ edge }) => !!edge);
    }
    default:
      return false;
  }
};

export const isCarouselEditableScene = (scene: SceneNode): scene is IgCarouselNode => {
  return scene.type === 'carousel';
};

export const isInitiableScene = (scene: SceneNode): scene is MessageNode | IgCarouselNode => {
  return scene.type === 'message' || scene.type === 'carousel';
};

export const isMessageEditableScene = (scene: SceneNode): scene is MessageNode => {
  return scene.type === 'message';
};

export const isButtonEditableScene = (scene: SceneNode): scene is MessageNode => {
  return scene.type === 'message';
};

export const isTextInputStateEditableScene = (scene: SceneNode): scene is MessageNode => {
  return scene.type === 'message';
};

export const isToAddressEditableScene = (scene?: SceneNode): scene is MailNode => {
  return !!scene && scene.type === 'mail';
};

export const isReplyToEditableScene = (scene?: SceneNode): scene is MailNode => {
  return !!scene && scene.type === 'mail';
};

export const isEndpointEditableScene = (scene: SceneNode): scene is IntegrationNode => {
  return scene.type === 'integration';
};

export const isMethodEditableScene = (scene: SceneNode): scene is IntegrationNode => {
  return scene.type === 'integration';
};

export const isHeadersEditableScene = (scene: SceneNode): scene is IntegrationNode => {
  return scene.type === 'integration';
};

export const isConditionEditableScene = (scene: SceneNode): scene is IntegrationNode => {
  return scene.type === 'integration';
};

export const isTerminalScene = (scene: ReadonlyDeep<SceneNode>) => {
  if (scene.type === 'message' && scene.response.quickReplies.length) {
    return false;
  }
  return (
    scene.fromEdges.length &&
    getAllEdgesFromSceneNode(scene).every(b => !b.edge) &&
    scene.fromEdges.length > 0
  );
};

export const correctFromEdges = ({
  fromScene,
  toScene,
  sameFromEdgesCount,
}: {
  fromScene: SceneNode;
  toScene: SceneNode;
  sameFromEdgesCount: number;
}) => {
  const sameToEdgesCount = getAllEdgesFromSceneNode(fromScene).reduce((acc, e) => {
    return acc + (e.edge === toScene.id ? 1 : 0);
  }, 0);
  if (sameFromEdgesCount < sameToEdgesCount) {
    toScene.fromEdges = toScene.fromEdges.concat(
      Array(sameToEdgesCount - sameFromEdgesCount).fill(fromScene.id)
    );
  } else if (sameFromEdgesCount > sameToEdgesCount) {
    let needToReduce = sameFromEdgesCount - sameToEdgesCount;
    toScene.fromEdges = toScene.fromEdges.filter(e => {
      if (e === fromScene.id && needToReduce) {
        needToReduce++;
        return false;
      }
      return true;
    });
  }
};

export const findDuplicateOrEmptyButton = (
  scene: MessageNode | IntegrationNode
): { hasDuplication: boolean; hasEmptyButton: boolean } => {
  const textMatch: { [key: string]: boolean } = {};
  let hasDuplication = false;
  let hasEmptyButton = false;
  const quickReplies =
    scene.type === 'message'
      ? scene.response.quickReplies
      : scene.integration.succeeded.quickReplies;
  quickReplies?.forEach(q => {
    if (!q.label) {
      hasEmptyButton = true;
    }
    if (!textMatch[q.label]) {
      textMatch[q.label] = true;
    } else {
      hasDuplication = true;
    }
  });

  return {
    hasDuplication,
    hasEmptyButton,
  };
};

export class ScenarioSpecification implements SceneNodeSpecification {
  public buildFullRelationMap(sceneNodes: SceneNode[]) {
    const nodeRelationMap = new Map<string, [SceneNode, string[], string[]]>();
    for (const sceneNode of sceneNodes) {
      nodeRelationMap.set(sceneNode.id, [
        sceneNode,
        [],
        getAllEdgesFromSceneNode(sceneNode).map(v => v.edge),
      ]);
    }
    for (const sceneNode of sceneNodes) {
      const toRelation = nodeRelationMap.get(sceneNode.id)!;
      for (const edge of toRelation[2]) {
        const fromRelation = nodeRelationMap.get(edge);
        if (fromRelation) {
          fromRelation[1].push(sceneNode.id);
        }
      }
    }
    return nodeRelationMap;
  }

  public cleanupDimensions(
    dimensions: RemoteSceneNodes['dimensions'] = {},
    fullNodeRelationMap: FullNodeRelationMap
  ): SceneDimensionCache {
    const newDimensions: SceneDimensionCache = {};
    for (const [key] of fullNodeRelationMap) {
      newDimensions[key] = dimensions[key];
    }
    return newDimensions;
  }

  public validate(scene: SceneNode): ValueOf<SceneErrors> {
    const ret: ValueOf<SceneErrors> = { size: 0 };
    if (isOrphanScene(scene)) {
      ret.NO_FROM_EDGE_NODE = 'NO_FROM_EDGE_NODE';
      ret.size++;
    }
    if (scene.type === 'message' && isSceneHasNoMessages(scene)) {
      ret.EMPTY_RESPONSE_MESSAGES = 'EMPTY_RESPONSE_MESSAGES';
      ret.size++;
    }
    if (scene.type === 'message') {
      const { hasEmptyImage, hasEmptyMessage } = isSceneHasInvalidMessage(scene);
      if (hasEmptyMessage) {
        ret.EMPTY_PLAIN_TEXT_MESSAGE = 'EMPTY_PLAIN_TEXT_MESSAGE';
        ret.size++;
      } else if (hasEmptyImage) {
        ret.EMPTY_IMAGE_URL = 'EMPTY_IMAGE_URL';
        ret.size++;
      }
    }

    if (isSceneHasOrphanButton(scene)) {
      ret.NO_DEST_QUICK_REPLY = 'NO_DEST_QUICK_REPLY';
      ret.size++;
    }
    if (scene.type === 'message') {
      if (
        !scene.response.messages.every(m =>
          m.attachment.mediaType === 'image' ? !!m.attachment.payload.url : true
        )
      ) {
        ret.EMPTY_IMAGE_URL = 'EMPTY_IMAGE_URL';
        ret.size++;
      }

      const { hasDuplication, hasEmptyButton } = findDuplicateOrEmptyButton(scene);
      if (hasDuplication) {
        ret.DUPLICATE_BUTTON_TEXT = 'DUPLICATE_BUTTON_TEXT';
        ret.size++;
      }
      if (hasEmptyButton) {
        ret.EMPTY_BUTTON_TEXT = 'EMPTY_BUTTON_TEXT';
        ret.size++;
      }
    }

    if (scene.type === 'carousel') {
      if (scene.carousel.id == null || scene.carousel.id == '0') {
        ret.EMPTY_CAROUSEL = 'EMPTY_CAROUSEL';
        ret.size++;
      }
    }
    return ret;
  }
}

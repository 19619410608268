/**
 * @fileOverview
 * @name WidgetCustomizationQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from './interface';
import { fetchService, FetchFailure } from '@s/io/fetchService';
import { welcomeMessage, welcomeMessagePreview } from '@c/config';
import { WelcomeMessageFormat } from '@c/domain/values/WelcomeMessage';

export type WelcomeMessageGettableQuery = GettableQuery<
  { dedicatedServerName: string; projectId: string },
  Optional<WelcomeMessageFormat>
>;

export class WelcomeMessageQuery implements WelcomeMessageGettableQuery {
  public async get({
    dedicatedServerName,
    projectId,
  }: {
    dedicatedServerName: string;
    projectId: string;
  }): Promise<Optional<WelcomeMessageFormat>> {
    try {
      return await fetchService<WelcomeMessageFormat>(
        welcomeMessagePreview(dedicatedServerName, projectId),
        {
          method: 'GET',
          responseType: 'json',
        }
      );
    } catch (e) {
      if (e instanceof FetchFailure && e.status === 404) {
        try {
          return await fetchService<WelcomeMessageFormat>(
            welcomeMessage(dedicatedServerName, projectId),
            {
              method: 'GET',
              responseType: 'json',
            }
          );
        } catch (e) {
          if (e instanceof FetchFailure && e.status === 404) {
            return null;
          }
        }
      }
      throw e;
    }
  }
}

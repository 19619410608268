/**
 * @fileOverview
 * @name urlList.ts
 * @author Taketoshi Aono
 * @license
 */

import { State } from './state';
import { ProjectEntity } from '@c/domain/entities/Project';

export const INITIAL_PAGE = '/a/p';

export const INITIAL_PAGE_UNDER_PROJECT = (project: ProjectEntity, isPc: boolean) => {
  if (isPc) {
    if (project.type === 'voice') return `/a/p/${project.id}/call-log`;
    if (project.type === 'dialogEngineVoice') return `/a/p/${project.id}/dialog-engine-call-log`;
    if (project.type === 'llmchat') return `/a/p/${project.id}/llm-widget-customization`;
    if (project.type === 'summary') return `/a/p/${project.id}/summaries`;

    return `/a/p/${project.id}/query-scenario-editor`;
  } else {
    return underProjectUrlList.productionCheckFloating.getPath(project.id);
  }
};

export type PathType = State['env']['type'];
export type UrlConfig = {
  getPath(projectId: string, ...args: string[]): string;
  getPath(...a: string[]): string;
  isMatch(path: string): boolean;
  path: string;
  routerPath: string;
  type: PathType[];
  isMatchWithEveryType(type: PathType[]): boolean;
  isMatchWithSomeType(type: PathType[]): boolean;
};

const createPathConfig = (
  isAuthed: boolean,
  path: string,
  type: PathType[] | PathType,
  ...routerPathAdditionals: string[]
): UrlConfig => {
  const pathWithSlash = `/${path}`;
  type = Array.isArray(type) ? type : [type];
  return {
    getPath: (...params: string[]) =>
      `/${isAuthed ? 'a/' : ''}${path}${params.length ? `/${params.join('/')}` : ''}`,
    path: pathWithSlash,
    routerPath: `/${isAuthed ? 'a/' : ''}${path}${
      routerPathAdditionals.length ? `/${routerPathAdditionals.join('/')}` : ''
    }`,
    isMatch: p => p.includes(pathWithSlash),
    isMatchWithSomeType(t: PathType[]) {
      return t.some(ty => type.includes(ty));
    },
    isMatchWithEveryType(t: PathType[]) {
      return t.every(ty => type.includes(ty));
    },
    type,
  };
};

const createUnderProjectPathConfig = (
  path: string,
  type: PathType[] | PathType,
  ...routerPathAdditionals: string[]
): UrlConfig => {
  const pathWithSlash = `/${path}`;
  type = Array.isArray(type) ? type : [type];
  return {
    getPath: (projectId: string, ...params: string[]) =>
      `/a/p/${projectId}/${path}${params.length ? `/${params.join('/')}` : ''}`,
    path: pathWithSlash,
    routerPath: `/a/p/:projectId/${path}${
      routerPathAdditionals.length ? `/${routerPathAdditionals.join('/')}` : ''
    }`,
    isMatch: p => p.includes(pathWithSlash),
    isMatchWithSomeType(t: PathType[]) {
      return t.some(ty => type.includes(ty));
    },
    isMatchWithEveryType(t: PathType[]) {
      return t.every(ty => type.includes(ty));
    },
    type,
  };
};

export const unauthedUrlList = {
  loginUrl: createPathConfig(false, 'login', ['instagram', 'chat', 'voice', 'line']),
};

export const underProjectUrlList = {
  root: createUnderProjectPathConfig('', ['instagram', 'chat', 'voice', 'line', 'llmchat']),
  dashboard: createUnderProjectPathConfig('dashboard', ['instagram', 'chat', 'line']),
  fblogin: createUnderProjectPathConfig('fblogin', ['instagram', 'chat', 'line']),
  queryScenarioEditor: createUnderProjectPathConfig(
    'query-scenario-editor',
    ['instagram', 'chat', 'line'],
    ':categoryId?',
    ':scenarioId?'
  ),
  igCarouselEditor: createUnderProjectPathConfig('ig-carousel-editor', ['instagram']),
  businessHour: createUnderProjectPathConfig('businesshour', ['instagram', 'chat', 'line']),
  systemMessages: createUnderProjectPathConfig('system-messages', [
    'instagram',
    'chat',
    'line',
    'llmchat',
  ]),
  llmChatSystemMessages: createUnderProjectPathConfig('llmchat-system-messages', ['llmchat']),
  igFixedPhrase: createUnderProjectPathConfig('ig-fixed-phrase', ['instagram']),
  synonym: createUnderProjectPathConfig('synonym', ['instagram', 'chat', 'line']),
  inquiryOrder: createUnderProjectPathConfig('inquiry-order', ['instagram', 'chat', 'line']),
  previewCheckFloating: createUnderProjectPathConfig('preview-check-floating', [
    'instagram',
    'chat',
    'line',
  ]),
  previewCheckEmbedded: createUnderProjectPathConfig('preview-check-embedded', [
    'instagram',
    'chat',
    'line',
  ]),
  productionCheckFloating: createUnderProjectPathConfig('production-check-floating', [
    'instagram',
    'chat',
    'line',
  ]),
  productionCheckEmbedded: createUnderProjectPathConfig('production-check-embedded', [
    'instagram',
    'chat',
    'line',
  ]),
  aicCommon: createUnderProjectPathConfig('aicompass', ['instagram', 'chat', 'line']),
  aicMatchingTraining: createUnderProjectPathConfig('aicompass/matching-training', [
    'instagram',
    'chat',
    'line',
  ]),
  aicResolutionTraining: createUnderProjectPathConfig(
    'aicompass/resolution-training',
    ['instagram', 'chat', 'line'],
    ':categoryId?',
    ':scenarioId?'
  ),
  widgetCustomization: createUnderProjectPathConfig(
    'widget-customization',
    ['instagram', 'chat', 'line'],
    ':section?'
  ),
  llmWidgetCustomization: createUnderProjectPathConfig('llm-widget-customization', ['llmchat']),
  embeddingFileResourceUpload: createUnderProjectPathConfig('files', ['llmchat']),
  fixedScenario: createUnderProjectPathConfig('fixed-scenario', ['llmchat']),
  embeddingBaseUrls: createUnderProjectPathConfig('webpages', ['llmchat']),
  ragConfig: createUnderProjectPathConfig('llm-chat-config', ['llmchat']),
  widgetInspector: createUnderProjectPathConfig('widget-inspector', ['instagram', 'chat', 'line']),
  support: createUnderProjectPathConfig('support', ['instagram', 'chat', 'line']),
  accounts: createUnderProjectPathConfig('accounts', ['instagram', 'chat', 'voice', 'line']),
  operators: createUnderProjectPathConfig('operators', ['instagram', 'chat', 'voice', 'line']),
  operationHistories: createUnderProjectPathConfig('operation-histories', [
    'instagram',
    'chat',
    'voice',
    'line',
  ]),
  chat: createUnderProjectPathConfig('chat', ['instagram', 'chat', 'line']),
  dataExport: createUnderProjectPathConfig('data-export', ['instagram', 'chat', 'line']),
  mediaUpload: createUnderProjectPathConfig('media-upload', ['instagram', 'chat', 'line']),
  transferCheck: createUnderProjectPathConfig('transfer-check', 'voice'),
  callLog: createUnderProjectPathConfig('call-log', 'voice'),
  callLogDetail: createUnderProjectPathConfig('call-log', 'voice', ':conversationId?'),
  dialogEngineCallLog: createUnderProjectPathConfig('dialog-engine-call-log', 'voice'),
  dialogEnginecallLogDetail: createUnderProjectPathConfig(
    'dialog-engine-call-log',
    'voice',
    ':conversationId?'
  ),
  storyList: createUnderProjectPathConfig('stories', 'voice'),
  dialogEngineBusinessHour: createUnderProjectPathConfig('dialog-engine-businesshour', 'voice'),
  story: createUnderProjectPathConfig('story', 'voice', ':storyID?'),
  scenario: createUnderProjectPathConfig('scenario', 'voice', ':storyID?', ':scenarioID?'),
  dialogEngineEntity: createUnderProjectPathConfig(
    'dialog-engine-entity',
    'voice',
    ':storyID?',
    ':entityId?'
  ),
  dialogEngineSlot: createUnderProjectPathConfig(
    'dialog-engine-slot',
    'dialogEngineVoice',
    ':storyID?'
  ),
  dialogEngineApiAction: createUnderProjectPathConfig(
    'dialog-engine-action/api',
    'voice',
    ':storyID?'
  ),
  dialogEnginePhoneTransferAction: createUnderProjectPathConfig(
    'dialog-engine-action/phone-transfer',
    'voice'
  ),
  dialogEngineSmsAction: createUnderProjectPathConfig('dialog-engine-action/sms', 'voice'),
  dialogEngineMailAction: createUnderProjectPathConfig('dialog-engine-action/mail', 'voice'),
  summaries: createUnderProjectPathConfig('summaries', 'summary'),
  summary: createUnderProjectPathConfig('summary', 'summary', ':contentId?'),
  summaryCreateTask: createUnderProjectPathConfig('summary-task/new', 'summary'),
  summaryTask: createUnderProjectPathConfig('summary-tasks/:taskId', 'summary'),
  summaryEditTask: createUnderProjectPathConfig('summary-tasks/:taskId/edit', 'summary'),
  summariesTasks: createUnderProjectPathConfig('summary-tasks', 'summary'),
};

export const authedUrlList = {
  project: createPathConfig(true, 'p', ['instagram', 'chat', 'voice', 'line']),
  support: createPathConfig(true, 'support', ['instagram', 'chat', 'line']),
  accounts: createPathConfig(true, 'accounts', ['instagram', 'chat', 'voice', 'line']),
  operators: createPathConfig(true, 'operators', ['instagram', 'chat', 'voice', 'line']),
  updatePassword: createPathConfig(true, 'update-password', ['instagram', 'chat', 'voice', 'line']),
  chat: createPathConfig(true, 'chat', ['instagram', 'chat']),
  dataExport: createPathConfig(true, 'data-export', ['instagram', 'chat', 'line']),
  mediaUpload: createPathConfig(true, 'media-upload', ['instagram', 'chat', 'line']),
  twilioPhoneNumber: createPathConfig(true, 'twilio-phone-number', ['voice']),
};

type UnderProjectUrlListKeys = keyof typeof underProjectUrlList;
type AuthedUrlListKeys = keyof typeof authedUrlList;
type UnauthedUrlListKeys = keyof typeof unauthedUrlList;
export type UrlListKeys = UnderProjectUrlListKeys | AuthedUrlListKeys | UnauthedUrlListKeys;
const IGNORE_KEYS: { [P in UrlListKeys]?: number } = {
  root: 1,
  aicCommon: 1,
};

export const findUrl = (path: string): UrlListKeys | null => {
  const key1 = (Object.keys(underProjectUrlList) as UnderProjectUrlListKeys[]).find(
    key => !IGNORE_KEYS[key] && underProjectUrlList[key].isMatch(path)
  );
  if (key1) {
    return key1;
  }
  const key2 = (Object.keys(authedUrlList) as any as AuthedUrlListKeys[]).find(
    key => !IGNORE_KEYS[key] && authedUrlList[key].isMatch(path)
  );
  if (key2) {
    return key2;
  }

  const key3 = (Object.keys(unauthedUrlList) as any as UnauthedUrlListKeys[]).find(
    key => !IGNORE_KEYS[key] && unauthedUrlList[key].isMatch(path)
  );
  if (key3) {
    return key3;
  }
  return null;
};

export const getUrlObjectFromKey = (key: UrlListKeys): UrlConfig => {
  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  return (
    underProjectUrlList[key as UnderProjectUrlListKeys] ||
    authedUrlList[key as AuthedUrlListKeys] ||
    unauthedUrlList[key as UnauthedUrlListKeys]
  );
  /* eslint-enable @typescript-eslint/no-unnecessary-condition */
};

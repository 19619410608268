/**
 * @fileOverview
 * @author
 */

import { LabelEntity } from '@c/domain/entities/Label';

export interface LabelSpecification {
  isValid(label: LabelEntity): [boolean, string];
}

export class LabelValidator implements LabelSpecification {
  public isValid(label: LabelEntity): [boolean, string] {
    if (!label.name) {
      return [false, 'ラベル名は必須です'];
    }
    if (label.name.length > 32) {
      return [false, 'ラベル名は32文字以内で入力してください'];
    }
    return [true, ''];
  }
}

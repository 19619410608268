/**
 * @fileOverview
 * @name AccountSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  EMAIL_REGEXP_FROM_HTML5_ELEMENT,
  PASSWORD_CHARS,
  REQUIRED_CHARS,
  SYMBOLS,
} from './validationUtils';
import { isValidPhoneNumberString } from '@c/util/formatPhoneNumber';
import { GenericError } from '@c/application/GenericError';

export interface AccountProfileSpecification {
  isValidPassword(password: string): [boolean, string];
  isValidEmail(email: string): boolean;
  isValidPhoneNumber(phoneNumber: string): [boolean, string];
  isValidCode(verificationCode: string): [boolean, string];
  isValidDisplayName(displayName: string): [boolean, string];
}

const PHONE_NUMBER_REQUIRED = '電話番号は必須です';
const PHONE_NUMBER_FORMAT = '電話番号はハイフン(-)などの記号を含めず入力してください';
const INVALID_PHONE_NUMBER = '不正な電話番号です';
const DISPLAY_NAME_REQUIRED = '表示名は必須です';
const VERIFICATION_CODE_REQUIRED = '認証コードを入力してください';
const INVALID_VERIFICATION_CODE = '認証コードは6桁の数字です';
const PASSWORD_MATCH_ERROR = 'パスワードと確認用パスワードが違います';

export class AccountProfileValidator implements AccountProfileSpecification {
  public isValidCode(verificationCode: string): [boolean, string] {
    if (!verificationCode) {
      return [false, VERIFICATION_CODE_REQUIRED];
    }
    const reg = new RegExp(/^[0-9]*$/);
    if (!reg.test(verificationCode) || verificationCode.length != 6) {
      return [false, INVALID_VERIFICATION_CODE];
    }
    return [true, ''];
  }

  public isValidDisplayName(displayName: string): [boolean, string] {
    if (!displayName) {
      return [false, DISPLAY_NAME_REQUIRED];
    }
    return [true, ''];
  }

  public isValidEmail(email: string): boolean {
    return EMAIL_REGEXP_FROM_HTML5_ELEMENT.test(email);
  }

  public isValidPassword(password: string): [boolean, string] {
    const isValid =
      REQUIRED_CHARS.every(regexp => regexp.test(password)) &&
      PASSWORD_CHARS.test(password) &&
      password.length >= 8;
    return isValid
      ? [true, '']
      : [
          false,
          `パスワードは8文字以上の英字小文字、英字大文字、数字（各半角）記号（${SYMBOLS}）をそれぞれ1文字以上含むものでなければなりません`,
        ];
  }

  public isValidPhoneNumber(phoneNumber: string): [boolean, string] {
    if (!phoneNumber) {
      return [false, PHONE_NUMBER_REQUIRED];
    }
    const reg = new RegExp(/^[0-9]*$/);
    if (!reg.test(phoneNumber)) {
      return [false, PHONE_NUMBER_FORMAT];
    }
    if (!isValidPhoneNumberString(phoneNumber)) {
      return [false, INVALID_PHONE_NUMBER];
    }
    return [true, ''];
  }

  public validateIssueNewAccountParameter(
    displayName: string,
    password: { confirmation: string; raw: string }
  ): GenericError {
    let [isValid, message] = this.isValidDisplayName(displayName);
    const e: GenericError = { message, errors: [] };
    if (!isValid) {
      e.errors.push({ field: 'displayName', message });
    }
    [isValid, message] = this.isValidPassword(password.raw);
    if (isValid) {
      if (password.raw !== password.confirmation) {
        e.errors.push({ field: 'password', message: PASSWORD_MATCH_ERROR });
      }
    } else {
      e.errors.push({ field: 'password', message });
    }
    return e;
  }
}

/**
 * @fileOverview
 * @name FirebaseHandler.ts
 * @author Taketoshi Aono
 * @license
 */

import firebase, { getApp } from 'firebase/app';

export interface FirestoreHandleable {
  readonly app: firebase.FirebaseApp;
}

export class FirestoreHandler implements FirestoreHandleable {
  public constructor() {}

  public get app() {
    return getApp();
  }
}

/**
 * @fileOverview
 * @name ScenarioEditorRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import {
  SceneNodes,
  RemoteSceneNodes,
  CsvConvertedNodesMap,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { UpdatableRepository, DeletableRepository } from '@aim/shared/src/Repository';
import { RemoteFlattenNode } from '@c/domain/entities/QueryScenarioEditor/ScenarioCsv';
import { Inquiry } from '@c/domain/entities/Inquiry';
import { ProjectType } from '@c/domain/entities/Project';

export type ScenarioEditableRepository = UpdatableRepository<
  {
    projectId: string;
    scenarioId: string;
    nodes: ReadonlyDeep<SceneNodes>;
  },
  void
> &
  DeletableRepository<
    {
      projectId: string;
      scenarioId: string;
    },
    void
  > & {
    convertCsvToSceneNodes(a: {
      csv: string;
      inquiryList: Inquiry[];
      projectType: ProjectType;
    }): Promise<{ convertedNodesMap: CsvConvertedNodesMap; flattenNodes: RemoteFlattenNode[] }>;
  };
type P = Params<ScenarioEditableRepository>;

export class ScenarioRepository implements ScenarioEditableRepository {
  public constructor(
    private readonly adapters: {
      sceneNodesToRemoteSceneNode(nodes: ReadonlyDeep<SceneNodes>): ReadonlyDeep<RemoteSceneNodes>;
      csvToSceneNodesAdapter(a: {
        csv: string;
        inquiryList: Inquiry[];
        projectType: ProjectType;
      }): Promise<{ convertedNodesMap: CsvConvertedNodesMap; flattenNodes: RemoteFlattenNode[] }>;
    }
  ) {}

  public async delete({
    projectId,
    scenarioId,
  }: P['delete'][0]): ReturnType<ScenarioEditableRepository['delete']> {
    await fetchService<{}>(apiEndpoint(`project/${projectId}/scenario/${scenarioId}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update({ projectId, scenarioId, nodes }: P['update'][0]) {
    await fetchService<RemoteSceneNodes>(
      apiEndpoint(`project/${projectId}/scenario/${scenarioId}`),
      {
        method: 'PUT',
        responseType: 'json',
        data: this.adapters.sceneNodesToRemoteSceneNode(nodes),
      }
    );
  }

  public async convertCsvToSceneNodes(
    args: Parameters<ScenarioEditableRepository['convertCsvToSceneNodes']>[0]
  ) {
    return this.adapters.csvToSceneNodesAdapter(args);
  }
}

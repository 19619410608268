/**
 * @fileOverview
 * @name ProjectRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { EditableRepository } from '@s/Repository';
import { ProjectDetailEntity, ProjectEntity } from '@c/domain/entities/Project';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type ProjectEditableRepository = EditableRepository<
  ProjectEntity,
  {},
  ProjectDetailEntity,
  {},
  { id: string },
  {}
>;

export class ProjectRepository implements ProjectEditableRepository {
  public async create(
    project: Parameters<ProjectEditableRepository['create']>[0]
  ): ReturnType<ProjectEditableRepository['create']> {
    return fetchService(apiEndpoint(`project/create`), {
      method: 'POST',
      data: { displayName: project.displayName, type: project.type },
      responseType: 'json',
    });
  }

  public async delete({
    id,
  }: Parameters<ProjectEditableRepository['delete']>[0]): ReturnType<
    ProjectEditableRepository['delete']
  > {
    return fetchService(apiEndpoint(`project/${id}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update(
    entity: Parameters<ProjectEditableRepository['update']>[0]
  ): ReturnType<ProjectEditableRepository['update']> {
    const { id: unused, ...detail } = entity;
    return fetchService(apiEndpoint(`project/${entity.id}`), {
      method: 'PUT',
      data: detail,
      responseType: 'json',
    });
  }
}

/**
 * @fileOverview
 * @name FacebookAppRepogitory.ts
 * @author Taketoshi Aono
 * @license
 */

import { EditableRepository } from '@s/Repository';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type FacebookAppEditableRepogitory = EditableRepository<
  {
    projectId: string;
    id: string;
    secret: string;
    displayName: string;
    verifyToken: string;
    pageAccessToken: string;
  },
  {},
  {
    projectId: string;
    id: string;
    secret: string;
    displayName: string;
    verifyToken: string;
    pageAccessToken: string;
  },
  {},
  {
    projectId: string;
  },
  {}
>;

export class FacebookAppRepository implements FacebookAppEditableRepogitory {
  public async create({
    projectId,
    ...data
  }: Parameters<FacebookAppEditableRepogitory['create']>[0]): ReturnType<
    FacebookAppEditableRepogitory['create']
  > {
    return fetchService(apiEndpoint(`/project/${projectId}/instagram/setting/preview-app`), {
      method: 'POST',
      data,
      responseType: 'json',
    });
  }

  public async delete({
    projectId,
  }: Parameters<FacebookAppEditableRepogitory['delete']>[0]): ReturnType<
    FacebookAppEditableRepogitory['delete']
  > {
    return fetchService(apiEndpoint(`/project/${projectId}/instagram/setting/preview-app`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update({
    projectId,
    ...data
  }: Parameters<FacebookAppEditableRepogitory['update']>[0]): ReturnType<
    FacebookAppEditableRepogitory['update']
  > {
    await this.delete({ projectId });
    return this.create({ projectId, ...data });
  }
}

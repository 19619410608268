/**
 * @fileOverview
 * @author
 */

import { genericError } from '@c/application/GenericError';
import { ThunkDeps } from '@c/ThunkDeps';
import { State } from '@c/state';
import { UpdateInquiryParameter } from '@c/domain/values/UpdateInquiryParameter';
import { Category } from '@c/domain/entities/Category';
import {
  createScenarioSucceeded,
  updateInquirySucceeded,
  deleteScenarioSucceeded,
  selectCategorySucceeded,
  cleanupQueryScenarioEditorSucceeded,
} from '@c/modules/queryScenarioEditor/action';
import { AsyncActionContext } from '@s/reactHooks';
import { required } from '@s/assertions';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { fetchScenarioSucceeded, Namespaces } from '@c/modules/scenarioEditor/action';
import { CreateInquiryResponse } from '@c/domain/entities/Inquiry';
import { copyScenario } from '@c/modules/scenarioEditor/usecase';

type C = AsyncActionContext<ThunkDeps, State>;

export const addNewQueryScenario =
  ({ context, state, dispatch }: C) =>
  async ({ inquiry }: { inquiry: string }) => {
    const error = context.inquiryEditorSpecification.validateNewInquiry({
      inquiry,
      inquiries:
        state.queryScenarioEditor.scenarioList[state.queryScenarioEditor.selectedCategory.id] ?? [],
    });
    if (error) {
      throw error;
    }
    try {
      const query = await context.scenarioEditorRepository.create({
        projectId: state.env.projectId,
        inquiry,
        category: state.queryScenarioEditor.selectedCategory,
      });
      dispatch(
        createScenarioSucceeded({
          category: state.queryScenarioEditor.selectedCategory.id,
          inquiry,
          scenarioId: query.scenarioId,
        })
      );
      return query.scenarioId;
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: '問い合わせの追加に失敗しました' });
    }
  };

export const copyQueryScenario =
  ({ context, state, dispatch, getState }: C) =>
  async (
    namespace: Namespaces,
    {
      inquiry,
      scenarioId,
      carousels,
    }: {
      inquiry: string;
      scenarioId: string;
      carousels?: Carousel[];
    }
  ) => {
    const error = context.inquiryEditorSpecification.validateNewInquiry({
      inquiry,
      inquiries:
        state.queryScenarioEditor.scenarioList[state.queryScenarioEditor.selectedCategory.id] ?? [],
    });
    if (error) {
      throw error;
    }
    try {
      const response: CreateInquiryResponse = await context.scenarioEditorRepository.create({
        projectId: state.env.projectId,
        inquiry,
        category: state.queryScenarioEditor.selectedCategory,
      });
      dispatch(
        createScenarioSucceeded({
          category: state.queryScenarioEditor.selectedCategory.id,
          inquiry,
          scenarioId: response.scenarioId,
        })
      );
      const errors = {};
      const scenario = await context.scenarioEditorQuery.find({
        scenarioId,
        projectId: state.env.projectId,
        errors,
        projectType: state.env.type,
        carousels,
      });
      scenario.id = response.scenarioId;
      await copyScenario({ context, dispatch, state, getState })(namespace, {
        scenarioId: response.scenarioId,
        scenes: scenario,
      });
      dispatch(
        fetchScenarioSucceeded(namespace, { scenario, scenarioId: response.scenarioId, errors })
      );
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: '問い合わせのコピーに失敗しました' });
    }
  };

export const updateInquiry =
  ({ context, state, dispatch }: C) =>
  async (payload: UpdateInquiryParameter) => {
    payload.validate(
      context.inquiryEditorSpecification,
      state.queryScenarioEditor.scenarioList[state.queryScenarioEditor.selectedCategory.id] ?? []
    );
    try {
      const { scenarioId } = state.queryScenarioEditor;
      await context.scenarioEditorRepository.update({
        ...payload,
        scenarioId,
        projectId: state.env.projectId,
      });
      dispatch(updateInquirySucceeded({ ...payload, scenarioId }));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({
        message: '問い合わせ文の変更に失敗しました',
      });
    }
  };

export const deleteScenario =
  ({ context, state, dispatch }: C) =>
  async (unusedA: string /* for notification*/) => {
    try {
      const scenarioId = required(state.queryScenarioEditor.selectedScenario?.scenes).id;
      await context.scenarioRepository.delete({
        projectId: state.env.projectId,
        scenarioId,
      });
      dispatch(deleteScenarioSucceeded({ scenarioId }));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: 'シナリオの削除に失敗しました' });
    }
  };

export const selectCategory =
  ({ dispatch }: C) =>
  async (category: Category) => {
    dispatch(selectCategorySucceeded({ category }));
  };

export const cleanupQueryScenarioEditor =
  ({ dispatch }: C) =>
  async () => {
    dispatch(cleanupQueryScenarioEditorSucceeded());
  };

/**
 * @fileOverview
 * @name CSVSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import { FlattenNode } from '../entities/QueryScenarioEditor/ScenarioCsv';

export interface CSVSpecification {
  validate(a: FlattenNode[]): { isValid: boolean; errors: string[] };
}

const DEFAULT_NODE_IDS = [
  'reent',
  'dacapo',
  'dacapo',
  'feed',
  'reent',
  '0001',
  'init',
  'feedyes',
  'feedno',
  'callop',
  'cattop',
  'sorry',
  'feedno_swop',
];

export class CSVSpecificationImpl implements CSVSpecification {
  private isValidFlattenNode(a: FlattenNode): boolean {
    return a.ID.length > 0 && a.タイプ.length > 0;
  }

  public validate(a: FlattenNode[]): { isValid: boolean; errors: string[] } {
    const idSet = new Set<string>(a.map(v => v.ID).concat(DEFAULT_NODE_IDS));
    const errors: string[] = [];
    const isValid =
      a.every(node => {
        let isValid = true;
        if (node.ボタン) {
          isValid = node.ボタン.split('\n').every(v => {
            const s = v.split('::');
            if (!idSet.has(s[s.length - 1])) {
              errors.push(`${node.ID}の ボタン ${v} は存在しないIDを指定しています。`);
              return false;
            }
            return true;
          });
        }
        if (node.自由入力時遷移先ID && !idSet.has(node.自由入力時遷移先ID)) {
          errors.push(
            `${node.ID}の 自由入力時遷移先ID ${node.自由入力時遷移先ID} は存在しないIDを指定しています。`
          );
          isValid = false;
        }
        if (node.自動遷移先ID && !idSet.has(node.自動遷移先ID)) {
          errors.push(
            `${node.ID}の 自動遷移先ID ${node.自動遷移先ID} は存在しないIDを指定しています。`
          );
          isValid = false;
        }
        return isValid;
      }) && a.every(this.isValidFlattenNode.bind(this));

    return { isValid, errors };
  }
}

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { v4 } from 'uuid';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import { useSWRConfig } from 'swr';
import { WidgetType } from './components/atom/WidgetType';
import { Environment } from './platform/Environment';
import { forceLayout } from './components/atom/domUtils';
import { Cache, Mutate } from '@s/Repository';

export const useRefState = <T>(initialValue: T): [{ current: T }, (value: T) => void] => {
  const isMounted = useRef(false);
  const [value, update] = useState(initialValue);
  const valueRef = useRef(value);

  isMounted.current = true;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  let ref: any;
  if (process.env.NODE_ENV !== 'production') {
    ref = Object.defineProperty({}, 'current', {
      get() {
        return valueRef.current;
      },
    });
  } else {
    ref = valueRef;
  }

  return [
    ref,
    (value: T) => {
      if (!isMounted.current) {
        return;
      }
      if (process.env.NODE_ENV !== 'production') {
        valueRef.current = value;
      } else {
        ref.current = value;
      }
      update(value);
    },
  ];
};

export const useLabel = (
  rootRef: React.RefObject<HTMLElement | null | undefined>,
  labelRef?: React.RefObject<HTMLLabelElement | null | undefined>
) => {
  useEffect(() => {
    if (labelRef && labelRef.current && rootRef.current) {
      if (!rootRef.current.id) {
        rootRef.current.id = v4();
      }
      if (!labelRef.current.id) {
        labelRef.current.id = v4();
        labelRef.current.htmlFor = rootRef.current.id;
      }
      (rootRef.current as any)['aria-labelledby'] = labelRef.current.id;
    }
  }, [labelRef ? labelRef.current : false, rootRef.current]);
};

export interface EnhancedAsyncAction<T extends any[], U = any> {
  (...args: T): Promise<void>;
  skipCallbacks(...args: T): Promise<void>;
  loading: boolean;
  result: U;
  error: any;
  exitHook?(...args: T): void;
  succeededCallback?(...args: T): void;
  failedCallback?(...args: T): void;
}

export type AsyncActionContextGetter<Args, State> = () => AsyncActionContext<Args, State>;

export const AsyncActionContext = React.createContext<any>({});

export type AsyncActionContext<Args = any, State = any> = {
  context: Args;
  getState(): State;
  state: State;
  dispatch: Dispatch;
  cache?: Cache<any>;
  mutate?: Mutate;
  getAsyncActionContext?(): AsyncActionContext<Args, State>;
};

export const useAsyncActionWithoutState = <T extends any[], U>(
  thunk: (a: AsyncActionContext) => (...args: T) => Promise<U>
): EnhancedAsyncAction<T, U> => {
  const dispatch = useDispatch();
  const context = useContext(AsyncActionContext);
  const store = useStore();
  const [result, setResult] = useRefState<U | {}>({});
  const { cache, mutate } = useSWRConfig();

  return useMemo(() => {
    let isSkipCallback = false;
    const ret: EnhancedAsyncAction<T> = async (...args: T) => {
      const getAsyncActionContext = () => ({
        context,
        state: store.getState(),
        getState: () => store.getState(),
        dispatch,
        cache: cache as Cache<any>,
        mutate,
        getAsyncActionContext,
      });
      const t = thunk(getAsyncActionContext());
      if (typeof t !== 'function') {
        throw new Error(
          `The function passed to useAsyncAction must return redux Action, but got ${typeof t}`
        );
      }
      try {
        const r = await t(...args);
        setResult(r);
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (ret?.succeededCallback ?? (() => {}))(...args);
        }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (ret?.failedCallback ?? (() => {}))(...args);
        }
      } finally {
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (ret?.exitHook ?? (() => {}))(...args);
        }
      }
    };

    ret.loading = false;
    ret.result = result.current;
    ret.error = null;
    ret.skipCallbacks = async (...args: T) => {
      isSkipCallback = true;
      return ret(...args);
    };

    return ret;
  }, [thunk]);
};

export const useAsyncAction = <T extends any[], U>(
  thunk: (a: AsyncActionContext) => (...args: T) => Promise<U>
): EnhancedAsyncAction<T, U> => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useRefState(false);
  const [result, setResult] = useRefState<U | {}>({});
  const [error, setError] = useRefState<any | null>(null);
  const context = useContext(AsyncActionContext);
  const store = useStore();
  const { cache, mutate } = useSWRConfig();

  return useMemo(() => {
    let isSkipCallback = false;
    const ret = async (...args: T) => {
      setLoading(true);
      const getAsyncActionContext = () => ({
        context,
        state: store.getState(),
        getState: () => store.getState(),
        dispatch,
        cache: cache as Cache<any>,
        mutate,
        getAsyncActionContext,
      });
      const t = thunk(getAsyncActionContext());
      if (typeof t !== 'function') {
        throw new Error(
          `The function passed to useAsyncAction must return redux Action, but got ${typeof t}`
        );
      }
      try {
        const r = await t(...args);
        setResult(r);
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ((ret as EnhancedAsyncAction<T>)?.succeededCallback ?? (() => {}))(...args);
        }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        setError(e);
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ((ret as EnhancedAsyncAction<T>)?.failedCallback ?? (() => {}))(...args);
        }
      } finally {
        if (!isSkipCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ((ret as EnhancedAsyncAction<T>)?.exitHook ?? (() => {}))(...args);
        }
        setLoading(false);
      }
    };

    Object.defineProperties(ret, {
      loading: {
        get() {
          return loading.current;
        },
      },
      result: {
        get() {
          return result.current;
        },
      },
      error: {
        get() {
          return error.current;
        },
      },
      skipCallbacks: {
        value: async (...args: T) => {
          isSkipCallback = true;
          return ret(...args);
        },
      },
    });

    return ret as EnhancedAsyncAction<T>;
  }, [thunk]);
};

export const useBeforeMount = (callback: () => any) => {
  const [isMounted, setMounted] = useRefState(false);
  const [isCalled, setCalled] = useRefState(false);
  let resultCallback: (() => void) | null = null;
  if (!isMounted.current && !isCalled.current) {
    resultCallback = callback();
    setCalled(true);
  }
  useEffect(() => {
    setMounted(true);
    if (typeof resultCallback === 'function') {
      return resultCallback;
    }
  }, []);
};

export const usePreviousValue = <T>(cb: (oldValue: T, newValue: T) => any, oldValue: T) => {
  const [oldState, updateOldValue] = useRefState(oldValue);

  useEffect(() => {
    if (!Object.is(oldState.current, oldValue)) {
      cb(oldState.current, oldValue);
      updateOldValue(oldValue);
    }
  }, [oldValue]);
};

export const useWidgetMobileAdaption = ({
  type,
  environment,
}: {
  type: WidgetType;
  environment: Environment;
}) => {
  const defaultOverflowValue = useRef({
    body: '',
    html: '',
    scrollTop: 0,
    rootScrollTop: 0,
    height: '',
  });

  return (isChatOpen: boolean) => {
    if (environment.isSupportedMobileBrowser && type === WidgetType.FLOATING) {
      if (isChatOpen) {
        defaultOverflowValue.current.scrollTop = document.body.scrollTop;
        defaultOverflowValue.current.rootScrollTop = document.documentElement.scrollTop;
        defaultOverflowValue.current.html = document.documentElement.style.overflow;
        defaultOverflowValue.current.height = document.body.style.height;
        defaultOverflowValue.current.body = document.body.style.overflow;
        document.documentElement.style.setProperty('overflow', 'hidden', 'important');
        document.body.style.setProperty('overflow', 'hidden', 'important');
        document.body.style.setProperty('height', '100%', 'important');
      } else {
        document.documentElement.style.overflow = defaultOverflowValue.current.html;
        document.body.style.overflow = defaultOverflowValue.current.body;
        forceLayout(document.body);
        document.documentElement.scrollTop = defaultOverflowValue.current.rootScrollTop;
        document.body.scrollTop = defaultOverflowValue.current.scrollTop;
        document.body.style.height = defaultOverflowValue.current.height;
      }
    }
  };
};

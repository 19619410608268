export interface DialogEngineStoryRevisionHistorySpecification {
  isValidName(name: string): [boolean, string];
  isValidNote(note: string): [boolean, string];
}

export class DialogEngineStoryRevisionHistoryValidator
  implements DialogEngineStoryRevisionHistorySpecification
{
  isValidName(name: string): [boolean, string] {
    if (name.length === 0) {
      return [false, '名前は必須です'];
    } else if (name.length > 1000) {
      return [false, '名前は1000文字以下です'];
    }
    return [true, ''];
  }

  isValidNote(note: string): [boolean, string] {
    if (note.length > 1000) {
      return [false, 'noteは1000文字以下です'];
    }
    return [true, ''];
  }
}

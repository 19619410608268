import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  addConversationEventSucceeded,
  addConversationSucceeded,
  addVoiceConversationLabelSucceeded,
  deleteConversationEventSucceeded,
  deleteConversationSucceeded,
  deleteVoiceConversationLabelSucceeded,
  getConversationEventSucceeded,
  getConversationSucceeded,
  getEntityHeadersSucceeded,
  getRecordingInfoSucceeded,
  getVoiceSettingSucceeded,
  modifyConversationEventSucceeded,
  modifyConversationSucceeded,
  updateVoiceSettingSucceeded,
  voiceCustomerNoteUpdated,
  voiceConversationNoteUpdated,
  voiceEntityUpdated,
  voiceErrorUpdated,
  getConversationsSucceeded,
} from '@c/modules/voice/action';
import { formatVoiceEntity, VoiceEntity } from '@c/domain/entities/voice/Conversation';

type ActionTypes = ReturnType<
  | typeof getConversationSucceeded
  | typeof getConversationsSucceeded
  | typeof getEntityHeadersSucceeded
  | typeof getConversationEventSucceeded
  | typeof voiceCustomerNoteUpdated
  | typeof voiceConversationNoteUpdated
  | typeof addConversationSucceeded
  | typeof modifyConversationSucceeded
  | typeof deleteConversationSucceeded
  | typeof addConversationEventSucceeded
  | typeof voiceEntityUpdated
  | typeof addVoiceConversationLabelSucceeded
  | typeof deleteVoiceConversationLabelSucceeded
  | typeof modifyConversationEventSucceeded
  | typeof deleteConversationEventSucceeded
  | typeof getRecordingInfoSucceeded
  | typeof getVoiceSettingSucceeded
  | typeof updateVoiceSettingSucceeded
  | typeof voiceErrorUpdated
>;

export default (
  state: State['voice'] = initialState.voice,
  action: ActionTypes
): State['voice'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'GET_CONVERSATIONS_SUCCEEDED':
        state[action.payload.type].conversation = action.payload.conversationEntities;
        return;
      case 'GET_CONVERSATION_SUCCEEDED':
        state.callLogDetail.conversation = action.payload.conversationEntities;
        return;
      case 'GET_ENTITY_HEADERS_SUCCEEDED':
        state['callLog'].entityHeaders = action.payload.entityHeaders;
        return;
      case 'ADD_CONVERSATION_SUCCEEDED':
        const conversation = state[action.payload.type].conversation.filter(
          c => c.id === action.payload.conversationEntity.id
        );
        if (conversation.length === 0) {
          const index = state[action.payload.type].conversation.findIndex(
            c =>
              c.createdAt.toDate().getTime() <
              action.payload.conversationEntity.createdAt.toDate().getTime()
          );
          if (index < 0) {
            state[action.payload.type].conversation.push(action.payload.conversationEntity);
          } else {
            state[action.payload.type].conversation.splice(
              index,
              0,
              action.payload.conversationEntity
            );
          }
        }
        return;
      case 'MODIFY_CONVERSATION_SUCCEEDED': {
        const conversationIndex = state[action.payload.type].conversation.findIndex(
          c => c.id === action.payload.conversationEntity.id
        );
        if (conversationIndex > -1) {
          state[action.payload.type].conversation[conversationIndex] =
            action.payload.conversationEntity;
        }
        return;
      }
      case 'DELETE_CONVERSATION_SUCCEEDED': {
        const conversationIndex = state[action.payload.type].conversation.findIndex(
          c => c.id === action.payload.conversationEntity.id
        );
        if (conversationIndex > -1) {
          state[action.payload.type].conversation.splice(conversationIndex, 1);
        }
        return;
      }
      case 'GET_CONVERSATION_EVENT_SUCCEEDED':
        state.conversationEvent = action.payload;
        return;
      case 'ADD_CONVERSATION_EVENT_SUCCEEDED':
        const conversationEvent = state.conversationEvent.filter(e => e.id === action.payload.id);
        if (conversationEvent.length === 0) {
          state.conversationEvent.push(action.payload);
        }
        return;
      case 'MODIFY_CONVERSATION_EVENT_SUCCEEDED': {
        const eventIndex = state.conversationEvent.findIndex(e => e.id === action.payload.id);
        if (eventIndex > -1) {
          state.conversationEvent[eventIndex] = action.payload;
        }
        return;
      }
      case 'DELETE_CONVERSATION_EVENT_SUCCEEDED': {
        const eventIndex = state.conversationEvent.findIndex(e => e.id === action.payload.id);
        if (eventIndex > -1) {
          state.conversationEvent.splice(eventIndex, 1);
        }
        return;
      }
      case 'VOICE_CUSTOMER_NOTE_UPDATED':
        if (state.callLogDetail.conversation)
          state.callLogDetail.conversation.voiceCustomer.note = action.payload.note ?? '';
        return;
      case 'VOICE_CONVERSATION_NOTE_UPDATED':
        const conversationState = state[action.payload.type].conversation
          .filter(c => c.voiceCustomer.id === action.payload.voiceCustomerId)
          .find(c => c.id === action.payload.conversationId);
        if (conversationState) {
          conversationState.note = action.payload.note;
        }
        return;
      case 'VOICE_ENTITY_UPDATED': {
        const updatedConversation = state[action.payload.type].conversation.find(conversation => {
          return conversation.id === action.payload.conversationId;
        });
        if (updatedConversation) {
          const entity: VoiceEntity = {};
          action.payload.conversationEntities.map(val => {
            entity[val.name] = val.value || val.values;
          });
          updatedConversation.entity = entity;
          updatedConversation.formattedEntity = formatVoiceEntity(entity);
        }

        const updatedConversationEvent = state.conversationEvent.find(event => {
          return event.id === action.payload.eventId;
        });
        if (updatedConversationEvent) {
          const eventEntity: VoiceEntity = {};
          action.payload.conversationEventsEntities.map(val => {
            eventEntity[val.name] = val.value || val.values;
          });
          updatedConversationEvent.entity = eventEntity;
          const seekTime =
            updatedConversationEvent.formattedEntity &&
            updatedConversationEvent.formattedEntity.length > 0
              ? updatedConversationEvent.formattedEntity[0].seekTime
              : undefined;
          updatedConversationEvent.formattedEntity = formatVoiceEntity(eventEntity, seekTime);
        }
        return;
      }
      case 'ADD_VOICE_CONVERSATION_LABEL_SUCCEEDED': {
        if (Array.isArray(action.payload.conversation)) {
          for (const c of action.payload.conversation) {
            const updatedConversation = state[action.payload.type].conversation.find(
              conversation => {
                return conversation.id === c.id;
              }
            );
            if (!updatedConversation) {
              return;
            }
            if (!updatedConversation.labelIds) {
              updatedConversation.labelIds = [];
            }
            if (!updatedConversation.labelIds.includes(action.payload.labelId)) {
              updatedConversation.labelIds.push(action.payload.labelId);
            }
          }
        } else {
          if (state.callLogDetail.conversation) {
            // NOTE:
            // ラベルが未付与の会話の場合、firestore の schema に labelIds field がないので、
            // undefined の場合は配列を初期化してlabelIDを追加する
            if (state.callLogDetail.conversation.labelIds) {
              state.callLogDetail.conversation.labelIds.push(action.payload.labelId);
            } else {
              state.callLogDetail.conversation.labelIds = [action.payload.labelId];
            }
          }
        }
        return;
      }
      case 'DELETE_VOICE_CONVERSATION_LABEL_SUCCEEDED': {
        // FIXME: callLog と callLogDetail で action を分ける

        // CallLogDetail からのラベル削除
        const updatedConversationInCallLogDetail = state.callLogDetail.conversation;
        if (updatedConversationInCallLogDetail) {
          updatedConversationInCallLogDetail.labelIds =
            updatedConversationInCallLogDetail.labelIds?.filter(labelId => {
              return labelId !== action.payload.labelId;
            });
        }

        // CallLog からのラベル削除
        const updatedConversationInCallLog = state.callLog.conversation.find(conversation => {
          return conversation.id === action.payload.conversation.id;
        });
        if (updatedConversationInCallLog) {
          updatedConversationInCallLog.labelIds = updatedConversationInCallLog.labelIds?.filter(
            labelId => {
              return labelId !== action.payload.labelId;
            }
          );
        }

        return;
      }
      case 'GET_VOICE_RECORDING_INFO_SUCCEEDED': {
        state.recordingInfo = action.payload;
        return;
      }
      case 'GET_VOICE_SETTING_SUCCEEDED':
        state.setting = action.payload;
        state.didGetSetting = true;
        return;
      case 'UPDATE_VOICE_SETTING_SUCCEEDED':
        state.setting = action.payload;
        return;
      case 'VOICE_ERROR_UPDATED':
        state.error = action.payload;
        return;
    }
  });
};

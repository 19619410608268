import { GettableQuery } from '../interface';
import { ENV } from '@c/config';
import { ConversationEvent } from '@c/domain/entities/voice/ConversationEvent';
import { formatVoiceEntity } from '@c/domain/entities/voice/Conversation';
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';

export type ConversationEventGettableQuery = GettableQuery<
  {
    tenantId: string;
    voiceCustomerId: string;
    projectId: string;
    conversationId: string;
  },
  ConversationEvent[]
>;

export class ConversationEventQuery implements ConversationEventGettableQuery {
  public constructor() {}

  public async get({
    tenantId,
    voiceCustomerId,
    projectId,
    conversationId,
  }: Parameters<ConversationEventGettableQuery['get']>[0]): ReturnType<
    ConversationEventGettableQuery['get']
  > {
    const db = getFirestore();

    const conversation_ = doc(
      db,
      ENV,
      tenantId,
      'customers',
      voiceCustomerId,
      'projects',
      projectId,
      'conversations',
      conversationId
    );

    const conversation = (await getDoc(conversation_)).data();
    if (!conversation) return [];

    const startTime = conversation['created_at'].toMillis();

    const collectinRef = collection(
      db,
      ENV,
      tenantId,
      'customers',
      voiceCustomerId,
      'projects',
      projectId,
      'conversations',
      conversationId,
      'conversation_events'
    );

    const q = query(collectinRef, orderBy('created_at', 'asc'));

    const conversationEventsQuery = await getDocs(q);

    const conversationEvents = conversationEventsQuery.docs.map(doc => {
      const conversationEvent = {
        id: doc.id,
        ...doc.data(),
        senderType: doc.data()['sender_type'],
        createdAt: doc.data()['created_at'],
        seekTime: (doc.data()['created_at'].toMillis() - startTime) / 1000,
        formattedEntity:
          doc.data().entity &&
          formatVoiceEntity(
            doc.data().entity,
            (doc.data()['created_at'].toMillis() - startTime) / 1000
          ),
      } as ConversationEvent;
      return conversationEvent;
    });
    return conversationEvents;
  }
}

import { UserEntity } from '@c/domain/entities/UserEntity';
import { ENV } from '@c/config';
import { TenantId } from '@c/domain/entities/TenantId';
import { AuthEntity } from '@c/domain/entities/AuthEntity';
import { GettableQuery } from './interface';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export type UsersGettableQuery = GettableQuery<
  { tenantId: TenantId; self: AuthEntity },
  UserEntity[]
>;

export class UsersQuery implements UsersGettableQuery {
  public constructor(private readonly checkLoginState: () => Promise<void>) {}

  public async get({
    tenantId,
    self,
  }: Parameters<UsersGettableQuery['get']>[0]): ReturnType<UsersGettableQuery['get']> {
    await this.checkLoginState();
    const db = getFirestore();
    const q = query(
      collection(db, ENV, tenantId, 'operators'),
      where('visibility_level', '<=', self.visibilityLevel)
    );

    const docsData = await getDocs(q);
    const usersCollection = docsData.docs.map(doc => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const {
        email,
        verified,
        display_name: displayName,
        uid,
        auth_time: { seconds: authTime } = { seconds: -1 },
        issued_at: { seconds: issuedAt } = { seconds: -1 },
        email_verified: emailVerified,
        disabled,
      } = doc.data();
      /* eslint-enable @typescript-eslint/naming-convention */

      return {
        id: doc.id,
        uid: uid,
        displayName,
        email,
        verified,
        authTime: authTime * 1000,
        issuedAt: issuedAt * 1000,
        emailVerified,
        disabled,
      };
    });
    return usersCollection;
  }
}

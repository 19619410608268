/**
 * @fileOverview
 * @name Link.tsx
 * @author Taketoshi Aono
 * @license
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { regularTextStyle } from '@s/components/atom/Text';

const ContainerElement = styled.span`
  > button {
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
  }
  > button,
  > a {
    ${regularTextStyle};
    color: #0066ff;
    text-decoration: none;
    transition: all 0.2s;
    outline: none;
    &:hover {
      color: #0099ff;
    }
    &:focus {
      color: #0099ff;
      border-radius: 8px;
      box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6);
      padding: 5px;
    }
  }
`;

export const InternalLink = compareOnlyProperties(
  ({ to, children }: { to: string; children: React.ReactChild }) => {
    const history = useHistory();
    return (
      <ContainerElement>
        <button tabIndex={0} onClick={() => history.push(to)}>
          {children}
        </button>
      </ContainerElement>
    );
  },
  'InternalLink'
);

export const ExternalLink = compareOnlyProperties(
  ({ to, children }: { to: string; children: React.ReactChild }) => {
    return (
      <ContainerElement>
        <a href={to} tabIndex={0}>
          {children}
        </a>
      </ContainerElement>
    );
  },
  'ExternalLink'
);

/**
 * @fileOverview
 * @name color.ts
 * @author Taketoshi Aono
 * @license
 */

const SHARP = /^#/;
export const hexToRgba = (hex: string, a: number): string => {
  hex = hex.replace(SHARP, '');
  if (hex.length === 3) {
    hex = hex.replace(/./g, a => {
      return `${a}${a}`;
    });
  }

  if (hex.length !== 6) {
    throw new Error(`Invalid hex value ${hex}`);
  }

  const splited = hex.split('');
  const nums: number[] = [];
  for (let i = 0; i < 6; i += 2) {
    nums.push(parseInt(`${splited[i]}${splited[i + 1]}`, 16));
  }

  return `rgba(${nums.join(',')}, ${a})`;
};

const HEX_REGEX = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i;
const hexToRgb = (
  hex: string,
  a = 1
): { red: number; green: number; blue: number; alpha: number } | null => {
  if (hex.length !== 4 && hex.length !== 7) {
    return null;
  }
  const result = HEX_REGEX.exec(hex);
  if (!result || result.length < 4) {
    return null;
  }
  let [, r, g, b] = result as any;
  if (hex.length === 4) {
    r += r;
    g += g;
    b += b;
  }
  return {
    red: parseInt(r, 16),
    green: parseInt(g, 16),
    blue: parseInt(b, 16),
    alpha: a,
  };
};

// from https://note.kiriukun.com/entry/20181206-rgb-and-hsl-conversion
const RGB_MAX = 255;
const HUE_MAX = 360;
const SATURATION_MAX = 100;
const LIGHTNESS_MAX = 100;
export const hslToRgb = (
  h: number,
  s: number,
  l: number
): { red: number; green: number; blue: number } => {
  let r;
  let g;
  let b;
  let max;
  let min;

  h = h % HUE_MAX;
  s = s / SATURATION_MAX;
  l = l / LIGHTNESS_MAX;

  if (l < 0.5) {
    max = l + l * s;
    min = l - l * s;
  } else {
    max = l + (1 - l) * s;
    min = l - (1 - l) * s;
  }

  const hp = HUE_MAX / 6;
  const q = h / hp;
  if (q <= 1) {
    r = max;
    g = (h / hp) * (max - min) + min;
    b = min;
  } else if (q <= 2) {
    r = ((hp * 2 - h) / hp) * (max - min) + min;
    g = max;
    b = min;
  } else if (q <= 3) {
    r = min;
    g = max;
    b = ((h - hp * 2) / hp) * (max - min) + min;
  } else if (q <= 4) {
    r = min;
    g = ((hp * 4 - h) / hp) * (max - min) + min;
    b = max;
  } else if (q <= 5) {
    r = ((h - hp * 4) / hp) * (max - min) + min;
    g = min;
    b = max;
  } else {
    r = max;
    g = min;
    b = ((HUE_MAX - h) / hp) * (max - min) + min;
  }

  return {
    red: Math.round(r * RGB_MAX),
    green: Math.round(g * RGB_MAX),
    blue: Math.round(b * RGB_MAX),
  };
};

export const chooseTextColor = (hex: string): string => {
  const ret = hexToRgb(hex);
  if (ret) {
    return chooseTextColorFromRgb(ret);
  }
  return 'black';
};

export const chooseTextColorFromRgb = ({
  red,
  green,
  blue,
}: {
  red: number;
  green: number;
  blue: number;
}): 'white' | 'black' => {
  // sRGB を RGB に変換し、背景色の相対輝度を求める
  const toRgbItem = (item: number) => {
    const i = item / 255;
    return i <= 0.03928 ? i / 12.92 : Math.pow((i + 0.055) / 1.055, 2.4);
  };
  const R = toRgbItem(red);
  const G = toRgbItem(green);
  const B = toRgbItem(blue);
  const lbg = 0.2126 * R + 0.7152 * G + 0.0722 * B;

  // 白と黒の相対輝度。定義からそれぞれ 1 と 0 になる。
  const lw = 1;
  const lb = 0;

  // 白と背景色のコントラスト比、黒と背景色のコントラスト比を
  // それぞれ求める。
  const cw = (lw + 0.05) / (lbg + 0.05);
  const cb = (lbg + 0.05) / (lb + 0.05);

  // コントラスト比が大きい方を文字色として返す。
  return cw < cb ? 'black' : 'white';
};

import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';

export type CarouselGettableQuery = GettableQuery<{ projectId: string }, Carousel[]>;
type Carousels = {
  carousels: Carousel[];
};
export class CarouselQuery implements CarouselGettableQuery {
  public async get({
    projectId,
  }: Parameters<CarouselGettableQuery['get']>[0]): ReturnType<CarouselGettableQuery['get']> {
    const ret = await fetchService<Carousels>(apiEndpoint(`project/${projectId}/carousel/list`), {
      method: 'GET',
      responseType: 'json',
    });
    return ret.carousels;
  }
}

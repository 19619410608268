import { initialState, State } from '@c/state';
import {
  addTwilioProjectRelationSucceeded,
  deleteTwilioProjectRelationSucceeded,
  getTwilioProjectRelationSucceeded,
  resetTwilioProjectRelationSucceeded,
  updateTwilioProjectRelationSucceeded,
} from '@c/modules/twilioProjectRelation/action';
import produce from 'immer';

type ActionTypes = ReturnType<
  | typeof getTwilioProjectRelationSucceeded
  | typeof addTwilioProjectRelationSucceeded
  | typeof updateTwilioProjectRelationSucceeded
  | typeof deleteTwilioProjectRelationSucceeded
  | typeof resetTwilioProjectRelationSucceeded
>;

export default (
  state: State['dialogEngine'] = initialState.dialogEngine,
  action: ActionTypes
): State['dialogEngine'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'GET_TWILIO_PROJECT_RELATION_SUCCEEDED':
        state.twilioProjectRelation = action.payload;
        return;
      case 'ADD_TWILIO_PROJECT_RELATION_SUCCEEDED':
        if (
          !state.twilioProjectRelation.find(
            projectRelation => projectRelation.id === action.payload.twilioProjectRelation.id
          )
        ) {
          state.twilioProjectRelation.push(action.payload.twilioProjectRelation);
        }
        return;
      case 'UPDATE_TWILIO_PROJECT_RELATION_SUCCEEDED':
        state.twilioProjectRelation = state.twilioProjectRelation.map(projectRelation => {
          if (projectRelation.id === action.payload.twilioProjectRelation.id) {
            return action.payload.twilioProjectRelation;
          }
          return projectRelation;
        });
        return;
      case 'DELETE_TWILIO_PROJECT_RELATION_SUCCEEDED': {
        state.twilioProjectRelation = state.twilioProjectRelation.filter(projectRelation => {
          return projectRelation.id !== action.payload.id;
        });
        return;
      }
      case 'RESET_TWILIO_PROJECT_RELATION_SUCCEEDED':
        state.twilioProjectRelation = [];
        return;
      default:
        return state;
    }
  });
};

/**
 * @fileOverview
 * @author
 */

import { ScenarioEditorCommonState, State } from '@c/state';
import {
  edgeConnected,
  initialEdgeConnected,
  messageAdded,
  messageDeleted,
  messageUpdated,
  textInputStateUpdated,
  sceneDeleted,
  quickRepliesAdded,
  quickRepliesDeleted,
  quickRepliesOrderChanged,
  quickRepliesTextUpdated,
  sceneSelected,
  edgeDeleted,
  integrationEndpointUpdated,
  integrationMethodUpdated,
  integrationHeaderUpdated,
  integrationConditionAdded,
  integrationHeaderAdded,
  integrationHeaderDeleted,
  integrationConditionDeleted,
  integrationConditionUpdated,
  parameterUpdated,
  parameterDeleted,
  sceneParameterUpdated,
  toAddressUpdated,
  toAddressAdded,
  toAddressDeleted,
  replyToUpdated,
  subjectUpdated,
  bodyUpdated,
  carouselUpdated,
} from '@c/modules/scenarioEditor/action';
import { v4 } from 'uuid';
import {
  MessageFactory,
  updateMessageEntityText,
  ImageMessageEntity,
} from '@c/domain/entities/MessageEntity';
import {
  SceneMessageType,
  SceneDimensionCache,
  createDefaultNodeFromType,
  addCustomFeedbackToSceneNode,
  getAllEdgesFromSceneNode,
  setParameterToSceneNode,
  MessageNode,
  NodeButton,
  CarouselSceneButton,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { addSceneError, removeSceneError } from '@c/domain/values/PromoteErrors';
import {
  cleanupFallback,
  isSceneHasOrphanButton,
  findDuplicateOrEmptyButton,
  isOrphanScene,
  isSceneHasNoMessages,
  isSceneHasInvalidMessage,
  isInitiableScene,
  isMessageEditableScene,
  isButtonEditableScene,
  isTextInputStateEditableScene,
  isEndpointEditableScene,
  isMethodEditableScene,
  isHeadersEditableScene,
  isConditionEditableScene,
  isToAddressEditableScene,
  correctFromEdges,
  isReplyToEditableScene,
  isCarouselEditableScene,
} from '@c/domain/specification/SceneNodeSpecification';
import { ActionPayloadWithNs, ActionPayloadWithoutNs } from '@s/typeUtils';
import { required } from '@s/assertions';
import { swap } from '@s/swap';
import { SceneType } from '../value/SceneType';
import { ProjectEntity } from '@c/domain/entities/Project';

export class ScenarioEditorService {
  public addIntegrationCondition(
    state: ScenarioEditorCommonState,
    { sceneId, label }: ActionPayloadWithoutNs<typeof integrationConditionAdded>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isConditionEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    scene.integration.succeeded.quickReplies?.push({
      id: `_Temporary_${v4()}_${scene.integration.succeeded.quickReplies.length}_${Date.now()}`,
      type: 'text',
      label: label,
      edge: '',
    });
    addSceneError({
      sceneId: scene.id,
      code: 'NO_DEST_QUICK_REPLY',
      errors: state.errors,
    });
    addSceneError({
      sceneId: scene.id,
      code: 'EMPTY_BUTTON_TEXT',
      errors: state.errors,
    });
  }

  public addIntegrationHeader(
    state: ScenarioEditorCommonState,
    { sceneId, header }: ActionPayloadWithoutNs<typeof integrationHeaderAdded>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isHeadersEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    Object.assign(scene.integration.request.header, header);
  }

  public addMailToAddress(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithoutNs<typeof toAddressAdded>
  ) {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isToAddressEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed, got ${scene.type}`);
    }

    scene.mail.to.push({ name: '', address: '' });
  }

  public addMessage(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithoutNs<typeof messageAdded>,
    idGen: () => string = v4
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (scene.type !== 'message') {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.response.messages.push({
      attachment: MessageFactory.text('').attachment,
      id: `_Temporary_${idGen()}`,
    });
    removeSceneError({
      sceneId: scene.id,
      code: 'EMPTY_RESPONSE_MESSAGES',
      errors: state.errors,
    });
  }

  public addScene(
    state: ScenarioEditorCommonState,
    option: { x: number; y: number; type: SceneType; projectType: State['env']['type'] }
  ): void {
    if (!state.selectedScenario) {
      return;
    }

    const scene = createDefaultNodeFromType(option.type);
    if (
      scene.type === 'message' &&
      (option.projectType === 'instagram' || option.projectType === 'line')
    ) {
      scene.textInputState = 'show';
    }
    if (scene.type === 'callOperator') {
      scene.response.messages[0].attachment.payload = state.systemMessages.callingOperator;
    }
    state.selectedScenario.scenes.nodes.insert(scene);
    (state.selectedScenario.scenes as any).dimensions[scene.id] = {
      x: option.x,
      y: option.y,
    } as any;
    state.errors[scene.id] = { size: 0 };

    if (!state.selectedScenario.scenes.nodes.hasInitialScene() && isInitiableScene(scene)) {
      state.selectedScenario.scenes.nodes.setInitialScene(scene.id);
    } else {
      addSceneError({
        sceneId: scene.id,
        code: 'NO_FROM_EDGE_NODE',
        errors: state.errors,
      });
    }
    if (isMessageEditableScene(scene)) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    } else if (isCarouselEditableScene(scene)) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_CAROUSEL',
        errors: state.errors,
      });
      addSceneError({
        sceneId: scene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    }
  }

  public changeMessageType(
    state: ScenarioEditorCommonState,
    { type, id }: { type: SceneMessageType; id: string }
  ): void {
    if (!state.selectedScenario) {
      return;
    }
    const node = state.selectedScenario.scenes.nodes.getSelectedNode();
    if (!node) {
      throw new Error(`Scene not selected. Something wrong.`);
    }
    if (!isMessageEditableScene(node)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${node.type}`);
    }

    const message = required(node.response.messages.find(m => m.id === id));
    (message.attachment as any) = MessageFactory.createFromType(type).attachment;

    const { hasEmptyImage, hasEmptyMessage } = isSceneHasInvalidMessage(node);

    if (!hasEmptyImage) {
      removeSceneError({
        sceneId: node.id,
        code: 'EMPTY_IMAGE_URL',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: node.id,
        code: 'EMPTY_IMAGE_URL',
        errors: state.errors,
      });
    }

    if (!hasEmptyMessage) {
      removeSceneError({
        sceneId: node.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: node.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    }
  }

  public connectEdge(
    state: ScenarioEditorCommonState,
    { fromSceneId, buttonId, toSceneId }: ActionPayloadWithoutNs<typeof edgeConnected>
  ): void {
    const fromScene = required(state.selectedScenario?.scenes.nodes.find(fromSceneId));
    const toScene = required(state.selectedScenario?.scenes.nodes.find(toSceneId));
    const button = required(getAllEdgesFromSceneNode(fromScene).find(q => q.id === buttonId));
    button.edge = toSceneId;

    if (isSceneHasOrphanButton(fromScene)) {
      addSceneError({
        sceneId: fromScene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: fromScene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    }

    const sameFromEdgesCount = toScene.fromEdges.reduce((acc, e) => {
      return acc + (e === fromSceneId ? 1 : 0);
    }, 0);
    correctFromEdges({ fromScene, toScene, sameFromEdgesCount });

    removeSceneError({
      sceneId: toSceneId,
      code: 'NO_FROM_EDGE_NODE',
      errors: state.errors,
    });

    cleanupFallback(fromScene);
    cleanupFallback(toScene);
    if (fromScene.type === 'message' && toScene.type === 'callOperator') {
      state.warnings.callOperatorConnectedWithoutBusinessHour = true;
    }
  }

  public connectInitialEdge(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithoutNs<typeof initialEdgeConnected>
  ): void {
    if (!state.selectedScenario) {
      return;
    }

    const oldInitialScene = state.selectedScenario.scenes.nodes.getInitialScene();
    const node = state.selectedScenario.scenes.nodes.find(sceneId);
    if (node && !isInitiableScene(node)) {
      return;
    }

    if (node) {
      cleanupFallback(node);
    }
    state.selectedScenario.scenes.nodes.setInitialScene(sceneId);

    removeSceneError({
      sceneId,
      errors: state.errors,
      code: 'NO_FROM_EDGE_NODE',
    });
    removeSceneError({
      sceneId: '',
      errors: state.errors,
      code: 'INITIAL_SCENE_NOT_FOUND',
    });

    if (oldInitialScene) {
      if (isOrphanScene(oldInitialScene)) {
        addSceneError({
          sceneId: oldInitialScene.id,
          errors: state.errors,
          code: 'NO_FROM_EDGE_NODE',
        });
      }
    }
  }

  public deleteEdge(
    state: ScenarioEditorCommonState,
    { sceneId, buttonId }: ActionPayloadWithoutNs<typeof edgeDeleted>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    let edge = '';
    const allEdges = getAllEdgesFromSceneNode(scene);
    if (
      allEdges.some(q => {
        if (q.id === buttonId) {
          edge = q.edge;
          q.edge = '';
          return true;
        }
      })
    ) {
      addSceneError({
        sceneId,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    }
    const toScene = required(state.selectedScenario?.scenes.nodes.find(edge));
    const fromEdgesIndex = toScene.fromEdges.indexOf(sceneId);
    cleanupFallback(scene);
    if (fromEdgesIndex > -1) {
      toScene.fromEdges.splice(fromEdgesIndex, 1);
    }
    if (isOrphanScene(toScene)) {
      addSceneError({
        sceneId: toScene.id,
        code: 'NO_FROM_EDGE_NODE',
        errors: state.errors,
      });
    }
  }

  public deleteIntegrationCondition(
    state: ScenarioEditorCommonState,
    { sceneId, conditionId }: ActionPayloadWithoutNs<typeof integrationConditionDeleted>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isConditionEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    scene.integration.succeeded.quickReplies = scene.integration.succeeded?.quickReplies?.filter(
      q => q.id !== conditionId
    );
    if (!isSceneHasOrphanButton(scene)) {
      removeSceneError({
        sceneId: scene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: scene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    }
    const { hasDuplication, hasEmptyButton } = findDuplicateOrEmptyButton(scene);
    if (hasDuplication) {
      addSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    }

    if (hasEmptyButton) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    }
  }

  public deleteIntegrationHeader(
    state: ScenarioEditorCommonState,
    { sceneId, headerFieldName }: ActionPayloadWithoutNs<typeof integrationHeaderDeleted>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isHeadersEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    delete scene.integration.request.header[headerFieldName];
  }

  public deleteMailToAddress(
    state: ScenarioEditorCommonState,
    { sceneId, index }: ActionPayloadWithoutNs<typeof toAddressDeleted>
  ) {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isToAddressEditableScene(scene)) {
      throw new Error(`Wrong scene type was passed. Got ${scene.type}`);
    }
    scene.mail.to.splice(index, 1);
  }

  public deleteMessage(
    state: ScenarioEditorCommonState,
    { sceneId, messageId }: ActionPayloadWithoutNs<typeof messageDeleted>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isMessageEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.response.messages = scene.response.messages.filter(v => v.id !== messageId);
    if (isSceneHasNoMessages(scene)) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_RESPONSE_MESSAGES',
        errors: state.errors,
      });
    }

    const { hasEmptyMessage, hasEmptyImage } = isSceneHasInvalidMessage(scene);

    if (!hasEmptyMessage) {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    } else if (!hasEmptyImage) {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_IMAGE_URL',
        errors: state.errors,
      });
    }
  }

  public deleteParameter(
    state: ScenarioEditorCommonState,
    { parameterIndex }: ActionPayloadWithoutNs<typeof parameterDeleted>
  ): void {
    if (!state.selectedScenario) {
      return;
    }
    const scenes = state.selectedScenario.scenes.nodes.filter(node => {
      return node.type === 'message' && node.paramId === state.parameters?.[parameterIndex].id;
    });
    scenes.forEach(scene => {
      delete (scene as MessageNode).paramId;
    });
    state.parameters?.splice(parameterIndex, 1);
  }

  public deleteScene(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithoutNs<typeof sceneDeleted>
  ): void {
    if (!state.selectedScenario) {
      return;
    }
    const { scenes } = state.selectedScenario;
    const { orphanScenes, noDestQuickRepliesScenes, newInitialScene } = scenes.nodes.delete(
      sceneId,
      scenes.dimensions as any as SceneDimensionCache
    );
    state.errors[scenes.id] = { size: 0 };
    orphanScenes.forEach(node => {
      addSceneError({
        sceneId: node.id,
        code: 'NO_FROM_EDGE_NODE',
        errors: state.errors,
      });
    });
    noDestQuickRepliesScenes.forEach(node => {
      addSceneError({
        sceneId: node.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    });
    if (newInitialScene) {
      removeSceneError({
        sceneId: newInitialScene.id,
        code: 'NO_FROM_EDGE_NODE',
        errors: state.errors,
      });
    }
  }

  public imageUrlChange(
    state: ScenarioEditorCommonState,
    { url, id }: { url: string; id: string }
  ): void {
    if (!state.selectedScenario) {
      return;
    }
    const node = state.selectedScenario.scenes.nodes.getSelectedNode();
    if (!node) {
      throw new Error(`Scene not selected. Something wrong.`);
    }
    if (!isMessageEditableScene(node)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${node.type}`);
    }

    const message = required(node.response.messages.find(m => m.id === id));
    const p = (message.attachment as ImageMessageEntity['attachment']).payload;
    p.previewUrl = p.url = url;

    const { hasEmptyImage } = isSceneHasInvalidMessage(node);

    if (!hasEmptyImage) {
      removeSceneError({
        sceneId: node.id,
        code: 'EMPTY_IMAGE_URL',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: node.id,
        code: 'EMPTY_IMAGE_URL',
        errors: state.errors,
      });
    }
  }

  public quickRepliesAdd(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithoutNs<typeof quickRepliesAdded>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isButtonEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.response.quickReplies.push({
      id: `_Temporary_${v4()}_${scene.response.quickReplies.length}_${Date.now()}`,
      type: 'text',
      label: '',
      edge: '',
    });
    addSceneError({
      sceneId: scene.id,
      code: 'NO_DEST_QUICK_REPLY',
      errors: state.errors,
    });
    addSceneError({
      sceneId: scene.id,
      code: 'EMPTY_BUTTON_TEXT',
      errors: state.errors,
    });
  }

  public quickRepliesDelete(
    state: ScenarioEditorCommonState,
    { sceneId, quickRepliesId }: ActionPayloadWithoutNs<typeof quickRepliesDeleted>
  ): void {
    const scene = required(required(state.selectedScenario).scenes.nodes.find(sceneId));
    if (!isButtonEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.response.quickReplies = scene.response.quickReplies.filter(q => q.id !== quickRepliesId);
    cleanupFallback(scene);
    if (!isSceneHasOrphanButton(scene)) {
      removeSceneError({
        sceneId: scene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: scene.id,
        code: 'NO_DEST_QUICK_REPLY',
        errors: state.errors,
      });
    }
    const { hasDuplication, hasEmptyButton } = findDuplicateOrEmptyButton(scene);
    if (hasDuplication) {
      addSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    }

    if (hasEmptyButton) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    }
  }

  public quickRepliesOrderChange(
    state: ScenarioEditorCommonState,
    { sceneId, quickRepliesId, isDown }: ActionPayloadWithoutNs<typeof quickRepliesOrderChanged>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isButtonEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    const index = scene.response.quickReplies.findIndex(q => q.id === quickRepliesId);
    if (isDown && index < scene.response.quickReplies.length - 1) {
      swap(scene.response.quickReplies, index, index + 1);
    } else if (!isDown && index >= 1) {
      swap(scene.response.quickReplies, index, index - 1);
    }
  }

  public quickRepliesTextUpdate(
    state: ScenarioEditorCommonState,
    { sceneId, quickRepliesId, value }: ActionPayloadWithoutNs<typeof quickRepliesTextUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isButtonEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    const q = required(scene.response.quickReplies.find(q => q.id === quickRepliesId));
    q.label = value;
    const { hasDuplication, hasEmptyButton } = findDuplicateOrEmptyButton(scene);
    if (hasDuplication) {
      addSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    }

    if (hasEmptyButton) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    }
  }

  public selectScene(
    state: ScenarioEditorCommonState,
    { sceneId }: ActionPayloadWithNs<typeof sceneSelected>
  ): void {
    state.selectedScenario?.scenes.nodes.select(sceneId);
  }

  public updateCarousel(
    state: ScenarioEditorCommonState,
    { sceneId, carousel }: ActionPayloadWithoutNs<typeof carouselUpdated>
  ): void {
    if (state.selectedScenario) {
      const node = state.selectedScenario.scenes.nodes.find(sceneId);
      if (node) {
        if (!isCarouselEditableScene(node)) {
          throw new Error(
            `Wrong scene node type passed IgCarouselNode expected, but got ${node.type}`
          );
        }
        node.carousel.id = carousel.id;
        node.carousel.displayName = carousel.displayName;
        node.carousel.elements = [];
        node.carousel.contents = [];
        for (const element of carousel.elements) {
          const buttons: CarouselSceneButton[] = [];
          const buttonsView: NodeButton[] = [];
          for (const button of element.buttons || []) {
            if (button.type === 'postback') {
              buttons.push({
                title: button.title,
                edge: '',
              });
              buttonsView.push({
                isActionButton: true,
                id: `_Temporary_${v4()}_${Date.now()}`,
                type: 'postback',
                label: button.title,
                edge: '',
              });
            }
          }
          node.carousel.elements.push({
            id: element.id,
            title: element.title,
            buttons,
            buttonsView,
          });
          node.carousel.contents = [
            ...node.carousel.contents,
            {
              id: `_Temporary_${v4()}_${Date.now()}`,
              attachment: {
                mediaType: 'carousel',
                payload: MessageFactory.carousel({
                  buttons:
                    element.buttons
                      ?.filter(button => button.type === 'postback')
                      .map(button => {
                        return { title: button.title };
                      }) || [],
                  title: element.title,
                }).attachment.payload,
              },
            },
          ];
        }

        if (isSceneHasOrphanButton(node)) {
          addSceneError({
            sceneId: sceneId,
            code: 'NO_DEST_QUICK_REPLY',
            errors: state.errors,
          });
        } else {
          removeSceneError({
            sceneId: sceneId,
            code: 'NO_DEST_QUICK_REPLY',
            errors: state.errors,
          });
        }
      }

      removeSceneError({
        sceneId: sceneId,
        code: 'EMPTY_CAROUSEL',
        errors: state.errors,
      });
    }
  }

  public updateCustomFeedback(state: ScenarioEditorCommonState, isFeedbackOn: boolean): void {
    if (state.selectedScenario) {
      const node = state.selectedScenario.scenes.nodes.getSelectedNode();
      if (node && node.type === 'message') {
        if (isFeedbackOn) {
          addCustomFeedbackToSceneNode(node);
        } else {
          delete node.customFeedbackView;
        }
      }
    }
  }

  public updateFallbackUsage(
    state: ScenarioEditorCommonState,
    isEnabled: boolean,
    projectType: ProjectEntity['type'] | ''
  ): void {
    if (state.selectedScenario) {
      const node = state.selectedScenario.scenes.nodes.getSelectedNode();
      if (node && node.type === 'message') {
        if (isEnabled) {
          node.shouldUseFallbackView = true;
          node.response.quickReplies = [];
          node.textInputState = 'hide';
          delete node.customFeedbackView;
          addSceneError({
            sceneId: node.id,
            code: 'NO_DEST_QUICK_REPLY',
            errors: state.errors,
          });
        } else {
          if (
            !!node.fallbackView.edge &&
            !!state.selectedScenario?.scenes.nodes.find(node.fallbackView.edge)
          ) {
            this.deleteEdge(state, { sceneId: node.id, buttonId: 'fallback' });
          }
          node.shouldUseFallbackView = false;
          node.fallbackView.edge = '';
          if (projectType === 'line') {
            node.textInputState = 'show';
          }
          removeSceneError({
            sceneId: node.id,
            code: 'NO_DEST_QUICK_REPLY',
            errors: state.errors,
          });
        }
      }
    }
  }

  public updateIntegrationCondition(
    state: ScenarioEditorCommonState,
    { sceneId, conditionId, condition }: ActionPayloadWithoutNs<typeof integrationConditionUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isConditionEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const q = required(scene.integration.succeeded?.quickReplies?.find(q => q.id === conditionId));
    q.label = condition;
    const { hasDuplication, hasEmptyButton } = findDuplicateOrEmptyButton(scene);
    if (hasDuplication) {
      addSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'DUPLICATE_BUTTON_TEXT',
        errors: state.errors,
      });
    }

    if (hasEmptyButton) {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    } else {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_BUTTON_TEXT',
        errors: state.errors,
      });
    }
  }

  public updateIntegrationEndpoint(
    state: ScenarioEditorCommonState,
    { sceneId, endpoint }: ActionPayloadWithoutNs<typeof integrationEndpointUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isEndpointEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    scene.integration.request.endpoint = endpoint;
  }

  public updateIntegrationHeader(
    state: ScenarioEditorCommonState,
    { sceneId, previousHeaderName, header }: ActionPayloadWithoutNs<typeof integrationHeaderUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isHeadersEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    delete scene.integration.request.header[previousHeaderName];
    Object.assign(scene.integration.request.header, header);
  }

  public updateIntegrationMethod(
    state: ScenarioEditorCommonState,
    { sceneId, method }: ActionPayloadWithoutNs<typeof integrationMethodUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isMethodEditableScene(scene)) {
      throw new Error(
        `Wrong scene type was passed. IntegrationNode expected, but got ${scene.type}`
      );
    }
    scene.integration.request.method = method;
  }

  public updateMailBody(
    state: ScenarioEditorCommonState,
    { sceneId, value }: ActionPayloadWithoutNs<typeof bodyUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isReplyToEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed. Got ${scene.type}`);
    }
    scene.mail.body = value;
  }

  public updateMailSubject(
    state: ScenarioEditorCommonState,
    { sceneId, value }: ActionPayloadWithoutNs<typeof subjectUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isReplyToEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed. Got ${scene.type}`);
    }
    scene.mail.subject = value;
  }

  public updateMailToAddress(
    state: ScenarioEditorCommonState,
    { sceneId, name, address, index }: ActionPayloadWithoutNs<typeof toAddressUpdated>
  ) {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isToAddressEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.mail.to[index] = { ...scene.mail.to[index], name, address };
  }

  public updateMessage(
    state: ScenarioEditorCommonState,
    { sceneId, messageId, value }: ActionPayloadWithoutNs<typeof messageUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isMessageEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    const message = required(scene.response.messages.find(m => m.id === messageId));
    updateMessageEntityText(message.attachment, value);

    const { hasEmptyMessage } = isSceneHasInvalidMessage(scene);

    if (!hasEmptyMessage) {
      removeSceneError({
        sceneId: scene.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    } else {
      addSceneError({
        sceneId: scene.id,
        code: 'EMPTY_PLAIN_TEXT_MESSAGE',
        errors: state.errors,
      });
    }
  }

  public updateParameter(
    state: ScenarioEditorCommonState,
    { parameterIndex, parameterId }: ActionPayloadWithoutNs<typeof parameterUpdated>
  ): void {
    if (!state.selectedScenario) {
      return;
    }
    if (state.parameters == null) {
      state.parameters = [];
    }
    if (state.parameters.some(v => v.id === parameterId)) {
      state.warnings.duplicateParameter = true;
      return;
    }
    state.warnings.duplicateParameter = false;
    if (state.parameters.length === parameterIndex && parameterId) {
      state.parameters.push({ id: parameterId, type: 'string' });
    } else if (state.parameters.length > parameterIndex) {
      if (!parameterId) {
        this.deleteParameter(state, { parameterIndex });
      } else {
        const scenes = state.selectedScenario.scenes.nodes.filter(node => {
          return node.type === 'message' && node.paramId === state.parameters?.[parameterIndex].id;
        });
        scenes.forEach(scene => {
          setParameterToSceneNode(scene as MessageNode, parameterId);
        });
        state.parameters[parameterIndex].id = parameterId;
      }
    }
  }

  public updateReplyTo(
    state: ScenarioEditorCommonState,
    { sceneId, name, address }: ActionPayloadWithoutNs<typeof replyToUpdated>
  ): void {
    const scene = required(state.selectedScenario?.scenes.nodes.find(sceneId));
    if (!isReplyToEditableScene(scene)) {
      throw new Error(`Wrong scene node type passed MessageNode expected, but got ${scene.type}`);
    }
    scene.mail.replyTo = { name, address };
  }

  public updateSceneParameter(
    state: ScenarioEditorCommonState,
    { sceneId, parameter }: ActionPayloadWithoutNs<typeof sceneParameterUpdated>
  ): void {
    if (state.selectedScenario) {
      const scene = required(state.selectedScenario.scenes.nodes.find(sceneId));
      if (scene.type === 'message') {
        if (parameter === 'none') {
          delete scene.paramId;
        } else {
          setParameterToSceneNode(scene, parameter);
        }
      }
    }
  }

  public updateTextInputState(
    state: ScenarioEditorCommonState,
    { sceneId, value }: ActionPayloadWithoutNs<typeof textInputStateUpdated>
  ): void {
    if (state.selectedScenario) {
      const node = state.selectedScenario.scenes.nodes.find(sceneId);
      if (node) {
        if (!isTextInputStateEditableScene(node)) {
          throw new Error(
            `Wrong scene node type passed MessageNode expected, but got ${node.type}`
          );
        }
        node.textInputState = value;
        if (value === 'hide') {
          node.freeInputEdgeView.edge = '';
          cleanupFallback(node);
        }
      }
    }
  }
}

/**
 * @fileOverview
 * @name SynonymQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from './interface';
import { Synonym, RemoteSynonym } from '@c/domain/entities/Synonym';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type SynonymGettableQuery = GettableQuery<{ projectId: string }, Synonym>;

export class SynonymQuery implements SynonymGettableQuery {
  public constructor(private readonly adapter: (a: RemoteSynonym) => Synonym) {}

  public async get({
    projectId,
  }: Parameters<SynonymGettableQuery['get']>[0]): ReturnType<SynonymGettableQuery['get']> {
    const ret = await fetchService<RemoteSynonym>(apiEndpoint(`project/${projectId}/synonym`), {
      method: 'GET',
    });

    return this.adapter(ret);
  }
}

/**
 * @fileOverview
 * @author
 */
import { State, initialState } from '@c/state';
import produce from 'immer';
import { SystemMessages, SYSTEM_MESSAGES_ORDER } from '@c/domain/entities/SystemMessages';
import {
  fetchSystemMessagesSucceeded,
  updateSystemMessagesSucceeded,
} from '@c/modules/systemMessages/action';

type ActionTypes =
  | ReturnType<typeof fetchSystemMessagesSucceeded>
  | ReturnType<typeof updateSystemMessagesSucceeded>;

export default (namespace: string) =>
  (state: State['systemMessages'] = initialState.systemMessages, action: ActionTypes) => {
    if ((action as any)?.payload?.namespace && (action as any).payload.namespace !== namespace) {
      return state;
    }

    return produce(state, state => {
      switch (action.type) {
        case 'FETCH_SYSTEM_MESSAGES_SUCCEEDED':
          return action.payload.messages.reduce((acc, n) => {
            acc[SYSTEM_MESSAGES_ORDER[n.name]] = n;
            return acc;
          }, [] as SystemMessages);
        case 'UPDATE_SYSTEM_MESSAGES_SUCCEEDED':
          if (state) {
            const i = state.findIndex(v => v.name === action.payload.name);
            state[i] = action.payload;
          }
          return state;
        default:
          return state;
      }
    });
  };

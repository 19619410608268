/**
 * @fileOverview
 * @name SortOrder.ts
 * @author Taketoshi Aono
 * @license
 */

export const enum SortOrder {
  DESC,
  ASC,
}

/**
 * @fileOverview
 * @name EventRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { CreatableRepository } from '@s/Repository';
import { fetchService, abortController } from '@s/io/fetchService';
import { EventRequestParam } from '@s/domain/values/EventRequestParam';

export type MessageEventCreatableRepository = CreatableRepository<
  {
    param: EventRequestParam;
    projectId: string;
    customerId?: string;
    headers?: { [key: string]: string };
  },
  void
>;

export class MessageEventRepository implements MessageEventCreatableRepository {
  public constructor(private readonly getEndpoint: () => string) {}

  public async create({
    param,
    projectId,
    customerId,
    headers = {},
  }: Parameters<MessageEventCreatableRepository['create']>[0]): Promise<void> {
    const controller = abortController();
    const signal = controller.signal;

    const timeoutPromise = (ms: number, promise: Promise<any>): any => {
      return new Promise<void>(async (resolve, reject) => {
        const timeoutId = setTimeout(() => {
          controller.abort();
          reject(new Error('promise timeout'));
        }, ms);
        try {
          await promise;
          clearTimeout(timeoutId);
          return resolve();
        } catch (e) {
          clearTimeout(timeoutId);
          return reject(e);
        }
      });
    };

    await timeoutPromise(
      15000,
      fetchService(
        !customerId
          ? `${this.getEndpoint()}/project/${projectId}/event`
          : `${this.getEndpoint()}/message/project/${projectId}/customer/${customerId}/event`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
            ...headers,
          },
          data: param,
          signal,
        }
      )
    );
  }
}

export type ImageEventCreatableRepository = CreatableRepository<
  { param: EventRequestParam; projectId: string },
  void
>;

export class ImageEventRepository implements ImageEventCreatableRepository {
  public constructor(private readonly getEndpoint: () => string) {}

  public async create({
    param,
    projectId,
  }: Parameters<ImageEventCreatableRepository['create']>[0]): Promise<void> {
    const controller = abortController();
    const signal = controller.signal;
    delete param.imageUrl;

    const timeoutPromise = (ms: number, promise: Promise<any>): any => {
      return new Promise<void>(async (resolve, reject) => {
        const timeoutId = setTimeout(() => {
          controller.abort();
          reject(new Error('promise timeout'));
        }, ms);
        try {
          await promise;
          clearTimeout(timeoutId);
          return resolve();
        } catch (e) {
          clearTimeout(timeoutId);
          return reject(e);
        }
      });
    };

    await timeoutPromise(
      15000,
      fetchService(`${this.getEndpoint()}/project/${projectId}/event`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
        data: param,
        signal,
      })
    );
  }
}

/**
 * @fileOverview
 * @author Taketoshi Aono
 */

export type LineProjectSettingItem = {
  name: string;
  providerId: string;
  secret: string;
  id: string;
  isEditable: boolean;
  tokenExpiredAt: string;
  webhookEndpoint: string;
};

export type LineProjectSetting = {
  channel: LineProjectSettingItem;
  previewChannel?: LineProjectSettingItem;
};

export type RemoteLineProjectSetting = {
  channel?: LineProjectSettingItem;
  previewChannel?: LineProjectSettingItem;
};

export const DEFAULT_LINEPROJECT_SETTING_ITEM: LineProjectSettingItem = {
  name: '',
  providerId: '',
  secret: '',
  id: '',
  isEditable: true,
  tokenExpiredAt: '',
  webhookEndpoint: '',
};

export const DEFAULT_LINEPROJECT_SETTING: LineProjectSetting = {
  channel: DEFAULT_LINEPROJECT_SETTING_ITEM,
  previewChannel: undefined,
};

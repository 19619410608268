/**
 * @fileOverview
 * @name ProjectListQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';
import { NgWord } from '@c/domain/entities/NgWord';

export type NgWordGettableQuery = GettableQuery<undefined, NgWord[]>;

export class NgWordQuery implements NgWordGettableQuery {
  public async get(): ReturnType<NgWordGettableQuery['get']> {
    return fetchService<NgWord[]>(apiEndpoint(`/conversation/ngword`), {
      method: 'GET',
      responseType: 'json',
    });
  }
}

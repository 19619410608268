/**
 * @fileOverview
 * @name reportCrash.ts
 * @author Taketoshi Aono
 * @license
 */

import { CrashReportable } from '@s/crashreporting/CrashReporter';
import { FirestoreService } from '@c/domain/service/FirestoreService';
import { State } from '@c/state';
import { ActionLogQueueable } from '@s/application/service/ActionLogQueuingService';

export type ReportCrashed = (a: { error: Error; state: State; additionalData?: any }) => void;

// Create reportCrashed function which send state and action logs to firestore and send an error report to Sentry if logged in.
// If an user is not logged in, we will send Sentry with state and action logs.
// If network state is offline, this two requests will retry until network become online.
export const crashReporterFactory = ({
  crashReporter,
  firestoreService,
  actionLogQueuingService,
}: {
  crashReporter: CrashReportable;
  firestoreService: FirestoreService;
  actionLogQueuingService: ActionLogQueueable;
}) => {
  return ({ error, state, additionalData }: Parameters<ReportCrashed>[0]) => {
    console.error(error);
    if (import.meta.env.VITE_ENV !== 'prod') {
      return;
    }
    // Only store to firestore if an user logged in.
    if (state.auth.isLoggedIn && state.auth.authEntity?.claims.aim_user_id) {
      // A firestore url which includes all state and action logs.
      firestoreService
        .storeIncidentState({
          state,
          log: actionLogQueuingService.getList(),
          operatorId: state.auth.authEntity.claims.aim_user_id,
        })
        .then(firestoreUrl => {
          crashReporter.send(error, firestoreUrl, additionalData);
        });
    } else {
      crashReporter.sendWithData(error, {
        state,
        actionLog: actionLogQueuingService.getList(),
        additionalData,
      });
    }
  };
};

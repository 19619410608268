/**
 * @fileOverview
 * @name detectCssFeature.ts
 * @author Taketoshi Aono
 * @license
 */

const COMPUTED_STYLE: CSSStyleDeclaration = document.defaultView
  ? document.defaultView.getComputedStyle(document.createElement('div'))
  : ({} as any);
export const detectCssFeature = (name: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return COMPUTED_STYLE[name as any] != null;
};

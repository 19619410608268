/**
 * @fileOverview
 * @author
 */

import { State, initialState } from '@c/state';
import produce from 'immer';
import {
  getEnvSucceeded,
  getProjectIdSucceeded,
  latestUrlChanged,
  projectSelected,
} from '@c/modules/env/action';

type ActionTypes = ReturnType<
  | typeof getEnvSucceeded
  | typeof getProjectIdSucceeded
  | typeof projectSelected
  | typeof latestUrlChanged
>;

export default (state: State['env'] = initialState.env, action: ActionTypes) => {
  return produce(state, state => {
    switch (action.type) {
      case 'GET_ENV_SUCCEEDED':
        Object.assign(state, action.payload);
        if (state.dedicatedServerName) {
          state.dedicatedEnvConfig = btoa(
            JSON.stringify({
              firebaseConfig: action.payload.firebaseConfig,
              serverName: action.payload.dedicatedServerName,
            })
          );
        }
        return;
      case 'GET_PROJECT_ID_SUCCEEDED':
        state.projectId = action.payload;
        return;
      case 'PROJECT_SELECTED':
        if (!action.payload) {
          state.projectId = '';
          state.type = '';
          return;
        }
        state.projectId = action.payload.id;
        if (!action.payload.id) {
          state.type = 'chat';
        } else {
          state.type = action.payload.type;
        }
        return;
      case 'LATEST_URL_CHANGED':
        state.latestUrlMatch = action.payload.match;
        return;
      default:
        const unusedCheck: never = action;
    }
  });
};

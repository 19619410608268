/**
 * @fileOverview
 * @name WidgetInspectorWindowRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { storage } from '@s/io/storage';

export type WidgetInspectorWindowGettableQuery = GettableQuery<
  undefined,
  { url: string; isOpened: boolean } | null
>;

const KEY = '__AIM_INSPECTOR__';

export class WidgetInspectorWindowQuery {
  public async get(): ReturnType<WidgetInspectorWindowGettableQuery['get']> {
    const st = storage('local');
    return st.get(KEY, null);
  }
}

import { Inquiry } from '@c/domain/entities/Inquiry';
import { ProjectType } from '@c/domain/entities/Project';
import { FlattenNode, RemoteFlattenNode } from '@c/domain/entities/QueryScenarioEditor/ScenarioCsv';
import {
  CSVImportedRemoteSceneNodes,
  CSVImportedSceneNode,
  CsvConvertedNodesMap,
  MessageNode,
  RemoteSceneNodes,
  SceneNode,
  businessHourNode,
  callOperatorNode,
  integrationNode,
  mailNode,
  messageNode,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { CSVSpecification } from '@c/domain/specification/CSVSpecification';
import { ScenarioNodeAdapter } from '@c/query/ScenarioEditorQuery';

import { csv2json, json2csv } from 'json-2-csv';
import { v4 } from 'uuid';

const BUSINESS_HOUR_KEYS = {
  in: '$in',
  out: '$out',
};
const RESULT_TYPE_KEYS = {
  succeeded: '$succeeded',
  failed: '$failed',
};

const fieldConversions: [
  keyof FlattenNode,
  keyof MessageNode | keyof SceneNode | ((a: SceneNode) => string)
][] = [
  ['ID', 'id'],
  ['タイプ', 'type'],
  [
    'メッセージ',
    (node: SceneNode) => {
      if (node.type === 'message') {
        return (
          node.response.messages
            ?.map?.(message => message.attachment.payload)
            ?.join?.('\n$---$\n') ?? ''
        );
      }
      return '';
    },
  ],
  [
    'ボタン',
    (node: SceneNode) => {
      try {
        if (node.type === 'message') {
          return (
            node.response.quickReplies
              ?.map?.(button => `${button.label}::${button.type}::${button.edge}`)
              ?.join?.('\n') ?? ''
          );
        } else if (node.type === 'businessHour') {
          return (
            node.request.results
              ?.map?.(
                button =>
                  `${
                    button.displayName === '営業中' ? BUSINESS_HOUR_KEYS.in : BUSINESS_HOUR_KEYS.out
                  }::${button.edge}`
              )
              ?.join?.('\n') ?? ''
          );
        } else if (node.type === 'integration') {
          return (
            (
              node.integration.succeeded.quickReplies?.map?.(
                button => `${button.label}::${button.edge}`
              ) ?? []
            )
              .concat([
                ...(node.integration.succeeded.default
                  ? [`${RESULT_TYPE_KEYS.succeeded}::${node.integration.succeeded.default}`]
                  : []),
                ...(node.integration.failed
                  ? [`${RESULT_TYPE_KEYS.failed}::${node.integration.failed}`]
                  : []),
              ])
              ?.join?.('\n') ?? ''
          );
        } else if (node.type === 'mail') {
          return [
            `${RESULT_TYPE_KEYS.succeeded}::${node.mail.edges?.succeeded ?? ''}`,
            `${RESULT_TYPE_KEYS.failed}::${node.mail.edges?.failed ?? ''}`,
          ].join('\n');
        }
      } catch (e) {
        console.error(e, node);
      }
      return '';
    },
  ],
  ['テキスト入力欄', 'textInputState'],
  ['初期シーン', 'isInitialScene'],
  ['自動遷移先ID', 'fallback'],
  ['自由入力時遷移先ID', 'freeInputEdge'],
  [
    'カスタムフィードバック(はい)遷移先ID',
    (node: SceneNode) => (node as MessageNode).customFeedback?.edgeYes ?? '',
  ],
  [
    'カスタムフィードバック(いいえ)遷移先ID',
    (node: SceneNode) => (node as MessageNode).customFeedback?.edgeNo ?? '',
  ],
  ['変数', (node: SceneNode) => (node as MessageNode).paramId ?? ''],
  [
    '設定',
    (node: SceneNode) => {
      switch (node.type) {
        case 'integration': {
          return JSON.stringify(node.integration.request, null, '  ').trim();
        }
        case 'mail': {
          return JSON.stringify(
            {
              to: node.mail.to,
              replyTo: node.mail.replyTo,
              subject: node.mail.subject,
              body: node.mail.body,
            },
            null,
            '  '
          ).trim();
        }
        default: {
          return '{}';
        }
      }
    },
  ],
  ['__BASE_ID__', 'baseId'],
];

const flattenToSceneNodeMap: [keyof MessageNode | keyof SceneNode, keyof FlattenNode][] = [
  ['id', 'ID'],
  ['baseId', '__BASE_ID__'],
  ['type', 'タイプ'],
  ['textInputState', 'テキスト入力欄'],
  ['isInitialScene', '初期シーン'],
  ['fallback', '自動遷移先ID'],
  ['paramId', '変数'],
  ['freeInputEdge', '自由入力時遷移先ID'],
];

const convertFieldValue = (value: any) => {
  return typeof value === 'boolean' ? String(value) : value ?? '';
};

const convertFields = (node: SceneNode) => {
  return fieldConversions.reduce((acc, [from, to]) => {
    if (typeof to === 'function') {
      acc[from] = to(node);
    } else {
      acc[from] = convertFieldValue((node as MessageNode)[to]);
    }
    return acc;
  }, {} as FlattenNode);
};

const convertFlattenToSceneNodeField = (field: string) => {
  if (field === 'TRUE' || field === 'FALSE') {
    return field === 'TRUE';
  }
  return field ?? null;
};

const convertFlattenToSceneNode = (flattenNodes: RemoteFlattenNode[]) => {
  return flattenNodes.map(flatten => {
    const sceneNode = { faq: flatten['質問'], faqId: flatten['質問ID'], id: flatten['ID'] } as any;
    flattenToSceneNodeMap.reduce((acc, [to, from]) => {
      acc[to] = convertFlattenToSceneNodeField(flatten[from]);
      return acc;
    }, sceneNode);
    switch (sceneNode.type) {
      case 'message': {
        return messageNode(
          {
            ...sceneNode,
            ...(flatten['カスタムフィードバック(はい)遷移先ID'] ||
            flatten['カスタムフィードバック(いいえ)遷移先ID']
              ? {
                  customFeedback: {
                    edgeYes: flatten['カスタムフィードバック(はい)遷移先ID'],
                    edgeNo: flatten['カスタムフィードバック(いいえ)遷移先ID'],
                  },
                }
              : {}),
            response: {
              messages: String(flatten.メッセージ)
                .split('$---$')
                .filter(v => !!v)
                .map(message => {
                  return {
                    attachment: {
                      mediaType: 'plainText',
                      payload: message.trim(),
                    },
                    id: v4(),
                  };
                }),
              quickReplies: String(flatten.ボタン)
                .trim()
                .split('\n')
                .filter(v => !!v)
                .map(button => {
                  const items = button.split('::');
                  return { isDefault: false, label: items[0], type: items[1], edge: items[2] };
                }),
            },
          },
          flatten['ID']
        );
      }
      case 'businessHour': {
        const buttons = String(flatten.ボタン).split('\n');
        const b1 = buttons[0].split('::');
        const b2 = buttons[1].split('::');
        return businessHourNode(
          {
            ...sceneNode,
            request: {
              results: [
                { edge: b1[0] === BUSINESS_HOUR_KEYS.in ? b1[1] : b2[1] },
                { edge: b1[0] === BUSINESS_HOUR_KEYS.out ? b1[1] : b2[1] },
              ],
            },
          },
          flatten['ID']
        );
      }
      case 'integration': {
        const buttons = String(flatten.ボタン)
          .split('\n')
          .map(v => v.trim());
        const succeededEdge = buttons.find(button =>
          button.startsWith(`${RESULT_TYPE_KEYS.succeeded}::`)
        );
        const failedEdge = buttons.find(button =>
          button.startsWith(`${RESULT_TYPE_KEYS.failed}::`)
        );
        const otherEdges = buttons.filter(
          button =>
            !button.startsWith(`${RESULT_TYPE_KEYS.failed}::`) &&
            !button.startsWith(`${RESULT_TYPE_KEYS.succeeded}::`)
        );
        return integrationNode(sceneNode, {
          id: flatten['ID'],
          succeededEdge: succeededEdge?.split?.('::')?.[1] ?? '',
          failedEdge: failedEdge?.split?.('::')?.[1] ?? '',
          edges: otherEdges.map(e => ({ edge: e.split('::')[1], label: e.split('::')[0] })),
          requestOption: typeof flatten.設定 === 'object' ? (flatten.設定 as any) : {},
        });
      }
      case 'mail': {
        const buttons = String(flatten.ボタン)
          .split('\n')
          .map(v => v.trim());
        const succeededEdge = buttons.find(button =>
          button.startsWith(`${RESULT_TYPE_KEYS.succeeded}::`)
        );
        const failedEdge = buttons.find(button =>
          button.startsWith(`${RESULT_TYPE_KEYS.failed}::`)
        );
        return mailNode(
          { ...sceneNode, mail: typeof flatten.設定 === 'object' ? (flatten.設定 as any) : {} },
          {
            id: flatten['ID'],
            succeededEdge: succeededEdge?.split?.('::')?.[1] ?? '',
            failedEdge: failedEdge?.split?.('::')?.[1] ?? '',
          }
        );
      }
      case 'callOperator': {
        return callOperatorNode(sceneNode, flatten['ID']);
      }
      default: {
        throw new Error('Unsupported type found');
      }
    }
  });
};

/**
 * シナリオシーンのデータをcsvに変換する
 * @param jsonNodes シナリオシーンのデータ
 * @returns シナリオシーンのデータをcsvに変換した文字列
 */
export const jsonNodesToCsvNodes = async (
  params: {
    summaryText: string;
    scenarioId: string;
    sceneNodes: RemoteSceneNodes;
  }[]
): Promise<string> => {
  const nodes = [];
  for (const { sceneNodes, scenarioId, summaryText } of params) {
    for (const node of sceneNodes.nodes) {
      // fromEdges は必要のない情報なので空配列にする
      node.fromEdges = [];
      nodes.push({ 質問: summaryText, 質問ID: scenarioId, ...convertFields(node) });
    }
  }

  const option = {
    emptyFieldValue: '',
    trimHeaderFields: true,
    preventCsvInjection: true,
  };
  return json2csv(nodes, option);
};

export const remoteJsonNodesTocsvNodes = async (
  params: {
    summaryText: string;
    scenarioId: string;
    sceneNodes: RemoteSceneNodes;
  }[]
): Promise<string> => {
  return await jsonNodesToCsvNodes(params);
};

export const csvToSceneNodesAdapterFactory = ({
  csvSpecification,
  scenarioNodesAdapter,
}: {
  csvSpecification: CSVSpecification;
  scenarioNodesAdapter: ScenarioNodeAdapter;
}) => {
  /**
   * csvをシナリオのシーンデータに変換する
   * @param csv シナリオシーンのcsvデータ
   * @returns csvデータをシナリオシーンのデータに変換したもの
   */
  return async ({
    csv,
    inquiryList,
    projectType,
  }: {
    csv: string;
    inquiryList: Inquiry[];
    projectType: ProjectType;
  }): Promise<{ convertedNodesMap: CsvConvertedNodesMap; flattenNodes: RemoteFlattenNode[] }> => {
    const json = (await csv2json(csv.replace(/\r/g, ''), {
      trimHeaderFields: true,
    })) as RemoteFlattenNode[];
    const { isValid, errors } = csvSpecification.validate(json);
    if (!isValid) {
      throw new Error(errors.join('\n'));
    }
    const nodes = convertFlattenToSceneNode(json) as CSVImportedRemoteSceneNodes;
    const faqListMap = new Map(inquiryList.map(i => [i.inquiry, i]) || []);
    const nodeFaqMap = nodes.reduce((map, node) => {
      if (!map.has(node.faq)) {
        const a = {
          id: faqListMap.get(node.faq)?.scenarioId ?? node.faqId,
          nodes: [node],
          params: new Set(node.type === 'message' && node.paramId ? [node.paramId] : []),
        };
        map.set(node.faq, a);
      } else {
        const a = map.get(node.faq)!;
        a.nodes.push(node);
        if (node.type === 'message' && node.paramId) {
          a.params.add(node.paramId);
        }
      }
      return map;
    }, new Map<string, { id?: string; nodes: CSVImportedSceneNode[]; params: Set<string> }>());
    const convertedNodesMap = new Map(
      Array.from(nodeFaqMap.entries()).map(([faq, { id, nodes, params }]) => {
        return [
          faq,
          {
            id,
            nodes: scenarioNodesAdapter(
              id!,
              {
                id,
                nodes,
                updated: true,
                feed: false,
                switchToOp: false,
                parameters: Array.from(params.values()).map(v => ({ id: v, type: 'string' })),
              },
              {},
              false,
              projectType
            ),
            params,
          },
        ];
      })
    );

    return { flattenNodes: json, convertedNodesMap };
  };
};

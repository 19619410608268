/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@s/Repository';

export type CustomerUpdatableRepository = UpdatableRepository<
  { customerId: string; displayName: string; note: string },
  void
>;
type P = Params<CustomerUpdatableRepository>;

export class CustomerRepository implements CustomerUpdatableRepository {
  public async update({ customerId, displayName, note }: P['update'][0]): Promise<void> {
    await fetchService<void>(apiEndpoint(`/customer/${customerId}/description`), {
      method: 'PUT',
      data: { displayName, note },
      responseType: 'text',
    });
    return;
  }
}

/**
 * @fileOverview
 * @author
 */

import { ScenarioUsingNamespaces } from '@c/state';
import { Inquiry } from '@c/domain/entities/Inquiry';

export type Namespaces = ScenarioUsingNamespaces;

export const fetchScenarioListSucceeded = (
  namespace: Namespaces,
  payload: { categoryId: number; scenarioList: Inquiry[] }
) => {
  return {
    type: 'FETCH_SCENARIO_LIST_SUCCEEDED' as const,
    payload: { namespace, ...payload },
  };
};

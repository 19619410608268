import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@s/components/atom/Box';
import { largeTextStyle } from '@s/components/atom/Text';
import { Button } from '@s/components/atom/Button';
import { useAsyncAction, useRefState } from '@s/reactHooks';
import { GenericLogo } from '@c/components/atom/GenericLogo';
import {
  initializeRecaptchaVerifier,
  MULTI_FACTOR_HINTS,
  sendVerificationCode,
} from '@c/modules/account/usecase';
import { MultiFactorHint } from '@c/domain/entities/MultiFactorAuth';
import { storage } from '@s/io/storage';
import { RadioButton } from '@c/components/atom/RadioButton';
import { useErrorNotificator } from '@c/components/hooks/useErrorNotificator';

const MultiFactorContentWrapperElement = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  > div {
    width: 100%;
    height: 100%;
  }
`;
const LabelElement = styled.div`
  ${largeTextStyle};
  font-weight: bold;
  margin: 0 0 30px 0;
`;

export const VerificationCodeSender: React.FunctionComponent = () => {
  const initializeRecaptchaVerifierAction = useAsyncAction(initializeRecaptchaVerifier);
  const sendVerificationCodeAction = useAsyncAction(sendVerificationCode);
  const [selectedHintIndex, setSelectedHintIndex] = useRefState(0);
  const [multiFactorAuthHints, setMultiFactorAuthHints] = useRefState<MultiFactorHint[]>([]);
  const [recaptchaResolved, setRecaptchaResolved] = useRefState(false);

  useErrorNotificator(sendVerificationCodeAction.error);

  useEffect(() => {
    storage('local')
      .get<MultiFactorHint[] | undefined>(MULTI_FACTOR_HINTS)
      .then(hints => {
        if (hints) {
          setMultiFactorAuthHints(hints);
        }
      });
    initializeRecaptchaVerifierAction('recaptcha').then(() => {
      setRecaptchaResolved(true);
    });
    return () => {
      setRecaptchaResolved(false);
    };
  }, []);
  return (
    <MultiFactorContentWrapperElement>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        css={{ position: 'relative', height: '100%' }}
      >
        <Flex justifyContent="center" alignItems="center">
          <Box margin="0 0 80px 0">
            <GenericLogo size={200} />
          </Box>
        </Flex>
        <LabelElement>ご登録いただいた電話番号に認証コードを送信します</LabelElement>
        <Flex flexDirection={'column'}>
          <div>
            {multiFactorAuthHints.current.map((hint, index) => {
              return (
                <div key={`d_${index}`} style={{ marginBottom: '10px' }}>
                  <RadioButton
                    name="multi-factor-hint"
                    value={`${index}`}
                    key={`${index}`}
                    checked={index === selectedHintIndex.current}
                    onChange={() => {
                      setSelectedHintIndex(index);
                    }}
                    label={`${hint.phoneNumber}  (${hint.displayName || ''})`}
                  />
                </div>
              );
            })}
          </div>
          <div id={'recaptcha'} style={{ margin: '20px' }} />
          <Button
            css={{ marginLeft: '10px' }}
            type={'normal'}
            label="認証コードを送信"
            disabled={!recaptchaResolved.current}
            loading={sendVerificationCodeAction.loading}
            onClick={() => {
              sendVerificationCodeAction(selectedHintIndex.current);
            }}
          />
        </Flex>
      </Flex>
    </MultiFactorContentWrapperElement>
  );
};

/**
 * @fileOverview
 * @author
 */

import { NewInquiryParam } from '@c/domain/entities/Inquiry';
import { Category } from '@c/domain/entities/Category';

export const cleanupQueryScenarioEditorSucceeded = () => {
  return {
    type: 'CLEANUP_QUERY_SCENARIO_EDITOR_SUCCEEDED' as const,
  };
};

export const updateInquirySucceeded = (payload: { scenarioId: string; inquiry: string }) => {
  return {
    type: 'UPDATE_INQUIRY_SUCCEEDED' as const,
    payload,
  };
};

export const createScenarioSucceeded = (payload: NewInquiryParam) => {
  return {
    type: 'CREATE_SCENARIO_SUCCEEDED' as const,
    payload,
  };
};

export const deleteScenarioSucceeded = (payload: { scenarioId: string }) => {
  return {
    type: 'DELETE_SCENARIO_SUCCEEDED' as const,
    payload,
  };
};

export const selectCategorySucceeded = (payload: { category: Category }) => {
  return {
    type: 'QUERY_SCENARIO_EDITOR_SELECT_CATEGORY_SUCCEEDED' as const,
    payload,
  };
};

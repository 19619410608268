/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type ConversationLabelEditableRepository = {
  add(payload: {
    projectId: string;
    customerId: string;
    labelId: string;
    project?: 'chat' | 'voice';
    conversationId?: string;
  }): Promise<void>;
  delete(payload: {
    projectId: string;
    customerId: string;
    labelId: string;
    project?: 'chat' | 'voice';
    conversationId?: string;
  }): Promise<void>;
};

export class ConversationLabelRepository implements ConversationLabelEditableRepository {
  public async add({
    projectId,
    customerId,
    labelId,
    project = 'chat',
    conversationId,
  }: Parameters<ConversationLabelEditableRepository['add']>[0]): ReturnType<
    ConversationLabelEditableRepository['add']
  > {
    const endPoint =
      project === 'chat'
        ? `conversation/project/${projectId}/customer/${customerId}/label`
        : `conversation/project/${projectId}/customer/${customerId}/label/voice/${
            conversationId || ''
          }`;
    await fetchService<{}>(apiEndpoint(endPoint), {
      method: 'POST',
      responseType: 'json',
      data: { labelId },
    });
  }

  public async delete({
    projectId,
    customerId,
    labelId,
    project = 'chat',
    conversationId,
  }: Parameters<ConversationLabelEditableRepository['delete']>[0]): ReturnType<
    ConversationLabelEditableRepository['delete']
  > {
    const endPoint =
      project === 'chat'
        ? `conversation/project/${projectId}/customer/${customerId}/label/${labelId}`
        : `conversation/project/${projectId}/customer/${customerId}/label/${labelId}/voice/${
            conversationId || ''
          }`;
    await fetchService<{}>(apiEndpoint(endPoint), {
      method: 'DELETE',
      responseType: 'json',
    });
  }
}

import { DayjsWrapper } from '@s/util/DayjsWrapper';

export interface CustomerDataExportSettingSpecification {
  isValidDate(startDateTime?: string, endDateTime?: string): [boolean, string];
}

export class CustomerDataExportSettingValidator implements CustomerDataExportSettingSpecification {
  public isValidDate(startDateTime?: string, endDateTime?: string): [boolean, string] {
    if (startDateTime === undefined) {
      return [false, '開始日時を設定してください'];
    }
    if (endDateTime === undefined) {
      return [false, '終了日時を設定してください'];
    }
    if (new DayjsWrapper(startDateTime).isSameOrAfter(endDateTime)) {
      return [false, '開始日時は終了日時より前でなければなりません'];
    }
    if (!new DayjsWrapper(endDateTime).isSameOrBeforeThanCurrent()) {
      return [false, '未来の日時を終了日時に指定できません'];
    }
    return [true, ''];
  }
}

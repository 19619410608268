/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  conversationViewAdded,
  conversationViewDeleted,
  conversationViewUpdated,
  conversationViewBatchAdded,
  conversationSelected,
  chatMessageAdded,
  conversationUnselected,
  conversationFilterTypeChanged,
  updateCustomerDescriptionSucceeded,
  addConversationLabelSucceeded,
  deleteConversationLabelSucceeded,
  conversationViewOrderChanged,
  archivedChatMessagesFetched,
  conversationViewAttributesUpdated,
  searchWordChanged,
  searchLabelChanged,
  customerTypingUpdated,
  conversationDisconnected,
  archivedConversationsFilteredByCustomerId,
  mediaCached,
  isCustomerImageUploadEnabledUpdated,
  alwaysCustomerImageUploadEnabledUpdated,
  chatSubscriotionResetted,
  errorUpdated,
  chatMessageRemoved,
  chatTraitsUpdated,
  pushMessagesCompleted,
  fetchNgWordSucceeded,
  updateConversationView,
} from '@c/modules/chat/action';
import { SortOrder } from '@c/domain/values/SortOrder';
import { DEFAULT_EMPTY_WEB_CUSTOMER_ENTITY } from '@c/domain/entities/Customer';
import { ChatAssignType, ChatEntity } from '@c/domain/entities/Chat';
import { oneTrunMessages } from '@c/domain/entities/OneTurnMessage';

type ActionTypes = ReturnType<
  | typeof conversationViewAdded
  | typeof conversationViewDeleted
  | typeof conversationViewUpdated
  | typeof conversationViewBatchAdded
  | typeof conversationSelected
  | typeof chatMessageAdded
  | typeof conversationViewAttributesUpdated
  | typeof conversationUnselected
  | typeof conversationFilterTypeChanged
  | typeof updateCustomerDescriptionSucceeded
  | typeof addConversationLabelSucceeded
  | typeof deleteConversationLabelSucceeded
  | typeof conversationViewOrderChanged
  | typeof archivedConversationsFilteredByCustomerId
  | typeof archivedChatMessagesFetched
  | typeof updateConversationView
  | typeof searchWordChanged
  | typeof searchLabelChanged
  | typeof customerTypingUpdated
  | typeof conversationDisconnected
  | typeof mediaCached
  | typeof isCustomerImageUploadEnabledUpdated
  | typeof alwaysCustomerImageUploadEnabledUpdated
  | typeof chatSubscriotionResetted
  | typeof errorUpdated
  | typeof chatMessageRemoved
  | typeof chatTraitsUpdated
  | typeof pushMessagesCompleted
  | typeof fetchNgWordSucceeded
>;

export default (state: State['chat'] = initialState.chat, action: ActionTypes): State['chat'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'CHAT_SUBSCRIPTION_RESETTED':
        Object.assign(state, initialState.chat);
        return;
      case 'CONVERSATION_VIEW_ADDED': {
        const cv = state.allConversationAggregateIdMap[action.payload.conversation.id];
        if (!cv) {
          state.allConversationAggregateIdMap[action.payload.conversation.id] = {
            conversation: action.payload.conversation,
            customer: DEFAULT_EMPTY_WEB_CUSTOMER_ENTITY,
            messages: [],
            archives: [],
            mediaCache: {},
            typing: { isTyping: false, text: '' },
            isCustomerImageUploadEnabled: false,
          };
        }
        const list = state.conversationView[action.payload.filterType] ?? [];
        if (!list.find(v => v === action.payload.conversation.id)) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          state.conversationView[action.payload.filterType]?.unshift?.(
            action.payload.conversation.id
          );
        }
        return;
      }
      case 'CONVERSATION_VIEW_UPDATED':
        if (action.payload.oldIndex < action.payload.newIndex) {
          console.error('Unexpected data order');
        } else {
          const cv = state.allConversationAggregateIdMap[action.payload.conversation.id];
          if (cv) {
            cv.conversation = action.payload.conversation;
          }
          const view = state.conversationView[action.payload.filterType];
          if (view) {
            const foundIndex = view.findIndex(id => id === action.payload.conversation.id);
            if (foundIndex > -1) {
              view.splice(foundIndex, 1);
              view.unshift(action.payload.conversation.id);
            }
          }
        }
        return;
      case 'CONVERSATION_VIEW_DELETED': {
        state.conversationView[action.payload.filterType] = state.conversationView[
          action.payload.filterType
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ]?.filter?.(id => id !== action.payload.id);

        if (action.payload.isDeleteFromAllViews) {
          delete state.allConversationAggregateIdMap[action.payload.id];
          if (state.selectedConversationId === action.payload.id) {
            state.selectedConversationId = '';
          }
        }
        return;
      }
      case 'CONVERSATION_VIEW_BATCH_ADDED': {
        const currentViewIdList = state.conversationView[action.payload.filterType];
        if (action.payload.shouldResetPreviousList && currentViewIdList) {
          for (const id of currentViewIdList) {
            delete state.allConversationAggregateIdMap[id];
            if (state.selectedConversationId === id) {
              state.selectedConversationId = '';
            }
          }
        }
        if (action.payload.filterType === ChatAssignType.ARCHIVES) {
          const allSet = new Set(
            state.conversationView[ChatAssignType.ALL]!.map(
              id => state.allConversationAggregateIdMap[id]!.conversation.customerId
            )
          );
          const convs: { [key: string]: ChatEntity | undefined } = {};
          for (const conv of action.payload.conversations) {
            if (allSet.has(conv.customerId)) {
              continue;
            }
            const storedConv = convs[conv.customerId];
            if (!storedConv) {
              convs[conv.customerId] = conv;
            } else if (storedConv.archivedAt! < conv.archivedAt!) {
              convs[conv.customerId] = conv;
            }
          }
          action.payload.conversations = Object.keys(convs).map(key => convs[key]!);
        }
        action.payload.conversations.forEach(conversation => {
          if (state.allConversationAggregateIdMap[conversation.id]) {
            return;
          }
          state.allConversationAggregateIdMap[conversation.id] = {
            conversation,
            messages: [],
            customer: DEFAULT_EMPTY_WEB_CUSTOMER_ENTITY,
            archives: [],
            mediaCache: {},
            typing: { isTyping: false, text: '' },
            isCustomerImageUploadEnabled: false,
          };
        });
        const view = (state.conversationView[action.payload.filterType] =
          action.payload.conversations.map(v => v.id));
        if (state.conversationViewOrder === SortOrder.ASC) {
          state.conversationView[action.payload.filterType] = view.sort((a, b) => {
            const ac = state.allConversationAggregateIdMap[a]!.conversation;
            const bc = state.allConversationAggregateIdMap[b]!.conversation;
            return ac.updatedAt.seconds - bc.updatedAt.seconds;
          });
        } else {
          state.conversationView[action.payload.filterType] = view.sort((a, b) => {
            const ac = state.allConversationAggregateIdMap[a]!.conversation;
            const bc = state.allConversationAggregateIdMap[b]!.conversation;
            return bc.updatedAt.seconds - ac.updatedAt.seconds;
          });
        }
        return;
      }
      case 'CONVERSATION_SELECTED': {
        state.selectedProjectId = action.payload.conversation.projectId;
        state.selectedConversationId = action.payload.conversation.id;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // readOonly がすべてのパラメータについていてるので、直接代入できない
        state.allConversationAggregateIdMap[action.payload.conversation.id] = {
          ...state.allConversationAggregateIdMap[action.payload.conversation.id],
          ...action.payload,
        };
        return;
      }
      case 'CONVERSATION_CHAT_MESSAGE_ADDED': {
        const c = state.allConversationAggregateIdMap[action.payload.conversation.id];
        if (c) {
          const filteredMessage = action.payload.messages.filter(message => {
            return (
              message.uuid == null ||
              c.messages.filter(cMessage => {
                return cMessage.uuid != null && cMessage.uuid === message.uuid;
              }).length === 0
            );
          });
          if (filteredMessage.length) {
            c.messages = c.messages.concat(filteredMessage);
          }
        }
        return;
      }
      case 'CONVERSATION_CHAT_MESSAGE_REMOVED': {
        const c = state.allConversationAggregateIdMap[action.payload.conversation.id];
        if (c) {
          const set = new Set(action.payload.messages.map(m => m.id));
          c.messages = c.messages.filter(m => !set.has(m.id));
        }
        return;
      }
      case 'CONVERSATION_VIEW_ATTRIBUTES_UPDATED': {
        const c = state.allConversationAggregateIdMap[action.payload.conversation.id];
        if (c) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // readOonly がすべてのパラメータについていてるので、直接代入できない
          c.conversation = action.payload.conversation;
          if (action.payload.customer) {
            c.customer = action.payload.customer;
          }
          if (action.payload.messages) {
            c.messages = action.payload.messages;
          }
        }
        return;
      }
      case 'CONVERSATION_UNSELECTED':
        state.selectedConversationId = '';
        return;
      case 'CONVERSATION_FILTER_TYPE_CHANGED':
        state.filterType = action.payload;
        return;
      case 'UPDATE_CUSTOMER_DESCRIPTION_SUCCEEDED': {
        const c = state.allConversationAggregateIdMap[action.payload.conversation.id];
        if (c) {
          c.conversation.customerName = action.payload.displayName;
          c.customer.displayName = action.payload.displayName;
          c.customer.note = action.payload.note;
        }
        return;
      }
      case 'ADD_CONVERSATION_LABEL_SUCCEEDED': {
        return;
      }
      case 'DELETE_CONVERSATION_LABEL_SUCCEEDED': {
        return;
      }
      case 'CONVERSATION_VIEW_SORT_ORDER_CHANGED': {
        const cv = state.conversationView[state.filterType];
        if (cv) {
          if (action.payload === SortOrder.ASC) {
            cv.sort((a, b) => {
              const ac = state.allConversationAggregateIdMap[a]!.conversation;
              const bc = state.allConversationAggregateIdMap[b]!.conversation;
              return ac.updatedAt.seconds - bc.updatedAt.seconds;
            });
          } else {
            cv.sort((a, b) => {
              const ac = state.allConversationAggregateIdMap[a]!.conversation;
              const bc = state.allConversationAggregateIdMap[b]!.conversation;
              return bc.updatedAt.seconds - ac.updatedAt.seconds;
            });
          }
        }
        state.conversationViewOrder = action.payload;
        return;
      }
      case 'ARCHIVED_CHAT_MESSAGES_FETCHED': {
        const cv = state.allConversationAggregateIdMap[state.selectedConversationId];
        if (cv) {
          const index = cv.archives.findIndex(
            c => c.conversation.id === action.payload.conversation.id
          );
          if (index > -1) {
            cv.archives[index].messages = action.payload.messages;
          }
        }
        return;
      }
      case 'UPDATE_CONVERSATION_VIEW': {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // readOonly がすべてのパラメータについていてるので、直接代入できない
        state.conversationView[action.payload.filterType] = action.payload.conversationIds;
        return;
      }
      case 'ADD_ARCHIVED_CONVERSATIONS_FILTERED_BY_CUSTOMER_ID': {
        action.payload.conversations.map(conversation => {
          state.allConversationAggregateIdMap[conversation.id] = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // readOonly がすべてのパラメータについていてるので、直接代入できない
            conversation,
            messages: [],
            customer: DEFAULT_EMPTY_WEB_CUSTOMER_ENTITY,
            archives: [],
            mediaCache: {},
            typing: { isTyping: false, text: '' },
            isCustomerImageUploadEnabled: false,
          };
        });
        return;
      }
      case 'SEARCH_WORD_CHANGED': {
        state.searchWord = action.payload.word;
        return;
      }
      case 'SEARCH_LABEL_CHANGED': {
        state.searchLabel = action.payload.label;
        return;
      }
      case 'CUSTOMER_TYPING_UPDATED': {
        const { conversationId, ...typing } = action.payload;
        const entity = state.allConversationAggregateIdMap[conversationId];
        if (entity) {
          entity.typing = typing;
        }
        return;
      }
      case 'CONVERSATION_DISCONNECTED': {
        const entity = state.allConversationAggregateIdMap[action.payload];
        if (entity) {
          state.allConversationAggregateIdMap[action.payload] = {
            ...entity,
            messages: [],
          };
        }
        return;
      }
      case 'MEDIA_CACHED': {
        const entity = state.allConversationAggregateIdMap[action.payload.conversationId];
        if (entity) {
          entity.mediaCache[action.payload.url] = action.payload.cacheUrl;
        }
        return;
      }
      case 'IS_CUSTOMER_IMAGE_UPLOAD_ENABLED_UPDATED': {
        const entity = state.allConversationAggregateIdMap[action.payload.conversationId];
        if (entity) {
          entity.isCustomerImageUploadEnabled = action.payload.enable;
        }
        return;
      }
      case 'ALWAYS_CUSTOMER_IMAGE_UPLOAD_ENABLED_UPDATED': {
        state.alwaysCustomerImageUploadEnabled = action.payload.enable;
        return;
      }
      case 'ERROR_UPDATED': {
        state.error = action.payload;
        return;
      }
      case 'CHAT_TRAITS_UPDATED': {
        state.traits = action.payload;
        return;
      }
      case 'PUSH_MESSAGES_COMPLETED': {
        state.oneTurnMessages = oneTrunMessages();
        return;
      }
      case 'FETCH_NG_WORD_SUCCEEDED': {
        state.ngwords = action.payload.map(({ pattern }) => {
          return new RegExp(pattern);
        });
        return;
      }
      default:
        const unusedCheck: never = action;
        return;
    }
  });
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import {
  CarouselMessageEntity,
  ImageMessageEntity,
  MessageFactory,
  PlainTextMessageEntity,
} from '@c/domain/entities/MessageEntity';
import { v4 } from 'uuid';
import { immerable } from 'immer';
import { required } from '@s/assertions';
import { InputStateValues } from '@s/domain/values/InputStateValues';
import { SceneType } from '@c/application/value/SceneType';
import { isOrphanScene } from '@c/domain/specification/SceneNodeSpecification';
import { ScenarioParameter } from '@c/state';

export const enum EdgeConnectionDirection {
  L,
  T,
  R,
  B,
}

export type EdgeControlPosition = Coordinate & {
  r: 'x' | 'y';
  previousIndex: number;
  nextIndex: number;
};

export type EdgeTerminalGeometry = {
  start: {
    type: EdgeConnectionDirection;
    waypoint: Coordinate;
    position: Coordinate;
  };
  end: {
    type: EdgeConnectionDirection;
    waypoint: Coordinate;
    position: Coordinate;
  };
};

export type EdgeGeometry = EdgeTerminalGeometry & {
  verticles: Coordinate[];
  controlPoints: EdgeControlPosition[];
};

type SceneId = string;

export const FALLBACK_BUTTON_ID = 'fallback';

export const getFallbackQuickReply = (fallbackId: string) => ({
  id: FALLBACK_BUTTON_ID,
  label: 'その他',
  payload: 'その他',
  edge: fallbackId,
  type: 'text' as const,
});

// Vector-Hash struct for immer
// insert O(1)
// find O(1)
// delete O(N)
// toArray O(N)
export interface VectorHashForImmer {
  size(): number;

  insert(node: SceneNode): void;

  find(key: string): Optional<SceneNode>;

  delete(
    key: string,
    dimensions: SceneDimensionCache
  ): {
    orphanScenes: SceneNode[];
    noDestQuickRepliesScenes: SceneNode[];
    newInitialScene: Optional<SceneNode>;
  };

  select(id: string): Optional<SceneNode>;

  setInitialScene(sceneId: string): void;

  readonly selectedNode: Optional<SceneNode>;

  getSelectedNode(): Optional<SceneNode>;

  map<T>(callback: (node: SceneNode, index: number) => T): T[];

  filter(callback: (node: SceneNode, index: number) => boolean): SceneNode[];

  hasInitialScene(): boolean;

  getInitialScene(): Optional<SceneNode>;

  toArray(): SceneNode[];
}

type SceneNodeCacheJsonFormat = { selectedId: string; initialSceneId: string; nodes: SceneNode[] };

type NodeCache = { node: SceneNode; index: number };
type NodeCaches = { [key: string]: NodeCache | undefined };
export class SceneNodeCache implements VectorHashForImmer {
  private readonly array: string[] = [];
  private readonly caches: NodeCaches = {};
  private [immerable] = true;
  private initialSceneId = '';
  private selectedId = '';

  public constructor(sceneNodes: SceneNode[]) {
    sceneNodes.forEach(node => {
      this.insert(node);
    });
  }

  public delete(
    key: string,
    dimensions: SceneDimensionCache
  ): {
    orphanScenes: SceneNode[];
    noDestQuickRepliesScenes: SceneNode[];
    newInitialScene: Optional<SceneNode>;
  } {
    const find = this.caches[key];
    let orphanScenes: SceneNode[] = [];
    let noDestQuickRepliesScenes: SceneNode[] = [];
    let newInitialScene: Optional<SceneNode> = null;
    if (find) {
      ({ orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesOfNodeByType(
        find.node,
        dimensions
      ));
      this.caches[find.node.id] = null as any;
      this.array.splice(find.index, 1);
      this.array.forEach((id, index) => {
        const n = this.caches[id];
        if (n) {
          this.caches[id] = { ...n, index };
        }
      });
      if (this.initialSceneId === key) {
        this.initialSceneId = '';
        this.array.some(id => {
          if (this.caches[id]) {
            const maybeNewInitialScene = this.caches[id]?.node;
            if (
              maybeNewInitialScene &&
              (maybeNewInitialScene.type === 'message' || maybeNewInitialScene.type === 'carousel')
            ) {
              newInitialScene = maybeNewInitialScene;
              newInitialScene.isInitialScene = true;
              this.initialSceneId = newInitialScene.id;
              return true;
            }
          }
          return false;
        });
      }
      if (this.selectedId === find.node.id) {
        this.selectedId = '';
      }
    }
    return { orphanScenes, noDestQuickRepliesScenes, newInitialScene };
  }

  public filter(callback: (node: SceneNode, index: number) => boolean): SceneNode[] {
    return this.array.flatMap((id, index) => {
      const target = this.caches[id];
      if (target?.node && callback(target.node, index)) {
        return [target.node];
      }
      return [];
    });
  }

  public find(key: string): Optional<SceneNode> {
    return this.caches[key]?.node;
  }

  public getInitialScene(): Optional<SceneNode> {
    return this.caches[this.initialSceneId]?.node;
  }

  public getSelectedNode(): Optional<SceneNode> {
    return this.selectedId ? this.caches[this.selectedId]?.node : null;
  }

  public hasInitialScene() {
    return !!this.initialSceneId;
  }

  public insert(node: SceneNode): void {
    if (!this.caches[node.id]) {
      this.array.push(node.id);
      this.caches[node.id] = { node, index: this.array.length - 1 };
    } else {
      const f = this.caches[node.id];
      if (f) {
        f.node = node;
      }
    }
    if ((node.type === 'message' || node.type === 'carousel') && node.isInitialScene) {
      this.initialSceneId = node.id;
    }
  }

  public map<T>(callback: (node: SceneNode, index: number) => T): T[] {
    return this.array.map((id, index) => {
      return callback(required(this.caches[id]).node, index);
    });
  }

  public select(id: string): Optional<SceneNode> {
    if (this.caches[id]) {
      this.selectedId = id;
      return this.caches[id]?.node;
    }
    this.selectedId = '';
    return null;
  }

  public get selectedNode(): Optional<SceneNode> {
    return this.selectedId ? this.caches[this.selectedId]?.node : null;
  }

  public setInitialScene(sceneId: string): void {
    const target = this.caches[sceneId];
    if (target && (target.node.type === 'message' || target.node.type === 'carousel')) {
      if (this.initialSceneId) {
        const initialSceneCache = this.caches[this.initialSceneId];
        if (initialSceneCache) {
          (initialSceneCache.node as MessageNode).isInitialScene = false;
        }
      }
      target.node.isInitialScene = true;
      this.initialSceneId = this.caches[sceneId]?.node.id ?? '';
    }
  }

  public size(): number {
    return this.array.length;
  }

  public toArray(): SceneNode[] {
    return this.array.map(id => this.caches[id]!.node);
  }

  public toJSON(): SceneNodeCacheJsonFormat {
    return {
      initialSceneId: this.initialSceneId,
      selectedId: this.selectedId,
      nodes: this.array.map(id => this.caches[id]!.node),
    };
  }

  private deleteAllEdgesFromBusinessHourNode(
    node: BusinessHourNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const { orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesFromFromEdges(
      node,
      dimensions
    );
    node.request.results.forEach(({ edge }) => {
      const entry = this.caches[edge];
      if (!entry) {
        return;
      }
      entry.node.fromEdges = entry.node.fromEdges.filter(e => e !== node.id);
      if (isOrphanScene(entry.node)) {
        orphanScenes.push(entry.node);
      }
    });

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesFromCallOperatorNode(
    node: CallOperatorNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    return this.deleteAllEdgesFromFromEdges(node, dimensions);
  }

  private deleteAllEdgesFromCarouselNode(
    node: IgCarouselNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const { orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesFromFromEdges(
      node,
      dimensions
    );
    const buttons = [
      ...node.carousel.elements.flatMap(element => {
        return element.buttonsView;
      }),
      node.freeInputEdgeView,
    ];
    for (const { edge } of buttons) {
      const entry = this.caches[edge];
      if (entry) {
        entry.node.fromEdges = entry.node.fromEdges.filter(e => e !== node.id);
        if (isOrphanScene(entry.node)) {
          orphanScenes.push(entry.node);
        }
      }
    }

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesFromFromEdges(
    node: SceneNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const orphanScenes: SceneNode[] = [];
    const noDestQuickRepliesScenes: SceneNode[] = [];
    delete dimensions[node.id];
    node.fromEdges.forEach(edge => {
      const target = this.caches[edge]?.node;
      if (!target) {
        return;
      }
      if (target.type === 'message') {
        if (
          target.response.quickReplies.concat(target.freeInputEdgeView).some(q => {
            if (q.edge === node.id) {
              q.edge = '';
              return true;
            }
          })
        ) {
          noDestQuickRepliesScenes.push(target);
        }
        if (target.customFeedbackView) {
          let isInvalidFound = false;
          if (target.customFeedbackView.no.edge === node.id) {
            target.customFeedbackView.no.edge = '';
            isInvalidFound = true;
          }
          if (target.customFeedbackView.yes.edge === node.id) {
            target.customFeedbackView.yes.edge = '';
            isInvalidFound = true;
          }
          if (isInvalidFound) {
            noDestQuickRepliesScenes.push(target);
          }
        }
      } else if (target.type === 'businessHour') {
        if (target.request.results[0].edge === node.id) {
          target.request.results[0].edge = '';
        } else if (target.request.results[1].edge === node.id) {
          target.request.results[1].edge = '';
        }
        noDestQuickRepliesScenes.push(target);
      } else if (target.type === 'carousel') {
        if (
          target.carousel.elements
            .flatMap(element => element.buttonsView)
            .some(b => {
              if (b.edge === node.id) {
                b.edge = '';
                return true;
              }
            })
        ) {
          noDestQuickRepliesScenes.push(target);
        } else if (target.freeInputEdgeView.edge === node.id) {
          target.freeInputEdgeView.edge = '';
          noDestQuickRepliesScenes.push(target);
        }
      } else if (target.type === 'integration') {
        if (
          target.integration.succeeded.quickReplies?.some(q => {
            if (q.edge === node.id) {
              q.edge = '';
              return true;
            }
          })
        ) {
          noDestQuickRepliesScenes.push(target);
        } else if (target.integration.succeeded.defaultButton.edge === node.id) {
          target.integration.succeeded.defaultButton.edge = '';
          noDestQuickRepliesScenes.push(target);
        } else if (target.integration.failedButton.edge === node.id) {
          target.integration.failedButton.edge = '';
          noDestQuickRepliesScenes.push(target);
        }
      } else if (target.type === 'mail') {
        if (
          target.mail.edgesView?.succeeded.edge &&
          target.mail.edgesView.succeeded.edge === node.id
        ) {
          target.mail.edgesView.succeeded.edge = '';
        } else if (
          target.mail.edgesView?.failed.edge &&
          target.mail.edgesView.failed.edge === node.id
        ) {
          target.mail.edgesView.failed.edge = '';
        }
      }
    });

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesFromIntegrationNode(
    node: IntegrationNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const { orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesFromFromEdges(
      node,
      dimensions
    );
    const buttons = [
      node.integration.succeeded.defaultButton,
      node.integration.failedButton,
    ].concat(node.integration.succeeded.quickReplies || []);

    buttons.forEach(({ edge }) => {
      const entry = this.caches[edge];
      if (!entry) {
        return;
      }
      entry.node.fromEdges = entry.node.fromEdges.filter(e => e !== node.id);
      if (isOrphanScene(entry.node)) {
        orphanScenes.push(entry.node);
      }
    });

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesFromMailNode(
    node: MailNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const { orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesFromFromEdges(
      node,
      dimensions
    );
    const buttons = [node.mail.edgesView!.succeeded, node.mail.edgesView!.failed];

    buttons.forEach(({ edge }) => {
      const entry = this.caches[edge];
      if (!entry) {
        return;
      }
      entry.node.fromEdges = entry.node.fromEdges.filter(e => e !== node.id);
      if (isOrphanScene(entry.node)) {
        orphanScenes.push(entry.node);
      }
    });

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesFromMessageNode(
    node: MessageNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    const { orphanScenes, noDestQuickRepliesScenes } = this.deleteAllEdgesFromFromEdges(
      node,
      dimensions
    );
    node.response.quickReplies
      .concat(node.freeInputEdgeView)
      .concat(
        node.customFeedbackView ? [node.customFeedbackView.yes, node.customFeedbackView.no] : []
      )
      .forEach(({ edge }) => {
        const entry = this.caches[edge];
        if (!entry) {
          return;
        }
        entry.node.fromEdges = entry.node.fromEdges.filter(e => e !== node.id);
        if (!entry.node.fromEdges.length) {
          orphanScenes.push(entry.node);
        }
      });

    return { orphanScenes, noDestQuickRepliesScenes };
  }

  private deleteAllEdgesOfNodeByType(
    node: SceneNode,
    dimensions: SceneDimensionCache
  ): { orphanScenes: SceneNode[]; noDestQuickRepliesScenes: SceneNode[] } {
    switch (node.type) {
      case 'message':
        return this.deleteAllEdgesFromMessageNode(node, dimensions);
      case 'businessHour':
        return this.deleteAllEdgesFromBusinessHourNode(node, dimensions);
      case 'callOperator':
        return this.deleteAllEdgesFromCallOperatorNode(node, dimensions);
      case 'integration':
        return this.deleteAllEdgesFromIntegrationNode(node, dimensions);
      case 'mail':
        return this.deleteAllEdgesFromMailNode(node, dimensions);
      case 'carousel':
        return this.deleteAllEdgesFromCarouselNode(node, dimensions);
      default:
        throw new Error('unsupported type');
    }
  }
}

export type SceneCache = VectorHashForImmer;

export type SceneMessageAttachment =
  | PlainTextMessageEntity['attachment']
  | ImageMessageEntity['attachment']
  | CarouselMessageEntity['attachment'];

export type SceneMessageType = SceneMessageAttachment['mediaType'];

interface BaseNode {
  baseId?: string;
  id: SceneId;
  fromEdges: SceneId[];
  analytics?: Analytics;
}

export type NodeButton = {
  isActionButton?: boolean;
  id: string;
  type: 'text' | 'postback' | 'weburl';
  label: string;
  edge: SceneId;
  url?: string;
};

export interface MessageNode extends BaseNode {
  textInputState: InputStateValues;
  type: 'message';
  isInitialScene: boolean;
  fallback: SceneId;
  fallbackView: NodeButton;
  shouldUseFallbackView: boolean;
  freeInputEdge: string;
  freeInputEdgeView: NodeButton;
  customFeedback?: {
    edgeYes: string;
    edgeNo: string;
  };
  customFeedbackView?: {
    yes: NodeButton;
    no: NodeButton;
  };
  paramId?: string;
  response: {
    messages: {
      attachment: SceneMessageAttachment;
      id: string;
    }[];
    quickReplies: NodeButton[];
  };
}

export type CarouselSceneButton = {
  title: string;
  edge: string;
};

export type CarouselSceneElement = {
  id: number;
  title: string;
  buttons: CarouselSceneButton[];
  buttonsView: NodeButton[];
};

export interface IgCarouselNode extends BaseNode {
  textInputState: 'show';
  type: 'carousel';
  isInitialScene: boolean;
  freeInputEdge: string;
  freeInputEdgeView: NodeButton;
  customFeedback?: {
    edgeYes: string;
    edgeNo: string;
  };
  customFeedbackView?: {
    yes: NodeButton;
    no: NodeButton;
  };
  paramId?: string;
  carousel: {
    id?: string;
    displayName?: string;
    contents: {
      id: string;
      attachment: CarouselMessageEntity['attachment'];
    }[];
    elements: CarouselSceneElement[];
  };
}

export interface BusinessHourNode extends BaseNode {
  type: 'businessHour';
  request: {
    results: [
      {
        id: string;
        key: '_businessHour';
        op: 'eq';
        value: 'open';
        displayName: '営業中';
        edge: string;
      },
      {
        id: string;
        key: '_businessHour';
        op: 'eq';
        value: 'close';
        displayName: '営業時間外';
        edge: string;
      }
    ];
  };
}

export interface CallOperatorNode extends BaseNode {
  type: 'callOperator';
  response: {
    messages: {
      attachment: SceneMessageAttachment;
      id: string;
    }[];
  };
}

export interface MailNode extends BaseNode {
  type: 'mail';
  mail: {
    to: {
      name: string;
      address: string;
    }[];
    replyTo: {
      name: string;
      address: string;
    };
    subject: string;
    body: string;
    edges?: { succeeded: string; failed: string };
    edgesView?: {
      succeeded: NodeButton;
      failed: NodeButton;
    };
  };
}

export type IntegrationMethod = 'post';
export type IntegrationHeader = { [key: string]: string };
export interface IntegrationNode extends BaseNode {
  type: 'integration';
  integration: {
    request: {
      endpoint: string;
      method: IntegrationMethod;
      header: IntegrationHeader;
    };
    succeeded: {
      quickReplies?: NodeButton[];
      default?: string;
      defaultButton: NodeButton;
    };
    failed?: string;
    failedButton: NodeButton;
  };
}

export interface ActionNodeList {
  nodes: (BusinessHourNode | CallOperatorNode | IntegrationNode)[];
}

export type SceneNode =
  | MessageNode
  | IgCarouselNode
  | BusinessHourNode
  | CallOperatorNode
  | IntegrationNode
  | MailNode;

export type ButtonControlPointCoordinate = {
  lx: number;
  rx: number;
  y: number;
};
export type ControlPointCoordinate = {
  top: Coordinate;
  bottom: Coordinate;
  left: Coordinate;
  right: Coordinate;
};

export type Analytics = {
  finalReachedNum: number;
  nodeReachedNum: number;
  nodeExitNum: number;
  finalReachedRate: number;
  nodeExitRate: number;
  resolutionRate: number;
};

export interface SceneNodesCommonProperties {
  id: string;
  updated: boolean;
  feed: boolean;
  switchToOp: boolean;
  dimensions: {
    [key: string]:
      | {
          layoutId: string;
          x: number;
          y: number;
          width: number;
          height: number;
          controlPoint: ControlPointCoordinate;
          controlPoints: ({
            id: string;
          } & ButtonControlPointCoordinate)[];
        }
      | undefined;
  };
  parameters?: ScenarioParameter[];
}

export type CSVImportedSceneNode = SceneNode & { faq: string; faqId: string | undefined };

export type CSVImportedRemoteSceneNodes = CSVImportedSceneNode[];

export interface RemoteSceneNodes extends Partial<SceneNodesCommonProperties> {
  nodes: SceneNode[];
}

export interface SceneNodes extends SceneNodesCommonProperties {
  nodes: SceneCache;
  isAnalytics: boolean;
}

export interface SceneNodesJson extends SceneNodesCommonProperties {
  nodes: SceneNodeCacheJsonFormat;
  isAnalytics: boolean;
}

export const generateSceneId = () => {
  const date = new Date();
  return `${date.getFullYear()}${`${date.getMonth() + 1}`.padStart(
    2,
    '0'
  )}${`${date.getDate()}`.padStart(2, '0')}${v4()}`;
};

export const addCustomFeedbackToSceneNode = (
  messageNode: MessageNode,
  feedbacks: { edgeYes: string; edgeNo: string } = { edgeYes: '', edgeNo: '' }
) => {
  messageNode.customFeedbackView = {
    yes: {
      isActionButton: true,
      id: `feedback_${v4()}`,
      label: '[フィードバック結果] はい',
      type: 'text',
      edge: feedbacks.edgeYes,
    },
    no: {
      isActionButton: true,
      id: `feedback_${v4()}`,
      label: '[フィードバック結果] いいえ',
      type: 'text',
      edge: feedbacks.edgeNo,
    },
  };
};

export const addIntegrationButtonsToSceneNode = (
  integrationNode: IntegrationNode,
  defaultEdge: string,
  failedEdge: string
) => {
  integrationNode.integration.succeeded.defaultButton = {
    isActionButton: true,
    id: `_Temporary_${v4()}_${Date.now()}`,
    type: 'text',
    label: '成功時(省略可能)',
    edge: defaultEdge,
  };
  integrationNode.integration.failedButton = {
    isActionButton: true,
    id: `_Temporary_${v4()}_${Date.now()}`,
    type: 'text',
    label: 'エラー発生時',
    edge: failedEdge,
  };
};

export const addMailEdgesToSceneNode = (
  node: MailNode,
  succeededEdge: string,
  failedEdge: string
) => {
  node.mail.edgesView = {
    succeeded: {
      isActionButton: true,
      id: `_Temporary_${v4()}_${Date.now()}`,
      type: 'text',
      label: 'メール送信成功時',
      edge: succeededEdge,
    },
    failed: {
      isActionButton: true,
      id: `_Temporary_${v4()}_${Date.now()}`,
      type: 'text',
      label: 'メール送信失敗時',
      edge: failedEdge,
    },
  };
};

export const setParameterToSceneNode = (messageNode: MessageNode, parameter: string) => {
  messageNode.paramId = parameter;
};

type EdgeInformation = { id: string; edge: string };
export const getAllEdgesFromSceneNode = (
  scene: SceneNode | ReadonlyDeep<SceneNode>
): EdgeInformation[] => {
  switch (scene.type) {
    case 'message': {
      return scene.response.quickReplies
        .concat(
          scene.customFeedbackView
            ? [scene.customFeedbackView.yes, scene.customFeedbackView.no]
            : []
        )
        .concat(scene.textInputState !== 'hide' ? [scene.freeInputEdgeView] : [])
        .concat(scene.shouldUseFallbackView ? [scene.fallbackView] : []);
    }
    case 'businessHour': {
      return scene.request.results.slice();
    }
    case 'mail':
      return [scene.mail.edgesView!.succeeded, scene.mail.edgesView!.failed];
    case 'callOperator':
      return [] as any;
    case 'integration': {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return scene.integration.succeeded?.quickReplies &&
        scene.integration.succeeded.quickReplies.length > 0
        ? scene.integration.succeeded.quickReplies.concat(scene.integration.failedButton)
        : [scene.integration.failedButton, scene.integration.succeeded.defaultButton];
    }
    case 'carousel': {
      if (Array.isArray(scene.carousel.elements)) {
        return [
          ...scene.carousel.elements.flatMap(element => {
            return element.buttonsView;
          }),
          scene.freeInputEdgeView,
        ];
      }
    }
    default:
      return [] as any;
  }
};

export const freeInputEdge = (edge: string): NodeButton => ({
  type: 'text',
  id: 'free-input-edge',
  label: 'ユーザー入力',
  isActionButton: true,
  edge,
});
export const fallbackView = (edge: string): NodeButton => {
  return {
    type: 'text',
    edge,
    id: 'fallback',
    label: '自動遷移',
    isActionButton: true,
  };
};
export const messageNode = (sceneNode: Partial<MessageNode> = {}, id?: string): MessageNode => {
  const ret: MessageNode = {
    isInitialScene: false,
    fromEdges: [],
    freeInputEdge: '',
    freeInputEdgeView: freeInputEdge(''),
    textInputState: 'hide' as const,
    type: 'message' as const,
    fallback: '',
    fallbackView: fallbackView(''),
    shouldUseFallbackView: false,
    ...sceneNode,
    id: id ?? generateSceneId(),
    response: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...(sceneNode?.response ?? {}),
      messages: [
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ...(sceneNode?.response?.messages ?? [
          {
            attachment: MessageFactory.text('').attachment,
            id: `_Temporary_${v4()}_${Date.now()}`,
          },
        ]),
      ],
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      quickReplies: sceneNode?.response?.quickReplies ?? [],
    },
  };
  // if (!ret.response.quickReplies.find(v => v.id === FALLBACK_BUTTON_ID)) {
  //   ret.response.quickReplies.push(getFallbackQuickReply(ret.fallback));
  // }

  return ret;
};

export const igCarouselNode = (sceneNode: Partial<IgCarouselNode> = {}): IgCarouselNode => {
  const ret: IgCarouselNode = {
    textInputState: 'show',
    isInitialScene: false,
    fromEdges: [],
    freeInputEdge: '',
    freeInputEdgeView: freeInputEdge(''),
    type: 'carousel' as const,
    ...sceneNode,
    id: generateSceneId(),
    carousel: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      contents: sceneNode?.carousel?.contents ?? [],
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      elements: sceneNode?.carousel?.elements ?? [],
    },
  };

  return ret;
};

export const businessHourNode = (
  node: Partial<BusinessHourNode> = {},
  id?: string
): BusinessHourNode => {
  const ret: BusinessHourNode = {
    fromEdges: [],
    type: 'businessHour' as const,
    ...node,
    id: id ?? generateSceneId(),
    request: {
      results: [
        {
          id: v4(),
          key: '_businessHour',
          op: 'eq',
          value: 'open',
          displayName: '営業中',
          edge: node?.request?.results?.[0]?.edge ?? '',
        },
        {
          id: v4(),
          key: '_businessHour',
          op: 'eq',
          value: 'close',
          displayName: '営業時間外',
          edge: node?.request?.results?.[1]?.edge ?? '',
        },
      ],
    },
  };
  return ret;
};

export const callOperatorNode = (
  node: Partial<CallOperatorNode> = {},
  id?: string
): CallOperatorNode => {
  const ret: CallOperatorNode = {
    fromEdges: [],
    type: 'callOperator' as const,
    ...node,
    id: id ?? generateSceneId(),
    response: {
      messages: [
        {
          attachment: MessageFactory.text('').attachment,
          id: `_Temporary_${v4()}_${Date.now()}`,
        },
      ],
    },
  };
  return ret;
};

export const integrationNode = (
  node: Partial<IntegrationNode> = {},
  option: {
    id?: string;
    succeededEdge?: string;
    failedEdge?: string;
    edges?: { edge: string; label: string }[];
    requestOption?: {
      endpoint: string;
      method: IntegrationMethod;
      header: { [key: string]: string };
    };
  } = {}
): IntegrationNode => {
  const ret: IntegrationNode = {
    fromEdges: [],
    type: 'integration' as const,
    ...node,
    id: option.id ?? generateSceneId(),
    integration: {
      request: {
        endpoint: option.requestOption?.endpoint ?? '',
        method: option.requestOption?.method ?? 'post',
        header: option.requestOption?.header ?? {},
      },
      succeeded: {
        quickReplies:
          option.edges?.map?.(edge => ({
            id: `_Temporary_${v4()}_${Date.now()}`,
            type: 'text',
            label: edge.label,
            edge: edge.edge,
          })) ?? [],
        default: option.succeededEdge ?? '',
        defaultButton: {
          isActionButton: true,
          id: `_Temporary_${v4()}_${Date.now()}`,
          type: 'text',
          label: '成功時(省略可能)',
          edge: option.succeededEdge ?? '',
        },
      },
      failed: option.failedEdge ?? '',
      failedButton: {
        isActionButton: true,
        id: `_Temporary_${v4()}_${Date.now()}`,
        type: 'text',
        label: 'エラー発生時',
        edge: option.failedEdge ?? '',
      },
    },
  };
  return ret;
};

export const mailNode = (
  node: Partial<MailNode> = {},
  options: { id?: string; succeededEdge?: string; failedEdge?: string } = {}
): MailNode => {
  const ret: MailNode = {
    fromEdges: [],
    type: 'mail',
    ...node,
    id: options.id ?? generateSceneId(),
    mail: {
      to: [],
      replyTo: {
        name: '',
        address: '',
      },
      subject: '',
      body: '',
      ...(node.mail ?? {}),
      edges: {
        succeeded: options.succeededEdge ?? '',
        failed: options.failedEdge ?? '',
      },
      edgesView: {
        succeeded: {
          isActionButton: true,
          id: `_Temporary_${v4()}_${Date.now()}`,
          type: 'text',
          label: 'メール送信成功時',
          edge: options.succeededEdge ?? '',
        },
        failed: {
          isActionButton: true,
          id: `_Temporary_${v4()}_${Date.now()}`,
          type: 'text',
          label: 'メール送信失敗時',
          edge: options.failedEdge ?? '',
        },
      },
    },
  };

  return ret;
};

export const createDefaultNodeFromType = (type: SceneType): SceneNode => {
  switch (type) {
    case SceneType.SCENARIO:
      return messageNode();
    case SceneType.BUSINESS_HOUR:
      return businessHourNode();
    case SceneType.CALL_OPERATOR:
      return callOperatorNode();
    case SceneType.INTEGRATION:
      return integrationNode();
    case SceneType.MAIL:
      return mailNode();
    case SceneType.IG_CAROUSEL:
      return igCarouselNode();
    default:
      throw new Error('unsupported node type');
  }
};

export type SceneDimension = ValueOf<SceneNodes['dimensions']>;
export type SceneDimensionCache = SceneNodes['dimensions'];

export type CsvConvertedNodesMap = Map<
  string,
  { id?: string; nodes: SceneNodes; params: Set<string> }
>;

export type RegisteredCsvNodesMap = Map<
  string,
  { id: string; nodes: SceneNodes; params: Set<string> }
>;

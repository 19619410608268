/**
 * @fileOverview
 * @author
 */

import { TextEncoder } from '@s/dom/TextEncoderPolyfill';

export type FirestoreChatEntity = {
  tenantId: string;
  projectId: string;
  customerId: string;
  customerName: string;
  lastMessage: {
    senderType: string;
    createdAt: { seconds: number; nanoseconds: number };
  };
  senderType: string;
  callingOperator: boolean;
  operatorId: string;
  operatorName: string;
  operatorVisitCount: number;
  operatorChatStarted: boolean;
  lastOperatorCalledAt: number;
  createdAt: { seconds: number; nanoseconds: number };
  updatedAt: { seconds: number; nanoseconds: number };
  labelIds?: string[];
  archivedAt?: { seconds: number; nanoseconds: number };
};

export const enum ChatAssignType {
  SELF,
  ALL,
  BOT,
  NOT_RESPONDING,
  NOW_RESPONDING,
  ARCHIVES,
}

export type ChatEntity = FirestoreChatEntity & { assignType: ChatAssignType; id: string };

export type ChatTraits = {
  isAllowUnicasting: boolean;
  isAllowControllingCustomerImageUploading: boolean;
  isAllowSendingImageFromOperator: boolean;
  unicastingRestriction: {
    lastMessageFrom: number;
    lastMessageFromOperator: number;
  };
  isValidMessage(text: string): boolean;
};

export const CHAT_TRAITS: { instagram: ChatTraits; chat: ChatTraits } & {
  [key: string]: ChatTraits | undefined;
} = {
  instagram: {
    isAllowUnicasting: true,
    isAllowControllingCustomerImageUploading: false,
    isAllowSendingImageFromOperator: true,
    unicastingRestriction: {
      lastMessageFrom: 1440000,
      lastMessageFromOperator: 10080000,
    },
    isValidMessage: (text: string) =>
      !!text.length && new TextEncoder().encode(text).byteLength <= 1000,
  },
  chat: {
    isAllowUnicasting: false,
    isAllowControllingCustomerImageUploading: true,
    isAllowSendingImageFromOperator: true,
    unicastingRestriction: {
      lastMessageFrom: 0,
      lastMessageFromOperator: 0,
    },
    isValidMessage: (text: string) => !!text.length && text.length <= 300,
  },
};

export const DEFAULT_CHAT_TRAIT = CHAT_TRAITS.chat;

/**
 * @fileOverview
 * @name ProjectDetailSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  DEFAULT_LINEPROJECT_SETTING_ITEM,
  LineProjectSetting,
  LineProjectSettingItem,
} from '../entities/Line';

class LineSettingProperties implements LineProjectSetting {
  public channel = { ...DEFAULT_LINEPROJECT_SETTING_ITEM };
  public previewChannel?: LineProjectSettingItem;
}

export interface LineSettingSpecification extends LineSettingProperties {
  validate(): [boolean, string];
  generateUpdateInfo(lineSetting?: LineSettingProperties): {
    channel: UpdatedType;
    previewChannel: UpdatedType;
  };
}

export const enum UpdatedType {
  NONE,
  INIT,
  EDIT,
}

export class LineSettingParameterProperties
  extends LineSettingProperties
  implements LineSettingSpecification
{
  private updatedInfoCache?: ReturnType<LineSettingSpecification['generateUpdateInfo']>;

  public constructor(args?: LineSettingProperties) {
    super();
    if (args) {
      Object.assign(this, args);
    }
  }

  public generateUpdateInfo(
    lineSetting: Parameters<LineSettingSpecification['generateUpdateInfo']>[0]
  ): ReturnType<LineSettingSpecification['generateUpdateInfo']> {
    return (
      this.updatedInfoCache ??
      (this.updatedInfoCache = {
        channel: this.isChannelInfoUpdated('channel', lineSetting?.channel),
        previewChannel: this.isChannelInfoUpdated('previewChannel', lineSetting?.previewChannel),
      })
    );
  }

  public validate(): [boolean, string] {
    if (!this.channel.id) {
      return [false, 'チャネルIDは必須です'];
    }
    if (!this.channel.secret) {
      return [false, 'チャネルシークレットは必須です'];
    }
    if (!this.channel.name) {
      return [false, 'チャネル名は必須です'];
    }
    if (!this.channel.providerId) {
      return [false, 'Provider IDは必須です'];
    }

    if (this.previewChannel) {
      if (!this.channel.id) {
        return [false, 'プレビュー用チャネルIDは必須です'];
      }
      if (!this.channel.secret) {
        return [false, 'プレビュー用チャネルシークレットは必須です'];
      }
      if (!this.channel.name) {
        return [false, 'プレビュー用チャネル名は必須です'];
      }
      if (!this.channel.providerId) {
        return [false, 'プレビュー用Provider IDは必須です'];
      }
    }

    return [true, ''];
  }

  private isChannelInfoUpdated(
    type: keyof LineSettingProperties,
    channel?: LineSettingProperties['channel']
  ): UpdatedType {
    if (!channel && !this[type]) {
      return UpdatedType.NONE;
    }
    if (!channel && !!this[type]) {
      return UpdatedType.INIT;
    }
    if (this[type]?.id && !channel?.id) {
      return UpdatedType.INIT;
    }
    if (this[type]?.secret !== channel?.secret) {
      return UpdatedType.EDIT;
    }
    if (this[type]?.name !== channel?.name) {
      return UpdatedType.EDIT;
    }
    return UpdatedType.NONE;
  }
}

/**
 * @fileOverview
 * @name InstagramSettingQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';
import { InstagramProjectSetting } from '@c/domain/entities/Instagram';

export type InstagramSettingGettableQuery = GettableQuery<
  { projectId: string },
  InstagramProjectSetting
>;

export class InstagramSettingQuery implements InstagramSettingGettableQuery {
  public async get({
    projectId,
  }: Parameters<InstagramSettingGettableQuery['get']>[0]): ReturnType<
    InstagramSettingGettableQuery['get']
  > {
    return fetchService(apiEndpoint(`/project/${projectId}/instagram/setting`), {
      method: 'GET',
      responseType: 'json',
    });
  }
}

/**
 * @fileOverview
 * @author
 */

import produce from 'immer';
import {
  Namespaces,
  oneTurnMessageAdded,
  oneTurnMessageQuickReplyAdded,
  oneTurnMessageImageUpdated,
  oneTurnMessageDeleted,
  oneTurnMessageQuickReplyDeleted,
  oneTurnMessageTextUpdated,
  oneTurnMessageTypeUpdated,
} from '@c/modules/oneturnMessage/action';
import { dynamciNamespaceIc } from '../dynamicNamespaceIC';
import { OneTurnMessageCommonState } from '@c/state';
import { MessageFactory } from '@c/domain/entities/MessageEntity';

type ActionType = ReturnType<
  | typeof oneTurnMessageAdded
  | typeof oneTurnMessageQuickReplyAdded
  | typeof oneTurnMessageImageUpdated
  | typeof oneTurnMessageDeleted
  | typeof oneTurnMessageQuickReplyDeleted
  | typeof oneTurnMessageTextUpdated
  | typeof oneTurnMessageTypeUpdated
>;

const invokeNamespaceIc = <T>() => dynamciNamespaceIc<T>();

export default (namespace: Namespaces) => (state: any, action: ActionType) => {
  if ((action as any)?.payload?.namespace !== namespace) {
    return state;
  }

  return produce(state, (state: any) => {
    const getCommonState = <T>() =>
      invokeNamespaceIc<T>()({ state, namespace: action.payload.namespace });
    const commonState =
      action.payload.stateIdx === undefined
        ? getCommonState<OneTurnMessageCommonState>()
        : getCommonState<OneTurnMessageCommonState[]>()[action.payload.stateIdx];

    switch (action.type) {
      case 'ONE_TURN_MESSAGE_ADDED':
        commonState.oneTurnMessages.messages.push(action.payload.messages);
        return;
      case 'ONE_TURN_MESSAGE_QUICK_REPLY_ADDED':
        const target = commonState.oneTurnMessages.messages[action.payload.index];
        if (target.attachment.mediaType === 'plainText') {
          target.attachment.quickReplies = action.payload.quickReplies;
        }
        return;
      case 'ONE_TURN_MESSAGE_IMAGE_UPDATED': {
        const target = commonState.oneTurnMessages.messages[action.payload.index];
        if (target.attachment.mediaType === 'image') {
          target.attachment.payload.url = action.payload.url;
          target.attachment.payload.mediaId = action.payload.mediaId;
        }
        return;
      }
      case 'ONE_TURN_MESSAGE_DELETED': {
        commonState.oneTurnMessages.messages.splice(action.payload.index, 1);
        return;
      }
      case 'ONE_TURN_MESSAGE_QUICK_REPLY_DELETED': {
        commonState.oneTurnMessages.messages[action.payload.index].attachment.quickReplies =
          undefined;
        return;
      }
      case 'ONE_TURN_MESSAGE_TEXT_UPDATED': {
        const target = commonState.oneTurnMessages.messages[action.payload.index];
        if (target.attachment.mediaType === 'plainText') {
          target.attachment.payload = action.payload.text;
        }
        return;
      }
      case 'ONE_TURN_MESSAGE_TYPE_UPDATED': {
        const target = commonState.oneTurnMessages.messages[action.payload.index];
        if (target.attachment.mediaType !== action.payload.type) {
          target.attachment.payload = MessageFactory.createFromType(action.payload.type).attachment
            .payload as any;
          target.attachment.mediaType = action.payload.type;
          if (target.attachment.mediaType === 'image') {
            target.attachment.payload.mediaId = '';
          }
        }
        return;
      }
      default:
        return state;
    }
  });
};

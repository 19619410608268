/**
 * @fileOverview
 * @name lineSettingAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  DEFAULT_LINEPROJECT_SETTING_ITEM,
  LineProjectSetting,
  RemoteLineProjectSetting,
} from '@c/domain/entities/Line';

export const lineSettingAdapter = (p: RemoteLineProjectSetting): LineProjectSetting => {
  if (!p.channel) {
    p.channel = DEFAULT_LINEPROJECT_SETTING_ITEM;
  } else {
    p.channel.isEditable = false;
  }
  if (p.previewChannel) {
    p.previewChannel.isEditable = false;
  }
  return p as LineProjectSetting;
};

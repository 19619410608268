import React from 'react';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import styled from '@emotion/styled';
import { Box, Flex } from '@s/components/atom/Box';
import { largeTextStyle } from '@s/components/atom/Text';
import { Button } from '@s/components/atom/Button';
import { useAsyncAction, useRefState } from '@s/reactHooks';
import { GenericLogo } from '@c/components/atom/GenericLogo';
import { verifyMultiFactorCode, verifyMultiFactorCodeInRegister } from '@c/modules/account/usecase';
import { useErrorNotificator } from '@c/components/hooks/useErrorNotificator';
import { VerificationCodeParameter } from '@c/domain/entities/Account';
import { useHistory } from 'react-router';
import { useNotificator } from '@c/components/atom/Toast';
import { TextInput } from '@c/shared/components/TextInput';

const MultiFactorContentWrapperElement = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  > div {
    width: 100%;
    height: 100%;
  }
`;
const LabelElement = styled.div`
  ${largeTextStyle};
  font-weight: bold;
  margin: 0 0 30px 0;
`;

export const MultiFactorAuth = compareOnlyProperties(
  ({ type }: { type: 'register' | 'verify' }) => {
    const verifyAuthenticationCodeAction = useAsyncAction(verifyMultiFactorCode);
    const verifyMultiFactorAuthCodeInRegisterAction = useAsyncAction(
      verifyMultiFactorCodeInRegister
    );

    const [verificationCode, setVerificationCode] = useRefState('');
    const history = useHistory();
    const notificator = useNotificator();

    useErrorNotificator(verifyAuthenticationCodeAction.error);
    useErrorNotificator(verifyMultiFactorAuthCodeInRegisterAction.error);

    verifyMultiFactorAuthCodeInRegisterAction.succeededCallback = () => {
      notificator.success('電話番号を登録しました');
    };

    return (
      <MultiFactorContentWrapperElement>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          css={{ position: 'relative', height: '100%' }}
        >
          <Flex justifyContent="center" alignItems="center">
            <Box margin="0 0 80px 0">
              <GenericLogo size={200} />
            </Box>
          </Flex>
          <LabelElement>ご登録いただいた電話番号に認証コードを送信しました</LabelElement>
          <Flex>
            <TextInput
              width={300}
              border
              placeholder={{
                value: '6桁の認証コードを入力してください',
                type: 'dynamic',
              }}
              onChange={e => setVerificationCode(e.target.value)}
            />
            <Button
              css={{ marginLeft: '10px' }}
              type={'normal'}
              label="認証コードを確認"
              loading={
                verifyMultiFactorAuthCodeInRegisterAction.loading ||
                verifyAuthenticationCodeAction.loading
              }
              onClick={() => {
                if (type === 'register') {
                  verifyMultiFactorAuthCodeInRegisterAction(
                    new VerificationCodeParameter({ verificationCode: verificationCode.current })
                  );
                } else {
                  verifyAuthenticationCodeAction(
                    new VerificationCodeParameter({ verificationCode: verificationCode.current })
                  );
                }
              }}
            />
          </Flex>
          <Button
            type={'normal'}
            label={'認証コードを再送する'}
            loading={
              verifyMultiFactorAuthCodeInRegisterAction.loading ||
              verifyAuthenticationCodeAction.loading
            }
            css={{ marginTop: '30px' }}
            onClick={() => {
              type === 'register'
                ? history.push('/phone-auth')
                : history.push('/send-verification-code');
            }}
          />
        </Flex>
      </MultiFactorContentWrapperElement>
    );
  },
  'MultiFactorAuth'
);

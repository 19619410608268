/**
 * @fileOverview
 * @name MediaSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

export interface MediaSpecification {
  isValidFile(file: File): [boolean, string];
}

const MIME_TYPE_REGEXP = /(image\/png)|(image\/gif)|(image\/jpe?g)/;
const MAX_FILE_SIZE = 1024 * 1024 * 5;

export class MediaValidator implements MediaSpecification {
  public isValidFile(file: File): [boolean, string] {
    if (!MIME_TYPE_REGEXP.test(file.type)) {
      return [false, 'アップロードするファイルはPNG/JPEG/GIFのいずれかである必要があります'];
    }
    if (file.size > MAX_FILE_SIZE) {
      return [false, 'ファイルサイズは5MB以下である必要があります'];
    }
    return [true, ''];
  }
}

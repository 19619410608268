/**
 * @fileOverview
 * @author
 */

import { apiEndpoint } from '@c/config';
import { RemoteLineProjectSetting } from '@c/domain/entities/Line';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';

export type LineSettingGettableQuery = GettableQuery<string, RemoteLineProjectSetting>;

export class LineSettingQuery implements LineSettingGettableQuery {
  public constructor(
    private readonly lineSettingAdapter: (p: RemoteLineProjectSetting) => RemoteLineProjectSetting
  ) {}
  public async get(projectId: string): ReturnType<LineSettingGettableQuery['get']> {
    const ret = await fetchService<RemoteLineProjectSetting>(
      apiEndpoint(`/line/project/${projectId}/channel`),
      {
        method: 'GET',
      }
    );
    return this.lineSettingAdapter(ret);
  }
}

import { apiEndpoint } from '@c/config';
import { FixedMessage } from '@c/query/FixedMessage';
import { UpdatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';

export type FixedMessageUpdatebleRepository = UpdatableRepository<
  { payload: FixedMessage; projectId: string },
  {}
>;
type P = Params<FixedMessageUpdatebleRepository>;

export class FixedMessageRepository implements FixedMessageUpdatebleRepository {
  public async update({
    projectId,
    payload,
  }: Parameters<FixedMessageUpdatebleRepository['update']>[0]): ReturnType<
    FixedMessageUpdatebleRepository['update']
  > {
    // 一時的に既存のsystem_messageのAPIを使う
    return fetchService(apiEndpoint(`project/${projectId}/scenario/system_message`), {
      method: 'PUT',
      data: { messages: [{ name: 'welcome', value: payload.welcome }] },
      responseType: 'json',
    });
  }
}

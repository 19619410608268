import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { UpdatableRepository } from '@s/Repository';

export type CategoryOrderUpdatableRepository = UpdatableRepository<
  { projectId: string; categoryIds: number[] },
  {}
>;

export class CategoryOrderRepository implements CategoryOrderUpdatableRepository {
  public async update({
    projectId,
    categoryIds,
  }: Parameters<CategoryOrderUpdatableRepository['update']>[0]): ReturnType<
    CategoryOrderUpdatableRepository['update']
  > {
    return await fetchService(apiEndpoint(`project/${projectId}/category/order`), {
      method: 'POST',
      data: { categoryIds },
      responseType: 'json',
    });
  }
}

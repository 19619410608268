/**
 * @fileOverview
 * @name action.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  InstagramAccount,
  InstagramAccountList,
  InstagramProjectSetting,
} from '@c/domain/entities/Instagram';
import { ProjectDetailEntity, ProjectEntity } from '@c/domain/entities/Project';
import { ProjectId } from '@c/domain/entities/ProjectId';

export const fetchProjectListSucceeded = (payload: ProjectEntity[]) => {
  return {
    type: 'FETCH_PROJECT_LIST_SUCCEEDED' as const,
    payload,
  };
};

export const projectDeleted = (payload: { id: ProjectId }) => {
  return {
    type: 'PROJECT_DELETED' as const,
    payload,
  };
};

export const projectUpdated = (payload: ProjectDetailEntity) => {
  return {
    type: 'PROJECT_UPDATED' as const,
    payload,
  };
};

export const getProjectDetailSucceeded = (payload: ProjectDetailEntity) => {
  return {
    type: 'GET_PROJECT_DETAIL_SUCCEEDED' as const,
    payload,
  };
};

export const projectDetailReset = () => {
  return {
    type: 'PROJECT_DETAIL_RESET' as const,
  };
};

export const projectFiltered = (payload: { type: ProjectEntity['type'] | '' }) => {
  return {
    type: 'PROJECT_FILTERED' as const,
    payload,
  };
};

export const instagramAccountListFetched = (payload: InstagramAccountList) => {
  return {
    type: 'INSTAGRAM_ACCOUNT_LIST_FETCHED' as const,
    payload,
  };
};

export const instagramAccountListResetted = () => {
  return {
    type: 'INSTAGRAM_ACCOUNT_LIST_RESETTED' as const,
  };
};

export const instagramAccountSelected = (payload: InstagramAccount) => {
  return {
    type: 'INSTAGRAM_ACCOUNT_SELECTED' as const,
    payload,
  };
};

export const facebookAppFetched = (payload: { appId: string; displayName: string }) => {
  return {
    type: 'FACEBOOK_APP_FETCHED' as const,
    payload,
  };
};

export const instagramAccountSettingFetched = (payload?: InstagramProjectSetting) => {
  return {
    type: 'INSTAGRAM_ACCOUNT_SETTING_FETCHED' as const,
    payload,
  };
};

export const lineSettingDeleted = (payload: { isPreview: boolean }) => {
  return {
    type: 'LINE_SETTING_DELETED' as const,
    payload,
  };
};

import { DialogEngineScenarioParameter } from '@c/domain/entities/dialogEngine/DialogEngineScenarioParameter';

export interface ScenarioParameterSpecification {
  isValid(parameterName: string): [boolean, string];
}

export class ScenarioParameterValidator implements ScenarioParameterSpecification {
  public isValid(parameterName: string): [boolean, string] {
    if (parameterName.length === 0) {
      return [false, 'パラメーター名は必須です'];
    }
    return [true, ''];
  }
}

export const isDuplicateScenarioParameter = (
  parameterName: string,
  scenarioParameterList: DialogEngineScenarioParameter[]
): [boolean, string] => {
  if (
    scenarioParameterList.find(scenarioParameter => {
      return scenarioParameter.name === parameterName;
    })
  ) {
    return [false, 'パラメータ名が重複しています'];
  }
  return [true, ''];
};

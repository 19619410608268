/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@s/Repository';
import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';

export type BusinessHourScheduleUpdatableRepository = UpdatableRepository<
  { projectId: string; date: string; entity: BusinessHourEntity[] },
  void
>;

export class BusinessHourScheduleRepository implements BusinessHourScheduleUpdatableRepository {
  public async update({
    projectId,
    date,
    entity,
  }: Params<BusinessHourScheduleUpdatableRepository>['update'][0]) {
    await fetchService<{}>(apiEndpoint(`/project/${projectId}/biz_hour/schedule`), {
      method: 'PUT',
      data: {
        /* eslint-disable @typescript-eslint/naming-convention */
        of_date: [
          /* eslint-enable @typescript-eslint/naming-convention */
          {
            date,
            ...(entity.length !== 0
              ? {
                  close: false,
                  opens: entity.map(x => [
                    `${x.startAt.hours}:${x.startAt.minutes}`,
                    `${x.endAt.hours}:${x.endAt.minutes}`,
                  ]),
                }
              : {
                  close: true,
                }),
          },
        ],
      },
      responseType: 'json',
    });
  }
}

import { SelectOption, SelectOptions } from '@c/components/atom/Select';
import {
  DialogEngineSceneType,
  PhoneTransferOption,
} from '@c/domain/entities/dialogEngine/DialogEngineScene';
import { DialogEngineComparatorType } from '@c/domain/specification/dialogEngine/DialogEngineAddRoute';

export const lineColorOption = { lightness: 41, saturation: 42, hue: 40 };
export const chipColorOption = { lightness: 84, saturation: 92, hue: 47 };
export const DISABLED_BACKGROUND_COLOR = '#CCCCCC';
export const ROUTING_ACTION_TARGET_TYPE_NAME = 'routing-action-target-type';
export const CONTROL_TIP_ICON_SIZE = 40;
export const SCENARIO_JIMP_ICON_SIZE = 30;
export const CONTROL_TIP_ICON_COLOR = '#000000';
export const SCENE_NODE_ICON_SIZE = 30;
export const ROUTING_ACTION_CHARACTER_LIMIT = 8;
export const ROUTING_ACTION_WIDTH = 100;
export const ROUTING_ACTION_Y_POSITION = 100;
export const ROUTING_ACTION_EDGE_Z_INDEX = 1001;
export const DIALOG_ENGINE_ROUTING_CONDITION_OPTIONS: SelectOption<
  DialogEngineComparatorType,
  number
>[] = [
  {
    value: 'eq',
    label: '等しい',
  },
  {
    value: 'not_eq',
    label: '一致しない',
  },
  {
    value: 'starts_with',
    label: 'で始まる',
  },
  {
    value: 'ends_with',
    label: 'で終わる',
  },
  {
    value: 'greater_than',
    label: '以上の',
  },
  {
    value: 'less_than',
    label: '以下の',
  },
  {
    value: 'reg_exp',
    label: '正規表現',
  },
  {
    value: 'not_exists',
    label: '存在しない',
  },
  {
    value: 'exists',
    label: '存在する',
  },
  {
    value: 'boolean',
    label: 'boolean',
  },
  {
    value: 'between',
    label: 'の間',
  },
  {
    value: 'true',
    label: 'である',
  },
  {
    value: 'false',
    label: 'ではない',
  },
];
export const ROUTING_ACTION_LABEL1 = 'が';
export const ROUTING_ACTION_LABEL2 = 'と';

// AがBと等しいのように比較のパターンと、Aが存在するのような条件のパターンがある。
export const DIALOG_ENGINE_ROUTING_CONDITION_SETTING: { [key: string]: string[] } = {
  eq: ['subject', ROUTING_ACTION_LABEL1, 'expect', ROUTING_ACTION_LABEL2],
  not_eq: ['subject', ROUTING_ACTION_LABEL1, 'expect', ROUTING_ACTION_LABEL2],
  starts_with: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
  ends_with: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
  greater_than: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
  less_than: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
  reg_exp: ['subject', ROUTING_ACTION_LABEL1, 'expect', ROUTING_ACTION_LABEL2],
  not_exists: ['subject', ROUTING_ACTION_LABEL1],
  exists: ['subject', ROUTING_ACTION_LABEL1],
  boolean: ['subject', ROUTING_ACTION_LABEL1, 'expect', ROUTING_ACTION_LABEL2],
  between: ['subject', ROUTING_ACTION_LABEL1, 'expect_to', ROUTING_ACTION_LABEL2, 'expect_from'],
  true: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
  false: ['subject', ROUTING_ACTION_LABEL1, 'expect'],
};
export const ACTION_BUTTON_ICON_SIZE = 15;
export const ACTION_BUTTON_WIDTH = 80;
export const ACTION_LABEL_ICON_SIZE = 25;
export const ACTION_LABEL_HEIGHT = 40;
export const NODE_DEFAULT_HEIGHT = 100;
export const SCENE_NODE_WIDTH = 250;
export const SCENE_NODE_HEIGHT = 120;
export const DEFAULT_NODE_COUNT = 3;
export const ROUTE_NODE_WIDTH = 60;
export const ROUTE_NODE_WIDTH_PADDING = 20;
export const ROUTE_NODE_HEIGHT = 25;
export const ROUTE_NODE_VERTICAL_MARGINE = 10;
export const DEFAULT_SCENE_NAME = '新規シーン';
export const DIALOG_ENGINE_SYSTEM_ENTITY_LIST = [
  {
    name: 'sys.phoneNumber',
    description: 'システムの電話番号',
  },
  { name: 'sys.customerPhoneNumber', description: '架電元番号' },
  { name: 'sys.version', description: 'version情報' },
  { name: 'sys.tenantName', description: 'テナント名' },
  { name: 'sys.tenantId', description: 'テナントID' },
  { name: 'sys.projectId', description: 'プロジェクトID' },
  { name: 'sys.conversationId', description: '会話ID' },
  { name: 'sys.customerId', description: 'カスタマーID' },
  { name: 'sys.callSid', description: 'Twilioのid' },
  { name: 'sys.preview', description: 'preview情報' },
  {
    name: 'sys.currentScenarioId',
    description: '現状動作しているメインのシナリオID',
  },
  {
    name: 'sys.scenarioCallStack',
    description: '別のシナリオが呼び出された場合のシナリオID',
  },
  { name: 'sys.currentSceneId', description: '現在のSceneID' },
  { name: 'sys.sceneCallStack', description: 'Sceneの呼出履歴' },
  { name: 'sys.businessHour', description: '営業時間' },
  { name: 'sys.currentTime', description: '現在時刻' },
];
export const START_NODE_ID = 'start';
export const START_EDGE_ID = 'start_edge';
export const ROUTE_NODE_ID_SUFFIX = '_route';
export const SCENE_DELETE_BUTTON_SIZE = 20;
export const SCENE_SHADOW_OPACITY = 0.07;
export const START_END_SCENE_COLOR = '#CCCCCC';
export const CONTROL_TIP_COLOR = '#FFFFFF';
export const SELECT_ROUTE_TARGET_MODE_SCENE_COLOR = '#24B8E5';
export const UNSELECT_MODE_SCENE_COLOR = '#CCCCCC';
export const SCENARIO_SCENE_COLOR = '#CCCCCC';
export const IVR_SCENE_COLOR = '#727171';
export const PHONE_TRANSFER_SCENE_COLOR = '#E0FFFF';
export const DEFAULT_SCENE_COLOR = '#FFFFFF';
export const START_SCENE_WIDTH = 250;
export const START_SCENE_HEIGHT = 50;

export const DIALOG_ENGINE_SCENE_TYPE_LIST: SelectOptions<DialogEngineSceneType> = [
  { label: 'デフォルト', value: 'normal' },
  { label: 'IVR', value: 'ivr' },
  { label: '外線転送', value: 'phone_transfer' },
  { label: '終端', value: 'terminal' },
];

export const MAX_IVR_DIGIT = 99999;

export const DEFAULT_PHONE_TRANSFER_OPTION: PhoneTransferOption = {
  messageForRecipient: '',
  note: '',
  phoneNumber: '',
};

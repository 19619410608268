/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  categoryChanged,
  saveInquiryOrderSucceeded,
  selectedScenarioIdsInitialized,
  selectedScenarioIdsAdded,
  selectedScenarioIdsDeleted,
  selectedScenarioIdsChanged,
  selectedScenarioIdsOrderChanged,
} from '@c/modules/inquiryOrder/action';
import { swap } from '@s/swap';

type ActionTypes = ReturnType<
  | typeof categoryChanged
  | typeof saveInquiryOrderSucceeded
  | typeof selectedScenarioIdsInitialized
  | typeof selectedScenarioIdsAdded
  | typeof selectedScenarioIdsDeleted
  | typeof selectedScenarioIdsChanged
  | typeof selectedScenarioIdsOrderChanged
>;

export default (
  state: State['inquiryOrder'] = initialState.inquiryOrder,
  action: ActionTypes
): State['inquiryOrder'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'INQUERY_ORDER_CATEGORY_CHANGED':
        state.selectedCategory = action.payload.category;
        return;
      case 'SAVE_INQUERY_ORDER_SUCCEEDED':
        state.scenarioList = action.payload;
        return;
      case 'SELECTED_SCENARIO_IDS_INITIALIZED': {
        const scenarioList = state.scenarioList[action.payload.categoryId];
        state.selectedScenarioIds[action.payload.categoryId] =
          scenarioList !== undefined
            ? scenarioList
                .filter(inquiry => inquiry.order)
                .sort((a, b) => a.order - b.order)
                .map(({ scenarioId }) => scenarioId)
            : [];
        return;
      }
      case 'SELECTED_SCENARIO_IDS_ADDED': {
        const scenarioList = state.scenarioList[state.selectedCategory.id];
        if (scenarioList && scenarioList[0])
          state.selectedScenarioIds[state.selectedCategory.id] = [
            ...(state.selectedScenarioIds[state.selectedCategory.id] ?? []),
            scenarioList[0].scenarioId,
          ];
        return;
      }
      case 'SELECTED_SCENARIO_IDS_DELETED': {
        const selectedScenarioIds = state.selectedScenarioIds[state.selectedCategory.id];
        if (selectedScenarioIds) {
          selectedScenarioIds.splice(action.payload.index, 1);
        }
        return;
      }
      case 'SELECTED_SCENARIO_IDS_CHANGED': {
        const selectedScenarioIds = state.selectedScenarioIds[state.selectedCategory.id];
        if (selectedScenarioIds) {
          selectedScenarioIds[action.payload.index] = action.payload.scenarioId;
        }
        return;
      }
      case 'SELECTED_SCENARIO_IDS_ORDER_CHANGED': {
        const selectedScenarioIds = state.selectedScenarioIds[state.selectedCategory.id];
        if (selectedScenarioIds) {
          swap(
            selectedScenarioIds,
            action.payload.index,
            action.payload.index + (action.payload.isUp ? -1 : 1)
          );
        }
        return;
      }
      default:
        const unusedCheck: never = action;
        return;
    }
  });
};

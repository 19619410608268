/**
 * @fileOverview
 * @name synonymAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import { RemoteSynonym, Synonym, synonymWord, synonymItem } from '@c/domain/entities/Synonym';

export const fromRemote = (remoteSynonym: RemoteSynonym) => {
  if (!remoteSynonym.synonym) {
    remoteSynonym.synonym = [];
  }
  return remoteSynonym.synonym.map(([displayName, ...words]) => {
    return synonymItem(displayName, words.map(synonymWord));
  });
};

export const fromLocal = (synonym: Synonym): RemoteSynonym => {
  return {
    synonym: synonym.map(synonym => {
      return [synonym.displayName, ...synonym.words.map(({ word }) => word)];
    }),
  };
};

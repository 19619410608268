/** @jsxImportSource @emotion/react */
import * as React from 'react';

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      css={{
        borderRadius: '8px',
        borderWidth: '1px',
        backgroundColor: '#fff',
        boxShadow:
          '0 0.6px 0.5px -9px rgba(0, 0, 0, 0.013), 0 1.4px 1px -9px rgba(0, 0, 0, 0.019), 0 2.3px 1.7px -9px rgba(0, 0, 0, 0.023), 0 3.5px 2.5px -9px rgba(0, 0, 0, 0.027), 0 5px 3.6px -9px rgba(0, 0, 0, 0.03), 0 7.1px 5.1px -9px rgba(0, 0, 0, 0.033), 0 10.1px 7.3px -9px rgba(0, 0, 0, 0.037), 0 14.6px 10.6px -9px rgba(0, 0, 0, 0.041), 0 22.5px 16.3px -9px rgba(0, 0, 0, 0.047), 0 40px 29px -9px rgba(0, 0, 0, 0.06)',
      }}
      {...props}
    />
  )
);
Card.displayName = 'Card';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        borderBottom: '4px solid #eeeeee',
        color: '#222',
      }}
      {...props}
    />
  )
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      css={{
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '1',
        letterSpacing: '-0.025em',
      }}
      {...props}
    />
  )
);
CardTitle.displayName = 'CardTitle';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      css={{
        padding: '24px',
        paddingTop: '0px',
      }}
      {...props}
    />
  )
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      css={{
        display: 'flex',
        alignItems: 'center',
        padding: '24px',
        paddingTop: '0px',
      }}
      {...props}
    />
  )
);
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardFooter, CardTitle, CardContent };

import { DialogEnginePublishRatio } from '@c/domain/entities/dialogEngine/DialogEnginePublishSchedule';

export interface DialogEnginePublishScheduleSpecification {
  isValidNote(note: string): [boolean, string];

  isValidPhoneNumberSID(phoneNumberSID: string): [boolean, string];

  isValidPublishRatio(publishRatio: DialogEnginePublishRatio[]): [boolean, string];
}

export class DialogEnginePublishScheduleValidator
  implements DialogEnginePublishScheduleSpecification
{
  public isValidNote(note: string): [boolean, string] {
    if (note.length > 1000) {
      return [false, 'noteは1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidPhoneNumberSID(phoneNumberSID: string): [boolean, string] {
    if (phoneNumberSID.length === 0) {
      return [false, 'phoneNumberSIDは必須です'];
    } else if (phoneNumberSID.length > 1000) {
      return [false, 'phoneNumberSIDは1000文字以下です'];
    }
    return [true, ''];
  }

  private isValidPublishRatioValue(publishRatio: DialogEnginePublishRatio): [boolean, string] {
    if (publishRatio.randomizationRatio <= 0) {
      return [false, '0以上の数値を入力してください'];
    } else if (publishRatio.storyID.length === 0) {
      return [false, 'ストーリーを指定してください'];
    } else if (publishRatio.storyRevision.length === 0) {
      return [false, 'ストーリーのリビジョンが不正です'];
    }
    return [true, ''];
  }

  public isValidPublishRatio(publishRatio: DialogEnginePublishRatio[]): [boolean, string] {
    for (const pr of publishRatio) {
      const [isValid, message] = this.isValidPublishRatioValue(pr);
      if (!isValid) {
        return [isValid, message];
      }
    }
    if (publishRatio.map(pr => pr.randomizationRatio).reduce((acc, cur) => acc + cur) !== 100) {
      return [false, '合計が100%となるように入力してください'];
    }

    return [true, ''];
  }
}

/**
 * @fileOverview
 * @name ProjectListQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { ProjectDetailEntity } from '@c/domain/entities/Project';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type ProjectGettableQuery = GettableQuery<{ projectId: string }, ProjectDetailEntity>;

export class ProjectQuery implements ProjectGettableQuery {
  public async get({
    projectId,
  }: Parameters<ProjectGettableQuery['get']>[0]): ReturnType<ProjectGettableQuery['get']> {
    return fetchService<ProjectDetailEntity>(apiEndpoint(`project/${projectId}`), {
      method: 'GET',
      responseType: 'json',
    });
  }
}

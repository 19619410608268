/**
 * @fileOverview
 * @name firestoreChatMessageAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import { MessageFormat } from '@s/domain/entity/MessageFormat';
import {
  convertMessageFormatToWidgetMessage,
  DisplayableMessageFormat,
} from '@s/components/atom/WidgetMessageConfig';
import { staticConfig } from '@c/config';

export const firestoreChatMessageAdapter = (a: MessageFormat): DisplayableMessageFormat[] => {
  return convertMessageFormatToWidgetMessage(
    a,
    a.sender.type === 'Customer' ? staticConfig.endpoints.base : ''
  );
};

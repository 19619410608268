import { IgAutoMessageDraftState, IgAutoMessageType } from '@c/domain/entities/IgAutoMessage';
import { OneTurnMessages } from '@c/domain/entities/OneTurnMessage';

export const igFixedPhraseDisplayNameMap: {
  [P in IgAutoMessageType]: string;
} = {
  messageReaction: 'Message Reactionへの返信内容',
  storyMention: 'Story Mentionへの返信内容',
  storyReply: 'Story Replyへの返信内容',
};

export const IG_FIXED_PHRASE_ORDER: { [P in IgAutoMessageType]: number } = {
  messageReaction: 0,
  storyMention: 1,
  storyReply: 2,
};

export type IgFixedPhrase = {
  entryType: IgAutoMessageType;
  draftState: IgAutoMessageDraftState;
  oneTurnMessages: OneTurnMessages;
};

export const getTargetState = (
  fixedPhrases: IgFixedPhrase[],
  payload: { entryType: IgAutoMessageType; replyMessagesId: string }
) =>
  fixedPhrases
    .find(s => s.entryType === payload.entryType)
    ?.oneTurnMessages.messages.find(s => s.id === payload.replyMessagesId);

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { CustomError } from 'ts-custom-error';

export interface GenericErrorItem {
  index?: number;
  field: string;
  message?: string;
  messages?: string[];
}

export type RequiredActionType = 'push' | null;

export type RequiredAction = { type: RequiredActionType; args?: any[] };

export interface GenericError {
  message: string | React.ReactNode;
  domMessage?: React.ReactNode | { (): void };
  errors: GenericErrorItem[];
  requiredAction?: RequiredAction;
}

export const isGenericError = (error: unknown): error is GenericError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'errors' in error &&
    Array.isArray((error as GenericError).errors)
  );
};

class GenericErrorImpl extends CustomError implements GenericError {
  private _domMessage?: React.ReactNode | { (): void };

  public constructor(
    message: string | React.ReactNode,
    public errors: GenericErrorItem[],
    public requiredAction?: RequiredAction
  ) {
    super(typeof message === 'string' ? message : 'Error');
    if (typeof message !== 'string') {
      this._domMessage = message;
    }
  }

  public get domMessage(): React.ReactNode | { (): void } | undefined {
    return this._domMessage;
  }
}

export const genericError = (
  errors: Optional<Partial<GenericError>> = {},
  message?: string | React.ReactNode
): GenericError => {
  return new GenericErrorImpl(
    message ?? errors?.message ?? 'ネットワークエラーです',
    errors?.errors ?? ([] as GenericErrorItem[]),
    errors?.requiredAction
  );
};

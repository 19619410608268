/**
 * @fileOverview
 * @name ConversationAssigneeRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';
import { CreatableRepository, DeletableRepository } from '@s/Repository';

export type ConversationAssingeeEditableRepository = CreatableRepository<
  { projectId: string; customerId: string; operatorId: string },
  {}
> &
  DeletableRepository<{ projectId: string; customerId: string }, {}>;

export class ConversationAssingeeRepository implements ConversationAssingeeEditableRepository {
  public async create({
    projectId,
    operatorId,
    customerId,
  }: Parameters<ConversationAssingeeEditableRepository['create']>[0]): ReturnType<
    ConversationAssingeeEditableRepository['create']
  > {
    return fetchService(
      apiEndpoint(`conversation/project/${projectId}/customer/${customerId}/assignee`),
      {
        method: 'POST',
        responseType: 'json',
        data: {
          currentAssignee: operatorId,
        },
      }
    );
  }

  public async delete({
    projectId,
    customerId,
  }: Parameters<ConversationAssingeeEditableRepository['delete']>[0]): ReturnType<
    ConversationAssingeeEditableRepository['delete']
  > {
    return fetchService(
      apiEndpoint(`conversation/project/${projectId}/customer/${customerId}/close`),
      {
        method: 'POST',
        responseType: 'json',
        data: {},
      }
    );
  }
}

/**
 * @fileOverview
 * @name SystemMessages.ts
 * @author Taketoshi Aono
 * @license
 */

export type SystemMessageType =
  | 'sorryNotFound'
  | 'welcome'
  | 'reentry'
  | 'feedbackYes'
  | 'feedbackNo'
  | 'default'
  | 'frequently'
  | 'callingOperator'
  | 'callingOperatorTimeout'
  | 'feedbackNoSwitchOperator'
  | 'spam';

export const systemMessagesDisplayNameMap: {
  [P in SystemMessageType]: string;
} = {
  welcome: 'ウェルカムメッセージ',
  reentry: '「はじめに戻る」選択後メッセージ',
  sorryNotFound: '「この中にはない」選択後メッセージ',
  feedbackYes: '解決時メッセージ',
  feedbackNo: '未解決時メッセージ',
  frequently: 'セカンドウェルカムメッセージ',
  callingOperator: '有人呼び出し中メッセージ',
  callingOperatorTimeout: '有人対応混雑時に自動応答へ戻すメッセージ',
  feedbackNoSwitchOperator: '有人接続確認メッセージ',
  spam: 'ユーザーの自由入力がマッチしなかった場合のメッセージ',
  default: '',
};

export const SYSTEM_MESSAGES_ORDER: { [P in SystemMessageType]: number } = {
  welcome: 0,
  frequently: 1,
  feedbackYes: 2,
  feedbackNo: 3,
  sorryNotFound: 4,
  reentry: 5,
  callingOperator: 6,
  callingOperatorTimeout: 7,
  feedbackNoSwitchOperator: 8,
  spam: 9,
  default: 10,
};

export type SystemMessages = {
  name: SystemMessageType;
  value: string;
}[];

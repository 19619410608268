import { GettableQuery } from '../interface';
import { ENV } from '@c/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export type VoiceCustomerAttributeGettableQuery = GettableQuery<
  { tenantId: string; projectId: string; customerId: string },
  [string, string, string]
>;

export class VoiceCustomerAttributeQuery implements VoiceCustomerAttributeGettableQuery {
  public constructor() {}

  public async get({
    tenantId,
    projectId,
    customerId,
  }: Parameters<VoiceCustomerAttributeGettableQuery['get']>[0]): ReturnType<
    VoiceCustomerAttributeGettableQuery['get']
  > {
    const db = getFirestore();

    const customerDocRef = doc(db, ENV, tenantId, 'customers', customerId);

    const aimPhoneNumberDoc = await getDoc(doc(customerDocRef, 'projects', projectId));
    const aimPhoneNumber = aimPhoneNumberDoc.get('aim_phone_number');

    const noteDoc = await getDoc(customerDocRef);
    const note = noteDoc.get('voice')['note'];
    const customerPhoneNumber = noteDoc.get('voice')['customer_phone_number'];

    return [aimPhoneNumber, note, customerPhoneNumber];
  }
}

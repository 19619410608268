/**
 * @fileOverview
 * @name useErrorNotificator.ts
 * @author Taketoshi Aono
 * @license
 */

import { useEffect } from 'react';
import { useNotificator } from '../atom/Toast';
import { GenericError } from '@c/application/GenericError';
import { ToastOptions } from 'react-toastify';
import { useHistory } from 'react-router';

export const useErrorNotificator = (error: Optional<GenericError>, toastOptions?: ToastOptions) => {
  const notificator = useNotificator(toastOptions);
  const history = useHistory();
  useEffect(() => {
    if (error?.message) {
      notificator.error(error.domMessage ?? error.message);
      if (error.requiredAction?.type === 'push') {
        history.push((error.requiredAction.args as any[])[0]);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return !error?.requiredAction || error.requiredAction?.type !== 'push'
      ? () => notificator.close()
      : () => {};
  }, [error]);
};

/**
 * @fileoverview
 * @author
 */

type UnioMap<T extends string | number | symbol> = {
  [P in T]: P;
};

export const keyMirror = <T extends readonly any[]>(k: T): UnioMap<typeof k[number]> => {
  const ret: any = {};
  for (const key of k) {
    ret[key] = key;
  }

  return ret;
};

/**
 * @fileOverview
 * @name interface.ts
 * @author Taketoshi Aono
 * @license
 */

/* ---↓ swr の cache 型 ↓--- */
type State<Data = any, Error = any> = {
  data: Data;
  error?: Error;
  isValidating?: boolean;
  isLoading?: boolean;
};

export interface Cache<Data = any> {
  keys(): IterableIterator<string>;
  get(key: string): State<Data> | undefined;
  set(key: string, value: State<Data>): void;
  delete(key: string): void;
}
/* ---↑ swr の cache 型 ↑--- */

export type Mutate = <T>(key: string, fetcher: Promise<T>) => Promise<T>;
export class SWRCache {
  readonly key: string;
  readonly cache: Cache;

  public constructor(getUrl: string, cache: Cache) {
    this.key = getUrl;
    this.cache = cache;
  }
}

// TODO: QueryとRepositoryをすべてSWRに移行したらmutateは必須にする
export type CreatableRepository<T, E> = T extends undefined
  ? { create(mutate?: Mutate, swrCache?: SWRCache): Promise<E> }
  : {
      create(a: T, mutate?: Mutate, swrCache?: SWRCache): Promise<E>;
    };

// TODO: QueryとRepositoryをすべてSWRに移行したらmutateは必須にする
export type DeletableRepository<T, E> = T extends undefined
  ? { delete(mutate?: Mutate, swrCache?: SWRCache): Promise<E> }
  : {
      delete(a: T, mutate?: Mutate, swrCache?: SWRCache): Promise<E>;
    };

// TODO: QueryとRepositoryをすべてSWRに移行したらmutateは必須にする
export type UpdatableRepository<T, E> = T extends undefined
  ? {
      update(mutate?: Mutate, swrCache?: SWRCache): Promise<E>;
    }
  : {
      update(a: T, mutate?: Mutate, swrCache?: SWRCache): Promise<E>;
    };

export type AppendonlyRepository<
  CreateParameter,
  CreateEntity,
  UpdateParameter = CreateParameter,
  UpdateEntity = CreateEntity
> = CreatableRepository<CreateParameter, CreateEntity> &
  UpdatableRepository<UpdateParameter, UpdateEntity>;

export type EditableRepository<
  CreateParameter,
  CreateEntity,
  UpdateParameter = CreateParameter,
  UpdateEntity = CreateEntity,
  DeleteParameter = CreateParameter,
  DeleteEntity = CreateEntity
> = CreatableRepository<CreateParameter, CreateEntity> &
  UpdatableRepository<UpdateParameter, UpdateEntity> &
  DeletableRepository<DeleteParameter, DeleteEntity>;

import { genericError } from '@c/application/GenericError';
import {
  DialogEngineBusinessHourDayOfWeek,
  DialogEngineBusinessOpenPeriod,
} from '@c/domain/entities/dialogEngine/DialogEngineBusinessHour';
import { DialogEngineBusinessHourPatternSpecification } from '@c/domain/specification/dialogEngine/DialogEngineBusinessHourPatternSpecification';

export type DialogEngineBusinessHourPatternDayOfWeek = DialogEngineBusinessHourDayOfWeek | 'HOL';

export const DialogEngineBusinessHourPatternDayOfWeek: Record<
  DialogEngineBusinessHourPatternDayOfWeek,
  string
> = {
  MON: '月曜日',
  TUE: '火曜日',
  WED: '水曜日',
  THU: '木曜日',
  FRI: '金曜日',
  SAT: '土曜日',
  SUN: '日曜日',
  HOL: '祝日',
};

export type DialogEngineBusinessHourPattern = {
  id: string;
  businessOpenPeriods: DialogEngineBusinessOpenPeriod[];
  dayOfWeek: DialogEngineBusinessHourPatternDayOfWeek;
  createdAt: Date;
  updatedAt: Date;
};
export const isEqualBusinessHourPattern = (
  businessHourParameter: DialogEngineBusinessHourParameter,
  businessHourPatterns: DialogEngineBusinessHourPattern[]
): boolean => {
  const businessHourPattern =
    businessHourPatterns.find(pattern => pattern.dayOfWeek === businessHourParameter.dayOfWeek)
      ?.businessOpenPeriods || [];
  if (businessHourPatterns.length === 0 && businessHourParameter.businessOpenPeriods.length === 0) {
    return true;
  }
  const current = businessHourPattern.concat().sort((a, b) => {
    return parseInt(a.periodStart, 10) < parseInt(b.periodStart, 10) ? -1 : 1;
  });
  const updated = businessHourParameter.businessOpenPeriods.concat().sort((a, b) => {
    return parseInt(a.periodStart, 10) < parseInt(b.periodStart, 10) ? -1 : 1;
  });
  return JSON.stringify(updated) === JSON.stringify(current);
};
export type DialogEngineBusinessHourParameter = {
  businessOpenPeriods: DialogEngineBusinessOpenPeriod[];
  dayOfWeek: DialogEngineBusinessHourPatternDayOfWeek;
};

export class UpsertDialogEngineBusinessHourPatternField {
  public businessHours: DialogEngineBusinessHourParameter[] = [];
}

export class UpsertDialogEngineBusinessHourPatternParameter extends UpsertDialogEngineBusinessHourPatternField {
  public constructor(param: UpsertDialogEngineBusinessHourPatternField) {
    super();
    Object.assign(this, param);
  }

  public validate(spec: DialogEngineBusinessHourPatternSpecification) {
    const [isValid, errorMessage] = spec.isValidBusinessHours(this.businessHours);
    if (!isValid) {
      throw genericError({ message: errorMessage });
    }
  }
}

/**
 * @fileOverview
 * @author
 */

import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';
import { BusinessHourPattern } from '@c/domain/entities/BusinessHour';

export type BusinessHourPatternGettableQuery = GettableQuery<
  { projectId: ProjectId },
  { patterns: BusinessHourPattern[] }
>;

export class BusinessHourPatternQuery implements BusinessHourPatternGettableQuery {
  public async get({
    projectId,
  }: Parameters<BusinessHourPatternGettableQuery['get']>[0]): ReturnType<
    BusinessHourPatternGettableQuery['get']
  > {
    const ret = await fetchService<{ patterns: BusinessHourPattern[] }>(
      apiEndpoint(`/project/${projectId}/biz_hour/pattern`),
      {
        method: 'GET',
      }
    );
    return ret;
  }
}

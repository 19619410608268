/**
 * @fileOverview
 * @name ProjectRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { EditableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { LineProjectSettingItem } from '@c/domain/entities/Line';

export type LineProjectEditableRepository = EditableRepository<
  { projectId: string; setting: LineProjectSettingItem; isPreview: boolean },
  {},
  { projectId: string; setting: LineProjectSettingItem },
  {},
  { projectId: string; id: string },
  {}
>;

export class LineProjectRepository implements LineProjectEditableRepository {
  public async create({
    projectId,
    setting,
    isPreview,
  }: Parameters<LineProjectEditableRepository['create']>[0]): ReturnType<
    LineProjectEditableRepository['create']
  > {
    return fetchService(
      apiEndpoint(`line/project/${projectId}/channel${isPreview ? '/preview' : ''}`),
      {
        method: 'POST',
        data: {
          name: setting.name,
          id: setting.id,
          providerId: setting.providerId,
          secret: setting.secret,
        },
        responseType: 'json',
      }
    );
  }

  public async delete({
    projectId,
    id,
  }: Parameters<LineProjectEditableRepository['delete']>[0]): ReturnType<
    LineProjectEditableRepository['delete']
  > {
    return fetchService(apiEndpoint(`line/project/${projectId}/channel/${id}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update({
    projectId,
    setting,
  }: Parameters<LineProjectEditableRepository['update']>[0]): ReturnType<
    LineProjectEditableRepository['update']
  > {
    return fetchService(apiEndpoint(`line/project/${projectId}/channel/${setting.id}`), {
      method: 'PUT',
      data: {
        name: setting.name,
        secret: setting.secret,
      },
      responseType: 'json',
    });
  }
}

import { DialogEngineEntityEntryParameter } from '@c/domain/entities/dialogEngine/DialogEngineEntity';

const REGEXP = /^[0-9a-zA-Z]+$/;
export const MIN_ENTRY_VALUES = 1;

export interface DialogEngineEntitySpecification {
  isValidName(name: string): [boolean, string];

  isValidDisplayName(displayName: string): [boolean, string];

  isValidEntityEntries(entityEntries: DialogEngineEntityEntryParameter[]): [boolean, string];
}

export class DialogEngineEntityValidator implements DialogEngineEntitySpecification {
  public isValidName(name: string): [boolean, string] {
    if (name.length === 0) {
      return [false, 'エンティティ名は必須です'];
    } else if (!REGEXP.test(name)) {
      return [false, 'エンティティ名には英数字のみ利用できます'];
    } else if (name.length > 1000) {
      return [false, 'エンティティ名は1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidDisplayName(displayName: string): [boolean, string] {
    if (displayName.length === 0) {
      return [false, 'エンティティ表示名は必須です'];
    } else if (displayName.length > 1000) {
      return [false, 'エンティティ表示名は1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidEntityEntries(
    entityEntries: DialogEngineEntityEntryParameter[]
  ): [boolean, string] {
    for (const entry of entityEntries) {
      if (entry.entryName.length === 0) {
        return [false, '代表文は必須です'];
      } else if (entry.entryName.length > 1000) {
        return [false, '代表文は1000文字以下です'];
      } else if (
        entry.entryValues.length < MIN_ENTRY_VALUES ||
        entry.entryValues.some(value => !value)
      ) {
        return [false, '同義語は必須です'];
      } else if (!!entry.entryValues.find(value => value.length > 1000)) {
        return [false, '同義語は1000文字以下です'];
      }
    }
    return [true, ''];
  }
}

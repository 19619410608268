/**
 * @fileOverview
 * @name reducer.ts<widgetInspector>
 * @author Taketoshi Aono
 * @license
 */

import { initialState, State } from '@c/state';
import {
  widgetInspected,
  widgetInspectorUrlChanged,
  widgetInspectorUrlInputPopupClosed,
  widgetInspectorUrlInputPopupOpened,
  widgetInspectorDisconnected,
  widgetInspectorConnected,
  widgetInspectorFailedToFindTag,
  widgetInspectorPreviewEnabled,
  widgetInspectorPreviewDisabled,
} from './action';
import produce from 'immer';

type ActionType = ReturnType<
  | typeof widgetInspected
  | typeof widgetInspectorUrlChanged
  | typeof widgetInspectorUrlInputPopupClosed
  | typeof widgetInspectorUrlInputPopupOpened
  | typeof widgetInspectorDisconnected
  | typeof widgetInspectorConnected
  | typeof widgetInspectorFailedToFindTag
  | typeof widgetInspectorPreviewEnabled
  | typeof widgetInspectorPreviewDisabled
>;

export default (
  state = initialState.widgetInspector,
  action: ActionType
): State['widgetInspector'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'WIDGET_INSPECTOR_FAILED_TO_FIND_TAG':
        state.notFound = true;
        break;
      case 'WIDGET_INSPECTOR_CONNECTED':
        state.connected = true;
        state.notFound = false;
        break;
      case 'WIDGET_INSPECTOR_DISCONNECTED':
        state.connected = false;
        state.notFound = false;
        break;
      case 'WIDGET_INSPECTOR_URL_INPUT_POPUP_OPENED':
        state.isUrlInputPopupVisible = true;
        break;
      case 'WIDGET_INSPECTOR_URL_INPUT_POPUP_CLOSED':
        state.isUrlInputPopupVisible = false;
        break;
      case 'WIDGET_INSPECTOR_URL_CHANGED':
        state.url = action.payload;
        state.notFound = false;
        break;
      case 'WIDGET_INSPECTED':
        state.items = action.payload;
        break;
      case 'WIDGET_INSPECTOR_PREVIEW_ENABLED':
        state.isPreviewEnabled = true;
        break;
      case 'WIDGET_INSPECTOR_PREVIEW_DISABLED':
        state.isPreviewEnabled = false;
        break;
      default:
        return state;
    }
  });
};

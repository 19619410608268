/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useRef, useEffect, forwardRef, CSSProperties } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { FormRole } from '../FormRole';
import { v4 } from 'uuid';
import { List, AutoSizer } from 'react-virtualized';
import { TextInput } from '../TextInput';
import { Loading } from '@s/components/atom/Loading';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { useRefState } from '@s/reactHooks';
import { required } from '@s/assertions';
import { setRefedElement } from '@s/components/atom/domUtils';
import { Flex } from '@s/components/atom/Box';

const SelectIconRootContainerElement = styled.div<{ iconColor: string }>(
  p => `
  path {
    fill: ${p.iconColor}
  }
  `
);
const SelectIcon = ({ color, size = 20 }: { color: string; size: number }) => {
  return (
    <SelectIconRootContainerElement css={{ width: size, height: size }} iconColor={color}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <path d="M26.84,108.75a24.79,24.79,0,0,1,17.61,7.53L250,322.43,455.55,116.28c10.81-10.84,27.46-9.86,37.19,2.18s8.85,30.6-2,41.45L267.62,383.72a24.38,24.38,0,0,1-35.24,0L9.22,159.91c-10.81-10.85-11.69-29.4-2-41.45A25.16,25.16,0,0,1,26.84,108.75Z" />
      </svg>
    </SelectIconRootContainerElement>
  );
};

export interface SelectOption<T extends string, U = any> {
  value: T;
  label: string;
  key?: string;
  keys?: string[];
  data?: U;
}

export type SelectOptions<T extends string = any> = SelectOption<T>[];

export type OptionRendererProps = { index: number; option: SelectOption<any> };

export interface Props {
  label: string;
  selected: any;
  selectOptions: SelectOptions;
  isExcludeFirstEmptyOption?: boolean;
  shouldIgnoreAdjusterPadding?: boolean;
  enableSearch?: boolean;
  optionHeight?: number;
  className?: string;
  contentClassName?: string;
  isHorizontal?: boolean;
  disabled?: boolean;
  fixedWidth?: string;
  loading?: boolean;
  onChange?(a: SelectOption<any>): void;
  optionRenderer?(a: OptionRendererProps): React.ReactNode;
}

const SELECT_HEIGHT = 28;
const SelectContainerElement = FormRole(
  styled.div`
    position: relative;
    display: inline-block;
  `,
  'menu'
);
const horizontalSelectLabelStyle = `
  display: inline-block;
  margin-right: 5px;
`;
const SelectLabel = styled.label<{ isHorizontal: boolean }>(
  p => `
  white-space: nowrap;
  font-size: 10px;
  margin-bottom: ${p.isHorizontal ? 10 : 0}px;
  display: block;
  color: black;
  line-height: ${SELECT_HEIGHT}px;
  ${p.isHorizontal ? horizontalSelectLabelStyle : ''};
  `
);
const SelectInnerContainerElement = styled.div`
  position: relative;
  display: flex;
  color: #333;
`;
const SelectBoxContainerElement = styled.div`
  position: relative;
  display: flex;
`;
const SelectBox = styled.div<{ fixedWidth?: string; noMarginLeft: boolean }>(
  p => `
  ${p.fixedWidth ? `width: ${p.fixedWidth}` : ''};
  ${p.noMarginLeft ? 'margin-left: 0px' : 'margin-left: 10px'};
  overflow: hidden;
  display: inline-block;
  position: relative;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #eeeeee 0%, #e4e4e4 100%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  height: ${SELECT_HEIGHT}px;
  z-index: 0;
  transition: all 0.2s;
  padding-right: 22px;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    transform-origin: 50% 50%;
    transform: scale(0);
    transition: all 0.2s;
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover {
    :before {
      transform: scale(1);
    }
  }
}
  `
);

const SelectIconContainerElement = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 22px;
  background: #0066ff;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SelectOptionRoot = styled.span`
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 10px;
  text-align: center;
  padding: 0px 15px;
  line-height: ${SELECT_HEIGHT}px;
`;

const OptionElement = styled.span<{
  selected: boolean;
}>`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: ${p => (p.selected ? '#0099FF' : '#666')};
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 12px;
  white-space: nowrap;
  padding: ${p => (p.theme.dummy ? '0px 30px' : '')};
`;

const OptionContainerElement = FormRole(
  styled.button<{ isSelected: boolean }>`
    background: none;
    border: 0;
    display: block;
    transition: background-color 0.3s;
    ${p => (p.isSelected ? 'background: #EFEFEF' : '')};
    &:hover {
      background-color: #00ccff;
    }
    list-style: none;
  `,
  'option'
);
const DummyAdjuster = styled.div`
  z-index: -1;
  position: relative;
  top: -1px;
  width: 100%;
  display: inline-block;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
`;

const MAX_HEIGHT = 500;
const OptionsContainerElement = styled.div<{
  optionsTransitionOrigin: string;
  position: { left: number; top: number; width: number };
  visible: boolean;
}>`
  margin: 0;
  padding: 0;
  transition: transform 0.2s;
  transform: scale(0);
  position: fixed;
  max-height: ${MAX_HEIGHT}px;
  top: ${p => p.position.top}px;
  left: ${p => p.position.left}px;
  width: ${p => p.position.width}px;
  z-index: 9999;
  background: #fff;
  font-size: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transform-origin: ${p => p.optionsTransitionOrigin};
  &:hover {
    > li > a {
      color: #fff !important;
    }
  }
  transform: ${p => (p.visible ? 'scale(1) !important' : '')};
`;

const updatePosition = ({
  selectBox,
  optionsHeight,
  setPosition,
  setOptionsTransitionOrigin,
}: {
  selectBox: React.RefObject<HTMLElement>;
  optionsHeight: number;
  setPosition(pos: { left: number; top: number; width: number; height: number }): void;
  setOptionsTransitionOrigin(a: string): void;
}) => {
  if (selectBox.current) {
    const rect = selectBox.current.getBoundingClientRect();
    const isOverflowScreen = Math.round(rect.top / window.innerHeight) > 0.5;
    if (isOverflowScreen) {
      setOptionsTransitionOrigin('center bottom');
    } else {
      setOptionsTransitionOrigin('center top');
    }
    let top = isOverflowScreen
      ? rect.top + window.pageYOffset - optionsHeight
      : rect.top + window.pageYOffset;
    if (top < 0) {
      top = 0;
    }
    let height = isOverflowScreen ? rect.top : window.innerHeight - rect.top;
    if (height > optionsHeight) {
      height = optionsHeight;
    }
    setPosition({
      left: rect.left + window.pageXOffset,
      top,
      height,
      width: rect.width - 22,
    });
  }
};

const Option = compareOnlyProperties(
  forwardRef(
    (
      {
        index,
        height,
        style,
        selectOptions,
        onChange,
        selected,
        selectedId,
        optionRenderer,
        shouldExpand = false,
      }: {
        index: number;
        height: number;
        selectOptions: SelectOptions;
        selected: any;
        selectedId: string;
        shouldExpand?: boolean;
        style: CSSProperties;
        optionRenderer(a: OptionRendererProps): React.ReactNode;
        onChange(a: SelectOption<any>): void;
      },
      ref: React.Ref<any>
    ) => {
      const option = selectOptions[index];
      const isSelected = String(option.value) === String(selected);
      const handleChange = () => {
        if (!isSelected) {
          onChange(option);
        }
      };
      return (
        <OptionContainerElement
          aria-label={option.label}
          aria-selected={isSelected}
          id={isSelected ? selectedId : undefined}
          onUserDecision={() => handleChange()}
          onClick={() => handleChange()}
          key={'key' in option ? option.key : option.value || index}
          isSelected={isSelected}
          ref={ref}
          css={{ width: shouldExpand ? '100%' : 'auto', height }}
          style={style}
        >
          <OptionElement selected={isSelected} aria-hidden={true}>
            {optionRenderer({ index, option })}
          </OptionElement>
        </OptionContainerElement>
      );
    }
  ),
  'Option'
);

interface OptionsProps {
  className?: string;
  optionsTransitionOrigin: string;
  visible: boolean;
  selectOptions: SelectOptions;
  optionHeight: number;
  selected: any;
  selectedId: string;
  position: { left: number; top: number; width: number; height: number };
  firstOptionRef: React.RefObject<any>;
  optionRenderer(a: OptionRendererProps): React.ReactNode;
  isExcludeFirstEmptyOption: boolean;
  searchWord: string;
  enableSearch: boolean;
  onChange(a: SelectOption<any>): void;
  onClose(): void;
  onSearch(a: { value: string }): void;
}

const Options = compareOnlyProperties(
  forwardRef(
    (
      {
        optionsTransitionOrigin,
        className,
        visible,
        position,
        onClose,
        selectOptions,
        selected,
        selectedId,
        optionHeight,
        isExcludeFirstEmptyOption,
        firstOptionRef,
        enableSearch,
        searchWord,
        onChange,
        onSearch,
        optionRenderer,
      }: OptionsProps,
      ref: React.Ref<any>
    ) => {
      const elementRef = useRef<HTMLElement | null>(null);
      useEffect(() => {
        if (elementRef.current && visible) {
          const spaces = Math.floor(window.innerHeight - (position.top - window.pageYOffset));
          if (spaces < MAX_HEIGHT) {
            elementRef.current.style.maxHeight = `${spaces}px`;
          } else {
            elementRef.current.style.height = '';
            elementRef.current.style.maxHeight = `${spaces}px`;
          }
        }
      }, [elementRef.current, visible, position.top]);

      useOnClickOutside(elementRef, onClose);

      const coefficient = isExcludeFirstEmptyOption ? 1 : 0;
      const listHeight = optionHeight * (selectOptions.length - coefficient);
      return (
        <OptionsContainerElement
          optionsTransitionOrigin={optionsTransitionOrigin}
          visible={visible}
          className={className || ''}
          position={position}
          ref={e => {
            setRefedElement(elementRef, e);
            setRefedElement(ref, e);
          }}
          css={{ height: position.height + (enableSearch ? 40 : 0) }}
        >
          <Flex flexDirection="column" css={{ height: '100%' }}>
            {enableSearch ? (
              <div css={{ flex: '0', borderBottom: '1px solid #DDD' }}>
                <TextInput
                  value={searchWord}
                  placeholder={{ value: '検索', type: 'normal' }}
                  width={position.width}
                  useBorder={false}
                  onChange={({ value }) => {
                    onSearch({ value });
                  }}
                />
              </div>
            ) : null}
            <div css={{ flex: '1 1 100%', height: '100%', width: '100%' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width === 0 ? position.width : width}
                    height={height === 0 ? listHeight : height}
                    rowCount={selectOptions.length - coefficient}
                    rowHeight={optionHeight}
                    rowRenderer={p => {
                      const index = p.index + coefficient;
                      return (
                        <Option
                          key={selectOptions[index].key ?? selectOptions[index].value ?? p.index}
                          style={p.style}
                          height={optionHeight}
                          shouldExpand={true}
                          ref={index - coefficient === 0 ? firstOptionRef : undefined}
                          index={index}
                          selectOptions={selectOptions}
                          selected={selected}
                          selectedId={selectedId}
                          optionRenderer={optionRenderer}
                          onChange={a => {
                            onChange(a);
                          }}
                        />
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          </Flex>
        </OptionsContainerElement>
      );
    }
  ),
  'SelectOptions'
);

export const Select = compareOnlyProperties(
  ({
    disabled,
    loading,
    label,
    selected,
    selectOptions,
    isExcludeFirstEmptyOption = false,
    optionHeight = 40,
    isHorizontal = false,
    fixedWidth,
    enableSearch = false,
    className = '',
    contentClassName = '',
    onChange = () => {},
    shouldIgnoreAdjusterPadding = false,
    optionRenderer = a => <div css={{ padding: '10px 30px' }}>{a.option.label}</div>,
  }: Props) => {
    const selectedId = useRef(`_${v4()}`);
    const [position, setPosition] = useRefState({ left: 0, top: 0, width: 0, height: 0 });
    const [isVisible, setVisibility] = useRefState(false);
    const ignoreClick = useRef(false);
    const selectBoxRef = useRef<HTMLElement>(null);
    const selectedValue = String(selected);
    const selectedOptions = selectOptions.filter(option => {
      if ('keys' in option && Array.isArray(option.keys)) {
        return option.keys.some(v => v === selectedValue);
      } else {
        return String(option.value) === selectedValue;
      }
    });
    const [selectOptionsState, setSelectOptionsState] = useRefState(selectOptions);
    const [searchWord, setSearchWord] = useRefState('');
    const firstOptionRef = useRef<HTMLElement>(null);
    const isExcludeFirstOption =
      isExcludeFirstEmptyOption && selectOptions[0] === selectOptionsState.current[0];
    const optionsHeight =
      optionHeight *
      (isExcludeFirstOption
        ? selectOptionsState.current.length - 1
        : selectOptionsState.current.length);
    const [optionsTransitionOrigin, setOptionsTransitionOrigin] = useRefState('center top');

    useEffect(() => {
      if (searchWord.current) {
        setSelectOptionsState(
          selectOptions.filter((opt, i) => {
            if (isExcludeFirstEmptyOption && i === 0) {
              return false;
            }
            return opt.label.includes(searchWord.current);
          })
        );
      } else {
        setSelectOptionsState(selectOptions);
      }
    }, [selectOptions, searchWord.current]);

    const handleClick = () => {
      if (disabled || loading || ignoreClick.current) {
        return;
      }
      if (selectBoxRef.current) {
        updatePosition({
          selectBox: selectBoxRef,
          setPosition,
          optionsHeight,
          setOptionsTransitionOrigin,
        });
        setVisibility(true);
        const el = document.querySelector(`#${selectedId.current}`);
        el && (el as HTMLElement).focus();
      }
    };

    const dummyIndex = selectOptions.length
      ? selectOptions.reduce((index, option, nextIndex) => {
          return String(selectOptions[index].label).length < String(option.label).length
            ? nextIndex
            : index;
        }, 0)
      : -1;

    const selectedNode = selectedOptions.map(v => (
      <SelectOptionRoot className="e2e__select__selected" key={v.key}>
        {v.label}
      </SelectOptionRoot>
    ));

    const labelNode = label ? (
      <SelectLabel
        isHorizontal={isHorizontal}
        onClick={() => {
          handleClick();
          firstOptionRef.current && firstOptionRef.current.focus();
        }}
        onKeyUp={e => {
          if (e.key === 'Enter' || e.key === 'Space') {
            handleClick();
            firstOptionRef.current && firstOptionRef.current.focus();
          }
        }}
      >
        {label}
      </SelectLabel>
    ) : null;

    const selectedElement =
      selectedNode.length > 0 ? (
        selectedNode[0]
      ) : (
        <SelectOptionRoot>
          {selectOptions.length > 0 ? required(selectOptions[0]).label : ''}
        </SelectOptionRoot>
      );

    const optionsRef = useRef<HTMLElement>(null);
    useEffect(() => {
      if (optionsRef.current) {
        const handler = (e: MouseEvent | TouchEvent) => {
          e.stopPropagation();
        };
        optionsRef.current.addEventListener('mousedown', handler, false);
        optionsRef.current.addEventListener('touchstart', handler, false);
        return () => {
          if (optionsRef.current) {
            optionsRef.current.removeEventListener('mousedown', handler);
            optionsRef.current.removeEventListener('touchstart', handler);
          }
        };
      }
    }, [optionsRef.current]);

    return (
      <>
        {createPortal(
          <Options
            ref={optionsRef}
            optionsTransitionOrigin={optionsTransitionOrigin.current}
            className={contentClassName}
            position={position.current}
            onClose={() => setVisibility(false)}
            visible={isVisible.current}
            isExcludeFirstEmptyOption={isExcludeFirstOption}
            firstOptionRef={firstOptionRef}
            selectOptions={selectOptionsState.current}
            selected={selected}
            selectedId={selectedId.current}
            searchWord={searchWord.current}
            optionHeight={optionHeight}
            optionRenderer={optionRenderer}
            enableSearch={enableSearch}
            onChange={a => {
              onChange(a);
              ignoreClick.current = true;
              selectBoxRef.current && selectBoxRef.current.click();
              setVisibility(false);
              ignoreClick.current = false;
            }}
            onSearch={({ value }) => {
              setSearchWord(value);
            }}
          />,
          document.body
        )}
        <SelectContainerElement
          aria-label={label}
          aria-disabled={disabled}
          aria-activedescendant={selectedId.current}
          onClick={() => {
            handleClick();
            firstOptionRef.current && firstOptionRef.current.focus();
          }}
          onUserDecision={() => {
            handleClick();
            firstOptionRef.current && firstOptionRef.current.focus();
          }}
          className={className}
        >
          <SelectInnerContainerElement aria-hidden={true}>
            {labelNode}
            <SelectBoxContainerElement>
              <SelectBox
                ref={selectBoxRef as any}
                fixedWidth={fixedWidth}
                noMarginLeft={label === ''}
              >
                <AnimatePresence>{loading ? <Loading scale={0.2} /> : null}</AnimatePresence>
                <DummyAdjuster aria-hidden={true}>
                  <ThemeProvider theme={{ dummy: shouldIgnoreAdjusterPadding ? undefined : true }}>
                    {dummyIndex >= 0 ? (
                      <Option
                        style={{}}
                        index={dummyIndex}
                        height={optionHeight}
                        selectOptions={selectOptions}
                        selected={''}
                        selectedId={''}
                        optionRenderer={optionRenderer}
                        onChange={() => {}}
                      />
                    ) : null}
                  </ThemeProvider>
                </DummyAdjuster>
                {selectedElement}
                <SelectIconContainerElement aria-hidden={true}>
                  <SelectIcon color="#FFF" size={10} />
                </SelectIconContainerElement>
              </SelectBox>
            </SelectBoxContainerElement>
          </SelectInnerContainerElement>
        </SelectContainerElement>
      </>
    );
  },
  'Select'
);

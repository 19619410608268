/**
 * @fileOverview
 * @name MatchingTrainingRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { EditableRepository } from '@s/Repository';
import { SceneNodes, RemoteSceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

type CreateParam = {
  projectId: string;
  clusterId: string;
  sceneNodes: ReadonlyDeep<SceneNodes>;
};
type CreateResponse = {
  scenarioId: string;
};
type UpdateParam = {
  projectId: string;
  clusterId: string;
  summaryId: string;
};
type DeleteParam = {
  projectId: string;
  clusterId: string;
};
export type MatchingTrainingEditableRepository = EditableRepository<
  CreateParam,
  CreateResponse,
  UpdateParam,
  {},
  DeleteParam,
  {}
>;

export class MatchingTrainingRepository implements MatchingTrainingEditableRepository {
  public constructor(
    private readonly sceneNodesToRemoteSceneNodes: (
      a: ReadonlyDeep<SceneNodes>
    ) => ReadonlyDeep<RemoteSceneNodes>
  ) {}

  public async create({ projectId, clusterId, sceneNodes }: CreateParam): Promise<CreateResponse> {
    return fetchService<CreateResponse>(
      apiEndpoint(`project/${projectId}/matching-training/cluster/${clusterId}/new`),
      {
        method: 'POST',
        responseType: 'json',
        data: this.sceneNodesToRemoteSceneNodes(sceneNodes),
      }
    );
  }

  public async delete({ projectId, clusterId }: DeleteParam): Promise<{}> {
    return fetchService(
      apiEndpoint(`project/${projectId}/matching-training/cluster/${clusterId}/delete`),
      {
        method: 'DELETE',
        responseType: 'json',
      }
    );
  }

  public async update({ projectId, clusterId, summaryId }: UpdateParam): Promise<{}> {
    return fetchService(
      apiEndpoint(`project/${projectId}/matching-training/cluster/${clusterId}/link`),
      {
        method: 'POST',
        responseType: 'json',
        data: { summaryId },
      }
    );
  }
}

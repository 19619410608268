/**
 * @fileoverview
 * @author
 */

import { initialState, State } from '@c/state';
import {
  loginSucceeded,
  deleteAuthEntity,
  facebookLoginRequired,
  loggedInWithFacebook,
  firestoreConnected,
  firestoreDisconnected,
  updateAuthEntityDisplayName,
} from '@c/modules/auth/action';
import produce from 'immer';

type ActionTypes = ReturnType<
  | typeof loginSucceeded
  | typeof deleteAuthEntity
  | typeof facebookLoginRequired
  | typeof loggedInWithFacebook
  | typeof firestoreConnected
  | typeof firestoreDisconnected
  | typeof updateAuthEntityDisplayName
>;

export default (state: State['auth'] = initialState.auth, action: ActionTypes): State['auth'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'LOGIN_SUCCEEDED':
        state.isLoggedIn = true;
        state.authEntity = action.payload;
        return;
      case 'UPDATE_AUTH_ENTITY_DISPLAY_NAME':
        if (state.authEntity) {
          state.authEntity.user.displayName = action.payload;
        }
        return;
      case 'DELETE_LOGIN_ENTITY':
        state.isLoggedIn = false;
        state.authEntity = null;
        return;
      case 'FACEBOOK_LOGIN_REQUIRED':
        state.isFbLoginRequired = true;
        return;
      case 'LOGGED_IN_WITH_FACEBOOK':
        state.isFbLoginRequired = false;
        return;
      case 'FIRESTORE_CONNECTED':
        state.isFirestoreConnected = true;
        return;
      case 'FIRESTORE_DISCONNECTED':
        state.isFirestoreConnected = false;
        return;
      default:
        return state;
    }
  });
};

/**
 * @fileOverview
 * @name firestoreCustomerAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import { RemoteCustomerEntity, CustomerEntity } from '@c/domain/entities/Customer';

export const firestoreCustomerAdapter = (id: string, a: RemoteCustomerEntity): CustomerEntity => {
  const { web, line, instagram, ...rest } = a;
  return {
    ...rest,
    id,
    displayName: a.display_name,
    note: a.note || '',
    updatedAt: a.updated_at,
    ...(a.web
      ? {
          web: {
            ...a.web,
            lastIpAddress: a.web.last_ip_address,
            lastUserAgent: a.web.last_user_agent,
          },
        }
      : {}),
    ...(a.line
      ? {
          line: {
            ...a.line,
            userId: a.line.user_id,
            displayName: a.line.display_name,
            pictureUrl: a.line.picture_url,
            statusMessage: a.line.status_message,
            updatedAt: a.line.updated_at,
          },
        }
      : {}),
    ...(a.instagram
      ? {
          instagram: {
            id: a.instagram.id,
            name: a.instagram.name,
            profilePic: a.instagram.profile_pic,
            followerCount: a.instagram.follower_count,
          },
        }
      : {}),
  };
};

/**
 * @fileOverview
 * @name SceneType.ts
 * @author Taketoshi Aono
 * @license
 */

export const enum SceneType {
  SCENARIO = 'SCENE_SCENARIO',
  IG_CAROUSEL = 'IG_CAROUSEL',
  BUSINESS_HOUR = 'SCENE_BUSINESS_HOUR',
  CALL_OPERATOR = 'SCENE_CALL_OPERATOR',
  INTEGRATION = 'SCENE_INTEGRATION',
  MAIL = 'MAIL',
}

export const allSceneTypes = [
  SceneType.SCENARIO,
  SceneType.IG_CAROUSEL,
  SceneType.BUSINESS_HOUR,
  SceneType.CALL_OPERATOR,
  SceneType.INTEGRATION,
  SceneType.MAIL,
];

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Flex, Box } from '@s/components/atom/Box';
import styled from '@emotion/styled';
import { useRefState, useAsyncAction, useBeforeMount } from '@s/reactHooks';
import { GenericLogo } from '@c/components/atom/GenericLogo';
import {
  checkLoginState,
  createLoginRequest,
  createLoginWithFbRequest,
} from '@c/modules/auth/usecase';
import { AnimatePresence } from 'framer-motion';
import { useErrorNotificator } from '../../hooks/useErrorNotificator';
import { staticConfig } from '@c/config';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { Loading } from '@s/components/atom/Loading';
import { css } from '@emotion/react';
import { ConsoleEnvContext } from '@c/components/atom/ConsoleEnvContext';
import { INITIAL_PAGE } from '@c/urlList';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { TypeSelector } from './components/TypeSelector';
import { LoginWithIdPassword } from './components/LoginWithIdPassword';

const LoginContentWrapperElement = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  > div {
    width: 100%;
    height: 100%;
  }
`;
const mobileInputCss = css`
  @media (max-width: 480px) {
    input {
      font-size: 16px;
    }
    width: 95%;
  }
`;
const ID = '__widget_suppoort_script__';
const enum LoginType {
  NONE,
  ID_PASSWORD,
  FACEBOOK,
}
const SPRING_TRANSITION = { type: 'tween', ease: 'anticipate', duration: 0.3 };

export const Login: React.FunctionComponent = compareOnlyProperties(() => {
  const environment = useContext(ConsoleEnvContext);
  const loginAction = useAsyncAction(createLoginRequest);
  const loginWithFbAction = useAsyncAction(createLoginWithFbRequest);
  const checkLoginStateAction = useAsyncAction(checkLoginState);
  const [loginType, setLoginType] = useRefState(LoginType.NONE);
  const [isLoggedIn, setLoggedIn] = useRefState<boolean | undefined>(undefined);
  const history = useHistory();
  useErrorNotificator(loginAction.error, { position: 'top-center' });
  useErrorNotificator(loginWithFbAction.error, { position: 'top-center' });

  const subscribeAuthStateChange = () => {
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      if (user) {
        await checkLoginStateAction();
        history.replace(INITIAL_PAGE);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      unsubscribe();
    });
  };

  useBeforeMount(() => {
    return subscribeAuthStateChange();
  });

  useEffect(() => {
    if (!environment.isSupportedMobileBrowser) {
      eval(
        `window.aimWidgetInitialConfigs = {
          projectId: "${staticConfig.chatSupport.PROJECT_ID}",
          tenantId: "${staticConfig.chatSupport.TENANT_ID}",
          tenantName: "${staticConfig.chatSupport.TENANT_NAME}",
          type: "floating"
        };
        var sc = document.createElement('script');
        sc.src = 'https://${staticConfig.chatSupport.ENV_PREFIX}w.ai-messenger-static.app/aim.js';
        document.body.appendChild(sc)`
      );
    }
    return () => {
      const t = document.querySelector(`#${ID}`);
      if (t && t.parentElement) {
        t.remove();
      }
      if ((window as any).aiMessenger) {
        (window as any).aiMessenger.destroy();
      }
    };
  }, []);

  return isLoggedIn.current === true ? null : (
    <LoginContentWrapperElement>
      <AnimatePresence>
        {loginAction.loading || loginWithFbAction.loading || isLoggedIn.current === undefined ? (
          <Loading isModalEnabled={true} />
        ) : null}
      </AnimatePresence>
      <div css={mobileInputCss}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          css={{ position: 'relative', height: '100%' }}
        >
          <Flex justifyContent="center" alignItems="center">
            <Box margin="0 0 80px 0">
              <GenericLogo size={200} />
            </Box>
          </Flex>
          <AnimatePresence initial={false}>
            {loginType.current === LoginType.NONE ? (
              <TypeSelector
                key="login-select"
                initial={{ opacity: 0, translateX: -200 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{
                  opacity: 0,
                  translateX: -200,
                  transition: SPRING_TRANSITION,
                }}
                transition={SPRING_TRANSITION}
                onSelectIdPassword={() => setLoginType(LoginType.ID_PASSWORD)}
                onLoginWithFacebook={() => {
                  loginWithFbAction(['public_profile']);
                }}
              />
            ) : (
              <LoginWithIdPassword
                key="login-with-id-pass"
                initial={{ opacity: 0, translateX: 200 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{
                  opacity: 0,
                  translateX: 200,
                  transition: SPRING_TRANSITION,
                }}
                transition={SPRING_TRANSITION}
                isSupportedMobileBrowser={environment.isSupportedMobileBrowser}
                onLogin={a => {
                  loginAction(a);
                }}
                onResetLoginType={() => setLoginType(LoginType.NONE)}
              />
            )}
          </AnimatePresence>
        </Flex>
      </div>
    </LoginContentWrapperElement>
  );
}, 'Login');

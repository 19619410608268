/**
 * @fileOverview
 * @name scenarioNodesAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  addCustomFeedbackToSceneNode,
  addIntegrationButtonsToSceneNode,
  addMailEdgesToSceneNode,
  CarouselSceneButton,
  CarouselSceneElement,
  fallbackView,
  freeInputEdge,
  RemoteSceneNodes,
  SceneNodeCache,
  SceneNodes,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { v4 } from 'uuid';
import { SceneErrors } from '@c/domain/values/PromoteErrors';
import {
  cleanupFallback,
  SceneNodeSpecification,
} from '@c/domain/specification/SceneNodeSpecification';
import { MessageFactory } from '@c/domain/entities/MessageEntity';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { State } from '@c/state';

export const scenarioNodesAdapterFactory =
  (specification: SceneNodeSpecification) =>
  (
    id: string,
    nodes: RemoteSceneNodes,
    errors: SceneErrors,
    isAnalytics: boolean,
    projectType: State['env']['type'],
    carousels?: Carousel[]
  ): SceneNodes => {
    let dimensions = nodes.dimensions ?? {};
    const { parameters = [] } = nodes;
    const paramSet = new Set(parameters.map(v => v.id));
    const sceneNodes = nodes.nodes.map(node => {
      node.fromEdges = (node.fromEdges as any) ?? [];
      if (node.type === 'message') {
        if (projectType === 'instagram' || projectType === 'line') {
          node.textInputState = 'show';
        }
        node.freeInputEdgeView = freeInputEdge(node.freeInputEdge || '');
        /* eslint-disable @typescript-eslint/no-unnecessary-condition */
        node.fallback = node.fallback || '';
        node.shouldUseFallbackView = node.fallback.includes('-');
        node.fallbackView = fallbackView(node.fallback);
        if (node.paramId && !paramSet.has(node.paramId)) {
          node.paramId = '';
        }
        node.response.quickReplies = (node.response as any).quickReplies ?? [];
        node.response.messages.forEach((m, index) => {
          if (!m.id) {
            m.id = `_Temporary_${index}`;
          }
        });

        node.response.quickReplies.forEach(message => {
          message.id = `_Temporary_${v4()}_${Date.now()}`;
        });
        if (node.customFeedback) {
          addCustomFeedbackToSceneNode(node, node.customFeedback);
          delete node.customFeedback;
        }
        cleanupFallback(node);
      } else if (node.type === 'businessHour') {
        node.request.results.forEach(r => {
          if (!r.id) {
            r.id = v4();
          }
        });
      } else if (node.type === 'integration') {
        node.integration.succeeded.quickReplies?.forEach(quickReply => {
          quickReply.id = `_Temporary_${v4()}_${Date.now()}`;
        });
        addIntegrationButtonsToSceneNode(
          node,
          node.integration.succeeded.default || '',
          node.integration.failed || ''
        );
        delete node.integration.succeeded.default;
        delete node.integration.failed;
      } else if (node.type === 'mail') {
        addMailEdgesToSceneNode(
          node,
          node.mail.edges?.succeeded ?? '',
          node.mail.edges?.failed ?? ''
        );
      } else if (node.type === 'carousel') {
        node.freeInputEdgeView = freeInputEdge(node.freeInputEdge || '');
        node.carousel.contents = [];
        const masterCarousel = carousels?.find(carousel => carousel.id === node.carousel.id);
        if (masterCarousel) {
          const elements: CarouselSceneElement[] = [];
          for (const element of masterCarousel.elements) {
            // 登録済のカルーセルテンプレートを参照してデータに不整合がないようにする
            const buttons: CarouselSceneButton[] = [];
            const fetchElement = node.carousel.elements.find(elem => elem.id === element.id);
            for (const button of element?.buttons || []) {
              if (button.type === 'postback') {
                const fetchButton = fetchElement?.buttons?.find(fb => fb.title === button.title);
                buttons.push({
                  title: button.title,
                  edge: fetchButton?.edge || '',
                });
              }
            }
            elements.push({
              id: element.id,
              title: element.title,
              buttons,
              buttonsView: [],
            });
          }
          node.carousel.elements = elements;
          node.carousel.displayName = masterCarousel.displayName;
        } else {
          node.carousel.id = undefined;
          node.carousel.elements = [];
          node.carousel.displayName = '';
        }
        for (const element of node.carousel.elements) {
          node.carousel.contents = [
            ...node.carousel.contents,
            {
              id: `_Temporary_${v4()}_${Date.now()}`,
              attachment: {
                mediaType: 'carousel',
                payload: MessageFactory.carousel({
                  buttons: element.buttons,
                  title: element.title,
                }).attachment.payload,
              },
            },
          ];
          element.buttonsView = [];
          for (const button of element.buttons) {
            element.buttonsView = [
              ...element.buttonsView,
              {
                isActionButton: true,
                id: `_Temporary_${v4()}_${Date.now()}`,
                type: 'postback',
                label: button.title,
                edge: button.edge,
              },
            ];
          }
        }
      }
      return node;
    });
    const nodeRelationMap = specification.buildFullRelationMap(sceneNodes);
    for (const node of sceneNodes) {
      node.fromEdges = nodeRelationMap.get(node.id)![1];
      const e = specification.validate(node);
      errors[node.id] = e;
    }
    dimensions = specification.cleanupDimensions(dimensions, nodeRelationMap);

    const ret: SceneNodes = {
      ...nodes,
      id,
      updated: !!nodes.updated,
      feed: nodes.feed ?? false,
      switchToOp: nodes.switchToOp ?? false,
      isAnalytics,
      parameters: nodes.parameters,
      nodes: new SceneNodeCache(sceneNodes),
      dimensions,
    };

    if (!ret.nodes.hasInitialScene() && nodes.nodes.length) {
      ret.nodes.setInitialScene(nodes.nodes[0].id);
    }

    return ret;
  };

export const sceneNodesToRemoteSceneNode = (
  sceneNodes: ReadonlyDeep<SceneNodes>
): ReadonlyDeep<RemoteSceneNodes> => {
  const nodes = sceneNodes.nodes.toArray().map(scene => {
    switch (scene.type) {
      case 'message':
        const { paramId, ...sceneProps } = scene;
        return {
          ...sceneProps,
          ...(paramId ? { paramId } : {}),
          ...(scene.customFeedbackView
            ? {
                customFeedback: {
                  edgeYes: scene.customFeedbackView.yes.edge,
                  edgeNo: scene.customFeedbackView.no.edge,
                },
              }
            : {}),
          fallback: scene.shouldUseFallbackView ? scene.fallbackView.edge : '',
          freeInputEdge: scene.freeInputEdgeView.edge,
          response: {
            ...scene.response,
            messages: scene.response.messages.map(m => {
              if (m.id.startsWith('_Temporary_')) {
                return { attachment: m.attachment } as typeof m;
              }
              return m;
            }),
            quickReplies: scene.response.quickReplies.map(v => {
              const edge = v.edge;
              if (v.id.startsWith('_Temporary_')) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...rest } = v;
                return { ...rest, edge } as typeof v;
              }
              return { ...v, edge };
            }),
          },
        };
      case 'businessHour':
        return {
          ...scene,
          request: {
            results: scene.request.results.map(({ id, ...rest }) => {
              return rest;
            }),
          },
        } as any;
      case 'integration':
        return {
          ...scene,
          integration: {
            request: scene.integration.request,
            succeeded: {
              quickReplies: scene.integration.succeeded.quickReplies?.map(v => {
                const edge = v.edge;
                if (v.id.startsWith('_Temporary_')) {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { id, ...rest } = v;
                  return { ...rest, edge } as typeof v;
                }
                return { ...v, edge };
              }),
              default:
                scene.integration.succeeded.quickReplies &&
                scene.integration.succeeded.quickReplies.length > 0
                  ? ''
                  : scene.integration.succeeded.defaultButton.edge,
            },
            failed: scene.integration.failedButton.edge,
          },
        };
      case 'mail':
        return {
          ...scene,
          mail: {
            ...scene.mail,
            edges: {
              succeeded: scene.mail.edgesView?.succeeded.edge ?? '',
              failed: scene.mail.edgesView?.failed.edge ?? '',
            },
          },
        };
      case 'carousel':
        const newElements: CarouselSceneElement[] = [];
        for (const element of scene.carousel.elements) {
          const buttons: CarouselSceneButton[] = [];
          for (const buttonView of element.buttonsView) {
            buttons.push({
              title: buttonView.label,
              edge: buttonView.edge,
            });
          }
          newElements.push({
            id: element.id,
            title: element.title,
            buttons,
            buttonsView: [],
          });
        }
        return {
          ...scene,
          freeInputEdge: scene.freeInputEdgeView.edge,
          carousel: {
            ...scene.carousel,
            elements: newElements,
          },
        };
      default:
        return scene;
    }
  });

  return {
    ...sceneNodes,
    nodes,
  };
};

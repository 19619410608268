/**
 * @fileOverview
 * @name ConversationStateOpQueue.ts
 * @author Taketoshi Aono
 * @license
 */

export const enum ConversationStateOpType {
  ASSIGN,
  CLOSE,
  TEXT_MESSAGE_EVENT,
  LABEL_ADDED,
  LABEL_DELETED,
}

interface ConversationStateOp {
  type: ConversationStateOpType;
  customerId: string;
  additionalKey?: string;
}

export interface ConversationStateOpQueueable {
  add(
    opState: ReadonlyDeep<ConversationStateOp>,
    onFinish: (op: ReadonlyDeep<ConversationStateOp>, isSucceeded: boolean) => void
  ): void;
  remove(type: ConversationStateOpType, id: string, additionalKey?: string): void;
}

export class ConversationStateOpQueue implements ConversationStateOpQueueable {
  private readonly mapQueue: {
    [id: string]:
      | {
          timerId: any;
          opState: ReadonlyDeep<ConversationStateOp>;
          onFinish(op: ReadonlyDeep<ConversationStateOp>, isSucceeded: boolean): void;
        }
      | undefined;
  } = {};

  public constructor(private readonly timeout = 120000) {}

  public add(
    opState: ReadonlyDeep<ConversationStateOp>,
    onFinish: (op: ReadonlyDeep<ConversationStateOp>, isSucceeded: boolean) => void
  ): void {
    const key = `${opState.customerId}_${opState.type}${
      opState.additionalKey ? `-${opState.additionalKey}` : ''
    }`;
    this.mapQueue[key] = {
      opState,
      onFinish,
      timerId: setTimeout(() => {
        onFinish(opState, false);
        delete this.mapQueue[key];
      }, this.timeout),
    };
  }

  public remove(type: ConversationStateOpType, id: string, additionalKey?: string) {
    const key = `${id}_${type}${additionalKey ? `-${additionalKey}` : ''}`;
    const item = this.mapQueue[key];
    if (item) {
      clearTimeout(item.timerId);
      delete this.mapQueue[key];
      item.onFinish(item.opState, true);
    }
  }
}

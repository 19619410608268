import { apiEndpoint } from '@c/config';
import { IgAutoMessage } from '@c/domain/entities/IgAutoMessage';
import { GettableQuery } from '@c/query/interface';
import { fetchService } from '@s/io/fetchService';

export type IgAutoMessageGettableQuery = GettableQuery<{ projectId: string }, IgAutoMessage>;

export class IgAutoMessageQuery implements IgAutoMessageGettableQuery {
  public async get({
    projectId,
  }: Parameters<IgAutoMessageGettableQuery['get']>[0]): ReturnType<
    IgAutoMessageGettableQuery['get']
  > {
    const ret = await fetchService<IgAutoMessage>(
      apiEndpoint(`project/${projectId}/instagram/auto-message`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );
    return ret;
  }
}

/**
 * @fileOverview
 * @name Connection.ts
 * @author Taketoshi Aono
 * @license
 */

import { LeastCommonDebuggerEventType } from '@s/debugger/EventType';
import { EventDispatcher } from '@s/EventDispacher';

export interface DebuggerConnectable {
  isOpener: boolean;
  self: MessageEventTarget;
  subject: MessageEventTarget;
  origin: string;
  connect<ReceivableEventType extends string, SendableEventType extends string>(): Promise<
    WindowEventBus<ReceivableEventType, SendableEventType>
  >;
  close(): void;
  onConnect(): void;
  onDisconnect(): void;
  onFailToConnect(): void;
}

export interface WindowEventBus<
  ReceivableEventType extends string,
  SendableEventType extends string
> {
  on(type: ReceivableEventType, handler: (e: MessageEvent) => void): void;
  off(type?: ReceivableEventType, handler?: (e: MessageEvent) => void): void;
  send(type: SendableEventType, args?: any): void;
}

export type MessageEventTarget = Omit<EventTarget, 'dispatchEvent'> & {
  close(): void;
  closed: boolean;
  postMessage(message: any, targetOrigin: string, transfer?: Transferable[]): void;
};

class DebuggerEventBus<ReceivableEventType extends string, SendableEventType extends string>
  implements WindowEventBus<ReceivableEventType, SendableEventType>
{
  private readonly bindMessageEvent: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-unnecessary-type-arguments
  private readonly eventDispatcher = new EventDispatcher<ReceivableEventType, SendableEventType>();
  private readonly unbindMessageEvent: () => void;

  public constructor(private readonly conn: DebuggerConnectable) {
    const handler = (event: MessageEvent) => {
      if (event.origin === this.conn.origin && event.data.type) {
        this.eventDispatcher.dispatch(event.data.type, event);
      }
    };
    this.bindMessageEvent = () => (this.conn.self as Window).addEventListener('message', handler);
    this.unbindMessageEvent = () =>
      (this.conn.self as Window).removeEventListener('message', handler);
  }

  public off(type?: ReceivableEventType, handler?: (e: MessageEvent) => void): void {
    this.eventDispatcher.off(type, handler);
    if (!this.eventDispatcher.length) {
      this.unbindMessageEvent();
    }
  }

  public on(type: ReceivableEventType, handler: (e: MessageEvent) => void): void {
    if (!this.eventDispatcher.length) {
      this.bindMessageEvent();
    }
    this.eventDispatcher.on(type, handler);
  }

  public send(type: SendableEventType, args: any = {}): void {
    this.conn.subject.postMessage({ type, payload: args }, this.conn.origin);
  }
}

export class DebuggerConnectionParameters {
  public isOpener = false;
  public origin = '';
  public self: MessageEventTarget = null as any;
  public subject: MessageEventTarget = null as any;
}

export class DebuggerConnection
  extends DebuggerConnectionParameters
  implements DebuggerConnectable
{
  private eventBus?: WindowEventBus<LeastCommonDebuggerEventType, LeastCommonDebuggerEventType>;
  private failToConnectTimer?: any;
  private pingFailTimer?: any;
  private pingTimer?: any;

  public constructor(a: DebuggerConnectionParameters) {
    super();
    Object.assign(this, a);
  }

  public close(): void {
    this.killAllTimer();
    if (this.eventBus) {
      this.eventBus.off();
    }
  }

  public async connect<
    ReceivableEventType extends string,
    SendableEventType extends string
  >(): Promise<WindowEventBus<ReceivableEventType, SendableEventType>> {
    const eventBus = new DebuggerEventBus<
      LeastCommonDebuggerEventType,
      LeastCommonDebuggerEventType
    >(this);
    return new Promise(resolve => {
      this.failToConnectTimer = setTimeout(() => {
        this.onFailToConnect();
      }, 5000);

      const pollWindowOpeningState = () => {
        if (!this.subject.closed) {
          setTimeout(pollWindowOpeningState, 1000);
        } else if (!this.isOpener) {
          this.self.close();
        } else {
          this.onDisconnect();
        }
      };
      pollWindowOpeningState();
      let retryTimer: any;
      eventBus.on('established', () => {
        clearInterval(retryTimer);
        this.killAllTimer();
        this.eventBus = eventBus;
        if (this.isOpener) {
          eventBus.send('established', {});
        }
        this.onConnect();
        if (!this.isOpener) {
          eventBus.send('ping');
        }
        resolve(eventBus as any);
      });

      let isLastPingFailed = false;
      eventBus.on('ping', () => {
        if (isLastPingFailed) {
          this.onConnect();
          isLastPingFailed = false;
        }
        clearTimeout(this.pingFailTimer);
        this.pingTimer = setTimeout(() => {
          this.pingFailTimer = setTimeout(() => {
            isLastPingFailed = true;
            this.onFailToConnect();
          }, 5000);
          eventBus.send('ping');
        }, 1000);
      });

      if (!this.isOpener) {
        eventBus.send('established', {});
        retryTimer = setInterval(() => {
          eventBus.send('established', {});
        }, 1000);
      }
    });
  }

  public onConnect: () => void = () => {};
  public onDisconnect: () => void = () => {};
  public onFailToConnect = () => {};

  private killAllTimer() {
    clearTimeout(this.failToConnectTimer);
    clearTimeout(this.pingTimer);
    clearTimeout(this.pingFailTimer);
  }
}

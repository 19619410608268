/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  addLabelSucceeded,
  deleteLabelSucceeded,
  fetchLabelSucceeded,
  updateLabelSucceeded,
} from '@c/modules/label/action';

type ActionTypes = ReturnType<
  | typeof addLabelSucceeded
  | typeof deleteLabelSucceeded
  | typeof fetchLabelSucceeded
  | typeof updateLabelSucceeded
>;

export default (
  state: State['label'] = initialState.label,
  action: ActionTypes
): State['label'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'ADD_LABEL_SUCCEEDED':
        state.push(action.payload);
        return;
      case 'UPDATE_LABEL_SUCCEEDED':
        state.forEach((x, i) => {
          if (x.id === action.payload.id) {
            state[i].name = action.payload.name;
            state[i].color = action.payload.color;
          }
        });
        return;
      case 'DELETE_LABEL_SUCCEEDED':
        state.forEach((x, i) => {
          if (x.id === action.payload.id) {
            state[i].archived = true;
          }
        });
        return;
      case 'FETCH_LABEL_SUCCEEDED':
        return action.payload;
      default:
        const unusedCheck: never = action;
        return;
    }
  });
};

/**
 * @fileOverview
 * @name BusinessHourParameter.ts
 * @author
 */

import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';
import { BusinessHourSpecificationValidator } from '../specification/BusinessHourSpecification';

export class BusinessHourParameter {
  public constructor(
    public entity: BusinessHourEntity[],
    public spec: BusinessHourSpecificationValidator
  ) {}

  public isValid() {
    return this.validatedEntity().filter(e => e.errors.length >= 1).length === 0;
  }

  public validatedEntity(): BusinessHourEntity[] {
    const duplicate = this.spec.validateDuplication(this.entity);
    const reverse = this.spec.validateHourOrder(this.entity);
    return this.entity.map((e, i) => {
      const errors: string[] = [];
      duplicate.hasDuplication[i] && errors.push(duplicate.errorMessage);
      reverse.hasInvalidHourOrder[i] && errors.push(reverse.errorMessage);
      return { ...e, errors };
    });
  }
}

import {
  DialogEngineRouteDraftCondition,
  RouteDraftParameter,
} from '@c/domain/entities/dialogEngine/DialogEngineRouteDraft';

export interface DialogEngineRouteDraftSpecification {
  isValidRouteDraft(
    routeDraftParameter: RouteDraftParameter,
    shouldCheckConditionExits?: boolean
  ): [boolean, string];
}

export class DialogEngineRouteDraftValidator implements DialogEngineRouteDraftSpecification {
  private isValidRouteDraftCondition(
    routeDraftCondition: DialogEngineRouteDraftCondition
  ): [boolean, string] {
    if (!routeDraftCondition.expect) {
      return [false, '条件は必須です'];
    } else if (routeDraftCondition.expect.length > 1000) {
      return [false, '条件は1000文字以下です'];
    } else if (!routeDraftCondition.subject) {
      return [false, '条件は必須です'];
    } else if (routeDraftCondition.subject.length > 1000) {
      return [false, '条件は1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidRouteDraft(
    routeDraftParameter: RouteDraftParameter,
    shouldCheckConditionExits = false
  ): [boolean, string] {
    if (!routeDraftParameter.fromSceneID) {
      return [false, '遷移元のシーンを指定してください'];
    } else if (
      !!routeDraftParameter.routes.find(
        route => route.toSceneID === routeDraftParameter.fromSceneID
      )
    ) {
      return [false, '同一のシーンを指定できません'];
    } else if (!!routeDraftParameter.routes.find(route => route.name.length > 1000)) {
      return [false, '名前は1000文字以下です'];
    } else if (shouldCheckConditionExits) {
      const [isValid, message] = this.existRouteDraftCondition(routeDraftParameter);
      if (!isValid) {
        return [false, message];
      }
    }
    let errorMessage = '';
    if (
      !!routeDraftParameter.routes.find(route => {
        return !!route.routeConditions.find(condition => {
          const [isValid, message] = this.isValidRouteDraftCondition(condition);
          if (!isValid) {
            errorMessage = message;
          }
          return !isValid;
        });
      })
    ) {
      return [false, errorMessage];
    }
    return [true, ''];
  }

  public existRouteDraftCondition(routeDraftParameter: RouteDraftParameter): [boolean, string] {
    if (!!routeDraftParameter.routes.find(route => route.routeConditions.length === 0)) {
      return [false, '遷移条件は必須です'];
    }
    return [true, ''];
  }
}

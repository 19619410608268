export interface DialogEngineStorySpecification {
  isValid(storyName: string): [boolean, string];
}

export class DialogEngineStoryValidator implements DialogEngineStorySpecification {
  public isValid(storyName: string): [boolean, string] {
    if (storyName.length === 0) {
      return [false, 'ストーリー名は必須です'];
    } else if (storyName.length > 1000) {
      return [false, 'ストーリー名は1000文字以下です'];
    }
    return [true, ''];
  }
}

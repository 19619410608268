import { WidgetConfiguration } from '@s/domain/values';

export interface WhitelistSpecification {
  correct(widgetCustomization: WidgetConfiguration): WidgetConfiguration;
}

export class WidgetCustomizationSpecification implements WhitelistSpecification {
  public correct(widgetCustomization: WidgetConfiguration): WidgetConfiguration {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!!widgetCustomization.whitelist?.acceptUrl) {
      return widgetCustomization;
    }
    const acceptUrl: string[] = [];
    (
      ((widgetCustomization as any)?.acceptDomain as string[] | undefined) ??
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      widgetCustomization.whitelist?.acceptDomains ??
      []
    ).forEach(domain => {
      if (!/^https?\:/.test(domain)) {
        acceptUrl.push(`*://${domain}*`);
      }
    });
    return { ...widgetCustomization, whitelist: { acceptUrl } };
  }
}

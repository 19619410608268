import { Action } from 'redux';
import Worker from '@s/sentry/SentryWorker.ts?worker&inline';
import {
  sentryInit,
  sentryLogin,
  sentryLoginAnonymously,
  sentrySend,
  sentryDrainQueue,
  sentrySendWithData,
} from '@s/sentry/SentryWorkerEventType';

export interface CrashReportable {
  login(state: { email: string; displayName: string }): void;
  loginAnonymous({ id }: { id: string }): void;
  init(
    opt: any /* We can't use 'import type from @sentry/browser' becuase of typescript-eslint not support that syntax*/
  ): CrashReportable;
  sendWithData(error: Error, data: { state: any; actionLog: Action[]; additionalData?: any }): void;
  send(error: Error, firestoreUrl: string, additionalData?: any): void;
}

let instance: CrashReportable | null = null;
export class CrashReporter implements CrashReportable {
  public static getInstance(): CrashReportable {
    if (!instance) {
      instance = new CrashReporter();
    }
    return instance;
  }

  private readonly sentryWorker = new Worker();

  private constructor() {
    window.addEventListener(
      'online',
      () => {
        this.sentryWorker.postMessage(sentryDrainQueue());
      },
      { passive: true }
    );
  }

  public init(opt: any): CrashReportable {
    this.sentryWorker.postMessage(sentryInit(opt));
    return this;
  }

  public login({ email, displayName }: { email: string; displayName: string }) {
    this.sentryWorker.postMessage(
      sentryLogin({
        email: email,
        username: displayName,
      })
    );
  }

  public loginAnonymous({ id }: { id: string }) {
    this.sentryWorker.postMessage(sentryLoginAnonymously({ id }));
  }

  public send(error: Error, firestoreUrl: string, additionalData?: any) {
    this.sentryWorker.postMessage(
      sentrySend({ url: location.href, error, firestoreUrl, additionalData })
    );
  }

  public sendWithData(
    error: Error,
    { state, actionLog }: { state: any; actionLog: Action[]; additionalData?: any }
  ) {
    this.sentryWorker.postMessage(
      sentrySendWithData({
        url: location.href,
        extras: JSON.stringify({
          state,
          actionLog,
          rawError: error,
        }),
        error,
      })
    );
  }
}

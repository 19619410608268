import { isValidPhoneNumberString } from '@c/util/formatPhoneNumber';

export interface TwilioPhoneNumberSpecification {
  isValidPhoneNumber(phoneNumber: string): [boolean, string];

  iaValidPhoneNumberSID(
    phoneNumberSID: string,
    currentPhoneNumberSIDs: string[]
  ): [boolean, string];

  isValidTwilioAccountSID(twilioAccountSID: string): [boolean, string];

  isValidTwilioFriendlyName(twilioFriendlyName: string): [boolean, string];
}

export class TwilioPhoneNumberValidator implements TwilioPhoneNumberSpecification {
  public iaValidPhoneNumberSID(
    phoneNumberSID: string,
    currentPhoneNumberSIDs: string[]
  ): [boolean, string] {
    if (phoneNumberSID.length === 0) {
      return [false, 'phoneNumberSIDは必須です'];
    } else if (phoneNumberSID.length > 1000) {
      return [false, 'phoneNumberSIDは1000文字以下です'];
    } else if (!!currentPhoneNumberSIDs.find(sid => sid === phoneNumberSID)) {
      return [false, 'phoneNumberSIDが重複しています'];
    }
    return [true, ''];
  }

  public isValidPhoneNumber(phoneNumber: string): [boolean, string] {
    if (phoneNumber.length === 0) {
      return [false, '電話番号は必須です'];
    } else if (phoneNumber.length > 1000) {
      return [false, '電話番号は1000文字以下です'];
    } else if (!isValidPhoneNumberString(phoneNumber)) {
      return [false, '不正な電話番号です'];
    }
    return [true, ''];
  }

  public isValidTwilioAccountSID(twilioAccountSID: string): [boolean, string] {
    if (twilioAccountSID.length === 0) {
      return [false, 'TwilioアカウントSIDは必須です'];
    } else if (twilioAccountSID.length > 1000) {
      return [false, 'TwilioアカウントSIDは1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidTwilioFriendlyName(twilioFriendlyName: string): [boolean, string] {
    if (twilioFriendlyName.length === 0) {
      return [false, 'Twilioフレンドリーネームは必須です'];
    } else if (twilioFriendlyName.length > 1000) {
      return [false, 'Twilioフレンドリーネームは1000文字以下です'];
    }
    return [true, ''];
  }
}

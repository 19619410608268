import { GettableQuery } from '@s/Query';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type FixedMessage = {
  welcome: string;
};

export type FixedMessageGettableQuery = GettableQuery<{ projectId: string }, FixedMessage>;

export class FixedMessageQuery implements FixedMessageGettableQuery {
  public async get({
    projectId,
  }: Parameters<FixedMessageGettableQuery['get']>[0]): ReturnType<
    FixedMessageGettableQuery['get']
  > {
    const ret = await fetchService<FixedMessage>(
      apiEndpoint(`project/${projectId}/fixed_message`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );
    return ret;
  }
}

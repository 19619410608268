/**
 * @fileOverview
 * @name InquirySpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import { genericError, GenericError } from '@c/application/GenericError';

export interface InquirySpecification {
  validateNewInquiry(a: {
    inquiry: string;
    inquiries: { inquiry: string }[];
  }): Optional<GenericError>;
}
type P = Params<InquirySpecification>;

let instance: InquirySpecification | null = null;

export class InquiryEditorSpecification implements InquirySpecification {
  public static getInstance() {
    if (!instance) {
      instance = new InquiryEditorSpecification();
    }
    return instance;
  }

  private constructor() {}

  public validateNewInquiry({
    inquiries,
    inquiry,
  }: P['validateNewInquiry'][0]): Optional<GenericError> {
    if (!inquiry.length) {
      return genericError({ message: 'お問い合わせは1文字以上必要です' });
    }
    if (inquiries.some(v => v.inquiry === inquiry)) {
      return genericError({
        message: `お問い合わせ ${inquiry} はすでに存在します`,
      });
    }
    return null;
  }
}

/**
 * @fileoverview
 * @author
 */

import { ThunkDeps } from '@c/ThunkDeps';
import { State } from '@c/state';
import { push } from 'connected-react-router';
import { genericError } from '@c/application/GenericError';
import { firebaseErrorHandler } from '@c/io/firebaseErrorHandler';
import {
  UpdateEmailParameter,
  UpdateDisplayNameParameter,
  UpdatePasswordParameter,
  PhoneNumberParameter,
  VerificationCodeParameter,
} from '@c/domain/entities/Account';
import {
  deleteAuthEntity,
  loginSucceeded,
  updateAuthEntityDisplayName,
} from '@c/modules/auth/action';
import { FetchFailure } from '@s/io/fetchService';
import { AsyncActionContext } from '@s/reactHooks';
import { apiEndpoint, staticConfig } from '@c/config';
import { storage } from '@s/io/storage';
import { Dispatch } from 'redux';
import { INITIAL_PAGE } from '@c/urlList';
import { updateMyOperator } from '@c/repository/OperatorRepository';

type C = AsyncActionContext<ThunkDeps, State>;
const MULTI_FACTOR_VERIFICATION_ID = '__aim_mf_verification_id';
export const MULTI_FACTOR_DISPLAY_NAME = '__aim_mf_display_name';
export const MULTI_FACTOR_HINTS = '__aim_mf_hints';

export const logout =
  ({ context, dispatch, state }: C) =>
  async () => {
    try {
      await context.firestoreService.releaseAllConnection();
      await context.accountRepository.loguot();
      await context.fbService.logout();
      dispatch(deleteAuthEntity());
      dispatch(push('/login'));
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw firebaseErrorHandler(e, () => genericError({ message: 'ログアウトに失敗しました' }));
    }
  };

export const updateEmail =
  ({ context, state }: C) =>
  async (
    payload: UpdateEmailParameter,
    { isLimitedProjectList }: { isLimitedProjectList: boolean }
  ) => {
    payload.validate(context.accountProfileValidator);
    if (isLimitedProjectList) {
      try {
        await updateMyOperator(apiEndpoint(`/operators/me`), {
          arg: { email: payload.email },
        });
      } catch (e: any) {
        context.reportCrashed({ error: e, state });
        throw genericError({ message: 'メールアドレス更新に失敗しました' });
      }
    } else {
      try {
        await context.accountRepository.updateEmail(payload);
      } catch (e: any) {
        context.reportCrashed({ error: e, state });
        throw firebaseErrorHandler(e, () =>
          genericError({ message: 'メールアドレス更新に失敗しました' })
        );
      }
    }
  };

export const updatePassword =
  ({ context, state }: C) =>
  async (payload: UpdatePasswordParameter) => {
    const operatorEmailaddress = state.auth.authEntity?.user.email ?? '';
    payload.validate(context.accountProfileValidator);

    try {
      // パスワード変更時には、ユーザが入力した現在のパスワードで再認証
      await context.accountRepository.reAuthenticate({
        id: operatorEmailaddress,
        password: payload.currentPassword,
      });
      await context.accountRepository.updatePassword({ password: payload.newPassword });
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw firebaseErrorHandler(e, () =>
        genericError({ message: 'パスワード更新に失敗しました' })
      );
    }
  };

export const sendRemindPasswordEmail =
  ({ context, state }: C) =>
  async (payload: UpdateEmailParameter) => {
    payload.validate(context.accountProfileValidator);
    try {
      await context.accountRepository.sendRemindPasswordEmail(payload);
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw firebaseErrorHandler(e, () => genericError({ message: 'メール送信に失敗しました' }));
    }
  };

export const updateDisplayName =
  ({ context, state, dispatch }: C) =>
  async (
    payload: UpdateDisplayNameParameter,
    { isLimitedProjectList }: { isLimitedProjectList: boolean }
  ) => {
    payload.validate();
    if (isLimitedProjectList) {
      try {
        await updateMyOperator(apiEndpoint(`/operators/me`), {
          arg: { displayName: payload.displayName },
        });
      } catch (e: any) {
        context.reportCrashed({ error: e, state });
        throw genericError({ message: '表示名の更新に失敗しました' });
      }
    } else {
      try {
        await context.accountRepository.updateDisplayName(payload);
      } catch (e: any) {
        context.reportCrashed({ error: e, state });
        throw firebaseErrorHandler(e, () =>
          genericError({ message: '表示名の更新に失敗しました' })
        );
      }
    }
    dispatch(updateAuthEntityDisplayName(payload.displayName));
  };

export const loginWithEmailLink =
  ({ context, state }: C) =>
  async (email: string) => {
    try {
      await context.accountRepository.loginWithEmailLink(email);
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw firebaseErrorHandler(e, () => genericError({ message: e.message }));
    }
  };

export const loginWithFb =
  ({ context, state }: C) =>
  async (email: string) => {
    try {
      const accessToken = await context.fbService.getAccessToken({
        appId: staticConfig.facebook.appId,
        scope: ['public_profile'],
        shouldStartLoginProcess: true,
      });
      if (accessToken) {
        await context.accountRepository.issueWithFb(accessToken, email);
      } else {
        throw new Error('Get facebook access token failed when issue new account');
      }
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw firebaseErrorHandler(e, () =>
        genericError({ message: 'Facebookログインに失敗しました' })
      );
    }
  };

export const initializeRecaptchaVerifier =
  ({ context }: C) =>
  async (containerId: string) => {
    await context.accountRepository.initializeRecaptchaVerifier({ containerId });
  };

export const sendVerificationCodeInRegister =
  ({ context, dispatch, state }: C) =>
  async (payload: PhoneNumberParameter, errorDirect: boolean) => {
    payload.validate(context.accountProfileValidator);
    try {
      storage('local').set(MULTI_FACTOR_DISPLAY_NAME, payload.displayName);
      const verificationId = await context.accountRepository.registerPhoneNumber({
        phoneNumber: payload.phoneNumber,
      });
      storage('local').set(MULTI_FACTOR_VERIFICATION_ID, verificationId);
      dispatch(push('/multi-factor-register'));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      errorMultiFactor(context, dispatch, e.message, errorDirect);
    }
  };

export const verifyMultiFactorCodeInRegister =
  ({ context, state, dispatch }: C) =>
  async (payload: VerificationCodeParameter) => {
    payload.validate(context.accountProfileValidator);
    try {
      const verificationId = await storage('local').get(MULTI_FACTOR_VERIFICATION_ID);
      const displayName: string =
        (await storage('local').get(MULTI_FACTOR_DISPLAY_NAME)) || '電話番号';
      if (!verificationId) {
        errorMultiFactor(context, dispatch);
      }
      const authEntity = await context.accountRepository
        .verifyMultiFactorCodeInRegister({
          verificationId: verificationId as string,
          verificationCode: payload.verificationCode,
          displayName,
        })
        .catch(error => {
          errorMultiFactor(context, dispatch, error.message);
        });
      if (authEntity) {
        clearMultiFactor(context);
        dispatch(loginSucceeded({ authEntity }));
        dispatch(push(INITIAL_PAGE));
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      errorMultiFactor(context, dispatch, e.message);
    }
  };

export const sendVerificationCode =
  ({ context, dispatch }: C) =>
  async (selectedIndex: number) => {
    try {
      const verificationId = await context.accountRepository.sendVerificationCode({
        selectedIndex,
      });
      storage('local').set(MULTI_FACTOR_VERIFICATION_ID, verificationId);
      dispatch(push('/multi-factor-auth'));
    } catch (e) {
      errorMultiFactor(context, dispatch);
    }
  };

export const verifyMultiFactorCode =
  ({ context, state, dispatch }: C) =>
  async (payload: VerificationCodeParameter) => {
    payload.validate(context.accountProfileValidator);
    const verificationId = await storage('local').get(MULTI_FACTOR_VERIFICATION_ID);
    if (!verificationId) {
      errorMultiFactor(context, dispatch);
    }
    try {
      const authEntity = await context.accountRepository
        .verifyMultiFactorCode({
          verificationId: verificationId as string,
          verificationCode: payload.verificationCode,
        })
        .catch(error => {
          errorMultiFactor(context, dispatch, error.message);
        });
      if (authEntity) {
        clearMultiFactor(context);
        dispatch(loginSucceeded({ authEntity }));
        dispatch(push(INITIAL_PAGE));
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      errorMultiFactor(context, dispatch, e.message);
    }
  };

const errorMultiFactor = (
  context: ThunkDeps,
  dispatch: Dispatch,
  message?: string,
  redirect = true
) => {
  if (redirect) {
    clearMultiFactor(context);
    setTimeout(() => {
      dispatch(push('/login'));
    }, 5000);
  }
  throw genericError({
    message:
      message ||
      '制限時間を超えたか再読み込みを行った為、認証に失敗しました。もう一度ログインしてください。',
  });
};

const clearMultiFactor = (context: ThunkDeps) => {
  context.accountRepository.clearMultiFactorParameter();
  storage('local').delete(MULTI_FACTOR_VERIFICATION_ID);
  storage('local').delete(MULTI_FACTOR_DISPLAY_NAME);
};

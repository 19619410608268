/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import { BusinessHourEntity, BusinessHourPattern } from '@c/domain/entities/BusinessHour';
import {
  businessHourAdded,
  businessHourDeleted,
  individualBusinessHourInitialized,
  businessHourStartAtHoursChanged,
  businessHourStartAtMinutesChanged,
  businessHourEndAtHoursChanged,
  businessHourEndAtMinutesChanged,
  fetchBusinessHourPatternSucceeded,
  businessHourPatternValidated,
  saveBusinessHourPatternSucceeded,
  fetchBusinessHourScheduleSucceeded,
  businessHourScheduleValidated,
  saveBusinessHourScheduleSucceeded,
} from '@c/modules/businessHour/action';

type ActionTypes =
  | ReturnType<typeof businessHourAdded>
  | ReturnType<typeof businessHourDeleted>
  | ReturnType<typeof individualBusinessHourInitialized>
  | ReturnType<typeof businessHourStartAtHoursChanged>
  | ReturnType<typeof businessHourStartAtMinutesChanged>
  | ReturnType<typeof businessHourEndAtHoursChanged>
  | ReturnType<typeof businessHourEndAtMinutesChanged>
  | ReturnType<typeof fetchBusinessHourPatternSucceeded>
  | ReturnType<typeof businessHourPatternValidated>
  | ReturnType<typeof saveBusinessHourPatternSucceeded>
  | ReturnType<typeof fetchBusinessHourScheduleSucceeded>
  | ReturnType<typeof businessHourScheduleValidated>
  | ReturnType<typeof saveBusinessHourScheduleSucceeded>;

const patternToEntity = (pattern: BusinessHourPattern): BusinessHourEntity[] => {
  const defaultEntity = {
    startAt: { hours: '00', minutes: '00' },
    endAt: { hours: '23', minutes: '59' },
    errors: [],
  };
  if (pattern.close) {
    return [];
  } else if (pattern.opens === undefined || pattern.opens.length === 0) {
    return [defaultEntity];
  } else {
    const entity: BusinessHourEntity[] = [];
    pattern.opens.forEach(([start, end]) => {
      entity.push({
        startAt: {
          hours: start.substr(0, start.indexOf(':')),
          minutes: start.substr(start.indexOf(':') + 1, 2),
        },
        endAt: {
          hours: end.substr(0, end.indexOf(':')),
          minutes: end.substr(end.indexOf(':') + 1, 2),
        },
        errors: [],
      });
    });
    return entity;
  }
};

export default (
  state: State['businessHour'] = initialState.businessHour,
  action: ActionTypes
): State['businessHour'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'BUSINESS_HOUR_ADDED':
        const newEntity: BusinessHourEntity = {
          startAt: { hours: '00', minutes: '00' },
          endAt: { hours: '01', minutes: '00' },
          errors: [],
        };
        state.schedule[action.payload.key].entity.push(newEntity);
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'BUSINESS_HOUR_DELETED':
        state.schedule[action.payload.key].entity.splice(action.payload.index, 1);
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'INDIVIDUAL_BUSINESS_HOUR_INITIALIZED':
        state.schedule[action.payload.date].entity = action.payload.entity;
        return;
      case 'BUSINESS_HOUR_START_AT_HOURS_CHANGED':
        state.schedule[action.payload.key].entity[action.payload.index].startAt.hours =
          action.payload.hours;
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'BUSINESS_HOUR_START_AT_MINUTES_CHANGED':
        state.schedule[action.payload.key].entity[action.payload.index].startAt.minutes =
          action.payload.minutes;
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'BUSINESS_HOUR_END_AT_HOURS_CHANGED':
        state.schedule[action.payload.key].entity[action.payload.index].endAt.hours =
          action.payload.hours;
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'BUSINESS_HOUR_END_AT_MINUTES_CHANGED':
        state.schedule[action.payload.key].entity[action.payload.index].endAt.minutes =
          action.payload.minutes;
        if (action.payload.key === 'weekly' || action.payload.key === 'holiday') {
          state.saveRequiredPattern = true;
        } else {
          state.schedule[action.payload.key].weekday = -1;
          state.saveRequiredSchedule = true;
        }
        return;
      case 'FETCH_BUSINESS_HOUR_PATTERN_SUCCEEDED':
        state.schedule.weekly.entity = patternToEntity(
          action.payload.patterns.find(x => x.weekday === 1) ?? { weekday: 1 }
        );
        state.schedule.holiday.entity = patternToEntity(
          action.payload.patterns.find(x => x.weekday === 7) ?? { weekday: 7 }
        );
        return;
      case 'BUSINESS_HOUR_PATTERN_VALIDATED':
        state.schedule.weekly.entity = action.payload.weekly.validatedEntity();
        state.schedule.holiday.entity = action.payload.holiday.validatedEntity();
        state.saveRequiredPattern = false;
        return;
      case 'SAVE_BUSINESS_HOUR_PATTERN_SUCCEEDED':
        return;
      case 'FETCH_BUSINESS_HOUR_SCHEDULE_SUCCEEDED':
        action.payload.schedule.forEach(x => {
          state.schedule[x.date] = {
            entity: patternToEntity({ weekday: x.weekday, opens: x.opens, close: x.close }),
            weekday: x.weekday,
            publicHoliday: x.publicHoliday,
          };
        });
        return;
      case 'BUSINESS_HOUR_SCHEDULE_VALIDATED':
        state.schedule[action.payload.date].entity = action.payload.parameter.validatedEntity();
        state.saveRequiredSchedule = false;
        return;
      case 'SAVE_BUSINESS_HOUR_SCHEDULE_SUCCEEDED':
        return;
      default:
        return;
    }
  });
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { css } from '@emotion/react';

export const scrollbarStyle = css`
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #efefef;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }
`;

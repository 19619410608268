/**
 * @fileOverview
 * @name Synonym.ts
 * @author Taketoshi Aono
 * @license
 */

import { v4 } from 'uuid';

export type RemoteSynonym = {
  synonym: [string, ...string[]][] | null;
};

export type Synonym = {
  id: string;
  displayName: string;
  words: { id: string; word: string }[];
}[];

export const synonymItem = (displayName = '', words: ReturnType<typeof synonymWord>[] = []) => {
  return { words, id: v4(), displayName };
};

export const synonymWord = (word = ''): ArrayElementType<ArrayElementType<Synonym>['words']> => {
  return { word, id: v4() };
};

/**
 * @fileOverview
 * @name EnvQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { TenantId } from '@c/domain/entities/TenantId';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { ENV, apiEndpoint } from '@c/config';
import { GettableQuery } from './interface';
import { EnvValues } from '@c/domain/values/EnvValues';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export type ProjectIdGettableQuery = GettableQuery<TenantId, ProjectId>;
export type EnvGettableQuery = GettableQuery<undefined, EnvValues>;

export class ProjectIdQuery implements ProjectIdGettableQuery {
  public async get(tenantId: TenantId): Promise<ProjectId> {
    const db = getFirestore();
    const data = await getDocs(collection(db, ENV, tenantId, 'projects'));
    if (data.docs.length > 1) {
      const found = data.docs[0];
      return found.id;
    }
    return data.docs[0].id;
  }
}

export class EnvQuery implements EnvGettableQuery {
  public async get(): Promise<EnvValues> {
    // NOTE: fetchServiceは内部でfirebase.authを利用しており、この時点ではまだfirebaseの初期化が実施されていないため、通常のfetchを利用
    const res = await fetch(apiEndpoint(`/env`), {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      throw new Error(await res.text());
    }
    return res.json() as Promise<EnvValues>;
  }
}

/* eslint-disable no-console */
import { ILogger } from './ILogger';
import * as Sentry from '@sentry/browser';
import { initSentry } from '@s/sentry/config';

export class StageLogger implements ILogger {
  constructor(
    private sentryInit: typeof Sentry.init = Sentry.init,
    private sentryCaptureMessage = Sentry.captureMessage
  ) {
    initSentry(this.sentryInit);
  }
  log(message: string, ...args: any[]) {
    this.sentryCaptureMessage(
      `${message}, ${args.map(it => JSON.stringify(it)).join(', ')}`,
      'info'
    );
  }
  error(message: string, ...args: any[]) {
    this.sentryCaptureMessage(
      `${message} ${args.map(it => JSON.stringify(it)).join(', ')}`,
      'error'
    );
  }
  debug(message: string, ...args: any[]) {
    this.sentryCaptureMessage(
      `${message} ${args.map(it => JSON.stringify(it)).join(', ')}`,
      'debug'
    );
  }
  warn(message: string, ...args: any[]) {
    this.sentryCaptureMessage(
      `${message} ${args.map(it => JSON.stringify(it)).join(', ')}`,
      'warning'
    );
  }
}

/**
 * @fileOverview
 * @name validationUtils.ts
 * @author Taketoshi Aono
 * @license
 */

export const SYMBOLS = '\\!"#\\$%&\'\\(\\)\\*\\+,\\-./\\:;<=>\\?@\\[\\]\\^_`\\{\\|\\}~';
export const REQUIRED_CHARS = [/[0-9]/, /[a-z]/, /[A-Z]/, new RegExp(`[${SYMBOLS}]`)];
export const PASSWORD_CHARS = /^[a-zA-Z0-9!"#$%&'()*+,-.\/:;<=>?@\[\]^_`{|}~]*$/;
export const EMAIL_REGEXP_FROM_HTML5_ELEMENT =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const parseUrl = ({ url, isHttpOnly }: { url: string; isHttpOnly: boolean }): URL | null => {
  try {
    const parsedUrl = new URL(url);
    if (isHttpOnly) {
      return parsedUrl.protocol.startsWith('http') ? parsedUrl : null;
    }
    return parsedUrl;
  } catch (e) {
    return null;
  }
};

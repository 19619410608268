import { UpdatableRepository } from '@s/Repository';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';

export type EntityData = {
  name: string;
  value?: string;
  values?: string[];
};

export type VoiceEntityUpdatableRepository = UpdatableRepository<
  {
    customerId: string;
    projectId: string;
    conversationId: string;
    eventId: string;
    conversationEntities: EntityData[];
    conversationEventsEntities: EntityData[];
  },
  void
>;
type P = Params<VoiceEntityUpdatableRepository>;

export class VoiceEntityRepository implements VoiceEntityUpdatableRepository {
  public constructor() {}
  public async update(data: P['update'][0]): Promise<void> {
    await fetchService<void>(apiEndpoint(`/conversation/voice/entity`), {
      method: 'PUT',
      data: {
        customerId: data.customerId,
        projectId: data.projectId,
        conversationId: data.conversationId,
        entities: data.conversationEntities,
      },
      responseType: 'text',
    });
    if (data.eventId === '') {
      return;
    }
    await fetchService<void>(apiEndpoint(`/conversation/voice/entity/event`), {
      method: 'PUT',
      data: {
        customerId: data.customerId,
        projectId: data.projectId,
        conversationId: data.conversationId,
        eventId: data.eventId,
        entities: data.conversationEventsEntities,
      },
      responseType: 'text',
    });
  }
}

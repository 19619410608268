/**
 * @fileOverview
 * @name ScenarioAnalyticsQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from './interface';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';
import { RemoteSceneNodes, SceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { SceneErrors } from '@c/domain/values/PromoteErrors';
import { State } from '@c/state';

export type AnalyzedScenarioGettableQuery = GettableQuery<
  { scenarioId: string; projectId: string },
  SceneNodes
>;

export class AnalyzedScenarioQuery implements AnalyzedScenarioGettableQuery {
  public constructor(
    private readonly remoteSceneNodeToSceneNodeAdapter: (
      id: string,
      node: RemoteSceneNodes,
      errors: SceneErrors,
      isAnalytics: boolean,
      projectType: State['env']['type']
    ) => SceneNodes
  ) {}

  public async get({
    projectId,
    scenarioId,
  }: Parameters<AnalyzedScenarioGettableQuery['get']>[0]): ReturnType<
    AnalyzedScenarioGettableQuery['get']
  > {
    const remoteSceneNode = await fetchService<RemoteSceneNodes>(
      apiEndpoint(`project/${projectId}/resolution-training/scenario/${scenarioId}`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );

    return this.remoteSceneNodeToSceneNodeAdapter(scenarioId, remoteSceneNode, {}, true, 'chat');
  }
}

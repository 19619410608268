/**
 * @fileOverview
 * @name ActionLogQueuingService.ts
 * @author Taketoshi Aono
 * @license
 */

import { Action } from 'redux';
import { Queue } from '@s/structs/Queue';

export interface ActionLogQueueable {
  enqueue(action: Action): void;
  getList(): Action[];
}

let instance: ActionLogQueueable | null = null;
export class ActionLogQueuingService implements ActionLogQueueable {
  public static MAX_QUEUE_SIZE = 20;

  public static getInstance(): ActionLogQueueable {
    if (!instance) {
      instance = new ActionLogQueuingService();
    }
    return instance;
  }

  private readonly queue = new Queue<{ [key: string]: any } & { type: string }>();

  private constructor() {}

  public enqueue(
    action: Parameters<ActionLogQueueable['enqueue']>[0]
  ): ReturnType<ActionLogQueueable['enqueue']> {
    if (this.queue.length === ActionLogQueuingService.MAX_QUEUE_SIZE) {
      this.queue.pop();
    }
    this.queue.push(action);
  }

  public getList(): Action[] {
    return this.queue.toArray();
  }
}

import { CreatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { ExportTargetType } from '@c/domain/entities/CustomerDataExport';

export type CustomerDataExportSettingCreatableRepository = CreatableRepository<
  {
    target: ExportTargetType;
    startDateTime?: string;
    endDateTime?: string;
    projectId?: string;
  },
  {}
>;

type DataExportParam = {
  id: string;
  filename: string;
};
export interface DataExportableRepository {
  export({ id, filename }: DataExportParam): Promise<void>;
}

export class CustomerDataExportSettingRepository
  implements CustomerDataExportSettingCreatableRepository, DataExportableRepository
{
  public async create(data: Parameters<CustomerDataExportSettingCreatableRepository['create']>[0]): Promise<{}> {
    return fetchService<{}>(apiEndpoint(`/data-export`), {
      method: 'POST',
      responseType: 'json',
      data: data,
    });
  }

  public async export({
    id,
    filename,
  }: Parameters<DataExportableRepository['export']>[0]): ReturnType<
    DataExportableRepository['export']
  > {
    await fetchService<Blob>(apiEndpoint(`/data-export/${id}`), {
      method: 'GET',
      responseType: 'blob',
    }).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }
}

import { AppendonlyRepository } from '@s/Repository';
import { storage } from '@s/io/storage';
import { DisplayStatusEntity } from '@c/components/environments/CallLog/types';
import {
  VoiceConversationStatus,
  VoiceDirection,
  VoiceCallStatusFilter,
  VoiceTransferStatus,
} from '@c/domain/entities/voice/Conversation';
import { LabelCategory } from '@c/domain/values/Label';
import { VoiceCsvTargetType } from '@c/domain/entities/CustomerDataExport';

export type VoiceSetting = {
  startDate?: Date;
  endDate?: Date;
  displaySetting?: DisplayStatusEntity[];
  direction?: VoiceDirection;
  callStatus?: VoiceCallStatusFilter;
  conversationStatus?: VoiceConversationStatus;
  transferStatus?: VoiceTransferStatus;
  phoneNumber?: string;
  conversationId?: string;
  label?: string;
  labelCategory?: LabelCategory;
  shouldExcludeLabel?: boolean;
  excludeLabelCategory?: LabelCategory;
  csvTarget?: VoiceCsvTargetType;
};

export type VoiceSettingAppendableRepository = AppendonlyRepository<
  VoiceSetting,
  void,
  VoiceSetting,
  void
>;

const KEY = '__AIM_VOICE_SETTING__';

export class VoiceSettingRepository implements VoiceSettingAppendableRepository {
  public async create(
    a: Parameters<VoiceSettingAppendableRepository['create']>[0]
  ): ReturnType<VoiceSettingAppendableRepository['create']> {
    const st = storage('local');
    st.set(KEY, a);
  }

  public async update(
    a: Parameters<VoiceSettingAppendableRepository['update']>[0]
  ): ReturnType<VoiceSettingAppendableRepository['update']> {
    const st = storage('local');
    const ret = st.get(KEY, {}, true);
    st.set(KEY, { ...ret, ...a });
  }
}

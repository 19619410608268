import { DialogEngineScenario } from '@c/domain/entities/dialogEngine/DialogEngineScenario';

export interface DialogEngineScenarioSpecification {
  isValid(scenarioName: string): [boolean, string];
  isValidScenarioList(scenarioList: DialogEngineScenario[]): [boolean, string];
}

export class DialogEngineScenarioValidator implements DialogEngineScenarioSpecification {
  public isValid(scenarioName: string): [boolean, string] {
    if (scenarioName.length === 0) {
      return [false, 'シナリオ名は必須です'];
    } else if (scenarioName.length > 1000) {
      return [false, 'シナリオ名は1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidScenarioList(scenarioList: DialogEngineScenario[]): [boolean, string] {
    if (scenarioList.filter(scenario => scenario.isInitialScenario).length !== 1) {
      return [false, '開始シナリオを設定してください'];
    }
    return [true, ''];
  }
}

import { GettableQuery } from '@s/Query';
import { Notification } from '@c/domain/entities/Notification';
import { contentfulService } from '@c/domain/service/contentfulService';

export type NotificationsGettableQuery = GettableQuery<undefined, Notification[]>;

export class NotificationsQuery implements NotificationsGettableQuery {
  public async get(): ReturnType<NotificationsGettableQuery['get']> {
    const res = await contentfulService.getNotifications();

    return res.map(item => ({
      id: item.sys.id,
      title: item.fields.title,
      description: item.fields.description,
      content: item.fields.content,
      expiry: new Date(item.fields.expiry),
      updatedAt: new Date(item.sys.updatedAt),
      pdf: item.fields.pdf ?? undefined,
    }));
  }
}

/**
 * @fileOverview
 * @name SynonymRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { UpdatableRepository } from '@s/Repository';
import { Synonym, RemoteSynonym } from '@c/domain/entities/Synonym';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type SynonymUpdatableRepository = UpdatableRepository<
  { projectId: string; synonym: Synonym },
  {}
>;

export class SynonymRepository implements SynonymUpdatableRepository {
  public constructor(private readonly synonymToRemoteSynonym: (a: Synonym) => RemoteSynonym) {}

  public async update({
    projectId,
    synonym,
  }: Parameters<SynonymUpdatableRepository['update']>[0]): ReturnType<
    SynonymUpdatableRepository['update']
  > {
    return fetchService<{}>(apiEndpoint(`project/${projectId}/synonym`), {
      method: 'PUT',
      responseType: 'json',
      data: this.synonymToRemoteSynonym(synonym),
    });
  }
}

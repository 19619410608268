/**
 * @fileOverview
 * @name Account.ts
 * @author Taketoshi Aono
 * @license
 */

import { GenericError, genericError } from '@c/application/GenericError';
import { AccountProfileSpecification } from '../specification/AccountSpecification';
import { formatJpPhoneNumber } from '@c/util/formatPhoneNumber';

export class IssueNewAccountParameterField {
  public displayName = '';
  public email = '';
  public password = '';
}

const INVALID_EMAIL_ADDRESS = '不正なメールアドレスです';
const DISPLAYNAME_REQUIRED = '表示名は必須です';
const EMAIL_ADDRESS_REQUIRED = 'メールアドレスは必須です';
const PASSWORD_REQUIRED = 'パスワードは必須です';

export class IssueNewAccountParameter extends IssueNewAccountParameterField {
  public constructor(param: IssueNewAccountParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate(message: string, spec: AccountProfileSpecification): void {
    const e: GenericError = { message, errors: [] };
    if (!this.displayName) {
      e.errors.push({ field: 'displayName', message: DISPLAYNAME_REQUIRED });
    }
    if (!this.email) {
      e.errors.push({ field: 'email', message: EMAIL_ADDRESS_REQUIRED });
    }
    if (!spec.isValidEmail(this.email)) {
      e.errors.push({ field: 'email', message: INVALID_EMAIL_ADDRESS });
    }
    if (!this.password) {
      e.errors.push({ field: 'password', message: PASSWORD_REQUIRED });
    } else {
      const [isValidPassword, errorMessage] = spec.isValidPassword(this.password);
      if (!isValidPassword) {
        e.errors.push({ field: 'password', message: errorMessage });
      }
    }

    if (e.errors.length) {
      throw e;
    }
  }
}

export class UpdateDisplayNameParameterField {
  public displayName = '';
}
export class UpdateDisplayNameParameter extends UpdateDisplayNameParameterField {
  public constructor(param: UpdateDisplayNameParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate() {
    if (!this.displayName) {
      throw genericError({ message: DISPLAYNAME_REQUIRED });
    }
  }
}

export class UpdateEmailParameterField {
  public email = '';
}
export class UpdateEmailParameter extends UpdateEmailParameterField {
  public constructor(param: UpdateEmailParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate(spec: AccountProfileSpecification) {
    if (!this.email) {
      throw genericError({ message: EMAIL_ADDRESS_REQUIRED });
    }
    if (!spec.isValidEmail(this.email)) {
      throw genericError({ message: INVALID_EMAIL_ADDRESS });
    }
  }
}

export class UpdatePasswordParameterField {
  public currentPassword = '';
  public newPassword = '';
}
export class UpdatePasswordParameter extends UpdatePasswordParameterField {
  public constructor(param: UpdatePasswordParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate(spec: AccountProfileSpecification) {
    if (!this.newPassword) {
      throw genericError({ message: PASSWORD_REQUIRED });
    } else {
      const [isValidPassword, errorMessage] = spec.isValidPassword(this.newPassword);
      if (!isValidPassword) {
        throw genericError({ message: errorMessage });
      }
    }
  }
}

export class PhoneNumberParameterField {
  public displayName = '';
  public phoneNumber = '';
}
export class PhoneNumberParameter extends PhoneNumberParameterField {
  public constructor(param: PhoneNumberParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate(spec: AccountProfileSpecification) {
    const [isValidPhoneNumber, errorMessage] = spec.isValidPhoneNumber(this.phoneNumber);
    if (!isValidPhoneNumber) {
      throw genericError({ message: errorMessage });
    }
    const [isValidDisplayName, displayNameErrorMessage] = spec.isValidDisplayName(this.displayName);
    if (!isValidDisplayName) {
      throw genericError({ message: displayNameErrorMessage });
    }
    this.phoneNumber = formatJpPhoneNumber(this.phoneNumber);
  }
}

export class VerificationCodeParameterField {
  public verificationCode = '';
}

export class VerificationCodeParameter extends VerificationCodeParameterField {
  public constructor(param: VerificationCodeParameterField) {
    super();
    Object.assign(this, param);
  }

  public validate(spec: AccountProfileSpecification) {
    const [isValid, errorMessage] = spec.isValidCode(this.verificationCode);
    if (!isValid) {
      throw genericError({ message: errorMessage });
    }
  }
}

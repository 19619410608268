/**
 * @fileOverview
 * @name CategoryRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { Category } from '@c/domain/entities/Category';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { EditableRepository } from '@s/Repository';

export type CategoryEditableRepository = EditableRepository<
  { projectId: string; displayName: string },
  Category,
  { projectId: string; displayName: string; categoryId: number },
  Category,
  { projectId: string; categoryId: number },
  {}
>;

type P = Params<CategoryEditableRepository>;

export class CategoryRepository implements CategoryEditableRepository {
  public async create({ projectId, displayName }: P['create'][0]) {
    const ret = await fetchService<Category[]>(apiEndpoint(`project/${projectId}/category`), {
      method: 'POST',
      data: { displayName },
      responseType: 'json',
    });

    return ret[0];
  }

  public async delete({
    projectId,
    categoryId,
  }: P['delete'][0]): ReturnType<CategoryEditableRepository['delete']> {
    return fetchService(apiEndpoint(`project/${projectId}/category/${categoryId}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update({ projectId, displayName, categoryId }: P['update'][0]): Promise<Category> {
    const ret = await fetchService<Category[]>(
      apiEndpoint(`/project/${projectId}/category/${categoryId}`),
      {
        method: 'PUT',
        data: { displayName },
        responseType: 'json',
      }
    );

    return ret[0];
  }
}

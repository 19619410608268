import {
  DialogEngineBusinessHourParameter,
  DialogEngineBusinessHourPatternDayOfWeek,
} from '@c/domain/entities/dialogEngine/DialogEngineBusinessHourPattern';

export interface DialogEngineBusinessHourPatternSpecification {
  isValidBusinessHours(businessHours: DialogEngineBusinessHourParameter[]): [boolean, string];
}

export class DialogEngineBusinessHourPatternValidator
  implements DialogEngineBusinessHourPatternSpecification
{
  public isValidBusinessHours(
    businessHours: DialogEngineBusinessHourParameter[]
  ): [boolean, string] {
    const dayOfWeeks = businessHours.map(businessHour => businessHour.dayOfWeek);
    const uniqueDayOfWeeks = [...new Set(dayOfWeeks)];
    if (uniqueDayOfWeeks.length !== Object.keys(DialogEngineBusinessHourPatternDayOfWeek).length) {
      return [false, '営業時間の曜日が不足しています'];
    }

    for (const businessHour of businessHours) {
      for (const businessOpenPeriod of businessHour.businessOpenPeriods) {
        if (businessOpenPeriod.periodStart.length === 0) {
          return [false, '営業時間の開始時刻は必須です'];
        } else if (businessOpenPeriod.periodEnd.length === 0) {
          return [false, '営業時間の終了時刻は必須です'];
        }
      }
    }
    return [true, ''];
  }
}

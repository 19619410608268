import { UpdatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type CustomerImageUploadRequestUpdatableRepository = UpdatableRepository<
  {
    projectId: string;
    customerId: string;
    enable: boolean;
  },
  void
>;

type P = Params<CustomerImageUploadRequestUpdatableRepository>;

export class CustomerImageUploadRequestRepository
  implements CustomerImageUploadRequestUpdatableRepository
{
  public async update({ projectId, customerId, enable }: P['update'][0]): Promise<void> {
    await fetchService<void>(
      apiEndpoint(
        `conversation/project/${projectId}/customer/${customerId}/feature/image_up?enable=${String(
          enable
        )}`
      ),
      {
        method: 'POST',
        responseType: 'json',
        data: {},
      }
    );
    return;
  }
}

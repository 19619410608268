/**
 * @fileOverview
 * @name formatPhoneNumber.ts
 * @author Taketoshi Aono
 * @license
 */
import { AsYouType, ParseError, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

const wellKnownPhoneNumberMap: { [key: string]: string } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '+266696687': '非通知',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '+86282452253': '非通知',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '+8656696': '不明',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '+2562533': 'ブロックされた番号',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '+7378742833': '制限されている番号',
};

export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
  if (!phoneNumber) {
    return '';
  }
  if (wellKnownPhoneNumberMap[phoneNumber]) {
    return wellKnownPhoneNumberMap[phoneNumber];
  }
  return formatNational(phoneNumber);
};

export const formatNational = (phoneNumber: string): string => {
  if (phoneNumber.includes('+')) {
    try {
      const instance = parsePhoneNumber(phoneNumber);
      if (instance?.country === 'JP') {
        return instance.formatNational();
      }
      return phoneNumber;
    } catch (error) {
      if (error instanceof ParseError) console.error(error.message);
      // NOTE: パースに失敗した場合はそのまま返す
      return phoneNumber;
    }
  }
  return new AsYouType('JP').input(phoneNumber);
};

export const formatJpPhoneNumber = (phoneNumber: string): string => {
  try {
    const instance = parsePhoneNumber(phoneNumber, 'JP');
    return instance.number.toString();
  } catch (error) {
    if (error instanceof ParseError) console.error(error.message);
    // NOTE: パースに失敗した場合はそのまま返す
    return phoneNumber;
  }
};

export const isValidPhoneNumberString = (phoneNumber: string): boolean => {
  return isValidPhoneNumber(phoneNumber, 'JP');
};

import { EditableRepository } from '@s/Repository';
import {
  TwilioProjectRelation,
  TwilioProjectRelationParameter,
} from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type TwilioProjectRelationEditableRepository = EditableRepository<
  { projectId: string; data: TwilioProjectRelationParameter },
  TwilioProjectRelation,
  { projectId: string; id: string; data: TwilioProjectRelationParameter },
  TwilioProjectRelation,
  { projectId: string; id: string },
  void
>;

export class TwilioProjectRelationRepository implements TwilioProjectRelationEditableRepository {
  public async create({
    projectId,
    data,
  }: Parameters<TwilioProjectRelationEditableRepository['create']>[0]): ReturnType<
    TwilioProjectRelationEditableRepository['create']
  > {
    const ret = await fetchService<TwilioProjectRelation>(
      apiEndpoint(`dialog-engine/project/${projectId}/twilio_project_relation`),
      {
        method: 'POST',
        responseType: 'json',
        data,
      }
    );
    return ret;
  }

  public async delete({
    projectId,
    id,
  }: Parameters<TwilioProjectRelationEditableRepository['delete']>[0]): ReturnType<
    TwilioProjectRelationEditableRepository['delete']
  > {
    const ret = await fetchService<void>(
      apiEndpoint(`dialog-engine/project/${projectId}/twilio_project_relation/${id}`),
      {
        method: 'DELETE',
        responseType: 'json',
      }
    );
    return ret;
  }

  public async update({
    projectId,
    id,
    data,
  }: Parameters<TwilioProjectRelationEditableRepository['update']>[0]): ReturnType<
    TwilioProjectRelationEditableRepository['update']
  > {
    const ret = await fetchService<TwilioProjectRelation>(
      apiEndpoint(`dialog-engine/project/${projectId}/twilio_project_relation/${id}`),
      {
        method: 'PUT',
        responseType: 'json',
        data,
      }
    );
    return ret;
  }
}

/**
 * @fileOverview
 * @name Env.tsx
 * @author Taketoshi Aono
 * @license
 */

import React, { useEffect } from 'react';
import { fetchEnv } from '@c/modules/env/usecase';
import { useErrorNotificator } from '../../hooks/useErrorNotificator';
import { useSelector } from 'react-redux';
import { State } from '@c/state';
import { useAsyncAction } from '@s/reactHooks';
import { Loading } from '@s/components/atom/Loading';
import { useDevCycleClient } from '@devcycle/react-client-sdk';

export const Env = ({ children }: { children: React.ReactElement }) => {
  const envState = useSelector((s: State) => s.env);
  const fetchEnvAction = useAsyncAction(fetchEnv);
  const email = useSelector((state: State) => state.auth.authEntity?.user.email);

  useErrorNotificator(fetchEnvAction.error);
  useEffect(() => {
    fetchEnvAction();
  }, []);

  // NOTE: DevCycleのユーザー情報を更新する
  const devCycleUserData = {
    email,
    customData: {
      tenant_id: envState.tenantId,
    },
  };
  const devcycleClient = useDevCycleClient();
  devcycleClient.identifyUser(devCycleUserData);

  return fetchEnvAction.loading || !envState.tenantId ? (
    <div
      css={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
      }}
    >
      <Loading isModalEnabled={true} />
    </div>
  ) : (
    children
  );
};

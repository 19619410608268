import { VoiceCustomer } from '@c/domain/entities/voice/VoiceCustomer';
import { Timestamp } from 'firebase/firestore';
import { EntityData } from '@c/repository/voice/VoiceEntityRepository';

export const isTimeStamp = (x: any): boolean => {
  return x !== null && typeof x === 'object' && 'seconds' in x && 'nanoseconds' in x;
};

export const isObject = (x: unknown): boolean => {
  return x !== null && (typeof x === 'object' || typeof x === 'function');
};

export type VoiceEntity = {
  [key: string]: any;
};

type VoiceReadingForm = {
  [key: string]: ReadingForm;
};

export type VoiceEntityGroup = {
  key: string;
  entity?: any;
  original?: any;
  seekTime?: number;
};

type ReadingForm = {
  sttResult?: string;
  readingForm?: string;
  confidence?: number;
};

type RemoteReadingForm = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  stt_result: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reading_form: string;
  confidence: number;
};

export const formatVoiceEntity = (entity: VoiceEntity, seekTime?: number) => {
  const ret: VoiceEntityGroup[] = [];
  Object.entries(entity).map(([key, value]) => {
    const formatKey = key.replace('.original', '');
    if (
      ret.filter(val => {
        return val.key === formatKey;
      }).length === 0
    ) {
      ret.push({
        key: formatKey,
        entity: key.includes('.original') ? entity[formatKey] : value,
        original: key.includes('.original') ? value : entity[`${key}.original`],
        seekTime,
      });
    }
  });
  ret.sort((aEntity, bEntity) => {
    const a = aEntity.key.toLocaleLowerCase();
    const b = bEntity.key.toLocaleLowerCase();
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  });
  return ret;
};

export const formatReadingForm = (readingForm: { [key: string]: RemoteReadingForm }) => {
  const ret: VoiceReadingForm = {};
  Object.entries(readingForm).map(([key, value]) => {
    ret[key] = {
      sttResult: value.stt_result,
      confidence: value.confidence,
      readingForm: value.reading_form,
    };
  });
  return ret;
};

// nameと一致するkeyを持つEntityを更新する。Entityのvalueが配列の場合は、一番最後の値だけ更新する
export const updateEntityAndConvertToEntityData = (
  voiceEntity: VoiceEntity,
  name: string,
  newValue: string,
  oldValue: string
): EntityData[] => {
  const entityData = Object.entries(voiceEntity).map(([key, oldEntity]) => {
    if (Array.isArray(oldEntity)) {
      if (key === name) {
        const newEntity = oldEntity.map((ol, index) => {
          if (index === oldEntity.length - 1) {
            return newValue;
          }
          return ol;
        });
        return { name: key, values: newEntity };
      } else {
        return { name: key, values: oldEntity };
      }
    } else {
      if (key === name) {
        return { name: key, value: newValue };
      } else {
        return { name: key, value: oldEntity };
      }
    }
  });
  return entityData;
};

// 架電ステータス関連
export type VoiceCallStatus =
  | '終了'
  | '自動応答中'
  | '転送済'
  | '留守電切断'
  | '未対応'
  | '転送前切断'
  | 'エラー'
  | 'すべて';
export type VoiceCallStatusFilter = 'connected' | 'auto' | 'all';
export const voiceCallMaster = new Map<VoiceCallStatusFilter, VoiceCallStatus[]>([
  ['all', ['すべて']],
  ['connected', ['終了', '転送済', '転送前切断']], // 通電(終了/転送済/転送前切断)
  ['auto', ['自動応答中', '留守電切断']], // 自動音声(自動応答中/留守電切断)
]);

// 会話ステータス関連
export type VoiceConversationStatus = 'finished' | 'left' | 'all';

// 転送ステータス関連
export type VoiceTransferStatus = 'completed' | 'notCompleted' | 'all';

export type VoiceDirection = 'inbound' | 'outbound';
export type Conversation = {
  id: string;
  voiceCustomer: VoiceCustomer;
  entity?: VoiceEntity;
  formattedEntity?: VoiceEntityGroup[];
  readingForm?: VoiceReadingForm;
  isActive: boolean;
  status: VoiceCallStatus;
  conversationStatus?: VoiceConversationStatus;
  transferStatus?: VoiceTransferStatus;
  direction: VoiceDirection;
  labelIds?: string[];
  createdAt: Timestamp;
  note?: string;
};

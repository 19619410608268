import { ILogger } from './ILogger';
import { LocalLogger } from './LocalLogger';
import { DevLogger } from './DevLogger';
import { StageLogger } from './StageLogger';
import { ProdLogger } from './ProdLogger';
import { ENV } from '@c/config';
import * as Sentry from '@sentry/browser';

/**
 * LoggerFactory class
 */
export class LoggerFactory {
  /**
   * @param env
   * @returns ILogger
   */
  static createLogger(
    env: ENV,
    sentryInit: typeof Sentry.init = Sentry.init,
    sentryCaptureMessage: typeof Sentry.captureMessage = Sentry.captureMessage
  ): ILogger {
    switch (env) {
      case 'local':
        return new LocalLogger(sentryInit, sentryCaptureMessage);
      case 'dev':
        return new DevLogger(sentryInit, sentryCaptureMessage);
      case 'stage':
        return new StageLogger();
      case 'prod':
        return new ProdLogger();
      default: {
        const _exhaustiveCheck: never = env;
        if (_exhaustiveCheck) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Unknown environment: ${_exhaustiveCheck}`);
        }
        return new LocalLogger();
      }
    }
  }
}

import { ENV } from '@c/config';
import { EntityHeader } from '@c/domain/entities/voice/EntityHeader';
import { GettableQuery } from '@c/query/interface';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export type EntityHeadersGettableQuery = GettableQuery<
  { tenantId: string; customerId: string; projectId: string; startDate?: Date; endDate?: Date },
  EntityHeader[]
>;

export class EntityHeadersQuery implements EntityHeadersGettableQuery {
  public constructor() {}

  public async get({
    tenantId,
    customerId,
    projectId,
    startDate,
    endDate,
  }: Parameters<EntityHeadersGettableQuery['get']>[0]): ReturnType<
    EntityHeadersGettableQuery['get']
  > {
    const db = getFirestore();
    const docRef = doc(db, ENV, tenantId, 'customers', customerId, 'projects', projectId);
    const doc_ = await getDoc(docRef);
    const entityHeaders = (doc_.data()?.['entity_headers'] ?? []) as EntityHeader[];

    if (startDate && endDate) {
      return entityHeaders.filter(
        e => e.expired_at.toDate() >= startDate && e.created_at.toDate() <= endDate
      );
    }
    return entityHeaders;
  }
}

/**
 * @fileOverview
 * @name PromoteErrors.ts
 * @author Taketoshi Aono
 * @license
 */

export type SceneErrorCode =
  | 'INITIAL_SCENE_NOT_FOUND'
  | 'EMPTY_RESPONSE_MESSAGES'
  | 'EMPTY_PLAIN_TEXT_MESSAGE'
  | 'EMPTY_IMAGE_URL'
  | 'EMPTY_IMAGE_PREVIEW_URL'
  | 'EMPTY_BUTTON_TEXT'
  | 'INVALID_EDGE_FOUND'
  | 'DUPLICATE_BUTTON_TEXT'
  | 'NO_FROM_EDGE_NODE'
  | 'NO_DEST_QUICK_REPLY'
  | 'INTEGRATION_ENDPOINT_HTTPS_ONLY'
  | 'EMPTY_CAROUSEL';

export type SceneErrors = {
  [sceneId: string]:
    | ({ [P in SceneErrorCode]?: SceneErrorCode | undefined } & {
        size: number;
      })
    | undefined;
};

export const addSceneError = ({
  sceneId,
  code,
  errors,
}: {
  sceneId: string;
  code: SceneErrorCode;
  errors: SceneErrors;
}) => {
  if (!errors[sceneId]) {
    errors[sceneId] = { size: 0 };
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (errors?.[sceneId]?.[code]) {
    return;
  }
  const error = errors[sceneId];
  if (error) {
    error[code] = code;
    error.size++;
  }
};

export const removeSceneError = ({
  sceneId,
  code,
  errors,
}: {
  sceneId: string;
  code: SceneErrorCode;
  errors: SceneErrors;
}) => {
  const error = errors[sceneId];
  if (error?.[code]) {
    error[code] = undefined;
    error.size--;
  }
};

export interface RemotePromoteErrors {
  errors: {
    scenarioId: string;
    categoryId: number;
    displayName: string;
    errors: { id: string; code: SceneErrorCode }[];
  }[];
}

export interface PromoteError {
  scenarioId: string;
  categoryId: number;
  displayName: string;
  errors: SceneErrors;
}

export type PromoteErrors = PromoteError[];

const SCENE_MUST_BE_CONNECTED = 'シナリオは必ず到達できる必要があります';
/* eslint-disable @typescript-eslint/naming-convention */
export const sceneErrorMap: { [P in SceneErrorCode]: string } = {
  INITIAL_SCENE_NOT_FOUND: '開始シーンは必ず設定する必要があります',
  EMPTY_RESPONSE_MESSAGES: 'メッセージは1件以上設定する必要がります',
  EMPTY_PLAIN_TEXT_MESSAGE: 'テキストメッセージは必ず設定する必要があります',
  EMPTY_IMAGE_URL: '画像は必ず選択する必要があります',
  EMPTY_IMAGE_PREVIEW_URL: '画像は必ず選択する必要があります',
  EMPTY_BUTTON_TEXT: 'ボタンのメッセージは必ず設定する必要があります',
  INVALID_EDGE_FOUND: 'シナリオの遷移先が存在しません',
  DUPLICATE_BUTTON_TEXT: 'ボタンのテキストは全て違う内容である必要があります',
  NO_DEST_QUICK_REPLY: 'ボタンは必ず接続先が必要です',
  NO_FROM_EDGE_NODE: SCENE_MUST_BE_CONNECTED,
  INTEGRATION_ENDPOINT_HTTPS_ONLY:
    '外部API連携の送信先URLにhttpsから始まるURLを設定する必要があります',
  EMPTY_CAROUSEL: 'カルーセルは必ず選択する必要があります',
};
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * @fileOverview
 * @name ScenarioIdConvertionService.ts
 * @author Taketoshi Aono
 * @license
 */

export interface ScenarioIdConvertable {
  prepend(scenarioId: string): string;
  strip(scenarioId: string): string;
  has(scenarioId: string): boolean;
}

export const ANALYZED_SCENARIO_ID_PREFIX = 'analytics-';
const PREFIX_REGEX = new RegExp(`^${ANALYZED_SCENARIO_ID_PREFIX}`);

export class ScenarioIdConvertionService implements ScenarioIdConvertable {
  public has(scenarioId: string): boolean {
    return scenarioId.startsWith(ANALYZED_SCENARIO_ID_PREFIX);
  }

  public prepend(scenarioId: string): string {
    return `${ANALYZED_SCENARIO_ID_PREFIX}${scenarioId}`;
  }

  public strip(scenarioId: string): string {
    return scenarioId.replace(PREFIX_REGEX, '');
  }
}

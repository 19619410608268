/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { DND_TYPE } from '@c/domain/values/Category';

export interface BoxProps {
  padding?: string;
  margin?: string;
  position?: string;
  display?: string;
  isDragging?: boolean;
  maxHeight?: string;
  width?: string;
  height?: string;
}

export interface BoxDndProps {
  id: number;
  moveBox(fromId: number, toId: number): void;
  orderCategory(): void;
  children: React.ReactNode;
  width?: string;
  height?: string;
}

export const Box = styled.div<BoxProps>`
  display: ${props => props.display || 'block'};
  position: ${props => props.position || 'static'};
  margin: ${props => props.margin || 'none'};
  padding: ${props => props.padding || 'none'};
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  max-height: ${props => props.maxHeight || 'initial'};
`;

type ItemType = {
  type: typeof DND_TYPE;
  id: number;
};

export const BoxDnD = compareOnlyProperties(
  ({ id, moveBox, orderCategory, children }: BoxDndProps) => {
    const [{ isDragging }, drag] = useDrag({
      type: DND_TYPE,
      item: () => ({ id }),
      collect: m => ({ isDragging: m.isDragging() }),
    });
    const [, drop] = useDrop({
      accept: DND_TYPE,
      hover({ id: fromId }: ItemType) {
        if (fromId !== id) moveBox(fromId, id);
      },
      drop() {
        orderCategory();
      },
    });

    return (
      <Box ref={node => drag(drop(node))} isDragging={isDragging}>
        {children}
      </Box>
    );
  },
  'BoxDnD'
);

export const Margin = ({ children, margin }: { children: React.ReactChild; margin: string }) => {
  const child = React.Children.only(children);
  return React.cloneElement(child as any, {
    style: {
      margin,
    },
  });
};

export interface FlexProps {
  flex?: string;
  justifyContent?: string;
  shrink?: string;
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
    | 'inherit'
    | 'initial'
    | 'unset';
  flexWrap?: 'no-wrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'unset' | 'inherit';
}

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  flex: ${props => props.flex || 'none'};
  justify-content: ${props => props.justifyContent || 'initial'};
  align-items: ${props => props.alignItems || 'center'};
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: ${p => p.flexWrap ?? 'initial'};
  flex-shrink: ${p => p.shrink ?? 'inherit'};
`;

/**
 * @fileOverview
 * @author
 */

import produce from 'immer';
import { getInitialCategoryCommonState, CategoryCommonState } from '@c/state';
import {
  Namespaces,
  addCategorySucceeded,
  fetchCategorySucceeded,
  updateCategorySucceeded,
  categoryStateCleaned,
  deleteCategorySucceeded,
} from '@c/modules/categoryCommon/action';
import { dynamciNamespaceIc } from '../dynamicNamespaceIC';

type ActionType = ReturnType<
  | typeof addCategorySucceeded
  | typeof fetchCategorySucceeded
  | typeof updateCategorySucceeded
  | typeof categoryStateCleaned
  | typeof deleteCategorySucceeded
>;

const invokeNamespaceIc = dynamciNamespaceIc<CategoryCommonState>();

export default (namespace: Namespaces) => (state: any, action: ActionType) => {
  if (!(action as any)?.payload?.namespace || action.payload.namespace !== namespace) {
    return state;
  }

  return produce(state, (state: any) => {
    const commonState = invokeNamespaceIc({
      state,
      namespace: action.payload.namespace,
    });
    switch (action.type) {
      case 'ADD_CATEGORY_SUCCEEDED':
        commonState.categories.push(action.payload.category);
        return;
      case 'FETCH_CATEGORIES_SUCCEEDED':
        commonState.categories = action.payload.categories;
        commonState.selectedCategory = action.payload.categories[0];
        return;
      case 'UPDATE_CATEGORY_SUCCEEDED': {
        const found = commonState.categories.find(v => v.id === action.payload.category.id);
        if (!found) {
          return;
        }
        found.displayName = action.payload.category.displayName;
        return;
      }
      case 'DELETE_CATEGORY_SUCCEEDED':
        commonState.categories = commonState.categories.filter(
          category => category.id !== action.payload.categoryId
        );
        commonState.selectedCategory = commonState.categories[0];
        return;
      case 'CATEGORY_STATE_CLEANED':
        Object.assign(commonState, getInitialCategoryCommonState);
        return;
      default:
        return state;
    }
  });
};

import { UpdatableRepository } from '@s/Repository';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';

export type VoiceCustomersUpdatableRepository = UpdatableRepository<
  { projectId?: string; voiceCustomerId: string; conversationId?: string; note: string },
  void
>;
type P = Params<VoiceCustomersUpdatableRepository>;

export interface DownloadAudioFileRepository {
  download(url: string): Promise<void>;
}

export class VoiceCustomersRepository
  implements VoiceCustomersUpdatableRepository, DownloadAudioFileRepository
{
  public constructor() {}

  public async download(url: string): ReturnType<DownloadAudioFileRepository['download']> {
    await fetchService<Blob>(url, {
      method: 'GET',
      responseType: 'blob',
    }).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'audioFile.wav';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  public async update(data: P['update'][0]): Promise<void> {
    await fetchService<void>(apiEndpoint(`/customer/${data.voiceCustomerId}/voice/note`), {
      method: 'PUT',
      data: { note: data.note },
      responseType: 'text',
    });
  }

  public async updateConversationNote(data: P['update'][0]): Promise<void> {
    await fetchService<void>(
      apiEndpoint(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/conversation/project/${data.projectId}/customer/${data.voiceCustomerId}/voice/${data.conversationId}/note`
      ),
      {
        method: 'PUT',
        data: { note: data.note },
        responseType: 'text',
      }
    );
  }
}

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { CreatableRepository } from '@aim/shared/src/Repository';

export type LLMVersionCreatableRepository = CreatableRepository<{ projectId: string }, {}>;

export class LLMVersionRepository implements LLMVersionCreatableRepository {
  public async create({ projectId }: { projectId: string }): Promise<{}> {
    return fetchService<{}>(apiEndpoint(`llmchat/project/${projectId}/promote`), {
      method: 'POST',
      responseType: 'json',
      data: {},
    });
  }
}

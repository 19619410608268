/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { ActorType } from '@c/domain/entities/Assignee';
import { LineImagemapForRegistrationEntity } from '@c/domain/entities/LineImagemapForRegistrationEntity';
import { IgAutoMessageQuickRepliesType } from '@c/domain/entities/IgAutoMessage';
import { MessageMediaTypeOf, MessageMediaTypeKeys } from './MessageMediaType';

interface CommonMessageEntity {
  sender: {
    senderType: ActorType;
    name: string;
    id: string;
  };
  createdAt: number;
  id: string;
  meta: {
    delay?: number;
    referer?: string;
  };
  textValue: string;
}

export interface QuickRepliesAttachmentItem {
  title?: string;
  type?: string;
  payload?: string;
  label?: string;
  id?: string;
  targetId?: string;
  targetType?: IgAutoMessageQuickRepliesType;
}

export interface QuickRepliesAttachment {
  quickReplies?: QuickRepliesAttachmentItem[];
}

export interface PlainTextMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'plainText'>;
    payload: string;
    quickReplies?: QuickRepliesAttachment['quickReplies'];
    targetType?: IgAutoMessageQuickRepliesType;
  } & QuickRepliesAttachment;
}

export interface CarouselMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'carousel'>;
    payload: {
      buttons: {
        title: string;
      }[];
      title: string;
    };
  } & QuickRepliesAttachment;
}

export interface ImageMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'image'>;
    payload: {
      url: string;
      previewUrl: string;
    };
  } & QuickRepliesAttachment;
}

export interface VideoMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'video'>;
    payload: {
      url: string;
      previewUrl: string;
    };
  } & QuickRepliesAttachment;
}

export interface LineImageMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'lineImagemap'>;
    payload: {
      baseUrl: string;
      altText: string;
    };
  } & QuickRepliesAttachment;
}

export interface UnkownMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'unknown'>;
    payload: {
      info: string;
      text: string;
    };
  };
}

export enum SkeletonMessageType {
  SHORT,
  MIDDLE,
  LONG,
}

export interface SkeletonMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'skeleton'>;
    type: SkeletonMessageType;
  };
}

export interface UnknownMessageEntity extends CommonMessageEntity {
  attachment: {
    mediaType: MessageMediaTypeOf<'unknown'>;
    payload: {
      info: string;
      text: string;
    };
  };
}

function setTextValue(entity: MessageEntity, text = '', isUpdatePayload: boolean): MessageEntity {
  const { attachment } = entity;
  switch (attachment.mediaType) {
    case 'plainText':
      if (isUpdatePayload) {
        attachment.payload = text;
      }
      entity.textValue = attachment.payload;
      break;
    default:
      entity.textValue = '';
  }

  return entity;
}

export type MessageEntity =
  | PlainTextMessageEntity
  | CarouselMessageEntity
  | ImageMessageEntity
  | VideoMessageEntity
  | LineImageMessageEntity
  | UnknownMessageEntity
  | SkeletonMessageEntity;

export class MessageFactory {
  public static carousel(
    payload: {
      buttons: { title: string }[];
      title: string;
    },
    entity: PartialDeep<Omit<CarouselMessageEntity, 'attachment'>> = {}
  ): CarouselMessageEntity {
    return {
      attachment: {
        mediaType: 'carousel',
        payload,
      },
      createdAt: Date.now(),
      id: '',
      ...entity,
      sender: {
        senderType: 'bot',
        name: '',
        id: '',
        ...(entity.sender ?? {}),
      },
      meta: {
        referer: '',
        ...(entity.meta ?? {}),
      },
      textValue: '',
    };
  }
  public static createFromType<K extends MessageMediaTypeKeys>(
    type: K,
    payload: K extends MessageMediaTypeOf<'plainText'>
      ? Parameters<typeof MessageFactory['text']>
      : K extends MessageMediaTypeOf<'image'>
      ? Parameters<typeof MessageFactory['image']>
      : K extends MessageMediaTypeOf<'video'>
      ? Parameters<typeof MessageFactory['video']>
      : any = {} as any
  ): K extends MessageMediaTypeOf<'plainText'>
    ? PlainTextMessageEntity
    : K extends MessageMediaTypeOf<'image'>
    ? ImageMessageEntity
    : K extends MessageMediaTypeOf<'video'>
    ? ImageMessageEntity
    : PlainTextMessageEntity | ImageMessageEntity | VideoMessageEntity {
    switch (type) {
      case 'plainText':
        return MessageFactory.text.apply(null, payload) as any;
      case 'image':
        return MessageFactory.image.apply(null, payload) as any;
      case 'video':
        return MessageFactory.video.apply(null, payload) as any;
      default:
        throw new Error(`Unsupported MediaType found ${type}`);
    }
  }

  public static image(
    payload: { url: string; previewUrl: string } = { url: '', previewUrl: '' },
    entity: PartialDeep<Omit<ImageMessageEntity, 'attachment'>> = {}
  ): ImageMessageEntity {
    return {
      attachment: {
        mediaType: 'image',
        payload,
      },
      createdAt: Date.now(),
      id: '',
      ...entity,
      sender: {
        senderType: 'bot',
        name: '',
        id: '',
        ...(entity.sender ?? {}),
      },
      meta: {
        referer: '',
        ...(entity.meta ?? {}),
      },
      textValue: '',
    };
  }

  public static skeleton(
    id: string,
    type: SkeletonMessageType,
    senderType: ActorType
  ): SkeletonMessageEntity {
    return {
      createdAt: Date.now(),
      id: '',
      attachment: {
        mediaType: 'skeleton',
        type,
      },
      sender: {
        senderType,
        name: '',
        id: '',
      },
      meta: {
        referer: '',
      },
      textValue: '',
    };
  }

  public static text(
    text = '',
    entity: PartialDeep<Omit<PlainTextMessageEntity, 'attachment'>> = {}
  ): PlainTextMessageEntity {
    return {
      createdAt: Date.now(),
      id: '',
      attachment: {
        mediaType: 'plainText',
        payload: text,
      },
      sender: {
        senderType: 'bot',
        name: '',
        id: '',
        ...(entity.sender ?? {}),
      },
      meta: {
        referer: '',
        ...(entity.meta ?? {}),
      },
      textValue: text,
    };
  }

  public static video(
    payload: { url: string; previewUrl: string } = { url: '', previewUrl: '' },
    entity: PartialDeep<Omit<VideoMessageEntity, 'attachment'>> = {}
  ): VideoMessageEntity {
    return {
      createdAt: Date.now(),
      id: '',
      attachment: {
        mediaType: 'video',
        payload,
      },
      ...entity,
      sender: {
        senderType: 'bot',
        name: '',
        id: '',
        ...(entity.sender ?? {}),
      },
      meta: {
        referer: '',
        ...(entity.meta ?? {}),
      },
      textValue: '',
    };
  }
}

export function initMessage(message: MessageEntity): MessageEntity {
  message = setTextValue(message, '', false);
  if (!message.meta.referer) {
    message.meta = {
      referer: '',
    };
  }
  return message;
}

export function extractText(
  attachment: ReadonlyDeep<MessageEntity['attachment'] | LineImagemapForRegistrationEntity>
): string {
  switch (attachment.mediaType) {
    case 'plainText':
      return attachment.payload;
    case 'image':
      return attachment.payload.url;
    case 'video':
      return attachment.payload.url;
    case 'lineImagemap':
      return attachment.payload.baseUrl;
    case 'carousel':
      return '';
    case 'unknown':
      return attachment.payload.text;
    case 'lineImagemapForRegistration':
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${attachment.payload.layout}-${attachment.payload.areas}-${attachment.payload.baseUrl}`;
    default:
      throw new Error(`Unsupported media type ${attachment.mediaType}`);
  }
}

export function updateMessageEntityText(
  attachment: MessageEntity['attachment'],
  value: string
): MessageEntity['attachment'] {
  switch (attachment.mediaType) {
    case 'plainText':
      attachment.payload = value;
      return attachment;
    case 'image':
      attachment.payload.url = value;
      return attachment;
    case 'video':
      attachment.payload.url = value;
      return attachment;
    case 'lineImagemap':
      attachment.payload.baseUrl = value;
      return attachment;
    case 'carousel':
      return attachment;
    case 'unknown':
      attachment.payload.text = value;
      return attachment;
    default:
      throw new Error('Unsupported media type');
  }
}

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FONT_SIZE_MEDIUM } from '@s/components/atom/Text';

export const alertTextStyle = css`
  color: #ff9900;
  font-size: ${FONT_SIZE_MEDIUM}px;
`;

export const AlertText = styled.span`
  ${alertTextStyle};
`;

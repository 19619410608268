import { apiEndpoint } from '@c/config';
import {
  DataExportScheduleResponse,
  DataExportScheduleType,
} from '@c/domain/entities/DataExportSchedule';
import { GettableQuery } from '@c/query/interface';
import { Mutate, SWRCache } from '@s/Repository';
import { FetchServiceRequestOption, METHOD, fetchService } from '@s/io/fetchService';

export type DataExportScheduleGettableQuery = GettableQuery<
  { projectId: string },
  DataExportScheduleResponse<DataExportScheduleType>
>;

const mutator = <T>(
  method: METHOD,
  url: string,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate
): Promise<T> => mutate<T>(url, fetchService<T>(url, { ...req, method }));

// NOTE:
// get 関数は昔にSWRを導入したときに追加したものだと思うんですが、
// 特殊なSWRの使い方(SWRのキャッシュのみを活用する) なので今後このコードは消していきたい。
// consoel の SWR 移行のときに消していく
const get = async <T>(
  ins: GettableQuery<any, any>,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate,
  swrCache: SWRCache
): Promise<T> => {
  ins.swrCache = swrCache;
  const cachedData = swrCache.cache.get(swrCache.key);
  if (cachedData) return cachedData.data;

  return await mutator<T>('GET', swrCache.key, req, mutate);
};

export class DataExportScheduleQuery implements DataExportScheduleGettableQuery {
  public swrCache?: SWRCache = undefined;

  public async get(
    { projectId }: Parameters<DataExportScheduleGettableQuery['get']>[0],
    cache: Parameters<DataExportScheduleGettableQuery['get']>[1],
    mutate: Parameters<DataExportScheduleGettableQuery['get']>[2]
  ): ReturnType<DataExportScheduleGettableQuery['get']> {
    const res = await get<DataExportScheduleResponse<DataExportScheduleType>>(
      this,
      { responseType: 'json' },
      mutate!,
      new SWRCache(apiEndpoint(`/data-export/schedule/list?project=${projectId}&limit=200`), cache!)
    );
    return res;
  }
}

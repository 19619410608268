import { GettableQuery } from '@s/Query';
import { TwilioProjectRelation } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type TwilioProjectRelationGettableQuery = GettableQuery<
  { projectId: string },
  TwilioProjectRelation[]
>;

export class TwilioProjectRelationQuery implements TwilioProjectRelationGettableQuery {
  public async get({
    projectId,
  }: Parameters<TwilioProjectRelationGettableQuery['get']>[0]): ReturnType<
    TwilioProjectRelationGettableQuery['get']
  > {
    const ret = await fetchService<TwilioProjectRelation[]>(
      apiEndpoint(`/dialog-engine/project/${projectId}/twilio_project_relation`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );
    return ret;
  }
}

/**
 * @fileOverview
 * @name Submitable.tsx
 * @author Taketoshi Aono
 * @license
 */

import React, { forwardRef } from 'react';
import { SerializedStyles } from '@emotion/react';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { useRefState } from '@s/reactHooks';

export const Submittable = compareOnlyProperties(
  forwardRef(
    (
      {
        children,
        onSubmit,
        cssProps,
      }: {
        cssProps?: SerializedStyles;
        onSubmit(): void;
        children: React.ReactChild[] | React.ReactChild;
      },
      ref: React.Ref<HTMLDivElement>
    ) => {
      const [isCompositioning, setCompositioning] = useRefState(false);
      return (
        <div
          ref={ref}
          onKeyDown={e => e.key === 'Enter' && !isCompositioning.current && onSubmit()}
          onCompositionStart={() => setCompositioning(true)}
          onCompositionEnd={() => setCompositioning(false)}
          css={cssProps}
        >
          {children}
        </div>
      );
    }
  )
);

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { forwardRef } from 'react';
import { Icon, iconType } from '@c/components/atom/Icon';
import { COLOR_SCHEME, chooseTextColorFromScheme } from '@c/config';
import styled from '@emotion/styled';
import { SerializedStyles, css } from '@emotion/react';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { Button } from '@s/components/atom/Button';

const createCustomizedButton = (
  {
    label,
    icon,
    colorType,
    additionalCss,
    behaviorType,
  }: {
    label: string;
    icon: keyof typeof iconType;
    colorType?: { color: string; background: string };
    additionalCss?: SerializedStyles;
    behaviorType?: 'submit' | 'button' | 'reset';
  },
  displayName: string
) =>
  compareOnlyProperties(
    forwardRef(
      (
        {
          label: l = label,
          className = '',
          loading = false,
          disabled = false,
          type,
          iconSvg,
          onClick,
        }: {
          type?: { color: string; background: string };
          label?: string;
          className?: string;
          iconSvg?: React.ReactElement;
          loading?: boolean;
          disabled?: boolean;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
          onClick(e: React.MouseEvent<HTMLButtonElement>): void;
        },
        ref: React.Ref<HTMLButtonElement>
      ) => {
        return (
          <Button
            ref={ref}
            label={l}
            type={type ?? colorType ?? 'normal'}
            behaviorType={behaviorType}
            iconSvg={iconSvg ?? <Icon name={icon} size={15} />}
            loading={loading}
            className={className}
            disabled={disabled}
            additionalCss={additionalCss}
            onClick={onClick}
          />
        );
      }
    ),
    displayName
  );

const chooseBgColorOption = { lightness: 80, saturation: 80 };
export const ChoseButton = createCustomizedButton(
  {
    label: '選択',
    icon: 'check',
    colorType: {
      color: chooseTextColorFromScheme(chooseBgColorOption),
      background: COLOR_SCHEME(chooseBgColorOption),
    },
  },
  'ChoseButton'
);

const completeBgColorOption = { lightness: 80, saturation: 90 };
export const CompleteButton = createCustomizedButton(
  {
    label: '完了',
    icon: 'check',
    colorType: {
      color: chooseTextColorFromScheme(completeBgColorOption),
      background: COLOR_SCHEME(completeBgColorOption),
    },
  },
  'CompleteButton'
);

const editBgColorOption = { lightness: 80, saturation: 90 };
export const EditButton = createCustomizedButton(
  {
    label: '編集',
    icon: 'edit',
    colorType: {
      color: chooseTextColorFromScheme(editBgColorOption),
      background: COLOR_SCHEME(editBgColorOption),
    },
  },
  'EditButton'
);

const addBgColorOption = { lightness: 80, saturation: 90 };
export const AddButton = createCustomizedButton(
  {
    label: '追加',
    icon: 'plus',
    colorType: {
      color: chooseTextColorFromScheme(addBgColorOption),
      background: COLOR_SCHEME(addBgColorOption),
    },
  },
  'AddButton'
);

const saveBgColorOption = { lightness: 80, saturation: 90, hue: 200 };
export const SaveButton = createCustomizedButton(
  {
    label: '保存',
    icon: 'check',
    colorType: {
      color: chooseTextColorFromScheme(saveBgColorOption),
      background: COLOR_SCHEME(saveBgColorOption),
    },
  },
  'SaveButton'
);

export const SubmitSaveButton = createCustomizedButton(
  {
    label: '保存',
    icon: 'check',
    colorType: {
      color: chooseTextColorFromScheme(saveBgColorOption),
      background: COLOR_SCHEME(saveBgColorOption),
    },
    behaviorType: 'submit',
  },
  'SaveButton'
);

const searchBgColorOption = { lightness: 80, saturation: 100 };
export const SearchButton = createCustomizedButton(
  {
    label: '検索',
    icon: 'search',
    colorType: {
      color: chooseTextColorFromScheme(searchBgColorOption),
      background: COLOR_SCHEME(searchBgColorOption),
    },
  },
  'SearchButton'
);

const deleteBgColorOption = { lightness: 80, saturation: 100 };
export const DeleteButton = createCustomizedButton(
  {
    label: '削除',
    icon: 'crossbar',
    colorType: {
      color: chooseTextColorFromScheme(deleteBgColorOption),
      background: COLOR_SCHEME(deleteBgColorOption),
    },
  },
  'DeleteButton'
);

const refreshBgColorOption = { lightness: 80, saturation: 100 };
export const RefreshButton = createCustomizedButton(
  {
    label: '更新',
    icon: 'refresh',
    colorType: {
      color: chooseTextColorFromScheme(refreshBgColorOption),
      background: COLOR_SCHEME(refreshBgColorOption),
    },
  },
  'RefreshButton'
);

export const CircleButton = createCustomizedButton(
  {
    label: '削除',
    icon: 'crossbar',
    colorType: {
      color: '#222',
      background: 'none',
    },
    additionalCss: css`
      border-radius: 400px;
      border: 1px solid #ccc;
    `,
  },
  'DeleteButton'
);

export const CloseButton = createCustomizedButton(
  {
    label: '閉じる',
    icon: 'crossbar',
    colorType: {
      color: '#222',
      background: '#EFEFEF',
    },
  },
  'CloseButton'
);

export const OpenDialogButtonWithMailIcon = createCustomizedButton(
  {
    label: '開く',
    icon: 'mail',
    colorType: {
      color: '#222',
      background: '#EFEFEF',
    },
  },
  'OpenDialogButton'
);

export const SelectButton = ({
  iconSvg,
  label,
  onClick,
  selected,
  colorScehem = { lightness: 80, saturation: 100 },
}: {
  iconSvg?: any;
  label: string;
  selected?: boolean;
  colorScehem?: { lightness: number; saturation: number };
  onClick(): void;
}) => {
  return (
    <CircleButton
      label={label}
      iconSvg={iconSvg}
      onClick={onClick}
      type={
        selected
          ? {
              color: chooseTextColorFromScheme(colorScehem),
              background: COLOR_SCHEME(colorScehem),
            }
          : undefined
      }
    />
  );
};

const ButtonToolbarRootElement = styled.div<{ renderShadow: boolean }>`
  display: flex;
  border-radius: 8px;
  ${p => (p.renderShadow ? 'box-shadow: 0px 1px 4px rgba(0,0,0,0.2)' : '')};
  > button:first-of-type {
    border-radius: 8px 0 0 8px;
  }
  > button {
    border-radius: 0;
  }
  > button:last-child {
    border-radius: 0 8px 8px 0;
  }
`;
export const ButtonToolbar = compareOnlyProperties(
  ({
    children,
    isRenderShadow = false,
  }: {
    children: React.ReactNode;
    isRenderShadow?: boolean;
  }) => {
    return (
      <ButtonToolbarRootElement renderShadow={isRenderShadow}>{children}</ButtonToolbarRootElement>
    );
  }
);

export const YesAndNoButtons = compareOnlyProperties(
  ({
    loading,
    disabled = false,
    onYes,
    onNo,
    yesRef,
    noRef,
    yesLabel = 'OK',
    noLabel = 'キャンセル',
    externalDeps = [],
    className = '',
  }: {
    loading?: boolean;
    disabled?: boolean;
    onYes(): void;
    onNo(): void;
    yesRef?: React.Ref<HTMLElement | null>;
    noRef?: React.Ref<HTMLElement | null>;
    yesLabel?: string;
    noLabel?: string;
    externalDeps?: any;
    className?: string;
  }) => {
    return (
      <div css={{ display: 'flex' }} className={className}>
        <div css={{ marginRight: 10 }}>
          <SaveButton
            externalDeps={externalDeps}
            disabled={disabled}
            loading={loading}
            label={yesLabel}
            ref={yesRef as any}
            onClick={() => onYes()}
          />
        </div>
        <Button
          externalDeps={externalDeps}
          loading={loading}
          label={noLabel}
          type={'normal'}
          iconSvg={<Icon name="crossbar" size={15} />}
          ref={noRef as any}
          onClick={() => onNo()}
        />
      </div>
    );
  },
  'YesAndNoButtons'
);

export const EditOrDeleteButtons = compareOnlyProperties(
  ({
    onEdit,
    onDelete,
    editRef,
    deleteRef,
  }: {
    onEdit(): void;
    onDelete(): void;
    editRef?: React.Ref<HTMLElement | null>;
    deleteRef?: React.Ref<HTMLElement | null>;
  }) => {
    return (
      <div css={{ display: 'flex' }}>
        <div css={{ marginRight: 10 }}>
          <Button
            label="編集"
            type={'normal'}
            iconSvg={<Icon name="edit" size={15} />}
            ref={editRef as any}
            onClick={() => onEdit()}
          />
        </div>
        <Button
          label="削除"
          type={'delete'}
          iconSvg={<Icon name="crossbar" size={15} />}
          ref={deleteRef as any}
          onClick={() => onDelete()}
        />
      </div>
    );
  },
  'EditOrDeleteButtons'
);

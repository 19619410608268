/**
 * @fileOverview
 * @author
 */

import React from 'react';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import styled from '@emotion/styled';
import { xxlargeTextStyle, smallTextStyle } from '@s/components/atom/Text';
import { AlertText } from '@c/components/atom/Texts';
import { Select } from '@c/components/atom/Select';
import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';
import { Tooltip } from '@c/components/atom/Tooltip';
import { EditButton } from '@c/components/atom/Button';
import { IconOnlyButton } from '@c/components/atom/IconOnlyButton';
import { AutoSizer } from 'react-virtualized';
import { AnimatePresence, motion } from 'framer-motion';
import { COLOR_SCHEME, COLOR_SHCEME_HUE } from '@c/config';
import { Loading } from '@aim/shared/src/components/atom/Loading';

export const formatDate = (date: Date): string => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`;
};

const IndicatorRootElement = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
  padding-bottom: 35px;
`;
const HourIndicatorElement = styled.div`
  border-radius: 8px;
  position: relative;
  width: 4px;
  height: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  background: #cccccc;
`;
const MinutesIndicatorElement = styled.div`
  position: relative;
  width: 1px;
  height: 10px;
  background: #cccccc;
`;
const HourIndicatorValueElement = styled.div`
  position: absolute;
  top: 20px;
  left: -0.5rem;
  ${smallTextStyle};
  color: #333;
`;
const ONE_MINUTES = 60;
const ONE_SECONDS = 60;
const HOUR_LENGTH = 25;
const HOUR_LIST = Array(HOUR_LENGTH)
  .fill(1)
  .map((v, i) => String(i));
const HourIndicatorComponent = () => (
  <IndicatorRootElement>
    {HOUR_LIST.map(hour => (
      <React.Fragment key={hour}>
        <HourIndicatorElement key={`${hour}-hours`}>
          <HourIndicatorValueElement>{hour.padStart(2, '0')}</HourIndicatorValueElement>
        </HourIndicatorElement>
        {hour !== '24' ? <MinutesIndicatorElement key={`${hour}-minutes`} /> : null}
      </React.Fragment>
    ))}
  </IndicatorRootElement>
);

const HourSelectionRootElement = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
  padding: 0px 25px;
`;
const HourSelectionBarElement = styled(motion.div)`
  position: absolute;
  top: 15px;
  height: 10px;
  background: #00b900;
  border-radius: 999px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.8);
  &:hover {
    background: #00ca00;
  }
`;

interface HourSelectionComponentProps {
  entity: BusinessHourEntity[];
  indicatorPositions: IndicatorPositions;
  pitch: number;
  colorFactory: ColorFactory;
}

class ColorFactory {
  private assigned: Record<string, any> = {};
  private readonly colors = [
    COLOR_SCHEME({ lightness: 60 }),
    COLOR_SCHEME({ lightness: 60, hue: COLOR_SHCEME_HUE - 20 }),
    COLOR_SCHEME({ lightness: 60, hue: COLOR_SHCEME_HUE - 40 }),
    COLOR_SCHEME({ lightness: 60, hue: COLOR_SHCEME_HUE - 60 }),
    COLOR_SCHEME({ lightness: 60, hue: COLOR_SHCEME_HUE - 80 }),
  ];

  private cursor = 0;

  public getColor(id: string) {
    if (this.assigned[id]) {
      return this.assigned[id];
    }
    return (this.assigned[id] =
      this.colors[
        (() => {
          const index = this.cursor++;
          if (this.cursor === this.colors.length) {
            this.cursor = 0;
          }
          return index;
        })()
      ]);
  }
}

const HourSelectionComponent = ({
  entity,
  indicatorPositions,
  pitch,
  colorFactory,
}: HourSelectionComponentProps) => {
  return (
    <HourSelectionRootElement>
      <AnimatePresence>
        {entity.map(({ startAt, endAt }, i) => {
          const displayDate = `${startAt.hours}:${startAt.minutes} - ${endAt.hours}:${endAt.minutes}`;
          const endHours = parseInt(endAt.hours, 10);
          const endMinutes = parseInt(endAt.minutes, 10);
          const endDate = endHours * ONE_MINUTES + endMinutes;
          const startHours = parseInt(startAt.hours, 10);
          const startMinutes = parseInt(startAt.minutes, 10);
          const startDate = startHours * ONE_MINUTES + startMinutes;
          const baseDate = endDate > startDate ? endDate : startDate;
          const targetDate = endDate > startDate ? startDate : endDate;
          const baseHours = String(endHours > startHours ? startHours : endHours).padStart(2, '0');
          const baseMinutes = endHours > startHours ? startMinutes : endMinutes;
          const id = String(i);
          return (
            <Tooltip key={id} label={displayDate} delay={100} margin="20px 0 0 0">
              <HourSelectionBarElement
                style={{
                  left: `${(indicatorPositions[baseHours] || 0) + baseMinutes * pitch}px`,
                  width: `${(baseDate - targetDate) * pitch}px`,
                  background: colorFactory.getColor(id),
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.2 }}
              />
            </Tooltip>
          );
        })}
      </AnimatePresence>
    </HourSelectionRootElement>
  );
};

const HOUR_OPTIONS = HOUR_LIST.slice(0, -1).map(v => {
  const m = v.padStart(2, '0');
  return { label: m, value: m };
});
const MINUTES_OPTIONS = Array(ONE_MINUTES)
  .fill(1)
  .map((x, i) => {
    const m = String(i);
    const v = m.padStart(2, '0');
    return { label: v, value: v };
  });
const SECONDS_OPTIONS = Array(ONE_SECONDS)
  .fill(1)
  .map((x, i) => {
    const m = String(i);
    const v = m.padStart(2, '0');
    return { label: v, value: v };
  });
const ScheduleConfigsEachItemRootElement = styled.div`
  display: flex;
  align-items: center;
`;
const ScheduleConfigsEachItemSpacerElement = styled.div`
  ${xxlargeTextStyle};
  padding-left: 10px;
  text-align: center;
`;

interface ScheduleConfigsEachItemComponentProps {
  hours: string;
  minutes: string;
  disabled?: boolean;

  onChangeHours(hours: string): void;
  onChangeMinutes(minutes: string): void;
}

export const ScheduleConfigsEachItemComponent = ({
  hours,
  minutes,
  disabled,
  onChangeHours,
  onChangeMinutes,
}: ScheduleConfigsEachItemComponentProps) => (
  <ScheduleConfigsEachItemRootElement>
    <Select
      selectOptions={HOUR_OPTIONS}
      label=""
      selected={hours}
      disabled={disabled}
      onChange={({ value }) => onChangeHours(value)}
      fixedWidth="100px"
    />
    <ScheduleConfigsEachItemSpacerElement>:</ScheduleConfigsEachItemSpacerElement>
    <Select
      selectOptions={MINUTES_OPTIONS}
      label=""
      selected={minutes}
      disabled={disabled}
      onChange={({ value }) => onChangeMinutes(value)}
      fixedWidth="100px"
    />
  </ScheduleConfigsEachItemRootElement>
);

const ScheduleConfigsItemRootElement = styled(motion.div)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const ScheduleConfigsItemFlexElement = styled.div`
  display: flex;
  align-items: center;
`;
const ScheduleConfigsItemFromLabelElement = styled.div`
  padding-left: 10px;
  text-align: center;
  width: 40px;
`;

const ErrorTextElement = styled.div`
  padding: 0 0 10px;
`;

const ErrorText = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.map((e, i) => (
        <ErrorTextElement key={i}>
          <AlertText>{e}</AlertText>
        </ErrorTextElement>
      ))}
    </>
  );
};

interface ScheduleConfigsItemComponentProps {
  color: string;
  errors: string[];
  range: BusinessHourEntity;

  onDelete(): void;

  onUpdateStartAtHours(hours: string): void;

  onUpdateStartAtMinutes(minutes: string): void;

  onUpdateStartAtSeconds?(seconds: string): void;

  onUpdateEndAtHours(hours: string): void;

  onUpdateEndAtMinutes(minutes: string): void;

  onUpdateEndSeconds?(seconds: string): void;
}

const ColorBarElement = styled.div<{ color: string }>`
  width: 10px;
  height: 5px;
  background: ${p => p.color};
  border-radius: 10px;
`;

const ScheduleConfigsItemComponent = ({
  color,
  errors,
  range,
  onDelete,
  onUpdateStartAtHours,
  onUpdateStartAtMinutes,
  onUpdateEndAtHours,
  onUpdateEndAtMinutes,
}: ScheduleConfigsItemComponentProps) => (
  <ScheduleConfigsItemRootElement
    initial={{ translateX: -20, opacity: 0 }}
    animate={{ translateX: 0, opacity: 1 }}
    exit={{ translateX: -20, opacity: 0 }}
    transition={{ type: 'tween', duration: 0.3 }}
  >
    <ErrorText errors={errors} />
    <ScheduleConfigsItemFlexElement>
      <IconOnlyButton onClick={onDelete} name="trashcan" size={15} css={{ marginRight: '10px' }} />
      <ColorBarElement color={color} />
      <ScheduleConfigsEachItemComponent
        {...range.startAt}
        onChangeHours={onUpdateStartAtHours}
        onChangeMinutes={onUpdateStartAtMinutes}
      />
      <ScheduleConfigsItemFromLabelElement>から</ScheduleConfigsItemFromLabelElement>
      <ScheduleConfigsEachItemComponent
        {...range.endAt}
        onChangeHours={onUpdateEndAtHours}
        onChangeMinutes={onUpdateEndAtMinutes}
      />
    </ScheduleConfigsItemFlexElement>
  </ScheduleConfigsItemRootElement>
);

const ScheduleConfigsRootElement = styled.div`
  padding: 15px 30px;
  position: relative;
`;
type ScheduleConfigsComponentProps = {
  entity: BusinessHourEntity[];
  loading: boolean;
  colorFactory: ColorFactory;
} & OnedaySchedulerComponentHandlers;

const ScheduleConfigsComponent = ({
  colorFactory,
  entity,
  loading,
  onAddBusinessHour,
  onDeleteBusinessHour,
  onChangeStartAtHours,
  onChangeStartAtMinutes,
  onChangeEndAtHours,
  onChangeEndAtMinutes,
}: ScheduleConfigsComponentProps) => (
  <ScheduleConfigsRootElement>
    {loading ? <Loading /> : null}
    <AnimatePresence>
      {entity.map((args, index) => {
        return (
          <ScheduleConfigsItemComponent
            color={colorFactory.getColor(`${index}`)}
            key={index}
            errors={args.errors}
            range={args}
            onDelete={() => onDeleteBusinessHour({ index })}
            onUpdateStartAtHours={hours => onChangeStartAtHours({ hours, index })}
            onUpdateStartAtMinutes={minutes =>
              onChangeStartAtMinutes({
                minutes,
                index,
              })
            }
            onUpdateEndAtHours={hours => onChangeEndAtHours({ hours, index })}
            onUpdateEndAtMinutes={minutes =>
              onChangeEndAtMinutes({
                minutes,
                index,
              })
            }
          />
        );
      })}
    </AnimatePresence>
    <EditButton label="追加" onClick={onAddBusinessHour} loading={loading} />
  </ScheduleConfigsRootElement>
);

const OneDaySchedulerHourIndicatorElement = styled.div`
  background: rgb(245, 247, 249);
  border-radius: 8px;
`;

interface IndicatorPositions {
  [hour: string]: number;
}

export interface OnedaySchedulerComponentHandlers {
  onAddBusinessHour(): void;

  onDeleteBusinessHour(args: { index: number }): void;

  onChangeStartAtHours(args: { hours: string; index: number }): void;

  onChangeStartAtMinutes(args: { minutes: string; index: number }): void;

  onChangeStartAtSeconds?(args: { seconds: string; index: number }): void;

  onChangeEndAtHours(args: { hours: string; index: number }): void;

  onChangeEndAtMinutes(args: { minutes: string; index: number }): void;

  onChangeEndAtSeconds?(args: { seconds: string; index: number }): void;
}

export interface OnedaySchedulerComponentProperties {
  date: string;
  entity: BusinessHourEntity[];
  loading: boolean;
}

export type OnedaySchedulerComponentProps = OnedaySchedulerComponentHandlers &
  OnedaySchedulerComponentProperties;

const getPositions = (width: number) => {
  const p: IndicatorPositions = {};
  Array(HOUR_LENGTH)
    .fill(1)
    .forEach((x, hour) => {
      p[String(hour).padStart(2, '0')] = ((width - 50) / (HOUR_LENGTH - 1)) * hour + 25;
    });
  return p;
};

const getPitch = (width: number) => {
  const positions = getPositions(width);
  return (positions['01'] - positions['00']) / ONE_MINUTES;
};

export const OnedayScheduler = compareOnlyProperties((props: OnedaySchedulerComponentProps) => {
  const colorFactory = new ColorFactory();
  return (
    <>
      <OneDaySchedulerHourIndicatorElement>
        <AutoSizer style={{ height: '30px', width: '100%' }}>
          {({ width }) => (
            <HourSelectionComponent
              colorFactory={colorFactory}
              entity={props.entity}
              indicatorPositions={getPositions(width)}
              pitch={getPitch(width)}
            />
          )}
        </AutoSizer>
        <HourIndicatorComponent />
      </OneDaySchedulerHourIndicatorElement>
      <ScheduleConfigsComponent {...props} colorFactory={colorFactory} />
    </>
  );
}, 'OnedayScheduler');

import React, { ForwardedRef, useState } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { FONT_SIZE_XSMALL, regularTextStyle } from '@s/components/atom/Text';

const fadeUpAnime = keyframes`
  from{
    opacity: 0;
	  transform: translateY(5px);
  }
  to{
    opacity: 1;
	  transform: translateY(0);
  }
`;

const fadeDownAnime = keyframes`
from{
  opacity: 1;
  transform: translateY(0);
}
to{
  opacity: 0;
  transform: translateY(5px);
}
`;

const InputContainerElement = styled.div`
  position: relative;
  display: inline-block;
`;

const SinglelineInputElement = styled.input<{
  border: boolean;
  readOnly: boolean;
  disabled: boolean;
}>`
  border: 0;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  ${regularTextStyle};
  transition: all 0.2s;
  ${p => (p.border ? 'border: 1px solid #DDD' : '')};
  cursor: ${p => (p.readOnly || p.disabled ? 'not-allowed' : 'auto')};
  &:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6);
  }
  &::placeholder {
    user-select: none;
  }
`;

const PlaceholderElement = styled.div`
  position: absolute;
  ${regularTextStyle};
  padding: 0px 10px;
  border-radius: 4px;
  z-index: 1;
  top: -10px;
  left: 5px;
  border: 1px solid #ddd;
  background: #fff;
  font-size: ${FONT_SIZE_XSMALL};
  color: #222;
  animation-name: ${fadeUpAnime};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
`;

const fadeOutAnimation = css`
  animation-name: ${fadeDownAnime};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
`;

interface Props {
  className?: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: { value: string; type: 'normal' | 'dynamic' };
  width?: number | string;
  backgroundColor?: string;
  isPassword?: boolean;
  border?: boolean;
  validation?: RegExp | ((value: string) => boolean);
  defaultValue?: string;
  ref?: React.Ref<HTMLInputElement>;
  value?: string;
  name?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const DEFAULT_ONCHANGE = (e: React.ChangeEvent<HTMLInputElement>) => {};
const DEFAULT_ONBLUR = (e: React.ChangeEvent<HTMLInputElement>) => {};

/**
 *
Usage:
  ```ts
  <TextInput
    placeholder={{ value: '検索', type: 'dynamic' }}
    border
    readonly
    disabled
    isPassword
    width={'300px'}
    className=""
    validation={pattern}
    onChange={e => setSomeState(e.target.value)}
    onBlur={e => setSomeState(e.target.value)},
  />
  ```
 */
export const TextInput = React.forwardRef(
  (
    {
      width = 200,
      backgroundColor,
      placeholder = { value: 'テキストを入力', type: 'normal' },
      border = false,
      readonly = false,
      disabled = false,
      isPassword = false,
      className = '',
      validation = /.*/,
      defaultValue = '',
      name = '',
      min,
      max,
      maxLength,
      minLength,
      pattern,
      required,
      value,
      onChange = DEFAULT_ONCHANGE,
      onBlur = DEFAULT_ONBLUR,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [_value, setValue] = useState('');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        (typeof validation === 'function' && !validation(e.target.value)) ||
        (validation instanceof RegExp && !validation.test(e.target.value))
      ) {
        return;
      }
      onChange(e);
      setValue(e.target.value);
    };
    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onBlur(e);
    };
    return (
      <InputContainerElement>
        {placeholder.type === 'dynamic' ? (
          <PlaceholderElement
            aria-hidden={true}
            css={_value.length === 0 && !defaultValue.length ? fadeOutAnimation : ''}
          >
            {placeholder.value}
          </PlaceholderElement>
        ) : (
          <></>
        )}
        <SinglelineInputElement
          className={className}
          min={min}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          required={required}
          pattern={pattern}
          name={name}
          aria-hidden={true}
          border={border}
          type={isPassword ? 'password' : 'text'}
          tabIndex={0}
          readOnly={readonly}
          disabled={disabled}
          defaultValue={defaultValue}
          css={{ width, backgroundColor }}
          placeholder={placeholder.value}
          ref={ref}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={e => e.stopPropagation()}
        />
      </InputContainerElement>
    );
  }
);

import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { EditableRepository } from '@s/Repository';

export type CarouselEditableRepository = EditableRepository<
  { projectId: string; carousel: Carousel },
  void,
  { projectId: string; carousel: Carousel },
  void,
  { projectId: string; carouselId: string },
  void
>;

export class CarouselRepository implements CarouselEditableRepository {
  public async create({
    projectId,
    carousel,
  }: Parameters<CarouselEditableRepository['create']>[0]): ReturnType<
    CarouselEditableRepository['create']
  > {
    await fetchService<Carousel[]>(apiEndpoint(`project/${projectId}/carousel`), {
      method: 'POST',
      responseType: 'json',
      data: { ...carousel },
    });
  }
  public async delete({
    projectId,
    carouselId,
  }: Parameters<CarouselEditableRepository['delete']>[0]): ReturnType<
    CarouselEditableRepository['delete']
  > {
    await fetchService<Carousel[]>(apiEndpoint(`project/${projectId}/carousel/${carouselId}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }
  public async update({
    projectId,
    carousel,
  }: Parameters<CarouselEditableRepository['update']>[0]): ReturnType<
    CarouselEditableRepository['update']
  > {
    await fetchService<Carousel[]>(
      apiEndpoint(`project/${projectId}/carousel/${carousel.id || ''}`),
      {
        method: 'PUT',
        responseType: 'json',
        data: { ...carousel },
      }
    );
  }
}

/**
 * @fileOverview
 * @name mdstyle.ts
 * @author Taketoshi Aono
 * @license
 */

import { css } from '@emotion/react';

export const mdStyle = (scope: string) => css`
  body ${scope} .anchor {
    float: left;
    line-height: 1;
    margin-left: -20px;
    padding-right: 4px;
  }

  body ${scope} .anchor:focus {
    outline: none;
  }

  body ${scope} h1:hover .anchor,
  body ${scope} h2:hover .anchor,
  body ${scope} h3:hover .anchor,
  body ${scope} h4:hover .anchor,
  body ${scope} h5:hover .anchor,
  body ${scope} h6:hover .anchor {
    text-decoration: none;
  }

  body ${scope} details {
    display: block;
  }

  body ${scope} summary {
    display: list-item;
  }

  body ${scope} a {
    background-color: initial;
    transition: all 0.2s;
  }

  body ${scope} a:active,
  body ${scope} a:hover {
    outline-width: 0;
  }

  body ${scope} strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  body ${scope} h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  body ${scope} img {
    border-style: none;
  }

  body ${scope} code,
  body ${scope} kbd,
  body ${scope} pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  body ${scope} hr {
    box-sizing: initial;
    height: 0;
    overflow: visible;
  }

  body ${scope} input {
    font: inherit;
    margin: 0;
  }

  body ${scope} input {
    overflow: visible;
  }

  body ${scope} [type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  body ${scope} * {
    box-sizing: border-box;
  }

  body ${scope} input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  body ${scope} a {
    cursor: pointer;
    text-decoration: underline !important;
  }

  body ${scope} a:hover {
    opacity: 0.8;
  }

  body ${scope} strong {
    font-weight: 600;
  }

  body ${scope} hr {
    height: 0;
    margin: 15px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
  }

  body ${scope} hr:after,
  body ${scope} hr:before {
    display: table;
    content: '';
  }

  body ${scope} hr:after {
    clear: both;
  }

  body ${scope} table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  body ${scope} td,
  body ${scope} th {
    padding: 0;
  }

  body ${scope} details summary {
    cursor: pointer;
  }

  body ${scope} kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #d1d5da;
  }

  body ${scope} h1,
  body ${scope} h2,
  body ${scope} h3,
  body ${scope} h4,
  body ${scope} h5,
  body ${scope} h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  body ${scope} h1 {
    font-size: 32px;
  }

  body ${scope} h1,
  body ${scope} h2 {
    font-weight: 600;
  }

  body ${scope} h2 {
    font-size: 24px;
  }

  body ${scope} h3 {
    font-size: 20px;
  }

  body ${scope} h3,
  body ${scope} h4 {
    font-weight: 600;
  }

  body ${scope} h4 {
    font-size: 16px;
  }

  body ${scope} h5 {
    font-size: 14px;
  }

  body ${scope} h5,
  body ${scope} h6 {
    font-weight: 600;
  }

  body ${scope} h6 {
    font-size: 12px;
  }

  body ${scope} p {
    margin-top: 0;
    margin-bottom: 10px;
    white-space: pre-wrap;
  }

  body ${scope} blockquote {
    margin: 0;
  }

  body ${scope} ol,
  body ${scope} ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  body ${scope} ol ol,
  body ${scope} ul ol {
    list-style-type: lower-roman;
  }

  body ${scope} ol ol ol,
  body ${scope} ol ul ol,
  body ${scope} ul ol ol,
  body ${scope} ul ul ol {
    list-style-type: lower-alpha;
  }

  body ${scope} dd {
    margin-left: 0;
  }

  body ${scope} code,
  body ${scope} pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: 12px;
  }

  body ${scope} pre {
    margin-top: 0;
    margin-bottom: 0;
  }

  body ${scope} input::-webkit-inner-spin-button,
  body ${scope} input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
  }

  body ${scope} :checked + .radio-label {
    position: relative;
    z-index: 1;
    border-color: #0366d6;
  }

  body ${scope} .border {
    border: 1px solid #e1e4e8 !important;
  }

  body ${scope} .border-0 {
    border: 0 !important;
  }

  body ${scope} .border-bottom {
    border-bottom: 1px solid #e1e4e8 !important;
  }

  body ${scope} .rounded-1 {
    border-radius: 3px !important;
  }

  body ${scope} .bg-white {
    background-color: #fff !important;
  }

  body ${scope} .bg-gray-light {
    background-color: #fafbfc !important;
  }

  body ${scope} .text-gray-light {
    color: #6a737d !important;
  }

  body ${scope} .mb-0 {
    margin-bottom: 0 !important;
  }

  body ${scope} .my-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  body ${scope} .pl-0 {
    padding-left: 0 !important;
  }

  body ${scope} .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  body ${scope} .pl-1 {
    padding-left: 4px !important;
  }

  body ${scope} .pl-2 {
    padding-left: 8px !important;
  }

  body ${scope} .py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  body ${scope} .pl-3,
  body ${scope} .px-3 {
    padding-left: 16px !important;
  }

  body ${scope} .px-3 {
    padding-right: 16px !important;
  }

  body ${scope} .pl-4 {
    padding-left: 24px !important;
  }

  body ${scope} .pl-5 {
    padding-left: 32px !important;
  }

  body ${scope} .pl-6 {
    padding-left: 40px !important;
  }

  body ${scope} .f6 {
    font-size: 12px !important;
  }

  body ${scope} .lh-condensed {
    line-height: 1.25 !important;
  }

  body ${scope} .text-bold {
    font-weight: 600 !important;
  }

  body ${scope} .pl-c {
    color: #6a737d;
  }

  body ${scope} .pl-c1,
  body ${scope} .pl-s .pl-v {
    color: #005cc5;
  }

  body ${scope} .pl-e,
  body ${scope} .pl-en {
    color: #6f42c1;
  }

  body ${scope} .pl-s .pl-s1,
  body ${scope} .pl-smi {
    color: #24292e;
  }

  body ${scope} .pl-ent {
    color: #22863a;
  }

  body ${scope} .pl-k {
    color: #d73a49;
  }

  body ${scope} .pl-pds,
  body ${scope} .pl-s,
  body ${scope} .pl-s .pl-pse .pl-s1,
  body ${scope} .pl-sr,
  body ${scope} .pl-sr .pl-cce,
  body ${scope} .pl-sr .pl-sra,
  body ${scope} .pl-sr .pl-sre {
    color: #032f62;
  }

  body ${scope} .pl-smw,
  body ${scope} .pl-v {
    color: #e36209;
  }

  body ${scope} .pl-bu {
    color: #b31d28;
  }

  body ${scope} .pl-ii {
    color: #fafbfc;
    background-color: #b31d28;
  }

  body ${scope} .pl-c2 {
    color: #fafbfc;
    background-color: #d73a49;
  }

  body ${scope} .pl-c2:before {
    content: '^M';
  }

  body ${scope} .pl-sr .pl-cce {
    font-weight: 700;
    color: #22863a;
  }

  body ${scope} .pl-ml {
    color: #735c0f;
  }

  body ${scope} .pl-mh,
  body ${scope} .pl-mh .pl-en,
  body ${scope} .pl-ms {
    font-weight: 700;
    color: #005cc5;
  }

  body ${scope} .pl-mi {
    font-style: italic;
    color: #24292e;
  }

  body ${scope} .pl-mb {
    font-weight: 700;
    color: #24292e;
  }

  body ${scope} .pl-md {
    color: #b31d28;
    background-color: #ffeef0;
  }

  body ${scope} .pl-mi1 {
    color: #22863a;
    background-color: #f0fff4;
  }

  body ${scope} .pl-mc {
    color: #e36209;
    background-color: #ffebda;
  }

  body ${scope} .pl-mi2 {
    color: #f6f8fa;
    background-color: #005cc5;
  }

  body ${scope} .pl-mdr {
    font-weight: 700;
    color: #6f42c1;
  }

  body ${scope} .pl-ba {
    color: #586069;
  }

  body ${scope} .pl-sg {
    color: #959da5;
  }

  body ${scope} .pl-corl {
    text-decoration: underline;
    color: #032f62;
  }

  body ${scope} .mb-0 {
    margin-bottom: 0 !important;
  }

  body ${scope} .my-2 {
    margin-bottom: 8px !important;
  }

  body ${scope} .my-2 {
    margin-top: 8px !important;
  }

  body ${scope} .pl-0 {
    padding-left: 0 !important;
  }

  body ${scope} .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  body ${scope} .pl-1 {
    padding-left: 4px !important;
  }

  body ${scope} .pl-2 {
    padding-left: 8px !important;
  }

  body ${scope} .py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  body ${scope} .pl-3 {
    padding-left: 16px !important;
  }

  body ${scope} .pl-4 {
    padding-left: 24px !important;
  }

  body ${scope} .pl-5 {
    padding-left: 32px !important;
  }

  body ${scope} .pl-6 {
    padding-left: 40px !important;
  }

  body ${scope} .pl-7 {
    padding-left: 48px !important;
  }

  body ${scope} .pl-8 {
    padding-left: 64px !important;
  }

  body ${scope} .pl-9 {
    padding-left: 80px !important;
  }

  body ${scope} .pl-10 {
    padding-left: 96px !important;
  }

  body ${scope} .pl-11 {
    padding-left: 112px !important;
  }

  body ${scope} .pl-12 {
    padding-left: 128px !important;
  }

  body ${scope} hr {
    border-bottom-color: #eee;
  }

  body ${scope} kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #d1d5da;
  }

  body ${scope}:after, body ${scope}:before {
    display: table;
    content: '';
  }

  body ${scope}:after {
    clear: both;
  }

  body ${scope}>:first-child {
    margin-top: 0 !important;
  }

  body ${scope}>:last-child {
    margin-bottom: 0 !important;
  }

  body ${scope} a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  body ${scope} blockquote,
  body ${scope} details,
  body ${scope} dl,
  body ${scope} ol,
  body ${scope} p,
  body ${scope} pre,
  body ${scope} table,
  body ${scope} ul {
    margin-top: 0;
    margin-bottom: 16px;
  }

  body ${scope} hr {
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: #e1e4e8;
    border: 0;
  }

  body ${scope} blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
  }

  body ${scope} blockquote > :first-child {
    margin-top: 0;
  }

  body ${scope} blockquote > :last-child {
    margin-bottom: 0;
  }

  body ${scope} h1,
  body ${scope} h2,
  body ${scope} h3,
  body ${scope} h4,
  body ${scope} h5,
  body ${scope} h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  body ${scope} h1 {
    font-size: 2em;
  }

  body ${scope} h1,
  body ${scope} h2 {
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
  }

  body ${scope} h2 {
    font-size: 1.5em;
  }

  body ${scope} h3 {
    font-size: 1.25em;
  }

  body ${scope} h4 {
    font-size: 1em;
  }

  body ${scope} h5 {
    font-size: 0.875em;
  }

  body ${scope} h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  body ${scope} ol,
  body ${scope} ul {
    padding-left: 2em;
  }

  body ${scope} ol ol,
  body ${scope} ol ul,
  body ${scope} ul ol,
  body ${scope} ul ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  body ${scope} ol > li {
    list-style: decimal;
  }

  body ${scope} ul > li {
    list-style: circle;
  }

  body ${scope} li {
    display: list-item;
    word-wrap: break-all;
  }

  body ${scope} li > p {
    margin-top: 16px;
  }

  body ${scope} li + li {
    margin-top: 0.25em;
  }

  body ${scope} dl {
    padding: 0;
  }

  body ${scope} dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }

  body ${scope} dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  body ${scope} table {
    display: block;
    width: 100%;
    overflow: auto;
  }

  body ${scope} table th {
    font-weight: 600;
  }

  body ${scope} table td,
  body ${scope} table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  body ${scope} table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  body ${scope} table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  body ${scope} img {
    max-width: 100%;
    box-sizing: initial;
    background-color: #fff;
  }

  body ${scope} img[align='right'] {
    padding-left: 20px;
  }

  body ${scope} img[align='left'] {
    padding-right: 20px;
  }

  body ${scope} code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
  }

  body ${scope} pre {
    word-wrap: normal;
  }

  body ${scope} pre > code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }

  body ${scope} .highlight {
    margin-bottom: 16px;
  }

  body ${scope} .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }

  body ${scope} .highlight pre,
  body ${scope} pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }

  body ${scope} pre code {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: initial;
    border: 0;
  }

  body ${scope} .commit-tease-sha {
    display: inline-block;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: 90%;
    color: #444d56;
  }

  body ${scope} .full-commit .btn-outline:not(:disabled):hover {
    color: #005cc5;
    border-color: #005cc5;
  }

  body ${scope} .blob-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }

  body ${scope} .blob-wrapper-embedded {
    max-height: 240px;
    overflow-y: auto;
  }

  body ${scope} .blob-num {
    width: 1%;
    min-width: 50px;
    padding-right: 10px;
    padding-left: 10px;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: 12px;
    line-height: 20px;
    color: rgba(27, 31, 35, 0.3);
    text-align: right;
    white-space: nowrap;
    vertical-align: top;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body ${scope} .blob-num:hover {
    color: rgba(27, 31, 35, 0.6);
  }

  body ${scope} .blob-num:before {
    content: attr(data-line-number);
  }

  body ${scope} .blob-code {
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 20px;
    vertical-align: top;
  }

  body ${scope} .blob-code-inner {
    overflow: visible;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: 12px;
    color: #24292e;
    word-wrap: normal;
    white-space: pre;
  }

  body ${scope} .pl-token.active,
  body ${scope} .pl-token:hover {
    cursor: pointer;
    background: #ffea7f;
  }

  body ${scope} .tab-size[data-tab-size='1'] {
    -moz-tab-size: 1;
    tab-size: 1;
  }

  body ${scope} .tab-size[data-tab-size='2'] {
    -moz-tab-size: 2;
    tab-size: 2;
  }

  body ${scope} .tab-size[data-tab-size='3'] {
    -moz-tab-size: 3;
    tab-size: 3;
  }

  body ${scope} .tab-size[data-tab-size='4'] {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  body ${scope} .tab-size[data-tab-size='5'] {
    -moz-tab-size: 5;
    tab-size: 5;
  }

  body ${scope} .tab-size[data-tab-size='6'] {
    -moz-tab-size: 6;
    tab-size: 6;
  }

  body ${scope} .tab-size[data-tab-size='7'] {
    -moz-tab-size: 7;
    tab-size: 7;
  }

  body ${scope} .tab-size[data-tab-size='8'] {
    -moz-tab-size: 8;
    tab-size: 8;
  }

  body ${scope} .tab-size[data-tab-size='9'] {
    -moz-tab-size: 9;
    tab-size: 9;
  }

  body ${scope} .tab-size[data-tab-size='10'] {
    -moz-tab-size: 10;
    tab-size: 10;
  }

  body ${scope} .tab-size[data-tab-size='11'] {
    -moz-tab-size: 11;
    tab-size: 11;
  }

  body ${scope} .tab-size[data-tab-size='12'] {
    -moz-tab-size: 12;
    tab-size: 12;
  }

  body ${scope} .task-list-item {
    list-style-type: none;
  }

  body ${scope} .task-list-item + .task-list-item {
    margin-top: 3px;
  }

  body ${scope} .task-list-item input {
    margin: 0 0.2em 0.25em -1.6em;
    vertical-align: middle;
  }
`;

/**
 * @fileOverview
 * @name ChatMessageService.ts
 * @author Taketoshi Aono
 * @license
 */

import { MessageEventCreatableRepository } from '@s/repository/EventRepository';

export interface ChatMessageSendableService {
  sendTextMessage(a: {
    text: string;
    projectId: string;
    customerId: string;
    uuid: string;
  }): Promise<void>;

  sendImageMessage(a: {
    mediaId: string;
    projectId: string;
    customerId: string;
    uuid: string;
  }): Promise<void>;
}

export class ChatMessageService implements ChatMessageSendableService {
  public constructor(private readonly messageRepository: MessageEventCreatableRepository) {}

  public async sendImageMessage({
    mediaId,
    projectId,
    customerId,
    uuid,
  }: Parameters<ChatMessageSendableService['sendImageMessage']>[0]): ReturnType<
    ChatMessageSendableService['sendImageMessage']
  > {
    await this.messageRepository.create({
      param: {
        at: Date.now(),
        image: {
          mediaId,
        },
        type: 'image',
        uuid,
        meta: {
          uuid,
        },
      },
      projectId,
      customerId,
    });
  }

  public async sendTextMessage({
    text,
    projectId,
    customerId,
    uuid,
  }: Parameters<ChatMessageSendableService['sendTextMessage']>[0]): ReturnType<
    ChatMessageSendableService['sendTextMessage']
  > {
    await this.messageRepository.create({
      param: {
        at: Date.now(),
        message: text,
        type: 'message',
        uuid,
        meta: {
          uuid,
        },
      },
      projectId,
      customerId,
    });
  }
}

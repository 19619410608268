/**
 * @fileOverview
 * @name SystemMessagesRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { SystemMessages } from '@c/domain/entities/SystemMessages';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@aim/shared/src/Repository';

type SystemMessagesEditableRepository = UpdatableRepository<
  { payload: SystemMessages; projectId: string },
  {}
>;
type P = Params<SystemMessagesEditableRepository>;

export class SystemMessagesRepository implements SystemMessagesEditableRepository {
  public async update({
    payload,
    projectId,
  }: P['update'][0]): ReturnType<SystemMessagesEditableRepository['update']> {
    return fetchService(apiEndpoint(`project/${projectId}/scenario/system_message`), {
      method: 'PUT',
      data: { messages: payload },
      responseType: 'json',
    });
  }
}

import { DialogEngineBusinessOpenPeriod } from '@c/domain/entities/dialogEngine/DialogEngineBusinessHour';

export interface DialogEngineBusinessHourSpecification {
  isValidBusinessHour(businessOpenPeriods: DialogEngineBusinessOpenPeriod[]): [boolean, string];
}

export class DialogEngineBusinessHourValidator implements DialogEngineBusinessHourSpecification {
  public isValidBusinessHour(
    businessOpenPeriods: DialogEngineBusinessOpenPeriod[]
  ): [boolean, string] {
    for (const businessOpenPeriod of businessOpenPeriods) {
      if (businessOpenPeriod.periodStart.length === 0) {
        return [false, '営業時間の開始時刻は必須です'];
      } else if (businessOpenPeriod.periodEnd.length === 0) {
        return [false, '営業時間の終了時刻は必須です'];
      }
    }
    return [true, ''];
  }
}

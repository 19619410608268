/**
 * @fileOverview
 * @author
 */

import { initialState } from '@c/state';
import produce from 'immer';
import { promoteFailed } from '@c/modules/version/action';

type ActionType = ReturnType<typeof promoteFailed>;

export default (state = initialState.promote, action: ActionType) => {
  return produce(state, state => {
    switch (action.type) {
      case 'PROMOTE_FAILED':
        return action.payload;
      default:
        return state;
    }
  });
};

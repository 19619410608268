import { GettableQuery } from '@c/query/interface';
import { RecordingInfo } from '@c/domain/entities/voice/RecordingInfo';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type RecordingInfoGettableQuery = GettableQuery<
  { projectId: string; customerId: string; conversationId: string },
  RecordingInfo
>;

export class RecordingInfoQuery implements RecordingInfoGettableQuery {
  public async get({
    projectId,
    customerId,
    conversationId,
  }: Parameters<RecordingInfoGettableQuery['get']>[0]): ReturnType<
    RecordingInfoGettableQuery['get']
  > {
    const recordingInfo = await fetchService<RecordingInfo>(
      apiEndpoint(
        `/conversation/voice/${projectId}/${customerId}/${conversationId}/recording-info`
      ),
      {
        method: 'GET',
        responseType: 'json',
      }
    );
    const now = new Date();
    now.setSeconds(now.getSeconds() + recordingInfo.expiresIn);
    recordingInfo.expireDate = now;
    return recordingInfo;
  }
}

/**
 * @fileOverview
 * @name Logo.tsx
 * @author Taketoshi Aono
 * @license
 */

import React from 'react';

export const GenericLogo = React.memo(
  ({ size }: { size: number }) => {
    return (
      <div className="aim__logo" css={{ width: size }}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 45.176">
          <style type="text/css">{`.aim__logo .st0{fill:#231815;}`}</style>
          <defs></defs>
          <g>
            <g>
              <path
                className="st0"
                d="M12.1,4.8c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8
			          c1.6,0,2.8-1.3,2.8-2.8c0-0.8-0.3-1.5-0.8-2c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6c0.5-0.5,0.8-1.2,0.8-2
			          c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8C11.3,3.6,11.6,4.3,12.1,4.8z M12.8,5.3L12.8,5.3L12.8,5.3z"
              />
              <path
                className="st0"
                d="M25.4,5.6c0.8,0,1.5-0.3,2-0.8c0.9-0.9,1.4-1.7,3.6-1.7c2.3,0,2.7,0.8,3.6,1.7c0.5,0.5,1.2,0.8,2,0.8
			          c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8c-0.8,0-1.5,0.3-2,0.8l0,0c-0.9,0.9-1.4,1.7-3.6,1.7c-2.3,0-2.7-0.8-3.6-1.7
			          c-0.5-0.5-1.2-0.8-2-0.8c-1.6,0-2.8,1.3-2.8,2.8C22.6,4.4,23.9,5.6,25.4,5.6z M34.2,4.2C34.2,4.2,34.2,4.2,34.2,4.2
			          C34.2,4.2,34.2,4.2,34.2,4.2z M27.9,4.2L27.9,4.2L27.9,4.2z"
              />
              <circle className="st0" cx="48" cy="14.1" r="2.8" />
              <path
                className="st0"
                d="M4.8,23.4C4.8,23.4,4.8,23.4,4.8,23.4c-0.9-0.9-1.7-1.4-1.7-3.6s0.8-2.7,1.7-3.6l0,0c0.5-0.5,0.8-1.2,0.8-2
			          c0-1.6-1.3-2.8-2.8-2.8S0,12.6,0,14.1c0,0.8,0.3,1.5,0.8,2l0,0c0.9,0.9,1.7,1.4,1.7,3.6s-0.8,2.7-1.7,3.6l0,0
			          c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8C5.6,24.6,5.3,23.9,4.8,23.4z"
              />

              <ellipse
                transform="matrix(0.9959 -9.055473e-02 9.055473e-02 0.9959 -2.2431 1.3828)"
                className="st0"
                cx="14.1"
                cy="25.4"
                rx="2.8"
                ry="2.8"
              />
              <path
                className="st0"
                d="M48,22.6c-0.8,0-1.5,0.3-2,0.8l0,0c-0.9,0.9-1.4,1.7-3.6,1.7c-2.3,0-2.7-0.8-3.6-1.7l0,0c0,0,0,0,0,0l0,0
			          C37.8,22.5,37,22,37,19.8s0.8-2.7,1.7-3.6l0,0c0.5-0.5,0.8-1.2,0.8-2c0-1.6-1.3-2.8-2.8-2.8s-2.8,1.3-2.8,2.8c0,0.8,0.3,1.5,0.8,2
			          c0,0,0,0,0,0c0.9,0.9,1.7,1.4,1.7,3.6s-0.8,2.7-1.7,3.6l0,0c0,0,0,0,0,0l0,0c-0.9,0.9-1.4,1.7-3.6,1.7c-2.3,0-2.7-0.8-3.6-1.7
			          c0,0,0,0,0,0c-0.9-0.9-1.7-1.4-1.7-3.6s0.8-2.7,1.7-3.6c0.5-0.5,0.8-1.2,0.8-2c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8
			          c0,0.8,0.3,1.5,0.8,2l0,0c0.9,0.9,1.7,1.4,1.7,3.6s-0.8,2.7-1.7,3.6c0,0,0,0,0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8
			          c0.8,0,1.5-0.3,2-0.8c0.9-0.9,1.4-1.7,3.6-1.7c2.3,0,2.7,0.8,3.6,1.7l0,0c0,0,0,0,0,0c0.9,0.9,1.7,1.4,1.7,3.6
			          c0,2.3-0.8,2.7-1.7,3.6l0,0c0,0,0,0,0,0l0,0c-0.9,0.9-1.4,1.7-3.6,1.7c-2.3,0-2.7-0.8-3.6-1.7c-0.5-0.5-1.2-0.8-2-0.8
			          c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8c0.8,0,1.5-0.3,2-0.8c0.9-0.9,1.4-1.7,3.6-1.7c2.3,0,2.7,0.8,3.6,1.7
			          c0.5,0.5,1.2,0.8,2,0.8c1.6,0,2.8-1.3,2.8-2.8c0-0.8-0.3-1.5-0.8-2l0,0c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6
			          c0,0,0,0,0,0l0,0c0.9-0.9,1.4-1.7,3.6-1.7c2.3,0,2.7,0.8,3.6,1.7l0,0c0.5,0.5,1.2,0.8,2,0.8c1.6,0,2.8-1.3,2.8-2.8
			          S49.6,22.6,48,22.6z M27.9,24.1C27.9,24.1,27.9,24.1,27.9,24.1C27.9,24.1,27.9,24.1,27.9,24.1z M27.9,26.7
			          C27.9,26.7,27.9,26.7,27.9,26.7C27.9,26.7,27.9,26.7,27.9,26.7z M27.9,35.4C27.9,35.4,27.9,35.4,27.9,35.4
			          C27.9,35.4,27.9,35.4,27.9,35.4z M27.9,38L27.9,38L27.9,38z M35.4,27.9C35.4,27.9,35.4,27.9,35.4,27.9
			          C35.4,27.9,35.4,27.9,35.4,27.9z M38,27.9C38,27.9,38,27.9,38,27.9C38,27.9,38,27.9,38,27.9z M39.2,24.1
			          C39.2,24.1,39.2,24.1,39.2,24.1C39.2,24.1,39.2,24.1,39.2,24.1z"
              />
              <path
                className="st0"
                d="M29,33L29,33c0.5,0.5,1.2,0.8,2,0.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8c-0.8,0-1.5,0.3-2,0.8l0,0
			          c-0.9,0.9-1.4,1.7-3.6,1.7s-2.7-0.8-3.6-1.7l0,0c0,0,0,0,0,0l0,0c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6
			          c0.5-0.5,0.8-1.2,0.8-2c0-0.8-0.3-1.5-0.8-2l0,0c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6c0,0,0,0,0,0l0,0
			          c0.9-0.9,1.4-1.7,3.6-1.7s2.7,0.8,3.6,1.7l0,0c0,0,0,0,0,0c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0
			          c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-0.8-0.3-1.5-0.8-2l0,0c-0.9-0.9-1.7-1.4-1.7-3.6
			          c0-2.3,0.8-2.7,1.7-3.6c0.5-0.5,0.8-1.2,0.8-2c0-1.6-1.3-2.8-2.8-2.8c-0.8,0-1.5,0.3-2,0.8l0,0c-0.9,0.9-1.4,1.7-3.6,1.7
			          s-2.7-0.8-3.6-1.7l0,0c-0.5-0.5-1.2-0.8-2-0.8c-1.6,0-2.8,1.3-2.8,2.8c0,0.8,0.3,1.5,0.8,2c0.9,0.9,1.7,1.4,1.7,3.6
			          c0,2.3-0.8,2.7-1.7,3.6l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,0.9-1.4,1.7-3.6,1.7s-2.7-0.8-3.6-1.7c0,0,0,0,0,0c0,0,0,0,0,0l0,0
			          c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6c0.5-0.5,0.8-1.2,0.8-2c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8
			          c0,0.8,0.3,1.5,0.8,2c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,0.8,0.3,1.5,0.8,2
			          c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8
			          c0-0.8-0.3-1.5-0.8-2l0,0c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6c0,0,0,0,0,0c0.9-0.9,1.4-1.7,3.6-1.7s2.7,0.8,3.6,1.7
			          c0,0,0,0,0,0c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8
			          c0.8,0,1.5-0.3,2-0.8l0,0c0.9-0.9,1.4-1.7,3.6-1.7S28.1,32.1,29,33z M29.7,11C29.7,11,29.7,11,29.7,11C29.7,11,29.7,11,29.7,11z
			          M7.1,11C7.1,11,7.1,11,7.1,11C7.1,11,7.1,11,7.1,11z M21.1,22.3L21.1,22.3L21.1,22.3z M18.4,11C18.4,11,18.4,11,18.4,11
			          C18.4,11,18.4,11,18.4,11z M22.3,29.7C22.3,29.7,22.3,29.7,22.3,29.7C22.3,29.7,22.3,29.7,22.3,29.7z"
              />
              <path
                className="st0"
                d="M44.3,33.1c0.5-0.5,0.8-1.2,0.8-2c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8c0,0.8,0.3,1.5,0.8,2l0,0
			          c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8
			          c0-0.8-0.3-1.5-0.8-2c-0.9-0.9-1.7-1.4-1.7-3.6C42.6,34.4,43.4,34,44.3,33.1z"
              />
              <path
                className="st0"
                d="M40.4,10.5c0.9,0.9,1.7,1.4,1.7,3.6c0,2.3-0.8,2.7-1.7,3.6l0,0c-0.5,0.5-0.8,1.2-0.8,2c0,1.6,1.3,2.8,2.8,2.8
			          c1.6,0,2.8-1.3,2.8-2.8c0-0.8-0.3-1.5-0.8-2c-0.9-0.9-1.7-1.4-1.7-3.6c0-2.3,0.8-2.7,1.7-3.6c0.5-0.5,0.8-1.2,0.8-2
			          c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8C39.5,9.3,39.9,10,40.4,10.5z M41,11C41,11,41,11,41,11C41,11,41,11,41,11z
			          M43.7,11C43.7,11,43.7,11,43.7,11C43.7,11,43.7,11,43.7,11z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M144.2,24.5c-0.8,1.4-1.8,2.3-3.5,2.3c-2.2,0-3.5-1.9-3.5-3.8h9.2v-0.5c0-4-2.2-6.6-5.8-6.6
			          c-3.7,0-6,2.5-6,6.6c0,3.8,2.5,6.5,6.1,6.5c2.4,0,4.6-1.3,5.7-3.4l0.1-0.1L144.2,24.5L144.2,24.5z M137.3,21.1
			          c0.3-1.7,1.6-2.8,3.3-2.8c1.7,0,2.9,1,3.2,2.8H137.3z"
              />
              <path
                className="st0"
                d="M155.4,21.5c-0.2-0.1-0.4-0.2-0.5-0.2c-1.2-0.5-2.2-1-2.2-1.8c0-0.6,0.5-1.1,1.2-1.1c0.6,0,1.2,0.4,1.5,1
			          l0.1,0.1l2.1-1.1l0-0.1c-0.7-1.4-1.9-2.2-3.4-2.2c-2,0-3.7,1.6-3.7,3.6c0,1.7,1,2.4,2.4,3.2l2.3,1.2c0.5,0.4,0.8,0.6,0.8,1.2
			          c0,0.9-0.7,1.6-1.7,1.6c-1.1,0-1.7-0.7-2.1-1.5l-0.1-0.1l-2.2,1l0,0.1c0.6,1.6,2.4,2.8,4.3,2.8c2.4,0,4.3-1.8,4.3-4.1
			          C158.3,23,156.8,22.1,155.4,21.5z"
              />
              <path
                className="st0"
                d="M166.6,21.5c-0.2-0.1-0.4-0.2-0.5-0.2c-1.2-0.5-2.2-1-2.2-1.8c0-0.6,0.5-1.1,1.2-1.1c0.6,0,1.2,0.4,1.5,1
			          l0.1,0.1l2.1-1.1l0-0.1c-0.7-1.4-1.9-2.2-3.4-2.2c-2,0-3.7,1.6-3.7,3.6c0,1.7,1,2.4,2.4,3.2l2.3,1.2c0.5,0.4,0.8,0.6,0.8,1.2
			          c0,0.9-0.7,1.6-1.7,1.6c-1.1,0-1.7-0.7-2.1-1.5l-0.1-0.1l-2.2,1l0,0.1c0.6,1.6,2.4,2.8,4.3,2.8c2.4,0,4.3-1.8,4.3-4.1
			          C169.4,23,168,22.1,166.6,21.5z"
              />
              <path
                className="st0"
                d="M181.9,24.5c-0.8,1.4-1.8,2.3-3.5,2.3c-2.2,0-3.5-1.9-3.5-3.8h9.2v-0.5c0-4-2.2-6.6-5.8-6.6
			          c-3.7,0-6,2.5-6,6.6c0,3.8,2.5,6.5,6.1,6.5c2.4,0,4.6-1.3,5.7-3.4l0.1-0.1L181.9,24.5L181.9,24.5z M174.9,21.1
			          c0.3-1.7,1.6-2.8,3.3-2.8c1.7,0,2.9,1,3.2,2.8H174.9z"
              />
              <path
                className="st0"
                d="M193.9,16c-1.5,0-2.8,0.7-3.6,1.8v-1.4h-2.6v12.4h2.6V23c0-2.2,0-4.7,2.9-4.7c2.2,0,2.6,1.1,2.6,3.4v7.1h2.6
			          v-7.3C198.4,19.1,197.9,16,193.9,16z"
              />
              <path
                className="st0"
                d="M226.9,24.5c-0.8,1.4-1.8,2.3-3.5,2.3c-2.2,0-3.5-1.9-3.5-3.8h9.2v-0.5c0-4-2.2-6.6-5.8-6.6
			          c-3.7,0-6,2.5-6,6.6c0,3.8,2.5,6.5,6.1,6.5c2.4,0,4.6-1.3,5.7-3.4l0.1-0.1L226.9,24.5L226.9,24.5z M220,21.1
			          c0.3-1.7,1.6-2.8,3.3-2.8c1.7,0,2.9,1,3.2,2.8H220z"
              />
              <path
                className="st0"
                d="M239.9,16.6c-0.6-0.4-1.3-0.6-2-0.6c-1.5,0-2.6,0.8-3.3,2.1v-1.7h-2.6v12.4h2.6v-6.1c0-2,0.3-4.2,2.7-4.2
			          c0.6,0,1,0.1,1.4,0.5l0.1,0.1L239.9,16.6L239.9,16.6z"
              />
              <path
                className="st0"
                d="M211.5,18.3c-1-1.5-2.5-2.3-4.2-2.3c-2.8,0-5.8,2.3-5.8,6.5c0,4.6,3.1,6.7,5.9,6.7c1.6,0,3-0.8,4.1-2.1v2
			          c0,2.1-0.4,4.3-3.4,4.3c-0.6,0-1.2-0.1-1.6-0.3l-0.1,0l-1.2,2.1l0.1,0c0.8,0.3,1.7,0.5,2.7,0.5c5.3,0,6-3.9,6-7.3v-12h-2.6V18.3z
			          M207.8,18.3c2.3,0,3.8,1.7,3.8,4.2c0,3-1.9,4.3-3.8,4.3c-2.4,0-3.7-2.2-3.7-4.3C204.1,19.8,206,18.3,207.8,18.3z"
              />
              <polygon
                className="st0"
                points="114.2,28.7 114.2,14.3 120.6,22.6 120.7,22.7 121.7,22.7 128.1,14.1 128.1,28.7 130.7,28.7 
			          130.7,9.5 128.4,9.5 121.2,19.1 113.9,9.6 113.8,9.5 111.6,9.5 111.6,28.7 		"
              />
              <rect x="92.6" y="9.5" className="st0" width="2.7" height="19.2" />
              <path
                className="st0"
                d="M73.5,28.7l2-4.2h8.8l2,4.2h2.9L79.8,9.5l-9.2,19.2H73.5z M79.8,15.4l3.1,6.5h-6.2L79.8,15.4z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  },
  () => false
);

/**
 * @fileOverview
 * @name ScenarioEditorQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';
import { CSVWorkerQueryParameter } from '@c/domain/service/CSVWorker.type';
import { RemoteSceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';

export type ScenarioCSVQuery = GettableQuery<CSVWorkerQueryParameter, string>;

export class ScenarioCSVGettableQuery implements ScenarioCSVQuery {
  public constructor(
    private readonly scenarioNodesCsvAdapter: (
      a: {
        summaryText: string;
        scenarioId: string;
        sceneNodes: RemoteSceneNodes;
      }[]
    ) => Promise<string>
  ) {}

  public async get(param: CSVWorkerQueryParameter): Promise<string> {
    const res = await Promise.all(
      param.inquiries.map(async ({ endpoint, tenantName, summaryText, scenarioId }) => {
        const sceneNodes = await fetchService<RemoteSceneNodes>(endpoint, {
          method: 'GET',
          responseType: 'json',
          headers: {
            'x-aim-subdomain': tenantName,
          },
        });
        return { summaryText, scenarioId, sceneNodes };
      })
    );
    return this.scenarioNodesCsvAdapter(res);
  }
}

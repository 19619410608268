/* eslint-disable @typescript-eslint/naming-convention */
/**
 * @fileOverview
 * @name FbService.ts
 * @author Taketoshi Aono
 * @license
 */

import { InstagramAccountList } from '@c/domain/entities/Instagram';
import { staticConfig, FACEBOOK_APP_PROJECT_REQUIRED_SCOPES_SET } from '@c/config';
import { storage } from '@s/io/storage';
import { fetchService } from '@aim/shared/src/io/fetchService';

const FB_ACCESS_TOKEN_KEY = '__aim_fb_';

type FacebookAuthInfo = { userAccessToken: string; scope: FacebookAuthScopes['scope'] };

class FacebookSdk {
  public static async api<T>({
    appId,
    path,
    method = 'GET',
    data = {},
    shouldSkipAccessToken = false,
    shouldSkipContentType = false,
  }: {
    appId: string;
    path: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    data?: any;
    shouldSkipAccessToken?: boolean;
    shouldSkipContentType?: boolean;
  }): Promise<T> {
    let queryParams = '';
    const accessToken = !shouldSkipAccessToken
      ? (await this.getAuthInfo({ appId, checkServer: false }))?.userAccessToken
      : '';
    if (method === 'GET' || method === 'DELETE') {
      const extendedData = {
        ...(shouldSkipAccessToken ? {} : { access_token: accessToken }),
        ...data,
      };
      queryParams = Object.keys(extendedData).reduce((queryParams, key) => {
        return `${queryParams.length ? `${queryParams}&` : ''}${encodeURIComponent(
          key
        )}=${encodeURIComponent(extendedData[key]?.toString() ?? '')}`;
      }, '');
      queryParams = queryParams.length ? `?${queryParams}` : '';
    }
    return fetchService(`https://graph.facebook.com/v12.0${path}${queryParams}`, {
      method,
      data: {
        ...(shouldSkipAccessToken ? {} : { access_token: accessToken }),
        ...data,
      },
      responseType: 'json',
      shouldSkipContentType,
    });
  }

  public static async getAuthInfo({
    appId,
    checkServer,
  }: {
    appId: string;
    checkServer: boolean;
  }): Promise<FacebookAuthInfo | undefined> {
    const data = (
      await storage('local').get<{
        [key: string]: FacebookAuthInfo | undefined;
      }>(FB_ACCESS_TOKEN_KEY, {})
    )[appId];
    if (data?.userAccessToken) {
      if (checkServer) {
        try {
          await this.api({
            appId,
            path: '/me',
            shouldSkipAccessToken: true,
            data: { access_token: data.userAccessToken },
          });
        } catch (e) {
          return undefined;
        }
      }
      return data;
    }
    return undefined;
  }

  public static async login({
    appId,
    scope,
  }: {
    appId: string;
    scope: FacebookAuthScopes['scope'];
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      let isResponded = false;
      let interval = 0;
      const handler = async (e: MessageEvent) => {
        if (win) {
          if (e.data?.type === 'aim_fb_login') {
            isResponded = true;
            clearInterval(interval);
            win.close();
            const prevFbData = await storage('local').get(FB_ACCESS_TOKEN_KEY, {});
            await storage('local').set(FB_ACCESS_TOKEN_KEY, {
              ...prevFbData,
              [appId]: { userAccessToken: e.data.access_token, scope, expires: e.data.expires },
            });
            resolve(e.data.access_token);
            window.removeEventListener('message', handler);
          } else if (e.data?.type === 'aim_fb_login_error') {
            isResponded = true;
            clearInterval(interval);
            win.close();
            const { type: unusedType, ...errors } = e.data;
            reject(errors);
          }
        } else {
          isResponded = true;
          clearInterval(interval);
          resolve('');
          window.removeEventListener('message', handler);
        }
      };
      window.addEventListener('message', handler);
      const win = window.open(
        `${staticConfig.facebook.oauthUrl}?client_id=${appId}&scope=${scope.join(
          ','
        )}&_=${Date.now()}`,
        'aim_fb_login',
        'toolbar=no,menubar=no,width=400,height=600'
      );
      if (win) {
        interval = window.setInterval(() => {
          if (win.closed) {
            clearInterval(interval);
            if (!isResponded) {
              resolve('');
            }
          }
        }, 500);
      }
    });
  }

  public static async logout(): Promise<void> {
    return storage('local').delete(FB_ACCESS_TOKEN_KEY);
  }
}

type FacebookMeResponse<Additional = {}> = {
  data: ({
    id: string;
    access_token: string;
  } & Additional)[];
  paging?: {
    previous?: string;
    next?: string;
  };
};

export class FbService {
  public async deleteAppSubscription({
    appId,
    accessToken,
  }: {
    appId: string;
    accessToken?: string;
  }) {
    const pages = await FacebookSdk.api<
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      FacebookMeResponse<{
        connected_instagram_account: {
          id: string;
          username: string;
          profile_picture_url: string;
        };
      }>
    >({
      appId,
      path: '/me/accounts',
      method: 'GET',
      data: {
        fields: 'id,access_token',
      },
    });
    return Promise.all(
      pages.data.map(async page => {
        return FacebookSdk.api({
          appId,
          shouldSkipAccessToken: true,
          path: `/${page.id}/subscribed_apps?access_token=${accessToken || ''}`,
          method: 'DELETE',
          data: {},
        });
      })
    ).catch(() => {});
  }

  public async getAccessToken({
    appId = staticConfig.facebook.projectAppId,
    scope,
    shouldStartLoginProcess,
    shouldForceLogin = false,
  }: {
    appId?: string;
    scope: FacebookAuthScopes['scope'];
    shouldStartLoginProcess: boolean;
    shouldForceLogin?: boolean;
  }): Promise<string | undefined> {
    if (!shouldForceLogin) {
      const authInfo = await FacebookSdk.getAuthInfo({ appId, checkServer: true });
      if (authInfo) {
        const scopeSet = new Set(authInfo.scope);
        if (!scope.every(s => scopeSet.has(s))) {
          return;
        }
        return authInfo.userAccessToken;
      }
      if (!shouldStartLoginProcess) {
        return;
      }
    }
    return FacebookSdk.login({
      appId,
      scope,
    });
  }

  public async getAppName(appId: string): Promise<string> {
    const res = await FacebookSdk.api<{ name: string }>({
      appId,
      path: `/${appId}`,
      method: 'GET',
      data: { fields: 'name' },
    });
    return res.name;
  }

  public async getIgInfo(igId: string) {
    return FacebookSdk.api<
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      FacebookMeResponse<{
        connected_instagram_account?: {
          id: string;
          username: string;
          access_token: string;
          profile_picture_url: string;
        };
      }>
    >({
      appId: staticConfig.facebook.projectAppId,
      path: `/me/accounts`,
      method: 'GET',
      data: {
        fields: 'connected_instagram_account{id,username,profile_picture_url},access_token',
      },
    }).then(async data => {
      type Ret = ArrayElementType<typeof data.data>;
      const check = async (res: typeof data): Promise<Ret | undefined> => {
        const found = res.data.find(d => d.connected_instagram_account?.id === igId);
        if (!found) {
          if (res.paging?.next) {
            const r = await fetch(res.paging.next);
            if (r.ok) {
              return check(await r.json());
            }
          }
          return undefined;
        }
        return found;
      };
      return check(data);
    });
  }

  public async getInstagramAccounts(
    appId = staticConfig.facebook.projectAppId
  ): Promise<InstagramAccountList> {
    const res = await FacebookSdk.api<
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      FacebookMeResponse<{
        connected_instagram_account: {
          id: string;
          username: string;
          profile_picture_url: string;
        };
      }>
    >({
      appId,
      path: '/me/accounts',
      method: 'GET',
      data: {
        fields: 'connected_instagram_account{username,profile_picture_url},access_token',
      },
    });
    const uat = (await FacebookSdk.getAuthInfo({ appId, checkServer: false }))?.userAccessToken;

    if (!uat) {
      return { list: [] };
    }

    return {
      list: res.data
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        .filter(d => !!d.connected_instagram_account)
        .map(d => ({
          userName: d.connected_instagram_account.username,
          profilePictureUrl: d.connected_instagram_account.profile_picture_url,
          pageId: d.id,
          instagramAccountId: d.connected_instagram_account.id,
          pageAccessToken: d.access_token,
          userAccessToken: uat,
        })),
      next: res.paging?.next,
      prev: res.paging?.previous,
    };
  }

  public async getInstagramAccountsFromPaging({
    appId,
    url,
  }: {
    appId: string;
    url: string;
  }): Promise<InstagramAccountList> {
    const res = await fetch(url);
    if (res.ok) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      const json: FacebookMeResponse<{
        connected_instagram_account: {
          id: string;
          username: string;
          profile_picture_url: string;
        };
      }> = await res.json();
      const uat = (await FacebookSdk.getAuthInfo({ appId, checkServer: false }))?.userAccessToken;

      if (!uat) {
        return { list: [] };
      }
      return {
        list: json.data
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          .filter(d => !!d.connected_instagram_account)
          .map(d => ({
            userName: d.connected_instagram_account.username,
            profilePictureUrl: d.connected_instagram_account.profile_picture_url,
            pageId: d.id,
            instagramAccountId: d.connected_instagram_account.id,
            pageAccessToken: d.access_token,
            userAccessToken: uat,
          })),
        next: json.paging?.next,
        prev: json.paging?.previous,
      };
    }
    throw new Error('Failed to fetch instagram accounts');
  }

  public async getLongTermToken({
    pageId,
    clientId,
    clientSecret,
    scope,
  }: {
    pageId: string;
    clientId: string;
    clientSecret: string;
    scope: FacebookAuthScopes['scope'];
  }): Promise<string | null> {
    const accessToken =
      (await this.getAccessToken({ appId: clientId, scope, shouldStartLoginProcess: true })) ??
      null;
    if (accessToken) {
      const longTermToken = await FacebookSdk.api<{ access_token?: string } | null>({
        appId: clientId,
        shouldSkipAccessToken: true,
        path: '/oauth/access_token',
        method: 'GET',
        data: {
          grant_type: 'fb_exchange_token',
          client_id: clientId,
          client_secret: clientSecret,
          fb_exchange_token: accessToken,
        },
        shouldSkipContentType: true,
      });
      if (longTermToken?.access_token) {
        const res = await FacebookSdk.api<FacebookMeResponse>({
          appId: clientId,
          path: '/me/accounts',
          method: 'GET',
          data: {
            access_token: longTermToken.access_token,
            fields: 'access_token',
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return res.data.find?.(p => p.id === pageId)?.access_token ?? null;
      }
    }
    return null;
  }

  public async getProfile(appId = staticConfig.facebook.appId): Promise<string> {
    const res = await FacebookSdk.api<{
      first_name: string;
      last_name: string;
      name_format: string;
      middle_name: string;
    }>({
      appId,
      path: '/me',
      method: 'GET',
      data: {
        fields: 'name_format,first_name,last_name,middle_name',
      },
    });
    return res.name_format
      .replace('{first}', res.first_name)
      .replace('{last}', res.last_name)
      .replace('{middle}', res.middle_name);
  }

  public async getSubscription({
    pageId,
    appId,
    pageAccessToken,
  }: {
    pageId: string;
    appId: string;
    pageAccessToken: string;
  }): Promise<{ data: string[] }> {
    return FacebookSdk.api<{ data: string[] }>({
      shouldSkipAccessToken: true,
      appId,
      path: `/${pageId}/subscribed_apps?access_token=${pageAccessToken}`,
      method: 'GET',
      data: {},
    });
  }

  public async hasValidAccessToken() {
    try {
      await FacebookSdk.api<{ name: string }>({
        appId: staticConfig.facebook.projectAppId,
        path: `/me`,
        method: 'GET',
        data: {},
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  public async isAccessTokenHasInstagramAccountScopes(
    appId = staticConfig.facebook.appId
  ): Promise<boolean> {
    const token = await this.getAccessToken({
      appId: staticConfig.facebook.projectAppId,
      shouldStartLoginProcess: false,
      scope: [],
    });
    if (token) {
      try {
        const ret = await FacebookSdk.api<{
          data: { app_id: string; scopes: FacebookAuthScopes['scope'] };
        } | null>({
          appId,
          path: '/debug_token',
          method: 'GET',
          data: { input_token: token },
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (ret?.data?.scopes) {
          return ret.data.scopes.every(scope =>
            FACEBOOK_APP_PROJECT_REQUIRED_SCOPES_SET.has(scope)
          );
        }
      } catch (e) {}
    }
    return false;
  }

  public async isInstagramMessageControlling({
    pageId,
    appId,
    pageAccessToken,
  }: {
    pageId: string;
    appId: string;
    pageAccessToken: string;
  }): Promise<boolean> {
    return FacebookSdk.api({
      shouldSkipAccessToken: true,
      appId,
      path: `/${pageId}/conversations?platform=instagram&access_token=${pageAccessToken}`,
      method: 'GET',
      data: {},
    }).then(() => true);
  }

  public async logout(): Promise<void> {
    await FacebookSdk.logout();
  }

  public async subscribeApp({
    pageId,
    appId,
    pageAccessToken,
  }: {
    pageId: string;
    appId: string;
    pageAccessToken: string;
  }) {
    return FacebookSdk.api({
      appId,
      path: `/${pageId}/subscribed_apps`,
      method: 'POST',
      data: {
        access_token: pageAccessToken,
        subscribed_fields: 'feed,mention,message_mention',
      },
    });
  }

  public async unsubscribeApp({
    pageId,
    appId,
    pageAccessToken,
  }: {
    pageId: string;
    appId: string;
    pageAccessToken: string;
  }) {
    return FacebookSdk.api({
      shouldSkipAccessToken: true,
      appId,
      path: `/${pageId}/subscribed_apps?access_token=${pageAccessToken}`,
      method: 'DELETE',
      data: {},
    }).catch(() => {});
  }
}

import { EntityData } from '@c/repository/voice/VoiceEntityRepository';

export interface EntityDataSpecification {
  isValid(entityData: EntityData[]): [boolean, string];
}

export class EntityDataValidator implements EntityDataSpecification {
  public isValid(entityData: EntityData[]): [boolean, string] {
    if (
      entityData.find(e => {
        return (
          (e.value !== undefined && e.value === '') ||
          (e.values !== undefined && e.values.find(v => v === '') !== undefined)
        );
      }) !== undefined
    ) {
      return [false, 'エンティティの値は空文字にできません'];
    }
    return [true, ''];
  }
}

/**
 * @fileOverview
 * @name swap.ts
 * @author Taketoshi Aono
 * @license
 */

export const swap = <T extends any[]>(a: T, indexA: number, indexB: number) => {
  const tmp = a[indexA];
  a[indexA] = a[indexB];
  a[indexB] = tmp;
};

/**
 * @fileOverview
 * @name ScenarioEditorQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { SceneNodes, RemoteSceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { Inquiry, RemoteInquiryList } from '@c/domain/entities/Inquiry';
import { GetAndFindableQuery } from './interface';
import { SceneErrors } from '@c/domain/values/PromoteErrors';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { State } from '@c/state';

export type ScenarioFindableQuery = GetAndFindableQuery<
  {
    categoryId: number;
    projectId: ProjectId;
  },
  Inquiry[],
  {
    scenarioId: string;
    projectId: ProjectId;
    errors: SceneErrors;
    projectType: State['env']['type'];
    carousels?: Carousel[];
  },
  SceneNodes
>;

export type ScenarioNodeAdapter = (
  id: string,
  nodes: RemoteSceneNodes,
  errors: SceneErrors,
  isAnalytics: boolean,
  projectType: State['env']['type'],
  carousels?: Carousel[]
) => SceneNodes;

export class ScenarioEditorQuery implements ScenarioFindableQuery {
  public constructor(
    private readonly adapters: {
      scenarioNodesAdapter: ScenarioNodeAdapter;
      scenarioNodesCsvAdapter(
        params: {
          summaryText: string;
          scenarioId: string;
          sceneNodes: RemoteSceneNodes;
        }[]
      ): Promise<string>;
    }
  ) {}

  public async find({
    scenarioId,
    projectId,
    errors,
    carousels,
    projectType,
  }: Parameters<ScenarioFindableQuery['find']>[0]): ReturnType<ScenarioFindableQuery['find']> {
    const res = await fetchService<RemoteSceneNodes>(
      apiEndpoint(`project/${projectId}/scenario/${scenarioId}`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );
    return this.adapters.scenarioNodesAdapter(
      scenarioId,
      res,
      errors,
      false,
      projectType,
      carousels
    );
  }

  public async get({
    categoryId,
    projectId,
  }: Parameters<ScenarioFindableQuery['get']>[0]): ReturnType<ScenarioFindableQuery['get']> {
    const inquiryList = await fetchService<RemoteInquiryList>(
      apiEndpoint(`project/${projectId}/inquiry/${categoryId}`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );

    return inquiryList.inquiries;
  }
}

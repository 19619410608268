/**
 * @fileOverview
 * @name PageLoading.tsx
 * @author Taketoshi Aono
 * @license
 */

import React from 'react';
import { createPortal } from 'react-dom';
import { Loading } from '@s/components/atom/Loading';

export const PageLoading = () => {
  return createPortal(
    <div
      css={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Loading isModalEnabled={true} />
    </div>,
    document.body
  );
};

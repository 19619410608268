/**
 * @fileoverview
 * @author
 */

import { ThunkDeps } from '@c/ThunkDeps';
import { State } from '@c/state';
import { firestoreConnected, firestoreDisconnected, loginSucceeded } from '@c/modules/auth/action';
import { push } from 'connected-react-router';
import { firebaseErrorHandler } from '@c/io/firebaseErrorHandler';
import { genericError } from '@c/application/GenericError';
import { staticConfig } from '@c/config';
import { PhoneMultiFactorGenerator } from 'firebase/auth';

import { FetchFailure } from '@s/io/fetchService';
import { AsyncActionContext } from '@s/reactHooks';
import { fetchInstagramAccountList } from '../project/usecase';
import { MULTI_FACTOR_HINTS } from '@c/modules/account/usecase';
import { storage } from '@s/io/storage';
import { INITIAL_PAGE } from '@c/urlList';
import { conversationReadableRoles, getOperatorRole } from '@c/domain/values/RoleBehavior';

type C = AsyncActionContext<ThunkDeps, State>;

export const checkLoginState =
  ({ context, state, dispatch, ...rest }: C) =>
  async () => {
    try {
      const authEntity = await context.accountRepository.isLoggedIn({
        entity: state.auth.authEntity,
      });
      if (authEntity) {
        if (authEntity.providerId === 'facebook') {
          if (await context.fbService.isAccessTokenHasInstagramAccountScopes()) {
            await fetchInstagramAccountList({ context, state, dispatch, ...rest })({});
          }
        }
        dispatch(loginSucceeded({ authEntity }));
      } else {
        await context.firestoreService.releaseAllConnection();
        dispatch(push('/login'));
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw firebaseErrorHandler(e, () => genericError({ message: 'ログインに失敗しました' }));
    }
  };

export const createLoginRequest =
  ({ context, dispatch, state }: C) =>
  async ({ id, password }: { id: string; password: string }) => {
    if (!id || !password) {
      throw genericError({
        message: 'ログインに失敗しました。不正なIDまたはパスワードです。',
      });
    }
    if (location.hostname === 'localhost') {
      try {
        const authEntity = await context.accountRepository.loginWithPasswordWithoutMultiFactor({
          id,
          password,
        });
        dispatch(loginSucceeded({ authEntity }));
        dispatch(push(INITIAL_PAGE));
      } catch (e: any) {
        if (!(e instanceof FetchFailure) || e.status !== 401) {
          context.reportCrashed({ error: e, state });
        }
        throw firebaseErrorHandler(e, () => genericError({ message: 'ログインに失敗しました' }));
      }
    } else {
      try {
        storage('local').delete(MULTI_FACTOR_HINTS);
        const [authEntity, multiFactorHints] = await context.accountRepository.loginWithPassword({
          id,
          password,
        });
        const phoneMultiFactorHints = multiFactorHints.filter(hint => {
          return hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID;
        });
        if (phoneMultiFactorHints.length > 0) {
          await storage('local').set(MULTI_FACTOR_HINTS, phoneMultiFactorHints);
          dispatch(push('/send-verification-code'));
          return;
        }
        if (authEntity) {
          dispatch(loginSucceeded({ authEntity }));
          dispatch(push(INITIAL_PAGE));
        }
      } catch (e: any) {
        if (!(e instanceof FetchFailure) || e.status !== 401) {
          context.reportCrashed({ error: e, state });
        }
        throw firebaseErrorHandler(e, () =>
          genericError({ message: e.message || 'ログインに失敗しました' })
        );
      }
    }
  };

export const createLoginWithFbRequest =
  ({ context, dispatch, state }: C) =>
  async (scopes: FacebookAuthScopes['scope']) => {
    try {
      const token = await context.fbService.getAccessToken({
        appId: staticConfig.facebook.appId,
        scope: scopes,
        shouldStartLoginProcess: true,
      });
      if (token) {
        const authEntity = await context.accountRepository.loginWithFacebook(token);
        dispatch(loginSucceeded({ authEntity }));
        dispatch(push(INITIAL_PAGE));
      } else {
        await context.fbService.logout();
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw firebaseErrorHandler(e, () => genericError({ message: 'ログインに失敗しました' }));
    }
  };

export const checkFirestoreConnectivity =
  ({ context, dispatch, state, ...a }: C) =>
  async () => {
    if (!conversationReadableRoles.has(getOperatorRole(state.auth.authEntity))) {
      return;
    }
    return context.firestoreService.measureConnectivity({
      tenantId: state.env.tenantId,
      operatorId: state.auth.authEntity!.claims.aim_user_id,
      onConnect() {
        dispatch(firestoreConnected());
      },
      onDisconnect() {
        dispatch(firestoreDisconnected());
      },
      onPingError(e) {
        context.reportCrashed({ error: e, state });
      },
      onReauth: async () => {
        await checkLoginState({ dispatch, context, state, ...a } as any)();
      },
    });
  };

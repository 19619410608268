/**
 * @fileOverview
 * @author
 */

import {
  fetchScenarioSucceeded,
  feedbackUpdated,
  edgeConnected,
  initialEdgeConnected,
  edgeDeleted,
  sceneSelected,
  messageAdded,
  messageDeleted,
  messageUpdated,
  textInputStateUpdated,
  sceneDeleted,
  quickRepliesAdded,
  quickRepliesDeleted,
  quickRepliesOrderChanged,
  quickRepliesTextUpdated,
  sceneAdded,
  userConfigSaved,
  queryScenarioEditorUnloaded,
  saveSucceeded,
  messageTypeChanged,
  imageUrlChanged,
  saveRequiredUpdated,
  scenarioStateCleaned,
  switchToOpUpdated,
  customFeedbackUpdated,
  warningDisplayed,
  parameterUpdated,
  integrationEndpointUpdated,
  integrationMethodUpdated,
  integrationConditionAdded,
  integrationConditionDeleted,
  integrationHeaderAdded,
  integrationHeaderDeleted,
  integrationHeaderUpdated,
  integrationConditionUpdated,
  parameterDeleted,
  sceneParameterUpdated,
  dimensionsUpdated,
  dimensionUpdated,
  toAddressUpdated,
  toAddressAdded,
  toAddressDeleted,
  replyToUpdated,
  subjectUpdated,
  bodyUpdated,
  carouselUpdated,
  fallbackUsagedUpdated,
} from '@c/modules/scenarioEditor/action';
import { ScenarioEditorService } from '@c/application/service/ScenarioEditorService';
import {
  ScenarioEditorUsingNamespaces,
  getInitialScenarioEditorCommonState,
  ScenarioEditorCommonState,
  SystemMessagesScenarioEditorDict,
} from '@c/state';
import produce from 'immer';
import { dynamciNamespaceIc } from '../dynamicNamespaceIC';
import { required } from '@s/assertions';
import { SceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { fetchSystemMessagesSucceeded } from '../systemMessages/action';

const invokeNamespaceIc = dynamciNamespaceIc<ScenarioEditorCommonState>();

type ActionTypes = ReturnType<
  | typeof fetchScenarioSucceeded
  | typeof feedbackUpdated
  | typeof switchToOpUpdated
  | typeof edgeConnected
  | typeof initialEdgeConnected
  | typeof edgeDeleted
  | typeof sceneSelected
  | typeof messageAdded
  | typeof messageDeleted
  | typeof messageUpdated
  | typeof textInputStateUpdated
  | typeof sceneDeleted
  | typeof quickRepliesAdded
  | typeof quickRepliesDeleted
  | typeof quickRepliesOrderChanged
  | typeof quickRepliesTextUpdated
  | typeof sceneAdded
  | typeof userConfigSaved
  | typeof queryScenarioEditorUnloaded
  | typeof dimensionUpdated
  | typeof dimensionsUpdated
  | typeof saveSucceeded
  | typeof messageTypeChanged
  | typeof imageUrlChanged
  | typeof saveRequiredUpdated
  | typeof scenarioStateCleaned
  | typeof fetchSystemMessagesSucceeded
  | typeof customFeedbackUpdated
  | typeof warningDisplayed
  | typeof parameterUpdated
  | typeof parameterDeleted
  | typeof sceneParameterUpdated
  | typeof integrationEndpointUpdated
  | typeof integrationMethodUpdated
  | typeof integrationHeaderUpdated
  | typeof integrationHeaderAdded
  | typeof integrationHeaderDeleted
  | typeof integrationConditionAdded
  | typeof integrationConditionUpdated
  | typeof integrationConditionDeleted
  | typeof toAddressUpdated
  | typeof toAddressAdded
  | typeof toAddressDeleted
  | typeof replyToUpdated
  | typeof subjectUpdated
  | typeof bodyUpdated
  | typeof carouselUpdated
  | typeof fallbackUsagedUpdated
>;

export default (service: ScenarioEditorService, namespace: ScenarioEditorUsingNamespaces) =>
  (state: any, action: ActionTypes) => {
    if (!(action as any)?.payload?.namespace || namespace !== action.payload.namespace) {
      return state;
    }

    return produce(state, (state: any) => {
      const commonState = invokeNamespaceIc({
        namespace: action.payload.namespace,
        state,
      });
      const updateScenesUpdatedFlag = (isUpdated = true) => {
        if (commonState.selectedScenario?.scenes) {
          (commonState.selectedScenario.scenes as any as SceneNodes).updated = isUpdated;
        }
        commonState.updated = isUpdated;
      };
      switch (action.type) {
        case 'DIMENSION_UPDATED':
          if (commonState.selectedScenario) {
            (commonState.selectedScenario.scenes.dimensions[action.payload.sceneId] as any) =
              action.payload.dimension;
          }
          return;
        case 'DIMENSIONS_UPDATED':
          if (commonState.selectedScenario) {
            (commonState.selectedScenario.scenes.dimensions as any) = action.payload.dimensions;
          }
          return;
        case 'SCENARIO_SAVE_SUCCEEDED':
          updateScenesUpdatedFlag(false);
          commonState.saveRequired = false;
          return;
        case 'FETCH_SCENARIO_SUCCEEDED':
          commonState.selectedScenario = {
            scenes: action.payload.scenario.scenario,
          };
          commonState.scenarioId = action.payload.scenario.scenarioId;
          commonState.errors = action.payload.scenario.errors;
          commonState.parameters = action.payload.scenario.scenario.parameters?.map(parameter => {
            return parameter;
          });
          return;
        case 'FEEDBACK_STATE_UPDATED': {
          const scenes = required(commonState.selectedScenario).scenes as any;
          scenes.feed = action.payload.isEnableFeedback;
          scenes.switchToOp = false;
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return;
        }
        case 'SWITCH_TO_OP_STATE_UPDATED':
          (required(commonState.selectedScenario).scenes as any).switchToOp =
            action.payload.isEnableSwitchToOp;
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return;
        case 'EDGE_CONNECTED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.connectEdge(commonState, action.payload);
        case 'EDGE_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteEdge(commonState, action.payload);
        case 'INITIAL_EDGE_CONNECTED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.connectInitialEdge(commonState, action.payload);
        case 'MESSAGE_UPDATED':
          updateScenesUpdatedFlag();
          return service.updateMessage(commonState, action.payload);
        case 'MESSAGE_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.addMessage(commonState, action.payload);
        case 'MESSAGE_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteMessage(commonState, action.payload);
        case 'SCENE_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.addScene(commonState, action.payload.option);
        case 'SCENE_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteScene(commonState, action.payload);
        case 'QUICKREPLIES_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.quickRepliesAdd(commonState, action.payload);
        case 'QUICKREPLIES_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.quickRepliesDelete(commonState, action.payload);
        case 'QUICKREPLIES_ORDER_CHANGED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.quickRepliesOrderChange(commonState, action.payload);
        case 'QUICKREPLIES_TEXT_UPDATED':
          updateScenesUpdatedFlag();
          return service.quickRepliesTextUpdate(commonState, action.payload);
        case 'SCENE_SELECTED':
          return service.selectScene(commonState, action.payload);
        case 'USER_CONFIG_SAVED':
          commonState.userConfig = action.payload;
          return;
        case 'QUERY_SCENARIO_EDITOR_UNLOADED':
          commonState.selectedScenario = undefined;
          return;
        case 'SCENE_MESSAGE_TYPE_CHANGED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.changeMessageType(commonState, action.payload);
        case 'SCENE_MESSAGE_IMAGE_URL_CHANGED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.imageUrlChange(commonState, action.payload);
        case 'SAVE_REQUIRED_UPDATED':
          commonState.saveRequired = action.payload.isSaveRequired;
          return;
        case 'SCENARIO_EDITOR_STATE_CLEANED':
          Object.assign(commonState, getInitialScenarioEditorCommonState());
          return;
        case 'FETCH_SYSTEM_MESSAGES_SUCCEEDED':
          state.systemMessages = action.payload.messages.reduce((acc, { value, name }) => {
            acc[name] = value;
            return acc;
          }, {} as SystemMessagesScenarioEditorDict);
          return;
        case 'CUSTOM_FEEDBACK_UPDATED': {
          service.updateCustomFeedback(state, action.payload.isFeedbackOn);
          commonState.updated = true;
          commonState.saveRequired = true;
          return;
        }
        case 'SCENARIO_EDITOR_WARNING_DISPLAYED':
          commonState.warnings[action.payload.warningType] = false;
          return;
        case 'PARAMETER_UPDATED': {
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateParameter(state, action.payload);
        }
        case 'PARAMETER_DELETED': {
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteParameter(state, action.payload);
        }
        case 'SCENE_PARAMETER_UPDATED': {
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateSceneParameter(state, action.payload);
        }
        case 'INTEGRATION_END_POINT_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateIntegrationEndpoint(commonState, action.payload);
        case 'INTEGRATION_METHOD_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateIntegrationMethod(commonState, action.payload);
        case 'INTEGRATION_HEADER_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.addIntegrationHeader(commonState, action.payload);
        case 'INTEGRATION_HEADER_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteIntegrationHeader(commonState, action.payload);
        case 'INTEGRATION_HEADER_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateIntegrationHeader(commonState, action.payload);
        case 'INTEGRATION_CONDITION_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.addIntegrationCondition(commonState, action.payload);
        case 'INTEGRATION_CONDITION_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateIntegrationCondition(commonState, action.payload);
        case 'INTEGRATION_CONDITION_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteIntegrationCondition(commonState, action.payload);
        case 'MAIL_ACTION_TO_ADDRESS_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateMailToAddress(commonState, action.payload);
        case 'MAIL_ACTION_TO_ADDRESS_ADDED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.addMailToAddress(commonState, action.payload);
        case 'MAIL_ACTION_TO_ADDRESS_DELETED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.deleteMailToAddress(commonState, action.payload);
        case 'MAIL_ACTION_REPLY_TO_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateReplyTo(commonState, action.payload);
        case 'MAIL_ACTION_SUBJECT_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateMailSubject(commonState, action.payload);
        case 'MAIL_ACTION_BODY_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateMailBody(commonState, action.payload);
        case 'MESSAGE_NODE_INPUT_STATE_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateTextInputState(commonState, action.payload);
        case 'CAROUSEL_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateCarousel(commonState, action.payload);
        case 'FALLBACK_USAGE_UPDATED':
          updateScenesUpdatedFlag();
          commonState.saveRequired = true;
          return service.updateFallbackUsage(
            commonState,
            action.payload.isEnabled,
            action.payload.projectType
          );
        default:
          const unusedCheck: never = action;
      }
    });
  };

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  fetchIgFixedPhraseSucceeded,
  updateIgFixedPhraseDraftStateSucceeded,
  updateIgFixedPhraseSucceeded,
  addIgFixedPhraseQuickReply,
  deleteIgFixedPhraseQuickReply,
  updateIgFixedPhraseQuickReplyOrder,
  updateIgFixedPhraseQuickReplyText,
} from '@c/modules/igFixedPhrase/action';
import { getTargetState } from '@c/domain/entities/IgFixedPhrase';

type ActionTypes =
  | ReturnType<typeof fetchIgFixedPhraseSucceeded>
  | ReturnType<typeof updateIgFixedPhraseSucceeded>
  | ReturnType<typeof updateIgFixedPhraseDraftStateSucceeded>
  | ReturnType<typeof addIgFixedPhraseQuickReply>
  | ReturnType<typeof deleteIgFixedPhraseQuickReply>
  | ReturnType<typeof updateIgFixedPhraseQuickReplyOrder>
  | ReturnType<typeof updateIgFixedPhraseQuickReplyText>;

export default (
  state: State['fixedPhrase'] = initialState.fixedPhrase,
  action: ActionTypes
): State['fixedPhrase'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_IG_FIXED_PHRASE_SUCCEEDED':
        state.fixedPhrases = action.payload;
        return state;
      case 'UPDATE_IG_FIXED_PHRASE_SUCCEEDED': {
        const i = state.fixedPhrases.findIndex(
          s => s.entryType === action.payload.igFixedPhrase.entryType
        );
        state.fixedPhrases[i] = action.payload.igFixedPhrase;
        return state;
      }
      case 'UPDATE_IG_FIXED_PHRASE_DRAFT_STATE_SUCCEEDED': {
        const i = state.fixedPhrases.findIndex(s => s.entryType === action.payload.entryType);
        state.fixedPhrases[i].draftState = action.payload.draftState;
        return state;
      }
      case 'ADD_IG_FIXED_PHRASE_QUICK_REPLY': {
        const targetState = getTargetState(state.fixedPhrases, action.payload);
        if (targetState)
          targetState.attachment.quickReplies = [
            ...(targetState.attachment.quickReplies ?? []),
            action.payload.quickReply,
          ];
        return state;
      }
      case 'DELETE_IG_FIXED_PHRASE_QUICK_REPLY': {
        const targetState = getTargetState(state.fixedPhrases, action.payload)?.attachment
          .quickReplies;
        if (targetState) {
          const i = targetState.findIndex(s => s.id === action.payload.quickRepliesId);
          targetState.splice(i, 1);
        }
        return state;
      }
      case 'UPDATE_IG_FIXED_PHRASE_QUICK_REPLY_ORDER': {
        const targetState = getTargetState(state.fixedPhrases, action.payload)?.attachment
          .quickReplies;
        if (targetState) {
          const i = targetState.findIndex(s => s.id === action.payload.quickRepliesId);
          const j = action.payload.isDown ? i + 1 : i - 1;
          if (targetState[i] && targetState[j])
            [targetState[i], targetState[j]] = [targetState[j], targetState[i]];
        }
        return state;
      }
      case 'UPDATE_IG_FIXED_PHRASE_QUICK_REPLY_TEXT': {
        const targetState = getTargetState(
          state.fixedPhrases,
          action.payload
        )?.attachment.quickReplies?.find(s => s.id === action.payload.quickRepliesId);
        if (targetState) targetState.targetId = action.payload.value;
        return state;
      }
      default:
        return state;
    }
  });
};

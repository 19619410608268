/**
 * @fileOverview
 * @name WidgetInspectorWindowRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { AppendonlyRepository } from '@aim/shared/src/Repository';
import { storage } from '@s/io/storage';

export type WidgetInspectorWindowAppendableRepository = AppendonlyRepository<
  { url: string; isOpened: boolean },
  void,
  { url?: string; isOpened?: boolean },
  void
>;

const KEY = '__AIM_INSPECTOR__';

export class WidgetInspectorWindowRepository implements WidgetInspectorWindowAppendableRepository {
  public async create(
    a: Parameters<WidgetInspectorWindowAppendableRepository['create']>[0]
  ): ReturnType<WidgetInspectorWindowAppendableRepository['create']> {
    const st = storage('local');
    st.set(KEY, a);
  }

  public async update(
    a: Parameters<WidgetInspectorWindowAppendableRepository['update']>[0]
  ): ReturnType<WidgetInspectorWindowAppendableRepository['update']> {
    const st = storage('local');
    const ret = st.get(KEY, {}, true);
    st.set(KEY, { ...ret, ...a });
  }
}

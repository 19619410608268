import { initialState, State } from '@c/state';
import produce from 'immer';
import { fetchCustomerDataExportSettingsSucceeded } from '@c/modules/dataExport/action';

type ActionTypes = ReturnType<typeof fetchCustomerDataExportSettingsSucceeded>;

export default (state: State['dataExport'] = initialState.dataExport, action: ActionTypes) => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_CUSTOMER_DATA_EXPORT_SETTINGS_SUCCEEDED': {
        state.customerSettings = action.payload;
        return state;
      }
      default: {
        return state;
      }
    }
  });
};

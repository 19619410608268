/**
 * @fileOverview
 * @name resetStyle.ts
 * @author Taketoshi Aono
 * @license
 */

import { css } from '@emotion/react';
import { regularTextStyle } from './Text';

export const resetStyle = css`
  /* CSS Remedy */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  html {
    line-sizing: normal;
  }
  body {
    margin: 0;
    font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo,
      'ＭＳ Ｐゴシック', sans-serif;
    ${regularTextStyle};
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.17rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.83rem;
  }
  h6 {
    font-size: 0.67rem;
  }
  h1 {
    margin: 0.67em 0;
  }
  pre {
    white-space: pre-wrap;
  }
  hr {
    border-style: solid;
    border-width: 1px 0 0;
    color: inherit;
    height: 0;
    overflow: visible;
  }
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display: block;
    vertical-align: middle;
    max-width: 100%;
  }
  canvas,
  img,
  svg,
  video {
    height: auto;
  }
  audio {
    width: 100%;
  }
  img {
    border-style: none;
  }
  svg {
    overflow: hidden;
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  button {
    outline: none;
    :focus {
      box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6);
    }
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
  textarea {
    font-family: inherit;
    font-size: inherit;
  }
`;

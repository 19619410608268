/**
 * @fileOverview
 * @name WidgetCustomizationQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from './interface';
import { fetchService, FetchFailure } from '@s/io/fetchService';
import { widgetConfigurationFile, widgetConfigurationFilePreview } from '@c/config';
import { defaultWidgetConfiguration, WidgetConfiguration } from '@s/domain/values';
import { WidgetCustomizationFile } from '@c/domain/entities/WidgetCustomization';

export type WidgetCustomizationGettableQuery = GettableQuery<
  { dedicatedServerName: string; projectId: string; file?: WidgetCustomizationFile },
  WidgetConfiguration
>;

export class WidgetCustomizationQuery implements WidgetCustomizationGettableQuery {
  public async get({
    dedicatedServerName,
    projectId,
    file = WidgetCustomizationFile.pc,
  }: {
    dedicatedServerName: string;
    projectId: string;
    file?: WidgetCustomizationFile;
  }): Promise<WidgetConfiguration> {
    try {
      const configuration = await fetchService<WidgetConfiguration>(
        widgetConfigurationFilePreview(dedicatedServerName, projectId, file),
        {
          method: 'GET',
          responseType: 'json',
        }
      );
      return configuration;
    } catch (e) {
      if (e instanceof FetchFailure) {
        try {
          return await fetchService<WidgetConfiguration>(
            widgetConfigurationFile(dedicatedServerName, projectId, file),
            {
              method: 'GET',
              responseType: 'json',
            }
          );
        } catch (e) {
          if (e instanceof FetchFailure) {
            if (file === WidgetCustomizationFile.sp) {
              return this.get({ dedicatedServerName, projectId, file: WidgetCustomizationFile.pc });
            }
            return defaultWidgetConfiguration;
          }
          throw e;
        }
      }
      throw e;
    }
  }
}

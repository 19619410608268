import { GettableQuery } from '@s/Query';
import { VoiceSetting } from '@c/repository/voice/VoiceSettingRepository';
import { storage } from '@s/io/storage';

export type VoiceSettingGettableQuery = GettableQuery<undefined, VoiceSetting | null>;

const KEY = '__AIM_VOICE_SETTING__';

export class VoiceSettingQuery implements VoiceSettingGettableQuery {
  public async get(): ReturnType<VoiceSettingGettableQuery['get']> {
    const st = storage('local');
    return st.get(KEY, null);
  }
}

/**
 * @fileOverview
 * @name reducer.ts
 * @author Taketoshi Aono
 * @license
 */

import { initialState } from '@c/state';
import {
  projectDeleted,
  projectUpdated,
  fetchProjectListSucceeded,
  getProjectDetailSucceeded,
  projectDetailReset,
  projectFiltered,
  instagramAccountListFetched,
  instagramAccountSelected,
  instagramAccountListResetted,
  facebookAppFetched,
  instagramAccountSettingFetched,
  lineSettingDeleted,
} from './action';
import produce from 'immer';
import { DEFAULT_LINEPROJECT_SETTING_ITEM } from '@c/domain/entities/Line';

type ActionType = ReturnType<
  | typeof projectDeleted
  | typeof projectUpdated
  | typeof fetchProjectListSucceeded
  | typeof getProjectDetailSucceeded
  | typeof projectDetailReset
  | typeof projectFiltered
  | typeof instagramAccountListFetched
  | typeof instagramAccountSelected
  | typeof instagramAccountListResetted
  | typeof facebookAppFetched
  | typeof instagramAccountSettingFetched
  | typeof lineSettingDeleted
>;
const PROJECT_TREAT_AS_SAME_TYPE_MAP: { [key: string]: boolean } = { instagram: true, chat: true };

export default (state = initialState['project'], action: ActionType) => {
  return produce(state, state => {
    switch (action.type) {
      case 'PROJECT_DELETED': {
        const index = state.list.findIndex(p => p.id === action.payload.id);
        state.list.splice(index, 1);
        return;
      }
      case 'PROJECT_UPDATED': {
        const index = state.list.findIndex(p => p.id === action.payload.id);
        state.list[index].displayName = action.payload.displayName;
        state.detail = action.payload;
        return;
      }
      case 'FETCH_PROJECT_LIST_SUCCEEDED':
        state.list = action.payload;
        if (state.list[0] && !state.list[0].displayName) {
          state.list[0].displayName = 'デフォルトプロジェクト';
        }
        return;
      case 'GET_PROJECT_DETAIL_SUCCEEDED':
        state.selectedFacebookApp = null;
        state.detail = action.payload;
        return;
      case 'PROJECT_DETAIL_RESET':
        state.selectedFacebookApp = null;
        state.detail = undefined;
        return;
      case 'PROJECT_FILTERED':
        if (action.payload.type) {
          state.filtered = state.list.filter(p => {
            if (PROJECT_TREAT_AS_SAME_TYPE_MAP[action.payload.type]) {
              return PROJECT_TREAT_AS_SAME_TYPE_MAP[p.type];
            }
            return action.payload.type === p.type;
          });
        } else {
          state.filtered = state.list;
        }
        return;
      case 'INSTAGRAM_ACCOUNT_LIST_FETCHED':
        state.instagramAccountList = action.payload;
        state.isInstagramAccountListFetched = true;
        return;
      case 'INSTAGRAM_ACCOUNT_SELECTED':
        state.selectedInstagramAccount = action.payload;
        return;
      case 'INSTAGRAM_ACCOUNT_LIST_RESETTED':
        state.instagramAccountList = { list: [] };
        state.isInstagramAccountListFetched = false;
        return;
      case 'FACEBOOK_APP_FETCHED':
        state.selectedFacebookApp = action.payload;
        return;
      case 'INSTAGRAM_ACCOUNT_SETTING_FETCHED':
        if (state.detail?.instagramSetting) {
          if (action.payload) {
            state.detail.instagramSetting.previewApp = action.payload.previewApp;
          } else {
            state.detail.instagramSetting.previewApp = null;
          }
        }
        return;
      case 'LINE_SETTING_DELETED':
        if (state.detail?.lineSetting) {
          if (!action.payload.isPreview) {
            state.detail.lineSetting.channel = { ...DEFAULT_LINEPROJECT_SETTING_ITEM };
          } else {
            state.detail.lineSetting.previewChannel = undefined;
          }
        }
        break;
      default:
        const unusedCheck: never = action;
    }
  });
};

/**
 * @fileOverview
 * @name WidgetConfigurationRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@s/Repository';
import { WidgetConfigurations } from '@c/state';

export type WidgetCustomizationUpdatableRepository = UpdatableRepository<
  { configuration: WidgetConfigurations; projectId: string },
  {}
>;

export class WidgetCustomizationRepository implements WidgetCustomizationUpdatableRepository {
  public async update({
    configuration,
    projectId,
  }: Parameters<WidgetCustomizationUpdatableRepository['update']>[0]): ReturnType<
    WidgetCustomizationUpdatableRepository['update']
  > {
    const formData = new FormData();
    const pc = new Blob([JSON.stringify(configuration.pc)], { type: 'application/json' });
    const sp = new Blob([JSON.stringify(configuration.sp)], { type: 'application/json' });
    formData.append('configuration', pc);
    formData.append('configuration-sp', sp);
    return fetchService(apiEndpoint(`project/${projectId}/widget/configuration`), {
      method: 'POST',
      responseType: 'json',
      formData: formData,
    });
  }
}

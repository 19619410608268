/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  getUsersSucceeded,
  inviteUserSucceeded,
  fetchOperationHistoriesSucceeded,
  operatorToggleSucceeded,
  disabledOperatorFilterToggled,
} from '@c/modules/users/action';

type ActionTypes = ReturnType<
  | typeof getUsersSucceeded
  | typeof inviteUserSucceeded
  | typeof fetchOperationHistoriesSucceeded
  | typeof operatorToggleSucceeded
  | typeof disabledOperatorFilterToggled
>;

export default (
  state: State['users'] = initialState.users,
  action: ActionTypes
): State['users'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'GET_USERS_SUCCEEDED':
        state.operators = action.payload;
        return;
      case 'INVITE_USER_SUCCEEDED':
        return state;
      case 'FETCH_OPERATION_HISTORIES_SUCCEEDED':
        state.histories = action.payload;
        return;
      case 'OPERATOR_TOGGLE_SUCCEEDED': {
        const operator = state.operators.find(v => v.id === action.payload.operatorId);
        if (operator) {
          operator.disabled = action.payload.isDisabled;
        }
        return;
      }
      case 'DISABLED_OPERATOR_FILTER_TOGGLED':
        state.shouldDisplayDisabledUsers = action.payload.shouldDisplay;
        return;
      default:
        return state;
    }
  });
};

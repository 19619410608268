import produce from 'immer';
import { fetchFixedMessageSucceeded, updateFixedMessageSucceeded } from './action';
import { initialState, State } from '@c/state';

type ActionTypes =
  | ReturnType<typeof fetchFixedMessageSucceeded>
  | ReturnType<typeof updateFixedMessageSucceeded>;

export default (
  state: State['fixedMessage'] = initialState.fixedMessage,
  action: ActionTypes
): State['fixedMessage'] => {
  return produce(state, _ => {
    switch (action.type) {
      case 'FETCH_FIXED_MESSAGE_SUCCEEDED':
        return action.payload;
      case 'UPDATE_FIXED_MESSAGE_SUCCEEDED':
        return action.payload;
      default:
        return;
    }
  });
};

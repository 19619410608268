import { GettableQuery } from './interface';
import { CustomerDataExportSettings } from '@c/domain/entities/CustomerDataExport';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { ProjectId } from '@c/domain/entities/ProjectId';

export type CustomerDataExportSettingsGettableQuery = GettableQuery<
  { projectId?: ProjectId },
  CustomerDataExportSettings
>;

export class CustomerDataExportSettingsQuery implements CustomerDataExportSettingsGettableQuery {
  public async get({
    projectId,
  }: Parameters<CustomerDataExportSettingsGettableQuery['get']>[0] = {}): ReturnType<
    CustomerDataExportSettingsGettableQuery['get']
  > {
    const url =
      projectId !== undefined
        ? `/data-export/history/list?project_id=${projectId}`
        : `/data-export/history/list`;
    const ret = await fetchService<CustomerDataExportSettings>(apiEndpoint(url), {
      method: 'GET',
    });
    return ret;
  }
}

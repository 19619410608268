/**
 * @fileOverview
 * @author Mana Horie
 */

import { MediaEntity } from '@s/domain/entity/Media';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';

export type MediaStorageGettableQuery = GettableQuery<undefined, MediaEntity[]>;

export class MediaStorageQuery implements MediaStorageGettableQuery {
  public async get(): ReturnType<MediaStorageGettableQuery['get']> {
    const ret = await fetchService<MediaEntity[]>(apiEndpoint(`media/list`), {
      method: 'GET',
    });
    return ret;
  }
}

/**
 * @fileOverview
 * @name AiCompassAnswersQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  RemoteResolutionTrainingList,
  ResolutionTraining,
} from '@c/domain/entities/ResolutionTrainingList';
import { fetchService } from '@s/io/fetchService';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { GettableQuery } from '@c/query/interface';

type ResolutionTrainingParam = {
  projectId: ProjectId;
  categoryId: number;
};
export type ResolutionTrainingListGettableQuery = GettableQuery<
  ResolutionTrainingParam,
  ResolutionTraining[]
>;

export class ResolutionTrainingListQuery implements ResolutionTrainingListGettableQuery {
  public async get({
    projectId,
    categoryId,
  }: ResolutionTrainingParam): ReturnType<ResolutionTrainingListGettableQuery['get']> {
    const ret = await fetchService<RemoteResolutionTrainingList>(
      apiEndpoint(`/project/${projectId}/resolution-training/inquiry/${categoryId}/list`),
      {
        method: 'GET',
      }
    );
    return ret.inquiries;
  }
}

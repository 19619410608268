/**
 * @fileoverview
 * @author
 */

import {
  fetchResolutionTrainingListSucceeded,
  categorySelected,
  resolutionTrainingStateCleaned,
  markAsOperated,
  saveResolutionSucceeded,
  analyzedScenarioRatioTypeChanged,
  analyzedScenarioDetailNumberDisplayChanged,
} from '@c/modules/aicompassAnswers/action';
import { State, initialState } from '@c/state';
import produce from 'immer';

type ActionTypes = ReturnType<
  | typeof fetchResolutionTrainingListSucceeded
  | typeof categorySelected
  | typeof resolutionTrainingStateCleaned
  | typeof markAsOperated
  | typeof saveResolutionSucceeded
  | typeof analyzedScenarioRatioTypeChanged
  | typeof analyzedScenarioDetailNumberDisplayChanged
>;

export default (state: State['aicompass'] = initialState.aicompass, action: ActionTypes) => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_RESOLUTION_TRAINING_LIST_SUCCEEDED':
        state.resolutionTraining.answerList[state.resolutionTraining.selectedCategory.id] =
          action.payload;
        state.resolutionTraining.saveRequired = false;
        return;
      case 'AICOMPASS_CATEGORY_SELECTED':
        state.resolutionTraining.selectedCategory = action.payload.category;
        state.resolutionTraining.selectedScenario = undefined;
        return;
      case 'RESOLUTION_TRAINING_STATE_CLEANED':
        state.resolutionTraining.answerList = {};
        return;
      case 'SAVE_RESOLUTION_TRAINING_SUCCEEDED':
        state.resolutionTraining.saveRequired = false;
        state.resolutionTraining.updated = false;
        return;
      case 'AICOMPASS_RESOLUTION_TRAINING_MARK_AS_OPERATED':
        {
          if (state.resolutionTraining.selectedScenario) {
            const scenarioId = state.resolutionTraining.selectedScenario.scenes.id;
            const list =
              state.resolutionTraining.answerList[state.resolutionTraining.selectedCategory.id];
            if (list) {
              const found = list.find(v => v.scenarioId === scenarioId);
              if (found) {
                found.operated = true;
              }
            }
          }
        }
        return;
      case 'ANALYZED_SCENARIO_RATIO_TYPE_CHANGED':
        state.resolutionTraining.ratioType = action.payload.type;
        return;
      case 'ANALYZED_SCENARIO_DETAIL_NUMBER_DISPLAY_CHANGED':
        state.resolutionTraining.shouldDisplayDetailNumbers = action.payload;
        return;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return state;
    }
  });
};

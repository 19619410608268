import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { useRefState } from '@s/reactHooks';
import { largeTextStyle, FONT_SIZE_SMALL } from '@s/components/atom/Text';
import { Flex } from '@s/components/atom/Box';
import styled from '@emotion/styled';
import { Card, CardHeader, CardTitle, CardContent } from '@c/shared/components/Card';
import { motion } from 'framer-motion';
import { scrollbarStyle } from '@c/components/atom/scrollbarStyle';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { css } from '@emotion/react';
import { ConcentrationEdit } from '@c/components/molecule/ConcentrationEdit';
import { SaveButton } from '@c/components/atom/Button';
import { OnedayScheduler, formatDate } from '@c/components/organism/OnedayScheduler';
import { State } from '@c/state';
import { Tooltip } from '@c/components/atom/Tooltip';
import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';
import { BusinessHourParameter } from '@c/domain/values/BusinessHourParameter';
import { BusinessHourSpecificationValidator } from '@c/domain/specification/BusinessHourSpecification';
import 'react-calendar/dist/Calendar.css';

const BusinessHourSettingCardContent = styled(CardContent)`
  ${scrollbarStyle};
  height: calc(100% - 72px);
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ItemElement = styled(motion.li)`
  position: relative;
  padding: 0 30px;
  :not(:last-of-type) {
    margin-bottom: 10px;
    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      width: 50%;
      min-width: 100px;
      height: 4px;
      background: #fff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) inset;
      left: 25%;
    }
  }
`;

const SectionTitle = styled.h3`
  ${largeTextStyle};
  font-weight: 100;
`;

export const calendarStyle = css`
  .react-calendar {
    width: 420px;
    background: rgb(245, 247, 249);
    border-radius: 8px;
  }
  .react-calendar,
  .react-calendar abbr,
  .react-calendar button,
  .react-calendar div {
    font-size: ${FONT_SIZE_SMALL};
    font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo,
      'ＭＳ Ｐゴシック', sans-serif;
  }
  .react-calendar button {
    border: 0 !important;
  }
  .react-calendar__navigation {
    margin: 0;
    height: 45px;
  }
  .react-calendar__navigation button[disabled] {
    background: none;
  }
  .react-calendar__navigation__label[disabled] {
    color: #000000;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration-line: none;
  }
  .react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth):not(.react-calendar__tile--active):enabled {
    color: #000000;
  }
  .react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth)[disabled] {
    color: rgba(16, 16, 16, 0.3);
  }
  .react-calendar__tile--now {
    background: none;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #e6e6e6;
  }
  .calendar-tile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0;
    flex-direction: column;
    position: relative;
  }
  .calendar-tile.holiday:not(.react-calendar__tile--active):enabled {
    color: #d10000;
  }
  .calendar-tile.set:not(.react-calendar__tile--active):enabled {
    background: #aaeeff;
  }
  .calendar-tile.set:not(.react-calendar__tile--active):enabled:hover,
  .calendar-tile.set:not(.react-calendar__tile--active):enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--active {
    background: #99ddff;
    color: black;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
`;

export const BusinessHourSetting = compareOnlyProperties(
  ({
    isBusinessHourScheduleLoading,
    onAddBusinessHour,
    onDeleteBusinessHour,
    onChangeStartAtHours,
    onChangeStartAtMinutes,
    onChangeEndAtHours,
    onChangeEndAtMinutes,
    onAddDefaultBusinessHour,
    onDeleteDefaultBusinessHour,
    onChangeDefaultStartAtHours,
    onChangeDefaultStartAtMinutes,
    onChangeDefaultEndAtHours,
    onChangeDefaultEndAtMinutes,
    onAddHolidayBusinessHour,
    onDeleteHolidayBusinessHour,
    onChangeHolidayStartAtHours,
    onChangeHolidayStartAtMinutes,
    onChangeHolidayEndAtHours,
    onChangeHolidayEndAtMinutes,
    initializeIndividualBusinessHour,
    saveBusinessHourSchedule,
  }: {
    isBusinessHourScheduleLoading: boolean;
    onAddBusinessHour: (individualDateString: string) => void;
    onDeleteBusinessHour: (individualDateString: string, index: number) => void;
    onChangeStartAtHours: (individualDateString: string, index: number, hours: string) => void;
    onChangeStartAtMinutes: (individualDateString: string, index: number, minutes: string) => void;
    onChangeEndAtHours: (individualDateString: string, index: number, hours: string) => void;
    onChangeEndAtMinutes: (individualDateString: string, index: number, minutes: string) => void;
    onAddDefaultBusinessHour: () => void;
    onDeleteDefaultBusinessHour: (index: number) => void;
    onChangeDefaultStartAtHours: (index: number, hours: string) => void;
    onChangeDefaultStartAtMinutes: (index: number, minutes: string) => void;
    onChangeDefaultEndAtHours: (index: number, hours: string) => void;
    onChangeDefaultEndAtMinutes: (index: number, minutes: string) => void;
    onAddHolidayBusinessHour: () => void;
    onDeleteHolidayBusinessHour: (index: number) => void;
    onChangeHolidayStartAtHours: (index: number, hours: string) => void;
    onChangeHolidayStartAtMinutes: (index: number, minutes: string) => void;
    onChangeHolidayEndAtHours: (index: number, hours: string) => void;
    onChangeHolidayEndAtMinutes: (index: number, minutes: string) => void;
    initializeIndividualBusinessHour: (payload: {
      date: string;
      entity: BusinessHourEntity[];
    }) => void;
    saveBusinessHourSchedule: (payload: { date: string; parameter: BusinessHourParameter }) => void;
  }) => {
    const businessHourState = useSelector((a: State) => a.businessHour);
    const [popupVisibility, setPopupVisibility] = useRefState<boolean>(false);
    const [individualDate, setIndivisualDate] = useRefState<Date>(new Date());
    const individualDateString = formatDate(individualDate.current);

    useEffect(() => {
      if (businessHourState.saveRequiredSchedule) {
        saveBusinessHourSchedule({
          date: individualDateString,
          parameter: new BusinessHourParameter(
            businessHourState.schedule[individualDateString].entity,
            new BusinessHourSpecificationValidator()
          ),
        });
      }
    }, [businessHourState.saveRequiredSchedule]);

    return (
      <>
        <ConcentrationEdit
          css={{ zIndex: 10 }}
          visible={popupVisibility.current}
          useModalBackdrop={true}
          isCloseOnclickOutside={false}
          onClose={() => setPopupVisibility(false)}
          title={`${individualDate.current.getFullYear()}年
        ${individualDate.current.getMonth() + 1}
          月${individualDate.current.getDate()}日`}
        >
          <>
            <div css={{ minWidth: 800, marginBottom: '10px' }}>
              <OnedayScheduler
                date={individualDateString}
                entity={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  businessHourState.schedule[individualDateString] !== undefined
                    ? businessHourState.schedule[individualDateString].entity
                    : []
                }
                loading={isBusinessHourScheduleLoading}
                onAddBusinessHour={() => {
                  onAddBusinessHour(individualDateString);
                }}
                onDeleteBusinessHour={({ index }) => {
                  onDeleteBusinessHour(individualDateString, index);
                }}
                onChangeStartAtHours={({ index, hours }) => {
                  onChangeStartAtHours(individualDateString, index, hours);
                }}
                onChangeStartAtMinutes={({ index, minutes }) => {
                  onChangeStartAtMinutes(individualDateString, index, minutes);
                }}
                onChangeEndAtHours={({ index, hours }) => {
                  onChangeEndAtHours(individualDateString, index, hours);
                }}
                onChangeEndAtMinutes={({ index, minutes }) => {
                  onChangeEndAtMinutes(individualDateString, index, minutes);
                }}
              />
            </div>
            <Flex justifyContent="flex-end">
              <div css={{ marginLeft: '10px' }}>
                <SaveButton onClick={() => setPopupVisibility(false)} label="完了" />
              </div>
            </Flex>
          </>
        </ConcentrationEdit>
        <Card
          title="営業時間を設定"
          css={{
            minWidth: '800px',
            minHeight: '500px',
            width: 'calc(100% - 70px)',
            height: 'calc(100vh - 120px)',
            maxHeight: '1000px',
          }}
        >
          <CardHeader>
            <CardTitle>営業時間を設定</CardTitle>
          </CardHeader>

          <BusinessHourSettingCardContent>
            <ItemElement>
              <SectionTitle>デフォルトの営業時間設定</SectionTitle>
              <OnedayScheduler
                date="weekly"
                entity={businessHourState.schedule.weekly.entity}
                loading={isBusinessHourScheduleLoading}
                onAddBusinessHour={() => {
                  onAddDefaultBusinessHour();
                }}
                onDeleteBusinessHour={({ index }) => {
                  onDeleteDefaultBusinessHour(index);
                }}
                onChangeStartAtHours={({ index, hours }) => {
                  onChangeDefaultStartAtHours(index, hours);
                }}
                onChangeStartAtMinutes={({ index, minutes }) => {
                  onChangeDefaultStartAtMinutes(index, minutes);
                }}
                onChangeEndAtHours={({ index, hours }) => {
                  onChangeDefaultEndAtHours(index, hours);
                }}
                onChangeEndAtMinutes={({ index, minutes }) => {
                  onChangeDefaultEndAtMinutes(index, minutes);
                }}
              />
            </ItemElement>
            <ItemElement>
              <SectionTitle>土日・祝日の営業時間設定</SectionTitle>
              <OnedayScheduler
                date="holiday"
                entity={businessHourState.schedule.holiday.entity}
                loading={isBusinessHourScheduleLoading}
                onAddBusinessHour={() => {
                  onAddHolidayBusinessHour();
                }}
                onDeleteBusinessHour={({ index }) => {
                  onDeleteHolidayBusinessHour(index);
                }}
                onChangeStartAtHours={({ index, hours }) => {
                  onChangeHolidayStartAtHours(index, hours);
                }}
                onChangeStartAtMinutes={({ index, minutes }) => {
                  onChangeHolidayStartAtMinutes(index, minutes);
                }}
                onChangeEndAtHours={({ index, hours }) => {
                  onChangeHolidayEndAtHours(index, hours);
                }}
                onChangeEndAtMinutes={({ index, minutes }) => {
                  onChangeHolidayEndAtMinutes(index, minutes);
                }}
              />
            </ItemElement>
            <ItemElement css={{ marginBottom: '10px' }}>
              <SectionTitle>
                カレンダーから営業時間を登録(デフォルト・土日・祝日の設定を上書きします)
              </SectionTitle>
              <Calendar
                value={individualDate.current}
                maxDetail="month"
                minDetail="month"
                onChange={(v: Date[]) => {
                  if (!Array.isArray(v)) {
                    setIndivisualDate(v);
                    if (businessHourState.schedule[formatDate(v)].weekday !== -1) {
                      initializeIndividualBusinessHour({
                        date: formatDate(v),
                        entity: [0, 6, 7].includes(
                          businessHourState.schedule[formatDate(v)].weekday ?? -1
                        )
                          ? businessHourState.schedule.holiday.entity
                          : businessHourState.schedule.weekly.entity,
                      });
                    }
                    setPopupVisibility(true);
                  }
                }}
                locale="ja-JP"
                minDate={new Date()}
                maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0)}
                tileClassName={({ date, view }) => {
                  if (view !== 'month') {
                    return '';
                  } else {
                    const day = formatDate(date);
                    const className = ['calendar-tile'];
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (businessHourState.schedule[day]?.publicHoliday) {
                      className.push('holiday');
                    }
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (businessHourState.schedule[day]?.weekday === -1) {
                      className.push('set');
                    }
                    return className;
                  }
                }}
                tileContent={({ date, view }) => {
                  if (view !== 'month') {
                    return null;
                  } else {
                    const day = formatDate(date);
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    return businessHourState.schedule[day]?.publicHoliday ? (
                      <Tooltip
                        label={businessHourState.schedule[day].publicHoliday ?? ''}
                        delay={100}
                      >
                        <div
                          css={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Tooltip>
                    ) : null;
                  }
                }}
              />
            </ItemElement>
          </BusinessHourSettingCardContent>
        </Card>
      </>
    );
  },
  'BusinessHourSetting'
);

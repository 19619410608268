import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  addCarouselSucceeded,
  fetchCarouselsSucceeded,
  deleteCarouselSucceeded,
  updateCarouselSucceeded,
} from '@c/modules/carousel/action';

type ActionTypes = ReturnType<
  | typeof addCarouselSucceeded
  | typeof fetchCarouselsSucceeded
  | typeof deleteCarouselSucceeded
  | typeof updateCarouselSucceeded
>;

export default (
  state: State['carousel'] = initialState.carousel,
  action: ActionTypes
): State['carousel'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_CAROUSELS_SUCCEEDED':
        return action.payload;
      case 'ADD_CAROUSEL_SUCCEEDED':
        state.push(action.payload);
        return;
      case 'UPDATE_CAROUSEL_SUCCEEDED':
        for (const [i, x] of state.entries()) {
          if (x.id === action.payload.id) {
            state[i] = action.payload;
          }
        }
        return;
      case 'DELETE_CAROUSEL_SUCCEEDED':
        const index = state.findIndex(t => t.id === action.payload);
        if (index > -1) {
          state.splice(index, 1);
        }
        return;
      default:
        const unusedCheck: never = action;
        return;
    }
  });
};

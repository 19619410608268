/**
 * @fileOverview
 * @name DebuggerWindowConnectorService.ts
 * @author Taketoshi Aono
 * @license
 */

import { QUERY_PARAMETER_KEY } from '@s/debugger/queryparameter';
import { EventDispatcher } from '@s/EventDispacher';
import {
  DebuggerConnection,
  MessageEventTarget,
  DebuggerConnectable,
  DebuggerConnectionParameters,
  WindowEventBus,
} from '@s/debugger/DebuggerConnector';
import { WidgetSendableEventType, ConsoleSendableEventType } from '@s/debugger/EventType';
import { State } from '@c/state';

type EventType =
  | 'connect'
  | 'disconnect'
  | 'notfound'
  | 'open'
  | 'inspector-receive'
  | 'inspector-option';

export type DebuggerWindowConnectionServiceEventType = EventType;

export interface DebuggerWindowConnectable {
  connect(a: {
    url: string;
    windowObject?: MessageEventTarget & {
      open(url: string, name: string, param: string): MessageEventTarget | null;
    };
    initDebuggerConnection?(a: DebuggerConnectionParameters): DebuggerConnectable;
    connect?(
      a: DebuggerConnectable
    ): Promise<WindowEventBus<WidgetSendableEventType, ConsoleSendableEventType>>;
  }): Promise<void>;
  close(): void;
  on(type: EventType, handler: (...args: any) => any): void;
}

const WINDOW_NAME = 'inspector';

export class DebuggerWindowConnectionService implements DebuggerWindowConnectable {
  private connection?: DebuggerConnectable;
  private readonly eventDispatcher = new EventDispatcher<EventType>();

  public close() {
    if (this.connection) {
      this.connection.subject.close();
      this.connection.close();
    }
    this.connection = undefined;
    this.eventDispatcher.off();
  }

  public async connect({
    url,
    windowObject = window,
    initDebuggerConnection = a => new DebuggerConnection(a),
    connect = async c => c.connect(),
  }: Parameters<DebuggerWindowConnectable['connect']>[0]): Promise<void> {
    if (this.connection) {
      this.connection.close();
    }
    const urlObject = new URL(url);
    urlObject.searchParams.append(QUERY_PARAMETER_KEY, '1');
    const win = windowObject.open(
      urlObject.href,
      WINDOW_NAME,
      'menubar=no,location=no,resize=yes,scrollbars=yes,status=yes'
    );
    if (win) {
      this.eventDispatcher.dispatch('open', url);

      const connection = (this.connection = initDebuggerConnection({
        isOpener: true,
        self: windowObject,
        subject: win,
        origin: urlObject.origin,
      }));
      connection.onFailToConnect = () => {
        this.eventDispatcher.dispatch('notfound');
      };
      connection.onConnect = async () => {
        this.eventDispatcher.dispatch('connect');
      };
      connection.onDisconnect = async () => {
        this.eventDispatcher.dispatch('disconnect');
      };
      const eventBus = await connect(connection);
      eventBus.on('checked', e => {
        this.eventDispatcher.dispatch('inspector-receive', e);
      });
      eventBus.on('option', event => {
        const url = new URL(event.data.payload.url);
        url.searchParams.delete(QUERY_PARAMETER_KEY);
        this.eventDispatcher.dispatch('inspector-option', {
          send: (args: State['preview'] | null) => {
            eventBus.send('option', args);
            eventBus.send('check');
          },
          url: url.href,
        });
      });
    }
  }

  public on(type: EventType, handler: (...args: any) => any): void {
    this.eventDispatcher.on(type, handler);
  }
}

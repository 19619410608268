/**
 * @fileOverview
 * @author Taketoshi Aono
 * @license
 */

import { v4 } from 'uuid';
import { TENANT_NAME } from '@c/config';
import { CSVWorkerServiceParameter } from './CSVWorker.type';
import { remoteJsonNodesTocsvNodes } from '@c/adapter/scenarioCsvNodesAdapter';
import { ScenarioCSVGettableQuery } from '@c/query/ScenarioCSVQuery';
const query = new ScenarioCSVGettableQuery(remoteJsonNodesTocsvNodes);

export interface CSVWorkerService {
  createCSV(payload: CSVWorkerServiceParameter): void;
}

export class CSVWorkerService {
  private promiseMap = new Map<
    string,
    { onSucceeded(ret: string): void; onFailed(e: string): void }
  >();

  // オリジンを含めたフルパスを生成する
  private createFullUrl(relativeUrl: string) {
    if (process.env.NODE_ENV !== 'production') {
      return `${self.location.origin}/dev-console/${relativeUrl}`;
    }

    return `${self.location.origin}/${relativeUrl}`;
  }

  public async createCSV({ inquiries, projectId }: CSVWorkerServiceParameter) {
    return new Promise<string>(async (resolve, reject) => {
      const id = v4();
      this.promiseMap.set(id, { onSucceeded: resolve, onFailed: reject });

      const payload = {
        id,
        inquiries: inquiries.map(scenario => {
          return {
            endpoint: this.createFullUrl(`/project/${projectId}/scenario/${scenario.scenarioId}`),
            summaryText: scenario.inquiry,
            scenarioId: scenario.scenarioId,
            projectId,
            tenantName: TENANT_NAME,
          };
        }),
      };

      try {
        const result = await query.get(payload);
        return this.promiseMap.get(id)?.onSucceeded?.(result);
      } catch (e: any) {
        return this.promiseMap.get(id)?.onFailed?.(e.message);
      }
    });
  }
}

import { staticConfig } from '@c/config';
import { createClient, EntryFieldTypes } from 'contentful';

const CONTENT_TYPE = 'notification';

type NotificationSkeleton = {
  contentTypeId: typeof CONTENT_TYPE;
  fields: {
    title: EntryFieldTypes.Text;
    description: EntryFieldTypes.Text;
    content: EntryFieldTypes.Text;
    expiry: EntryFieldTypes.Date;
    pdf: {
      fields: {
        title: string;
        description: string;
        file: {
          url: string;
          fileName: string;
        };
      };
    };
  };
};

export const contentfulService = {
  async getNotifications() {
    const client = createClient({
      space: staticConfig.contentful.spaceID,
      accessToken: staticConfig.contentful.accessKey,
      host: import.meta.env.VITE_ENV === 'stage' ? 'preview.contentful.com' : 'cdn.contentful.com',
    });

    const res = await client.getEntries<NotificationSkeleton>({
      content_type: CONTENT_TYPE,
    });

    return res.items;
  },
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useEffect, useRef, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from '@c/components/molecule/Card';
import { css } from '@emotion/react';
import useOnClickOutside from 'use-onclickoutside';
import { setRefedElement } from '@s/components/atom/domUtils';
import { blurEffectStyle } from '@s/components/atom/blurEffectStyle';
import { focusNthFocusableChild } from '@s/components/atom/tabControl';
import { useRefState } from '@aim/shared/src/reactHooks';

const ConcentrationEditModalElement = styled(motion.div)<{
  useModalBackdrop?: boolean;
}>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  ${p =>
    p.useModalBackdrop
      ? blurEffectStyle('#000', 'rgba(0, 0, 0, 0.6)')
      : css`
          background: rgba(0, 0, 0, 0.6);
        `};
  z-index: 998;
`;

const ConcentrationEditContainerElement = styled.section`
  position: absolute;
  z-index: 999;
  transition: left 0.2s, top 0.2s;
  transform-origin: 0px 0px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #00ccff;
  }
`;

const getPosition = ({ target, root }: { target: HTMLElement; root: HTMLElement }) => {
  const rect = target.getBoundingClientRect();
  const yMax = window.innerHeight;
  const xMax = window.innerWidth;
  const rootRect = root.getBoundingClientRect();
  let left = rect.left - target.scrollLeft;
  let top = rect.top - target.scrollTop;
  let isXReverted = false;
  let isYReverted = false;
  if (xMax - (left + rootRect.width) < 40) {
    left = xMax - rootRect.width - 40;
    isXReverted = true;
  }
  if (yMax - (top + rootRect.height) < 40) {
    top = yMax - rootRect.height - 40;
    isYReverted = true;
  }

  return {
    isXReverted,
    isYReverted,
    style: {
      left,
      top,
      display: 'block',
    },
  };
};

const useFocusControl = ({
  visible,
  firstControlRef,
  rootRef,
}: {
  visible: boolean;
  firstControlRef?: React.RefObject<HTMLElement>;
  rootRef: React.RefObject<HTMLElement>;
}) => {
  const previousActive = useRef<Element | null>(null);
  const focusFirstChild = () => {
    if (firstControlRef && firstControlRef.current) {
      firstControlRef.current.focus();
    } else if (rootRef.current) {
      focusNthFocusableChild(rootRef.current, 1);
    }
  };
  useEffect(() => {
    const focus = () => {
      if (visible) {
        previousActive.current = document.activeElement;
        focusFirstChild();
      } else {
        if (previousActive.current) {
          const el = previousActive.current as any;
          if (el.focus) {
            el.focus();
          } else {
            window.focus();
          }
        } else {
          window.focus();
        }
      }
    };
    setTimeout(focus, 300);
  }, [visible, firstControlRef ? firstControlRef.current : false]);

  return [focusFirstChild, () => rootRef.current && focusNthFocusableChild(rootRef.current, -2)];
};

const usePositionTrucker = ({
  visible,
  targetRef,
  rootRef,
}: {
  visible: boolean;
  targetRef?: React.RefObject<HTMLElement>;
  rootRef: React.RefObject<HTMLElement>;
}) => {
  const [position, updatePosition] = useRefState({
    style: { left: 0, top: 0, display: 'none' },
    isYReverted: false,
    isXReverted: false,
  });
  useEffect(() => {
    const update = () => {
      if (targetRef) {
        if (visible && targetRef.current && rootRef.current) {
          updatePosition(getPosition({ target: targetRef.current, root: rootRef.current }));
        }
      } else {
        updatePosition({
          isXReverted: false,
          isYReverted: false,
          style: {
            position: 'fixed',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            top: '50%',
            display: 'block',
          } as any,
        });
      }
    };
    let i: any;
    if (rootRef.current) {
      rootRef.current.style.transition = 'none';
      update();
      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.style.transition = '';
        }
        i = setInterval(update, 200);
      }, 500);
    }
    return () => clearInterval(i);
  }, [targetRef ? targetRef.current : false, visible, rootRef.current]);

  return position;
};

const ConcentRationEditBodyElement = styled.div<{ maxHeight: string }>`
  padding: 20px;
  max-height: ${p => p.maxHeight};
  overflow-y: ${p => (p.maxHeight !== 'auto' ? 'auto' : 'hidden')};
`;

export const ConcentrationEdit = forwardRef(
  (
    {
      visible,
      children,
      targetRef,
      firstControlRef,
      title = '',
      titleElement,
      label = '',
      maxWidth = 'auto',
      maxHeight = 'auto',
      isCloseOnclickOutside = false,
      useModalBackdrop,
      useModal = true,
      className = '',
      buttons = null,
      onClose,
    }: {
      visible: boolean;
      children:
        | React.ReactNode
        | ((a: { isXReverted: boolean; isYReverted: boolean }) => React.ReactElement);
      isCloseOnclickOutside?: boolean;
      useModalBackdrop?: boolean;
      useModal?: boolean;
      targetRef?: React.RefObject<HTMLElement>;
      firstControlRef?: React.RefObject<HTMLElement>;
      title?: string;
      titleElement?: React.ReactNode;
      label?: string;
      maxWidth?: string;
      maxHeight?: string;
      description?: string;
      className?: string;
      buttons?: React.ReactNode;
      onClose?(): void;
    },
    ref: React.Ref<any>
  ) => {
    if (typeof children !== 'function' && React.Children.count(children) !== 1) {
      throw new Error('ConsoleConcentrationEdit accept only one child.');
    }
    const rootRef = useRef<HTMLElement | null>(null);
    const position = usePositionTrucker({ visible, targetRef, rootRef });
    const [focusFirst, focusLast] = useFocusControl({
      visible,
      firstControlRef,
      rootRef,
    });
    useOnClickOutside(rootRef, () => {
      if (isCloseOnclickOutside) {
        onClose && onClose();
      }
    });

    return createPortal(
      <AnimatePresence>
        {visible ? (
          <>
            {useModal ? (
              <ConcentrationEditModalElement
                className={className}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.2 }}
                useModalBackdrop={useModalBackdrop}
              />
            ) : null}
            <ConcentrationEditContainerElement
              style={{ maxWidth }}
              className={className}
              role="dialog"
              aria-label={title || label}
              tabIndex={-1}
              css={position.current.style}
              ref={e => {
                ref && setRefedElement(ref, e);
                setRefedElement(rootRef, e);
              }}
              onKeyUp={e => {
                if (e.key === 'Escape') {
                  onClose && onClose();
                }
              }}
            >
              <button
                css={{
                  width: 0,
                  height: 0,
                  transform: 'scale(0)',
                  fontSize: 0,
                  position: 'absolute',
                  zIndex: -1,
                }}
                aria-hidden={true}
                onFocus={() => {
                  focusLast();
                }}
                tabIndex={0}
              ></button>
              <Card
                isTransparent={!useModalBackdrop}
                title={title}
                titleElement={titleElement}
                maxWidth={maxWidth}
                buttons={buttons}
              >
                <ConcentRationEditBodyElement maxHeight={maxHeight}>
                  {typeof children === 'function' ? children(position.current) : children}
                </ConcentRationEditBodyElement>
              </Card>
              <button
                css={{
                  width: 0,
                  height: 0,
                  transform: 'scale(0)',
                  fontSize: 0,
                  position: 'absolute',
                  zIndex: -1,
                }}
                aria-hidden={true}
                onFocus={() => focusFirst()}
                tabIndex={0}
              />
            </ConcentrationEditContainerElement>
          </>
        ) : null}
      </AnimatePresence>,
      document.body
    );
  }
);

ConcentrationEdit.displayName = 'ConcentrationEdit';

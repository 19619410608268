/**
 * @fileOverview
 * @author
 */

import { initialState, State } from '@c/state';
import produce from 'immer';
import {
  deleteMediaStorageSucceeded,
  getMediaStorageSucceeded,
  uploadImageSucceeded,
} from '@c/modules/mediaStorage/action';

type ActionTypes =
  | ReturnType<typeof getMediaStorageSucceeded>
  | ReturnType<typeof uploadImageSucceeded>
  | ReturnType<typeof deleteMediaStorageSucceeded>;

export default (
  state: State['mediaStorage'] = initialState.mediaStorage,
  action: ActionTypes
): State['mediaStorage'] => {
  return produce(state, state => {
    switch (action.type) {
      case 'DELETE_MEDIA_STORAGE_SUCCEEDED':
        return state.filter(entity => {
          return entity.id != action.payload.mediaId;
        });
      case 'GET_MEDIA_STORAGE_SUCCEEDED':
        return action.payload;
      case 'UPLOAD_IMAGE_SUCCEEDED':
        return [action.payload].concat(state);
      default:
        return state;
    }
  });
};

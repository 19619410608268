/**
 * @fileOverview
 * @author
 */

import { State, initialState } from '@c/state';
import produce from 'immer';
import {
  fetchWidgetConfigurationSucceeded,
  floatingIconUpdated,
  themeColorUpdated,
  chatIconUpdated,
  balloonTextUpdated,
  headerTextUpdated,
  whitelistAdded,
  whitelistDeleted,
  whitelistUpdated,
  eventsUpdated,
  floatingIconColorUpdated,
  updatedFlagUpdated,
  floatingWindowHeightUpdated,
} from '@c/modules/widgetCustomization/action';

type ActionTypes = ReturnType<
  | typeof fetchWidgetConfigurationSucceeded
  | typeof floatingIconUpdated
  | typeof themeColorUpdated
  | typeof floatingIconColorUpdated
  | typeof chatIconUpdated
  | typeof balloonTextUpdated
  | typeof headerTextUpdated
  | typeof whitelistAdded
  | typeof whitelistDeleted
  | typeof whitelistUpdated
  | typeof eventsUpdated
  | typeof updatedFlagUpdated
  | typeof floatingWindowHeightUpdated
>;

export default (
  state: State['widgetCustomization'] = initialState.widgetCustomization,
  action: ActionTypes
) => {
  return produce(state, state => {
    switch (action.type) {
      case 'FLOATING_WINDOW_HEIGHT_UPDATED': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        widgetCustomization.customization.floatingChatWindowHeight = action.payload.height;
        return;
      }
      case 'FETCH_WIDGETCONFIGURATION_SUCCEEDED': {
        return { config: { pc: action.payload.pc, sp: action.payload.sp }, updated: state.updated };
      }
      case 'UPDATE_FLOATING_ICON': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        widgetCustomization.customization.floatingIcon = action.payload.icon;
        return;
      }
      case 'UPDATE_THEME_COLOR': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        widgetCustomization.customization.themeColor = action.payload.color;
        return;
      }
      case 'FLOATING_ICON_COLOR_UPDATED': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (!action.payload.color) {
          delete widgetCustomization.customization.floatingIcon.color;
        } else {
          widgetCustomization.customization.floatingIcon.color = action.payload.color;
        }
        return;
      }
      case 'UPDATE_CHAT_ICON': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (!widgetCustomization.customization.icons) {
          widgetCustomization.customization.icons = {};
        }
        widgetCustomization.customization.icons[action.payload.type] = action.payload.url;
        return;
      }
      case 'UPDATE_BALLOON_TEXT': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (!widgetCustomization.customization.messages) {
          widgetCustomization.customization.messages = {};
        }
        widgetCustomization.customization.messages.balloonText = action.payload.text;
        return;
      }
      case 'UPDATE_HEADER_TEXT': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (!widgetCustomization.customization.messages) {
          widgetCustomization.customization.messages = {};
        }
        widgetCustomization.customization.messages.headerText = action.payload.text;
        return;
      }
      case 'ADD_WHITELIST': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (widgetCustomization.whitelist.acceptUrl) {
          widgetCustomization.whitelist.acceptUrl.push('');
        } else {
          (widgetCustomization.whitelist.acceptDomains ?? []).push('');
        }
        return;
      }
      case 'DELETE_WHITELIST': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (widgetCustomization.whitelist.acceptUrl) {
          widgetCustomization.whitelist.acceptUrl.splice(action.payload.index, 1);
        } else {
          (widgetCustomization.whitelist.acceptDomains ?? []).splice(action.payload.index, 1);
        }
        return;
      }
      case 'UPDATE_WHITELIST': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (widgetCustomization.whitelist.acceptUrl) {
          widgetCustomization.whitelist.acceptUrl[action.payload.index] = action.payload.value;
        } else if (widgetCustomization.whitelist.acceptDomains) {
          widgetCustomization.whitelist.acceptDomains[action.payload.index] = action.payload.value;
        }
        return;
      }
      case 'UPDATE_WIDGET_EVENTS_CALLBACK': {
        const widgetCustomization = state.config[action.payload.mode];
        if (!widgetCustomization) {
          return;
        }
        if (!widgetCustomization.events) {
          widgetCustomization.events = {};
        }
        widgetCustomization.events[action.payload.type] = [
          action.payload.wrappedFunctionParameters,
          action.payload.code,
        ];
        return;
      }
      case 'UPDATE_UPDATED_FLAG': {
        state.updated = action.payload.updated;
        return;
      }
      default:
        return state;
    }
  });
};

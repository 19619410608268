import { TwilioProjectRelation } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';

export const getTwilioProjectRelationSucceeded = (payload: TwilioProjectRelation[]) => {
  return { type: 'GET_TWILIO_PROJECT_RELATION_SUCCEEDED' as const, payload };
};

export const addTwilioProjectRelationSucceeded = (payload: {
  twilioProjectRelation: TwilioProjectRelation;
}) => {
  return { type: 'ADD_TWILIO_PROJECT_RELATION_SUCCEEDED' as const, payload };
};

export const updateTwilioProjectRelationSucceeded = (payload: {
  twilioProjectRelation: TwilioProjectRelation;
}) => {
  return { type: 'UPDATE_TWILIO_PROJECT_RELATION_SUCCEEDED' as const, payload };
};

export const deleteTwilioProjectRelationSucceeded = (payload: { id: string }) => {
  return { type: 'DELETE_TWILIO_PROJECT_RELATION_SUCCEEDED' as const, payload };
};

export const resetTwilioProjectRelationSucceeded = () => {
  return { type: 'RESET_TWILIO_PROJECT_RELATION_SUCCEEDED' as const };
};

import { fetchService } from '@s/io/fetchService';

export const getFetcher = <T>(url: string) => {
  return fetchService<T>(url, {
    method: 'GET',
    responseType: 'json',
  });
};

export const postFetcher = <T>(url: string, data: any) => {
  return fetchService<T>(url, {
    method: 'POST',
    responseType: 'json',
    data,
  });
};

export const putFetcher = <T>(url: string, data: any) => {
  return fetchService<T>(url, {
    method: 'PUT',
    responseType: 'json',
    data,
  });
};

export const deleteFetcher = <T>(url: string) => {
  return fetchService<T>(url, {
    method: 'DELETE',
    responseType: 'json',
  });
};

export const patchFetcher = <T>(url: string, data: any) => {
  return fetchService<T>(url, {
    method: 'PATCH',
    responseType: 'json',
    data,
  });
};

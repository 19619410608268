/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@s/Repository';
import { InquiryOrder } from '@c/domain/entities/Inquiry';

export type InquiryOrderUpdatableRepository = UpdatableRepository<
  { projectId: string; inquiries: InquiryOrder[] },
  {}
>;

export class InquiryOrderRepository implements InquiryOrderUpdatableRepository {
  public async update({
    projectId,
    inquiries,
  }: Parameters<InquiryOrderUpdatableRepository['update']>[0]): ReturnType<
    InquiryOrderUpdatableRepository['update']
  > {
    return await fetchService<{}>(apiEndpoint(`project/${projectId}/inquiry/orders`), {
      method: 'POST',
      responseType: 'json',
      data: { inquiries },
    });
  }
}

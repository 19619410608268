/**
 * @fileOverview
 * @name state.ts
 * @author Taketoshi Aono
 * @license
 */

import { AuthEntity } from '@c/domain/entities/AuthEntity';
import { SceneNodes, SceneNodesJson } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { ResolutionTrainingList } from '@c/domain/entities/ResolutionTrainingList';
import { UserEntity } from '@c/domain/entities/UserEntity';
import {
  MatchingCluster,
  MatchingClusters,
  SimilarQueries,
} from './domain/entities/MatchingClusters';
import { Categories } from './domain/entities/Category';
import { ProjectId } from './domain/entities/ProjectId';
import { InquiryList } from './domain/entities/Inquiry';
import {
  SystemMessages,
  systemMessagesDisplayNameMap,
  SystemMessageType,
} from './domain/entities/SystemMessages';
import { Synonym } from './domain/entities/Synonym';
import { MediaEntity } from '@s/domain/entity/Media';
import { BusinessHourEntity } from './domain/entities/BusinessHour';
import { PromoteError, PromoteErrors } from './domain/values/PromoteErrors';
import { WelcomeMessageFormat } from './domain/values/WelcomeMessage';
import { WidgetConfiguration } from '@s/domain/values';
import { EnvValues } from './domain/values/EnvValues';
import { AnalyticsRatioType } from './application/value/AnalyticsRatioType';
import { OperationHistoriesWithV2 } from './domain/entities/OperationHistoriesV2';
import { ChatAssignType, ChatTraits } from '@c/domain/entities/Chat';
import { ConversationAggregate } from '@c/domain/aggregate/ConversationAggregate';
import { LabelEntity } from '@c/domain/entities/Label';
import { SortOrder } from './domain/values/SortOrder';
import { CustomerDataExportSettings } from '@c/domain/entities/CustomerDataExport';
import { InspectionItems } from '@aim/shared/src/debugger/InspectionItems';
import { ConversationEvent } from '@c/domain/entities/voice/ConversationEvent';
import { Conversation } from '@c/domain/entities/voice/Conversation';
import { RecordingInfo } from '@c/domain/entities/voice/RecordingInfo';
import { ProjectDetailEntity, ProjectEntity } from './domain/entities/Project';
import { match } from 'react-router';
import { VoiceSetting } from '@c/repository/voice/VoiceSettingRepository';
import { GenericError } from '@c/application/GenericError';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { InstagramAccount, InstagramAccountList } from './domain/entities/Instagram';
import { IgFixedPhrase } from '@c/domain/entities/IgFixedPhrase';
import { OneTurnMessages, oneTrunMessages } from './domain/entities/OneTurnMessage';
import { Notification } from '@c/domain/entities/Notification';
import { DialogEngineScenario } from '@c/domain/entities/dialogEngine/DialogEngineScenario';
import { DialogEngineScene } from '@c/domain/entities/dialogEngine/DialogEngineScene';
import { DialogEnginePublishSchedule } from '@c/domain/entities/dialogEngine/DialogEnginePublishSchedule';
import { TwilioProjectRelation } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import { DialogEngineRouteDraft } from '@c/domain/entities/dialogEngine/DialogEngineRouteDraft';
import { FixedMessage } from './query/FixedMessage';
import { staticConfig } from './config';

export type ScenarioUsingNamespaces = 'queryScenarioEditor' | 'inquiryOrder' | 'fixedPhrase';

export type ScenarioEditorUsingNamespaces =
  | 'aicompass.matchingTraining.newAnswer'
  | 'aicompass.resolutionTraining'
  | 'queryScenarioEditor';

export type CategoriesUsingNamespaces =
  | 'aicompass.matchingTraining'
  | 'aicompass.resolutionTraining'
  | 'queryScenarioEditor'
  | 'inquiryOrder'
  | 'fixedPhrase';

export type OneTurnMessageUsingNamespaces = 'chat' | 'fixedPhrase.fixedPhrases';

export type SystemMessagesUsingNamespaces = ScenarioEditorUsingNamespaces | 'systemMessages';

export type ScenarioCommonState = {
  scenarioList: InquiryList;
};

export type SystemMessagesScenarioEditorDict = { [K in SystemMessageType]: string };
export type ScenarioParameter = { id: string; type: 'string' };
type BaseScenarioEditorCommonState = {
  userConfig: {
    scale: number;
    toolbarPosition: Coordinate;
  };
  systemMessages: SystemMessagesScenarioEditorDict;
  scenarioId: string;
  updated: boolean;
  saveRequired: boolean;
  errors: PromoteError['errors'];
  warnings: {
    callOperatorConnectedWithoutBusinessHour: boolean;
    duplicateParameter?: boolean;
  };
  parameters?: ScenarioParameter[];
};
export type ScenarioEditorCommonState = {
  selectedScenario?: {
    scenes: ReadonlyDeep<SceneNodes>;
  };
} & BaseScenarioEditorCommonState;

export type ScenarioEditorCommonStateJson = {
  selectedScenario: Optional<{
    scenes: ReadonlyDeep<SceneNodesJson>;
  }>;
} & BaseScenarioEditorCommonState;

export type CategoryCommonState = {
  selectedCategory: { id: number; displayName: string };
  categories: Categories;
};

export type OneTurnMessageCommonState = { oneTurnMessages: OneTurnMessages };

export type AnalyzedScenarioCommonState = {
  shouldDisplayDetailNumbers: boolean;
  ratioType: AnalyticsRatioType;
};

export type WidgetConfigurations = {
  pc: Optional<WidgetConfiguration>;
  sp: Optional<WidgetConfiguration>;
};

export const getInitialScenarioCommonState = () => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  scenarioList: { 0: [] },
});

export const getInitialScenarioEditorCommonState = (): ScenarioEditorCommonState => ({
  userConfig: {
    scale: 0.7,
    toolbarPosition: { x: 10, y: 0 },
  },
  systemMessages: Object.keys(systemMessagesDisplayNameMap).reduce((acc, k: string) => {
    acc[k as SystemMessageType] = '';
    return acc;
  }, {} as SystemMessagesScenarioEditorDict),
  scenarioId: '',
  selectedScenario: undefined,
  updated: false,
  saveRequired: false,
  errors: {},
  warnings: {
    callOperatorConnectedWithoutBusinessHour: false,
    duplicateParameter: false,
  },
});

export const getInitialCategoryCommonState = (): CategoryCommonState => ({
  selectedCategory: { id: 1, displayName: '' },
  categories: [],
});

export const getIntialAnalyzedScenarioCommonState = (): AnalyzedScenarioCommonState => {
  return {
    ratioType: 'relative',
    shouldDisplayDetailNumbers: true,
  };
};

export type State = {
  update: { isFound: boolean };
  env: {
    latestUrlMatch: match | null;
    projectId: ProjectId;
    type: ProjectEntity['type'] | '';
    dedicatedEnvConfig?: string;
  } & EnvValues;
  auth: {
    isLoggedIn: boolean;
    authEntity: AuthEntity | null;
    fbAuthEntity: { response: FacebookLoginStatusResponse; displayName: string } | null;
    isFbLoginRequired: boolean;
    isFirestoreConnected: boolean;
  };
  queryScenarioEditor: ScenarioCommonState & ScenarioEditorCommonState & CategoryCommonState;
  project: {
    list: ProjectEntity[];
    filtered: ProjectEntity[];
    detail?: ProjectDetailEntity;
    instagramAccountList: InstagramAccountList;
    selectedFacebookApp: { appId: string; displayName: string } | null;
    isInstagramAccountListFetched: boolean;
    selectedInstagramAccount: InstagramAccount | null;
  };
  aicompass: {
    resolutionTraining: {
      answerList: ResolutionTrainingList;
    } & ScenarioEditorCommonState &
      CategoryCommonState &
      AnalyzedScenarioCommonState;
    matchingTraining: {
      clusteringInProgress: boolean;
      queryClusters: MatchingClusters;
      similarQueries: Optional<SimilarQueries>;
      selectedCluster: Optional<MatchingCluster>;
      newAnswer: ScenarioEditorCommonState;
    } & CategoryCommonState;
  };
  systemMessages: Optional<SystemMessages>;
  fixedMessage: Optional<FixedMessage>;
  users: {
    operators: UserEntity[];
    histories: OperationHistoriesWithV2;
    shouldDisplayDisabledUsers: boolean;
  };
  synonym: Synonym;
  mediaStorage: MediaEntity[];
  widgetCustomization: {
    config: {
      pc: Optional<WidgetConfiguration>;
      sp: Optional<WidgetConfiguration>;
    };
    updated: boolean;
  };
  promote: Optional<PromoteErrors>;
  preview: {
    configuration: Optional<WidgetConfiguration>;
    welcomeMessage: Optional<WelcomeMessageFormat>;
    token: string;
    error: Optional<PromoteErrors>;
  };
  businessHour: {
    schedule: {
      weekly: { entity: BusinessHourEntity[] };
      holiday: { entity: BusinessHourEntity[] };
      [date: string]: {
        entity: BusinessHourEntity[];
        weekday?: number;
        publicHoliday?: string;
      };
    };
    saveRequiredPattern: boolean;
    saveRequiredSchedule: boolean;
  };
  chat: {
    traits: ChatTraits;
    filterType: ChatAssignType;
    allConversationAggregateIdMap: {
      [key: string]: ReadonlyDeep<ConversationAggregate> | undefined;
    };
    conversationViewOrder: SortOrder;
    conversationView: { [key: number]: string[] | undefined };
    selectedConversationId: string;
    selectedProjectId: string;
    searchWord: string;
    searchLabel: string;
    alwaysCustomerImageUploadEnabled: boolean;
    error?: GenericError;
    ngwords: RegExp[];
  } & OneTurnMessageCommonState;
  label: LabelEntity[];
  inquiryOrder: ScenarioCommonState &
    CategoryCommonState & {
      selectedScenarioIds: { [categoryId: number]: string[] | undefined };
    };
  dataExport: {
    customerSettings: CustomerDataExportSettings;
  };
  widgetInspector: {
    url: string;
    items: InspectionItems;
    isUrlInputPopupVisible: boolean;
    isPreviewEnabled: boolean;
    connected: boolean;
    notFound: boolean;
    winOpenParams: [string, string, string];
  };
  voice: {
    setting: VoiceSetting | null;
    didGetSetting: boolean;
    transferCheck: {
      conversation: Conversation[];
    };
    callLog: {
      conversation: Conversation[];
      entityHeaders: string[];
    };
    callLogDetail: {
      voiceCustomer: {
        id: string;
        note: string;
        phoneNumber: string;
      };
      conversation?: Conversation;
    };
    conversationEvent: ConversationEvent[];
    recordingInfo: RecordingInfo;
    error?: GenericError;
  };
  dialogEngine: {
    scenario: DialogEngineScenario[];
    scene: DialogEngineScene[];
    routeDraft: DialogEngineRouteDraft[];
    publishSchedule: DialogEnginePublishSchedule[];
    twilioProjectRelation: TwilioProjectRelation[];
  };
  carousel: Carousel[];
  fixedPhrase: { fixedPhrases: IgFixedPhrase[] } & CategoryCommonState & ScenarioCommonState;
  notifications: Notification[];
};

export const initialState: State = {
  update: { isFound: typeof __UPDATE_FOUND__ === 'boolean' ? __UPDATE_FOUND__ : false },
  env: {
    latestUrlMatch: null,
    tenantId: '',
    projectId: '',
    type: '',
    useAiCompass: true,
    useOperator: true,
    firebaseConfig: staticConfig.firestore.config,
  },
  auth: {
    isLoggedIn: false,
    authEntity: null,
    fbAuthEntity: null,
    isFbLoginRequired: false,
    isFirestoreConnected: false,
  },
  project: {
    list: [],
    filtered: [],
    detail: undefined,
    selectedFacebookApp: null,
    instagramAccountList: { list: [] },
    isInstagramAccountListFetched: false,
    selectedInstagramAccount: null,
  },
  queryScenarioEditor: {
    ...getInitialScenarioCommonState(),
    ...getInitialScenarioEditorCommonState(),
    ...getInitialCategoryCommonState(),
  },
  aicompass: {
    resolutionTraining: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      answerList: { 0: [] },
      ...getInitialScenarioEditorCommonState(),
      ...getInitialCategoryCommonState(),
      ...getIntialAnalyzedScenarioCommonState(),
    },
    matchingTraining: {
      clusteringInProgress: false,
      queryClusters: [],
      similarQueries: null,
      selectedCluster: null,
      newAnswer: getInitialScenarioEditorCommonState(),
      ...getInitialCategoryCommonState(),
    },
  },
  systemMessages: null,
  fixedMessage: null,
  users: {
    operators: [],
    histories: [],
    shouldDisplayDisabledUsers: false,
  },
  synonym: [],
  mediaStorage: [],
  widgetCustomization: { config: { pc: null, sp: null }, updated: false },
  promote: null,
  preview: { configuration: null, welcomeMessage: null, token: '', error: null },
  businessHour: {
    schedule: { weekly: { entity: [] }, holiday: { entity: [] } },
    saveRequiredPattern: false,
    saveRequiredSchedule: false,
  },
  chat: {
    traits: {
      isAllowUnicasting: false,
      isAllowControllingCustomerImageUploading: true,
      isAllowSendingImageFromOperator: true,
      unicastingRestriction: {
        lastMessageFrom: 0,
        lastMessageFromOperator: 0,
      },
      isValidMessage: () => false,
    },
    filterType: ChatAssignType.SELF,
    allConversationAggregateIdMap: {},
    conversationView: {},
    selectedConversationId: '',
    selectedProjectId: '',
    conversationViewOrder: SortOrder.DESC,
    searchWord: '',
    searchLabel: '',
    oneTurnMessages: oneTrunMessages(),
    ngwords: [],
    alwaysCustomerImageUploadEnabled:
      localStorage.getItem('alwaysCustomerImageUploadEnabled') === 'true',
  },
  label: [],
  inquiryOrder: {
    ...getInitialScenarioCommonState(),
    ...getInitialCategoryCommonState(),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    selectedScenarioIds: { 1: [] },
  },
  dataExport: {
    customerSettings: [],
  },
  widgetInspector: {
    url: '',
    items: {
      userAgent: { ok: true, value: '' },
      whitelist: { ok: true, value: [] },
      isMoreThanOneSnippetsIncluded: false,
      viewport: { ok: true, value: '' },
      isEmbeddedTargetExists: true,
      type: 'floating',
    },
    isPreviewEnabled: false,
    isUrlInputPopupVisible: true,
    connected: false,
    notFound: false,
    winOpenParams: ['', '', ''],
  },
  voice: {
    setting: null,
    didGetSetting: false,
    transferCheck: {
      conversation: [],
    },
    callLog: {
      conversation: [],
      entityHeaders: [],
    },
    callLogDetail: {
      voiceCustomer: {
        id: '',
        phoneNumber: '',
        note: '',
      },
      conversation: undefined,
    },
    conversationEvent: [],
    recordingInfo: {
      downloadUrl: '',
      playlistUrl: '',
      waveformUrl: '',
      segments: {},
      expiresIn: 0,
      expireDate: new Date(),
    },
  },
  dialogEngine: {
    scenario: [],
    scene: [],
    routeDraft: [],
    publishSchedule: [],
    twilioProjectRelation: [],
  },
  carousel: [],
  fixedPhrase: {
    fixedPhrases: [],
    ...getInitialCategoryCommonState(),
    ...getInitialScenarioCommonState(),
  },
  notifications: [],
};

/**
 * @fileOverview
 * @name blur.ts
 * @author Taketoshi Aono
 * @license
 */

import { css, SerializedStyles } from '@emotion/react';
import { detectCssFeature } from './detectCssFeature';
import { hexToRgba } from '@s/chooseColor';

export const blurEffectStyle: (color: string, fallbackColor?: string) => SerializedStyles =
  detectCssFeature('backdrop-filter')
    ? (color: string) => css`
        background: ${hexToRgba(color, 0.3)};
        backdrop-filter: blur(12px);
      `
    : (color: string, fallbackColor?: string) => css`
        background: ${fallbackColor ?? color};
      `;

/**
 * @fileOverview
 * @name Project.ts
 * @author Taketoshi Aono
 * @license
 */

import { ProjectId } from './ProjectId';
import { InstagramProjectSetting } from './Instagram';
import { LineProjectSetting } from './Line';

export type ProjectType =
  | 'chat'
  | 'voice'
  | 'instagram'
  | 'line'
  | 'llmchat'
  | 'summary'
  | 'dialogEngineVoice';

export interface ProjectEntity {
  id: ProjectId;
  displayName: string;
  type: ProjectType;
}

export const projectEntity = (e: Partial<ProjectEntity> = {}) => {
  return {
    id: '',
    displayName: '',
    type: 'chat' as const,
    ...e,
  };
};

export type ProjectDetailEntity = ProjectEntity & {
  integrationEndpoints: {
    conversationClose: string;
    conversationOpen: string;
    customerCreate: string;
  };
  useIntegrations: boolean;
  instagramSetting?: InstagramProjectSetting;
  lineSetting?: LineProjectSetting;
};

export type RawProject = {
  id: string;
  display_name: string;
  type: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
};

export const convertRawProjectToProjectEntity = (rawProject: RawProject): ProjectEntity => {
  return {
    id: rawProject.id,
    displayName: rawProject.display_name,
    type: rawProject.type as ProjectType,
  };
};

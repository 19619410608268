/**
 * @fileOverview
 * @name VersionRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { CreatableRepository } from '@aim/shared/src/Repository';

export type VersionCreatableRepository = CreatableRepository<{ projectId: string }, {}>;

export class VersionRepository implements VersionCreatableRepository {
  public async create({ projectId }: { projectId: string }): Promise<{}> {
    return fetchService<{}>(apiEndpoint(`project/${projectId}/scenario/promote`), {
      method: 'POST',
      responseType: 'json',
      data: {},
    });
  }
}

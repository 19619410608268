/**
 * @fileOverview
 * @name immerIE11Fix.ts
 * @author Taketoshi Aono
 * @license
 */

const { getOwnPropertyDescriptor, hasOwnProperty } = Object;
Object.getOwnPropertyDescriptor = function (obj, key) {
  return (
    getOwnPropertyDescriptor(obj, key) ||
    (hasOwnProperty.call(obj, key)
      ? {
          value: obj[key],
          enumerable: true,
          writable: true,
          configurable: true,
        }
      : void 0)
  );
};

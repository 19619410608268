import { apiEndpoint } from '@c/config';
import {
  DataExportScheduleRequest,
  DataExportScheduleType,
} from '@c/domain/entities/DataExportSchedule';
import { EditableRepository, Mutate, SWRCache } from '@s/Repository';
import { FetchServiceRequestOption, METHOD, fetchService } from '@s/io/fetchService';

export type DataExportScheduleEditableRepository = EditableRepository<
  { schedule: DataExportScheduleRequest<DataExportScheduleType> },
  void,
  { scheduleId: string; schedule: DataExportScheduleRequest<DataExportScheduleType> },
  void,
  { scheduleId: string },
  void
>;

const mutator = <T>(
  method: METHOD,
  url: string,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate
): Promise<T> => mutate<T>(url, fetchService<T>(url, { ...req, method }));

// NOTE:
// post, put, del 関数は昔にSWRを試験的に導入したときに追加されたものだと思われる。
// 特殊なSWRの使い方(SWRのキャッシュのみを活用する) なので今後このコードは消していきたい。
// consoel の SWR 移行のときに消していく
export const post = async (
  url: string,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate,
  swrCache?: SWRCache
): Promise<void> =>
  await mutator<void>('POST', url, req, mutate).then(() => {
    swrCache && swrCache.cache.delete(swrCache.key);
  });
export const put = async (
  url: string,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate,
  swrCache?: SWRCache
): Promise<void> =>
  await mutator<void>('PUT', url, req, mutate).then(() => {
    swrCache && swrCache.cache.delete(swrCache.key);
  });

export const del = async (
  url: string,
  req: Omit<FetchServiceRequestOption, 'method'>,
  mutate: Mutate,
  swrCache?: SWRCache
): Promise<void> =>
  await mutator<void>('DELETE', url, req, mutate).then(() => {
    swrCache && swrCache.cache.delete(swrCache.key);
  });

export class DataExportScheduleRepository implements DataExportScheduleEditableRepository {
  public async create(
    { schedule }: Parameters<DataExportScheduleEditableRepository['create']>[0],
    mutate: Parameters<DataExportScheduleEditableRepository['create']>[1],
    swrCache: Parameters<DataExportScheduleEditableRepository['create']>[2]
  ): ReturnType<DataExportScheduleEditableRepository['create']> {
    return post(
      apiEndpoint('/data-export/schedule'),
      { responseType: 'json', data: schedule },
      mutate!,
      swrCache
    );
  }

  public async update(
    { scheduleId, schedule }: Parameters<DataExportScheduleEditableRepository['update']>[0],
    mutate: Parameters<DataExportScheduleEditableRepository['update']>[1],
    swrCache: Parameters<DataExportScheduleEditableRepository['update']>[2]
  ): ReturnType<DataExportScheduleEditableRepository['update']> {
    return put(
      apiEndpoint(`/data-export/schedule/${scheduleId}`),
      { responseType: 'json', data: schedule },
      mutate!,
      swrCache
    );
  }

  public async delete(
    { scheduleId }: Parameters<DataExportScheduleEditableRepository['delete']>[0],
    mutate: Parameters<DataExportScheduleEditableRepository['delete']>[1],
    swrCache: Parameters<DataExportScheduleEditableRepository['delete']>[2]
  ): ReturnType<DataExportScheduleEditableRepository['delete']> {
    return del(
      apiEndpoint(`/data-export/schedule/${scheduleId}`),
      { responseType: 'json' },
      mutate!,
      swrCache
    );
  }
}

/**
 * @fileOverview
 * @name apiService.ts
 * @author Taketoshi Aono
 * @license
 */

import { AbortController, abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill';
import { CustomError } from 'ts-custom-error';

const { fetch } = abortableFetch({ fetch: self.fetch, Request });

export type PreFetchIntercepter = (
  url: string,
  p: FetchServiceRequestOption
) => Promise<FetchServiceRequestOption | null>;

const commonFetchFailureHandlerMap: {
  [key: number]: ((error: FetchFailure) => void) | undefined;
} = {};
const commonPreFetchIntercepters: PreFetchIntercepter[] = [];

export type METHOD = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
export type FetchServiceRequestOption = {
  method: METHOD;
  data?: any;
  formData?: FormData;
  headers?: { [key: string]: any };
  responseType?: 'json' | 'text' | 'blob';
  signal?: AbortSignal;
  shouldSkipContentType?: boolean;
};

export class FetchFailure extends CustomError {
  public constructor(
    message: string,
    public status: number,
    public headers: Headers,
    public response: Response
  ) {
    super(message);
  }
}

export const abortController = () => {
  return new AbortController();
};

export const fetchService = async <T extends any | string | Blob>(
  url: string,
  req: FetchServiceRequestOption
): Promise<T> => {
  const stack = new Error().stack;
  const interceptedReq = await execPreFetchIntercepters(url, req);
  if (!interceptedReq) {
    return new Promise(() => {});
  }
  const { method, data, formData, headers = {}, responseType = 'json', signal } = interceptedReq;
  const flattenedHeaders = Object.keys(headers).reduce((acc, key) => {
    acc[key.toLowerCase()] = headers[key];
    return acc;
  }, {} as { [key: string]: string });
  if (!req.shouldSkipContentType && !flattenedHeaders['content-type']) {
    flattenedHeaders['content-type'] = 'application/json';
  }
  if (formData) {
    delete flattenedHeaders['content-type'];
  }

  let p: Response;
  try {
    p = await fetch(url, {
      method,
      headers: {
        ...flattenedHeaders,
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      ...(method !== 'GET' && method !== 'DELETE' && data ? { body: JSON.stringify(data) } : {}),
      ...(formData ? { body: formData } : {}),
      ...(signal ? { signal } : {}),
    });
  } catch (e: any) {
    const error = new FetchFailure(e.message, -1, {} as any, {} as any);
    error.stack = stack;
    throw error;
  }

  if (!p.ok) {
    const error = new FetchFailure(await p.text(), p.status, p.headers, p);
    error.stack = stack;
    const commonFetchFailureHandler = commonFetchFailureHandlerMap[p.status];
    if (commonFetchFailureHandler) {
      commonFetchFailureHandler(error);
    }
    throw error;
  }

  const ret = await p[responseType]();
  return ret;
};

export const registerPreFetchIntercepter = (...intercepters: PreFetchIntercepter[]) => {
  commonPreFetchIntercepters.push(...intercepters);
};

const execPreFetchIntercepters = async (
  url: string,
  req: FetchServiceRequestOption
): Promise<FetchServiceRequestOption | null> => {
  return commonPreFetchIntercepters.reduce(
    async (req: Promise<FetchServiceRequestOption | null>, intercepter) => {
      const r = await req;
      if (r) {
        return intercepter(url, r);
      }
      return null;
    },
    Promise.resolve(req)
  );
};

export const registerCommonFetchErrorHandler = (handlerMap: {
  [key: number]: (error: FetchFailure) => void;
}) => {
  Object.keys(handlerMap).forEach(key => {
    commonFetchFailureHandlerMap[+key] = handlerMap[+key];
  });
};

/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@s/Repository';
import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';

export type BusinessHourPatternUpdatableRepository = UpdatableRepository<
  { projectId: string; weekly: BusinessHourEntity[]; holiday: BusinessHourEntity[] },
  void
>;

export class BusinessHourPatternRepository implements BusinessHourPatternUpdatableRepository {
  public async update({
    projectId,
    weekly,
    holiday,
  }: Params<BusinessHourPatternUpdatableRepository>['update'][0]) {
    await fetchService<{}>(apiEndpoint(`/project/${projectId}/biz_hour/pattern`), {
      method: 'PUT',
      data: {
        patterns: Array(8)
          .fill(0)
          .map((x, i) => {
            const pattern = [0, 6, 7].includes(i) ? holiday : weekly;
            return {
              weekday: i,
              ...(pattern.length !== 0
                ? {
                    close: false,
                    opens: pattern.map(x => [
                      `${x.startAt.hours}:${x.startAt.minutes}`,
                      `${x.endAt.hours}:${x.endAt.minutes}`,
                    ]),
                  }
                : {
                    close: true,
                  }),
            };
          }),
      },
      responseType: 'json',
    });
  }
}

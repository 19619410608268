/**
 * @fileOverview
 * @name assertions.ts
 * @author Taketoshi Aono
 * @license
 */

export const required = <T>(value: T | null | undefined): NonNullable<T> => {
  if (value == null) {
    throw new Error('Value required');
  }

  return value as any as NonNullable<T>;
};

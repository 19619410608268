/* eslint-disable no-console */
import { ILogger } from './ILogger';
import * as Sentry from '@sentry/browser';
import { initSentry } from '@s/sentry/config';

export class LocalLogger implements ILogger {
  constructor(
    private sentryInit: typeof Sentry.init = Sentry.init,
    private sentryCaptureMessage = Sentry.captureMessage
  ) {
    initSentry(this.sentryInit);
  }
  log(message: string, ...args: any[]) {
    console.log(`[LOCAL] ${message} ${args.map(it => JSON.stringify(it)).join(', ')}`);
  }
  error(message: string, ...args: any[]) {
    console.error(`[LOCAL] ${message} ${args.map(it => JSON.stringify(it)).join(', ')}`);
  }
  debug(message: string, ...args: any[]) {
    console.debug(`[LOCAL] ${message} ${args.map(it => JSON.stringify(it)).join(', ')}`);
  }
  warn(message: string, ...args: any[]) {
    console.warn(`[LOCAL] ${message} ${args.map(it => JSON.stringify(it)).join(', ')}`);
  }
}

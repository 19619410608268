/**
 * @fileOverview
 * @name index.ts<modules>
 * @author Taketoshi Aono
 * @license
 */

import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import auth from '@c/modules/auth/reducer';
import users from '@c/modules/users/reducer';
import scenarioEditorReducer from '@c/modules/scenarioEditor/reducer';
import queryScenarioEditor from '@c/modules/queryScenarioEditor/reducer';
import resolutionTraining from '@c/modules/aicompassAnswers/reducer';
import matchingTraining from '@c/modules/aicompassMatching/reducer';
import categoryCommon from '@c/modules/categoryCommon/reducer';
import scenarioCommon from '@c/modules/scenarioCommon/reducer';
import env from '@c/modules/env/reducer';
import systemMessages from '@c/modules/systemMessages/reducer';
import fixedMessage from '@c/modules/fixedMessage/reducer';
import widgetCustomization from '@c/modules/widgetCustomization/reducer';
import synonym from '@c/modules/synonym/reducer';
import mediaStorage from '@c/modules/mediaStorage/reducer';
import promote from '@c/modules/version/reducer';
import preview from '@c/modules/preview/reducer';
import update from '@c/modules/update/reducer';
import businessHour from '@c/modules/businessHour/reducer';
import chat from '@c/modules/chat/reducer';
import label from '@c/modules/label/reducer';
import inquiryOrder from '@c/modules/inquiryOrder/reducer';
import widgetInspector from '@c/modules/widgetInspector/reducer';
import dataExport from '@c/modules/dataExport/reducer';
import voice from '@c/modules/voice/reducer';
import carousel from '@c/modules/carousel/reducer';
import fixedPhrase from '@c/modules/igFixedPhrase/reducer';
import oneTurnMessage from '@c/modules/oneturnMessage/reducer';
import notifications from '@c/modules/notification/reducer';
import twilioProjectRelation from '@c/modules/twilioProjectRelation/reducer';
import { ScenarioEditorService } from '@c/application/service/ScenarioEditorService';
import project from '@c/modules/project/reducer';
import { ActionLogQueueable } from '@s/application/service/ActionLogQueuingService';

const mergeReducer = <T extends Reducer<any, any>[]>(...a: T) => {
  return (state: any, action: any) => {
    return a.reduce((state, reducer) => {
      return reducer(state, action);
    }, state);
  };
};

export default (
  {
    history,
    scenarioEditorService,
    actionLogQueuingService,
  }: {
    history: History;
    scenarioEditorService: ScenarioEditorService;
    actionLogQueuingService: ActionLogQueueable;
  },
  otherReducers: { [key: string]: any } = {}
) => {
  const reducer = combineReducers({
    update,
    env,
    auth,
    project,
    users,
    mediaStorage,
    synonym,
    fixedMessage,
    systemMessages: systemMessages('systemMessages'),
    widgetCustomization,
    queryScenarioEditor: mergeReducer(
      queryScenarioEditor,
      scenarioEditorReducer(scenarioEditorService, 'queryScenarioEditor'),
      categoryCommon('queryScenarioEditor'),
      scenarioCommon('queryScenarioEditor')
    ),
    aicompass: mergeReducer(
      resolutionTraining,
      matchingTraining,
      scenarioEditorReducer(scenarioEditorService, 'aicompass.resolutionTraining'),
      scenarioEditorReducer(scenarioEditorService, 'aicompass.matchingTraining.newAnswer'),
      categoryCommon('aicompass.matchingTraining'),
      categoryCommon('aicompass.resolutionTraining')
    ),
    router: connectRouter(history),
    promote,
    preview,
    businessHour,
    chat: mergeReducer(chat, oneTurnMessage('chat')),
    label,
    inquiryOrder: mergeReducer(
      inquiryOrder,
      scenarioCommon('inquiryOrder'),
      categoryCommon('inquiryOrder')
    ),
    widgetInspector,
    dataExport,
    voice,
    carousel,
    dialogEngine: mergeReducer(twilioProjectRelation),
    fixedPhrase: mergeReducer(
      fixedPhrase,
      oneTurnMessage('fixedPhrase.fixedPhrases'),
      categoryCommon('fixedPhrase'),
      scenarioCommon('fixedPhrase')
    ),
    notifications,
    ...otherReducers,
  });

  const ret = (state: Parameters<typeof reducer>[0], action: Parameters<typeof reducer>[1]) => {
    actionLogQueuingService.enqueue(action);
    return reducer(state, action);
  };

  return ret as typeof reducer;
};

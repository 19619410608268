/**
 * @fileOverview
 * @author Mana Horie
 */

import { fetchService } from '@s/io/fetchService';
import { MediaEntity } from '@s/domain/entity/Media';
import { CreatableRepository, DeletableRepository } from '@s/Repository';
import { apiEndpoint } from '@s/config';

export type MediaStorageEditableRepository = CreatableRepository<
  { file: File; description: string; isForOperator: boolean; endpoint: string },
  MediaEntity
> &
  DeletableRepository<
    {
      mediaId: string;
    },
    void
  >;

export class MediaStorageRepository implements MediaStorageEditableRepository {
  public async create({
    file,
    description,
    isForOperator,
    endpoint,
  }: Parameters<MediaStorageEditableRepository['create']>[0]): Promise<MediaEntity> {
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('description', description);
    formData.append('forOperator', String(isForOperator));

    const item = await fetchService<MediaEntity>(endpoint, {
      method: 'POST',
      responseType: 'json',
      formData: formData,
    });

    return item;
  }

  public async delete({
    mediaId,
  }: Parameters<MediaStorageEditableRepository['delete']>[0]): Promise<void> {
    return fetchService(apiEndpoint(`media/${mediaId}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }
}

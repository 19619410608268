/**
 * @fileOverview
 * @author
 */

import { State, initialState } from '@c/state';
import produce from 'immer';
import { fetchSynonymSucceeded } from '@c/modules/synonym/action';

type ActionTypes = ReturnType<typeof fetchSynonymSucceeded>;

export default (state: State['synonym'] = initialState.synonym, action: ActionTypes) => {
  return produce(state, state => {
    switch (action.type) {
      case 'FETCH_SYNONYM_SUCCEEDED':
        return action.payload;
      default:
        return state;
    }
  });
};

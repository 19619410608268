/**
 * @fileOverview
 * @name dynamicNamespaceIC.ts
 * @author Taketoshi Aono
 * @license
 */

type Ic<T> = (ns: string) => T;

export const dynamciNamespaceIc = <T>() => {
  const getStateIc: {
    [key: string]: Ic<T> | undefined;
  } = {};

  return ({
    state,
    namespace,
    isFromRoot = false,
  }: {
    state: any;
    namespace: string;
    isFromRoot?: boolean;
  }): T => {
    const ns = namespace.split('.').slice(isFromRoot ? 0 : 1, Number.MAX_SAFE_INTEGER);
    if (!ns.length) {
      return state;
    }
    let ic = getStateIc[namespace];
    if (!ic) {
      const code = `return state.${ns.join('.')}`;
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      ic = getStateIc[namespace] = Function('state', code) as Ic<T>;
    }

    return ic(state);
  };
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';

export const compareProperty = (
  key: any,
  valueA: any,
  valueB: any,
  differences: { [key: string]: boolean }
): boolean => {
  if (key === '_deps' || key === 'externalDeps') {
    if (valueA.length !== valueB.length) {
      return (differences[key] = false);
    }
    for (let i = 0; i < valueA.length; i++) {
      if (valueA[i] !== valueB[i]) {
        return (differences[key] = false);
      }
    }
    return true;
  }
  const typeA = typeof valueA;
  const typeB = typeof valueB;
  if (typeA !== typeB) {
    return (differences[key] = false);
  }

  if (typeA === 'function') {
    return (differences[key] = true);
  }

  const ret = (differences[key] = valueA === valueB || Object.is(valueA, valueB));
  return ret;
};

export const compareProps = <T>(
  keys: Array<keyof T>,
  p: T = {} as any,
  np: T = {} as any,
  differences: { [key: string]: boolean }
): boolean => {
  const props = p || ({} as any);
  const nextProps = np || ({} as any);
  switch (keys.length) {
    case 1:
      return compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences);
    case 2:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences)
      );
    case 3:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences)
      );
    case 4:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences)
      );
    case 5:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences)
      );
    case 6:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences)
      );
    case 7:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences) &&
        compareProperty(keys[6], props[keys[6]], nextProps[keys[6]], differences)
      );
    case 8:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences) &&
        compareProperty(keys[6], props[keys[6]], nextProps[keys[6]], differences) &&
        compareProperty(keys[7], props[keys[7]], nextProps[keys[7]], differences)
      );
    case 9:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences) &&
        compareProperty(keys[6], props[keys[6]], nextProps[keys[6]], differences) &&
        compareProperty(keys[7], props[keys[7]], nextProps[keys[7]], differences) &&
        compareProperty(keys[8], props[keys[8]], nextProps[keys[8]], differences)
      );
    case 10:
      return (
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences) &&
        compareProperty(keys[6], props[keys[6]], nextProps[keys[6]], differences) &&
        compareProperty(keys[7], props[keys[7]], nextProps[keys[7]], differences) &&
        compareProperty(keys[8], props[keys[8]], nextProps[keys[8]], differences) &&
        compareProperty(keys[9], props[keys[9]], nextProps[keys[9]], differences)
      );
    default:
      const isUntil10 =
        compareProperty(keys[0], props[keys[0]], nextProps[keys[0]], differences) &&
        compareProperty(keys[1], props[keys[1]], nextProps[keys[1]], differences) &&
        compareProperty(keys[2], props[keys[2]], nextProps[keys[2]], differences) &&
        compareProperty(keys[3], props[keys[3]], nextProps[keys[3]], differences) &&
        compareProperty(keys[4], props[keys[4]], nextProps[keys[4]], differences) &&
        compareProperty(keys[5], props[keys[5]], nextProps[keys[5]], differences) &&
        compareProperty(keys[6], props[keys[6]], nextProps[keys[6]], differences) &&
        compareProperty(keys[7], props[keys[7]], nextProps[keys[7]], differences) &&
        compareProperty(keys[8], props[keys[8]], nextProps[keys[8]], differences) &&
        compareProperty(keys[9], props[keys[9]], nextProps[keys[9]], differences) &&
        compareProperty(keys[10], props[keys[10]], nextProps[keys[10]], differences);

      if (!isUntil10) {
        return false;
      }

      for (let i = 11, len = keys.length; i < len; i++) {
        const next = keys[i];
        if (!compareProperty(next, props[next], nextProps[next], differences)) {
          return false;
        }
      }

      return true;
  }
};

export const compareOnlyProperties = <T>(
  component: React.FunctionComponent<T>,
  displayName?: string,
  onDifference?: (diff: { [key: string]: boolean }) => void
  /* eslint-disable @typescript-eslint/naming-convention */
): React.MemoExoticComponent<
  React.FunctionComponent<T & { _deps?: any[]; externalDeps?: any[] }>
> => {
  /* eslint-enable @typescript-eslint/naming-convention */
  if (displayName) {
    component.displayName = displayName;
  }
  return React.memo(component as any, (p: any, np: any) => {
    const differences = {};
    const ret = compareProps(Object.keys(p), p, np, differences);
    onDifference && onDifference(differences);
    return ret;
  });
};

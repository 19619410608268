import { ENV } from '@c/config';
import {
  Conversation,
  formatReadingForm,
  formatVoiceEntity,
  VoiceConversationStatus,
  VoiceDirection,
  VoiceCallStatus,
  voiceCallMaster,
  VoiceTransferStatus,
} from '@c/domain/entities/voice/Conversation';
import { VoiceCustomer } from '@c/domain/entities/voice/VoiceCustomer';
import { GetAndFindableQuery } from '@s/Query';
import { getDocs } from 'firebase/firestore';
import {
  collectionGroup,
  getFirestore,
  query,
  startAt,
  endAt,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';

export type SearchLabelEntity = {
  label: string;
  mainIds: string[];
  subIds: string[];
};

export type ConversationGettableQuery = GetAndFindableQuery<
  {
    tenantId: string;
    projectId: string;
    direction: VoiceDirection;
    useCountryCode: boolean;
    customerPhoneNumber?: string;
    status?: VoiceCallStatus[];
    conversationStatus?: VoiceConversationStatus;
    transferStatus?: VoiceTransferStatus;
    searchLabelIds?: SearchLabelEntity[];
    startDate: Date;
    endDate: Date;
    excludeLabelIds?: string[];
    conversationId?: string;
  },
  Conversation[],
  {
    tenantId: string;
    projectId: string;
    conversationId: string;
  },
  Conversation
>;

export class ConversationQuery implements ConversationGettableQuery {
  private static filterByDate(conversations: Conversation[], startDate: Date, endDate: Date) {
    const conversationCollection = conversations.filter(conversation => {
      return (
        conversation.createdAt.toDate() >= startDate && conversation.createdAt.toDate() < endDate
      );
    });
    return conversationCollection.sort((a, b) => {
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }

  private static filterByExcludeLabel(conversations: Conversation[], excludeLabelIds: string[]) {
    return conversations.filter(conversation => {
      return !excludeLabelIds.some(excludeLabelId => {
        return conversation.labelIds?.find(labelId => labelId === excludeLabelId);
      });
    });
  }

  private static filterBySearchLabel(
    conversations: Conversation[],
    searchLabelIds: SearchLabelEntity[]
  ) {
    return conversations.filter(conversation => {
      return searchLabelIds.every(searchLabel => {
        return (
          searchLabel.mainIds?.find(mainId => {
            return conversation.labelIds?.find(id => id === mainId);
          }) ||
          searchLabel.subIds?.find(subId => {
            return conversation.labelIds?.find(id => id === subId);
          })
        );
      });
    });
  }

  private static filterByTransferStatus(conversations: Conversation[]) {
    return conversations.filter(conversation => {
      return (
        conversation.transferStatus !== 'completed' && conversation.transferStatus !== undefined
      );
    });
  }

  public constructor() {}

  public async get({
    tenantId,
    projectId,
    direction,
    useCountryCode,
    customerPhoneNumber,
    status,
    conversationStatus,
    transferStatus,
    searchLabelIds,
    startDate,
    endDate,
    excludeLabelIds,
    conversationId,
  }: Parameters<ConversationGettableQuery['get']>[0]): ReturnType<
    ConversationGettableQuery['get']
  > {
    let conversationCollection: Conversation[] = [];

    const db = getFirestore();
    const conversationsCollectionGroup = collectionGroup(db, 'conversations');
    let query_ = query(
      conversationsCollectionGroup,
      where('for_query.env', '==', ENV),
      where('for_query.tenant_id', '==', tenantId),
      where('for_query.project_id', '==', projectId),
      where('is_active', '==', false)
    );

    if (conversationId) {
      query_ = query(query_, where('for_query.conversation_id', '==', conversationId));
    } else {
      if (direction === 'outbound') {
        // 架電ステータス
        if (status && status !== voiceCallMaster.get('all')) {
          query_ = query(query_, where('status', 'in', status));
        }

        // 会話ステータス
        if (conversationStatus && conversationStatus !== 'all') {
          if (voiceCallMaster.get('connected')!.toString() === (status || []).toString()) {
            query_ = query(query_, where('conversation_status', '==', conversationStatus));
          }
        }

        // 転送ステータス
        if (transferStatus && conversationStatus === 'finished' && transferStatus !== 'all') {
          if (transferStatus === 'completed') {
            query_ = query(query_, where('call_forwarding_status', '==', transferStatus));
          }
        }
      } else {
        // 会話ステータス
        if (conversationStatus && conversationStatus !== 'all') {
          query_ = query(query_, where('conversation_status', '==', conversationStatus));
        }

        // 転送ステータス
        if (transferStatus && conversationStatus === 'finished' && transferStatus !== 'all') {
          if (transferStatus === 'completed') {
            query_ = query(query_, where('call_forwarding_status', '==', transferStatus));
          }
        }
      }

      query_ = query(query_, where('direction', '==', direction));

      if (customerPhoneNumber) {
        if (useCountryCode) {
          query_ = query(
            query_,
            orderBy('for_query.customer_phone_number'),
            startAt(`+81${customerPhoneNumber.replace(/^0/, '')}`),
            endAt(`+81${customerPhoneNumber.replace(/^0/, '')}` + '\uf8ff')
          );
        } else {
          query_ = query(
            query_,
            orderBy('for_query.customer_phone_number'),
            startAt(`${customerPhoneNumber}`),
            endAt(`${customerPhoneNumber}` + '\uf8ff')
          );
        }
      } else {
        query_ = query(query_, where('created_at', '>=', startDate));
        query_ = query(query_, where('created_at', '<', endDate));
        query_ = query(query_, orderBy('created_at', 'desc'));
      }
    }
    query_ = query(query_, limit(10000));
    const conversationQuery = await getDocs(query_);

    conversationCollection = conversationQuery.docs.map(doc => {
      const voiceCustomer = {
        id: doc.data()['for_query']['customer_id'],
        customerPhoneNumber: doc.data()['for_query']['customer_phone_number'],
        aimPhoneNumber: '',
        note: '',
      } as VoiceCustomer;

      return {
        id: doc.id,
        ...doc.data(),
        voiceCustomer,
        conversationStatus: doc.data()['conversation_status'],
        transferStatus: doc.data()['call_forwarding_status'],
        isActive: doc.data()['is_active'],
        createdAt: doc.data()['created_at'],
        formattedEntity: doc.data().entity && formatVoiceEntity(doc.data().entity),
        readingForm: doc.data().reading_form && formatReadingForm(doc.data().reading_form),
        labelIds: doc.data()['label_ids'],
      } as Conversation;
    });
    if (!conversationId && customerPhoneNumber) {
      // firestoreで日付範囲指定と前方一致検索が同時にできないため
      conversationCollection = ConversationQuery.filterByDate(
        conversationCollection,
        startDate,
        endDate
      );
    }
    if (excludeLabelIds && excludeLabelIds.length > 0) {
      conversationCollection = ConversationQuery.filterByExcludeLabel(
        conversationCollection,
        excludeLabelIds
      );
    }
    if (searchLabelIds && searchLabelIds.length > 0) {
      conversationCollection = ConversationQuery.filterBySearchLabel(
        conversationCollection,
        searchLabelIds
      );
    }

    if (conversationStatus === 'finished' && transferStatus === 'notCompleted') {
      conversationCollection = ConversationQuery.filterByTransferStatus(conversationCollection);
    }
    return conversationCollection;
  }

  public async find({
    tenantId,
    projectId,
    conversationId,
  }: Parameters<ConversationGettableQuery['find']>[0]): ReturnType<
    ConversationGettableQuery['find']
  > {
    const db = getFirestore();
    const conversationsCollectionGroup = collectionGroup(db, 'conversations');
    let query_ = query(
      conversationsCollectionGroup,
      where('for_query.env', '==', ENV),
      where('for_query.tenant_id', '==', tenantId),
      where('for_query.project_id', '==', projectId),
      where('is_active', '==', false)
    );

    if (conversationId) {
      query_ = query(query_, where('for_query.conversation_id', '==', conversationId));
    }

    const conversationQuery = await getDocs(query_);
    const c = conversationQuery.docs[0];

    const voiceCustomer = {
      id: c.data()['for_query']['customer_id'],
      customerPhoneNumber: c.data()['for_query']['customer_phone_number'],
      aimPhoneNumber: '',
      note: '',
    } as VoiceCustomer;

    return {
      id: c.id,
      ...c.data(),
      voiceCustomer,
      conversationStatus: c.data()['conversation_status'],
      transferStatus: c.data()['call_forwarding_status'],
      isActive: c.data()['is_active'],
      createdAt: c.data()['created_at'],
      formattedEntity: c.data().entity && formatVoiceEntity(c.data().entity),
      readingForm: c.data().reading_form && formatReadingForm(c.data().reading_form),
      labelIds: c.data()['label_ids'],
    } as Conversation;
  }
}

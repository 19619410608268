/**
 * @fileOverview
 * @name ResolutionTrainingRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { SceneNodes, RemoteSceneNodes } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { UpdatableRepository } from '@aim/shared/src/Repository';

type UpdateParam = {
  projectId: string;
  scenarioId: string;
  sceneNodes: ReadonlyDeep<SceneNodes>;
};
export type ResolutionTrainingUpdatableRepository = UpdatableRepository<UpdateParam, {}>;

export class ResolutionTrainingRepository implements ResolutionTrainingUpdatableRepository {
  public constructor(
    private readonly sceneNodesToRemoteSceneNode: (
      a: ReadonlyDeep<SceneNodes>
    ) => ReadonlyDeep<RemoteSceneNodes>
  ) {}

  public async update({ projectId, sceneNodes, scenarioId }: UpdateParam): Promise<{}> {
    return fetchService(
      apiEndpoint(`project/${projectId}/resolution-training/scenario/${scenarioId}`),
      {
        method: 'POST',
        responseType: 'json',
        data: this.sceneNodesToRemoteSceneNode(sceneNodes),
      }
    );
  }
}

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';
import { COLOR_SCHEME } from '@c/config';
import { regularTextStyle } from '@s/components/atom/Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

const RadioButtonLabelContainerElement = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  > input:checked + span {
    border: 2px solid ${COLOR_SCHEME({ lightness: 50 })};
  }
  > input:checked + span::after {
    transform: scale(1);
  }
  > input:disabled ~ div {
    opacity: 0.5;
  }
  > input:disabled + span {
    opacity: 0.5;
  }
`;

const RadioButtonElement = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
`;

const LabelContainerElement = styled.div`
  cursor: pointer;
  ${regularTextStyle};
  margin-left: 5px;
`;

const PseudoRadioButtonElement = styled.span`
  border: 2px solid ${COLOR_SCHEME({ lightness: 70 })};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  outline: none;
  background: #fff;
  transition: all 0.2s;
  box-sizing: border-box;
  &:after {
    background: ${COLOR_SCHEME({ lightness: 50 })};
    flex: 0 0 12px;
    border-radius: 100px;
    display: block;
    width: 12px;
    height: 12px;
    transition: all 0.3s;
    transform: scale(0);
    content: '';
  }
`;

export const RadioButton = compareOnlyProperties(
  ({
    checked,
    name,
    value,
    label,
    onChange,
    disabled,
  }: {
    checked: boolean;
    name: string;
    value: string;
    label: string;
    onChange?(a: { checked: boolean }): void;
    disabled?: boolean;
  }) => {
    return (
      <RadioButtonLabelContainerElement>
        <RadioButtonElement
          tabIndex={0}
          type="radio"
          checked={checked}
          name={name}
          value={value}
          disabled={disabled}
          onChange={e => onChange && onChange({ checked: e.target.checked })}
        />
        <PseudoRadioButtonElement />
        <LabelContainerElement>{label}</LabelContainerElement>
      </RadioButtonLabelContainerElement>
    );
  }
);

/**
 * @fileOverview
 * @author
 */

import { LabelEntity } from '@c/domain/entities/Label';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';

export type LabelGettableQuery = GettableQuery<void, LabelEntity[]>;

export class LabelQuery implements LabelGettableQuery {
  public async get(): ReturnType<LabelGettableQuery['get']> {
    const ret = await fetchService<LabelEntity[]>(apiEndpoint(`/conversation/label/list`), {
      method: 'GET',
    });
    return ret;
  }
}

/**
 * @fileOverview
 * @name BusinessHourSpecification.ts
 * @author
 */

import { BusinessHourEntity } from '@c/domain/entities/BusinessHour';

export interface BusinessHourSpecification {
  validateDuplication(entity: BusinessHourEntity[]): {
    hasDuplication: boolean[];
    errorMessage: string;
  };
  validateHourOrder(entity: BusinessHourEntity[]): {
    hasInvalidHourOrder: boolean[];
    errorMessage: string;
  };
}

export class BusinessHourSpecificationValidator implements BusinessHourSpecification {
  public validateDuplication(entity: BusinessHourEntity[]): {
    hasDuplication: boolean[];
    errorMessage: string;
  } {
    const hasDuplication: boolean[] = [];
    entity.forEach((e, i, self) => {
      hasDuplication.push(
        self.filter(
          x =>
            Number(`${x.startAt.hours}${x.startAt.minutes}`) <=
              Number(`${e.endAt.hours}${e.endAt.minutes}`) &&
            Number(`${x.endAt.hours}${x.endAt.minutes}`) >=
              Number(`${e.startAt.hours}${e.startAt.minutes}`)
        ).length >= 2
      );
    });
    return { hasDuplication, errorMessage: '時間帯が重複しています' };
  }

  public validateHourOrder(entity: BusinessHourEntity[]): {
    hasInvalidHourOrder: boolean[];
    errorMessage: string;
  } {
    const hasInvalidHourOrder: boolean[] = [];
    entity.forEach(e => {
      hasInvalidHourOrder.push(
        Number(`${e.startAt.hours}${e.startAt.minutes}`) >=
          Number(`${e.endAt.hours}${e.endAt.minutes}`)
      );
    });
    return { hasInvalidHourOrder, errorMessage: '開始時間は終了時間より前でなければなりません' };
  }
}

/**
 * @fileOverview
 * @name SentryWorkerEventType.ts
 * @author Taketoshi Aono
 * @license
 */

import * as Sentry from '@sentry/browser';

export const sentryInit = (payload: Sentry.BrowserOptions) => {
  return {
    type: '@SENTRY_INIT' as const,
    payload,
  };
};

export const sentryLogin = (payload: Sentry.User) => {
  return {
    type: '@SENTRY_LOGIN' as const,
    payload,
  };
};

export const sentryLoginAnonymously = (payload: { id: string }) => {
  return {
    type: '@SENTRY_LOGIN_ANONYMOUSLY' as const,
    payload,
  };
};

export const sentrySendWithData = (payload: { url: string; extras: string; error: Error }) => {
  return {
    type: '@SENTRY_SEND_WITH_DATA' as const,
    payload,
  };
};

export const sentrySend = (payload: {
  url: string;
  firestoreUrl: string;
  error: Error;
  additionalData?: any;
}) => {
  return {
    type: '@SENTRY_SEND' as const,
    payload,
  };
};

export const sentryDrainQueue = () => {
  return {
    type: '@SENTRY_DRAIN_QUEUE' as const,
  };
};

export type SentryWorkerEventType = ReturnType<
  | typeof sentryInit
  | typeof sentryLogin
  | typeof sentryLoginAnonymously
  | typeof sentrySendWithData
  | typeof sentrySend
  | typeof sentryDrainQueue
>;

/**
 * @fileOverview
 * @name tabControl.ts
 * @author Taketoshi Aono
 * @license
 */

export const findAdjacentFocusableNodes = (
  el: HTMLElement
): [HTMLElement | null, HTMLElement | null] => {
  const nodes = document.querySelectorAll(`:not([tabindex='-1'])[tabindex]`) as NodeList;
  const index = Array.from(nodes).findIndex(node => node === el);
  if (index > -1) {
    return [
      index ? (nodes[index - 1] as HTMLElement) : null,
      index < nodes.length - 1 ? (nodes[index + 1] as HTMLElement) : null,
    ];
  }
  return [null, null];
};

export const findLastFocusableNode = (el: HTMLElement): HTMLElement | null => {
  const f = el.querySelectorAll(`:not([tabindex='-1'])[tabindex]`);
  return f.length ? (f[f.length - 1] as HTMLElement) : null;
};

export const findFirstFocusableChild = (el: HTMLElement): HTMLElement | null => {
  const f = el.querySelectorAll(`:not([tabindex='-1'])[tabindex]`);
  return f.length ? (f[0] as HTMLElement) : null;
};

export const findNthFocusableChild = (el: HTMLElement, nth: number): HTMLElement | null => {
  const f = el.querySelectorAll(`:not([tabindex='-1'])[tabindex]`);
  return nth < 0
    ? f.length - nth >= 0
      ? (f[f.length + nth] as HTMLElement)
      : null
    : f.length > nth
    ? (f[nth] as HTMLElement)
    : null;
};

export const focusPreviousNode = (el: HTMLElement) => {
  const [prev] = findAdjacentFocusableNodes(el);
  prev && prev.focus();
  return !!prev;
};

export const focusNextNode = (el: HTMLElement) => {
  const [, next] = findAdjacentFocusableNodes(el);
  next && next.focus();
  return !!next;
};

export const focusFirstFocusableChild = (el: HTMLElement) => {
  const n = findFirstFocusableChild(el);
  n && n.focus();
  return !!n;
};

export const focusNthFocusableChild = (el: HTMLElement, nth: number) => {
  const n = findNthFocusableChild(el, nth);
  n && n.focus();
  return !!n;
};

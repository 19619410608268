/**
 * @fileOverview
 * @name firestoreConversationViewAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import { FirestoreChatEntity, ChatEntity, ChatAssignType } from '@c/domain/entities/Chat';

export const firestoreConversationViewAdapter = (
  id: string,
  operatorId: string,
  entity: FirestoreChatEntity
): ChatEntity => {
  return {
    id,
    assignType: entity.archivedAt
      ? ChatAssignType.ARCHIVES
      : entity.operatorId === operatorId && entity.operatorChatStarted
      ? ChatAssignType.SELF
      : !!entity.operatorId && entity.operatorChatStarted
      ? ChatAssignType.NOW_RESPONDING
      : entity.callingOperator
      ? ChatAssignType.NOT_RESPONDING
      : ChatAssignType.BOT,
    ...entity,
    updatedAt: entity.archivedAt ? entity.archivedAt : entity.updatedAt,
    labelIds: entity.labelIds ?? [],
  };
};

/**
 * @fileOverview
 * @name UpdateFoundNotification.tsx
 * @author Taketoshi Aono
 * @license
 */

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon } from '@c/components/atom/Icon';
import { CircleButton } from '../atom/Button';
import { useSelector } from 'react-redux';
import { State } from '@c/state';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { FONT_SIZE_SMALL, RegularText } from '@s/components/atom/Text';
import { Box } from '@s/components/atom/Box';
import { useRefState } from '@s/reactHooks';

const ContainerElement = styled(motion.div)`
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12px);
  padding: 10px 20px;
  height: 50px;
  z-index: 2147483647;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: #fff;
`;
const FlexRoot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  button {
    height: 25px;
  }
  button span {
    font-size: ${FONT_SIZE_SMALL} !important;
    -webkit-text-fill-color: #fff !important;
    color: #fff !important;
  }
`;

export const UpdateFoundNotification = compareOnlyProperties(({}: {}) => {
  const { isFound } = useSelector((s: State) => s.update);
  const [visibility, setVisibility] = useRefState(isFound);
  const [updating, setUpdating] = useRefState(false);

  useEffect(() => {
    if (isFound) {
      setVisibility(isFound);
    }
  }, [isFound]);

  return (
    <AnimatePresence>
      {visibility.current ? (
        <ContainerElement
          initial={{ translateX: 100, opacity: 0 }}
          animate={{ translateX: 0, opacity: 1 }}
          exit={{ translateX: 100, opacity: 0 }}
          transition={{ type: 'tween', duration: 0.3, ease: 'anticipate' }}
        >
          <FlexRoot>
            <RegularText css={{ marginRight: 20 }}>アップデートがあります</RegularText>
            <CircleButton
              loading={updating.current}
              label="アップデート"
              iconSvg={<Icon name="refresh" size={15} />}
              onClick={async () => {
                setUpdating(true);
                await __RUN_UPDATE__();
                location.reload();
              }}
            />
            <Box margin="0 0 0 10px">
              <CircleButton
                loading={updating.current}
                label="今はしない"
                iconSvg={<Icon name="crossbar" size={15} />}
                onClick={() => setVisibility(false)}
              />
            </Box>
          </FlexRoot>
        </ContainerElement>
      ) : null}
    </AnimatePresence>
  );
});

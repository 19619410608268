/**
 * @fileOverview
 * @author
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { LabelColor, LabelEntity } from '@c/domain/entities/Label';
import { EditableRepository } from '@s/Repository';

export type LabelEditableRepository = EditableRepository<
  { name: string; category: 'main' | 'sub'; color: LabelColor },
  LabelEntity,
  { id: string; name: string; color: LabelColor },
  void,
  { id: string },
  void
>;

export class LabelRepository implements LabelEditableRepository {
  public async create({
    name,
    category,
    color,
  }: Parameters<LabelEditableRepository['create']>[0]): ReturnType<
    LabelEditableRepository['create']
  > {
    const label = await fetchService<LabelEntity>(apiEndpoint(`conversation/label`), {
      method: 'POST',
      responseType: 'json',
      data: { name: name.trim(), category, color },
    });
    return label;
  }

  public async delete({
    id,
  }: Parameters<LabelEditableRepository['delete']>[0]): ReturnType<
    LabelEditableRepository['delete']
  > {
    await fetchService<void>(apiEndpoint(`conversation/label/${id}`), {
      method: 'DELETE',
      responseType: 'json',
    });
  }

  public async update({
    id,
    name,
    color,
  }: Parameters<LabelEditableRepository['update']>[0]): ReturnType<
    LabelEditableRepository['update']
  > {
    await fetchService<{}>(apiEndpoint(`conversation/label/${id}`), {
      method: 'PATCH',
      responseType: 'json',
      data: { name, color },
    });
  }
}

/**
 * @fileOverview
 * @name ProjectListQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import {
  convertRawProjectToProjectEntity,
  ProjectEntity,
  RawProject,
} from '@c/domain/entities/Project';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type ProjectListGettableQuery = GettableQuery<
  { isLimitedProjectList: boolean },
  ProjectEntity[]
>;

// 既存のGET project/listは全取得のため、1ページ目のみ取得し、limitは十分に大きい数値を設定しておく
const PROJECT_LIST_PAGE = 1;
const PROJECT_LIST_LIMIT = 1000;

export class ProjectListQuery implements ProjectListGettableQuery {
  public async get({
    isLimitedProjectList,
  }: {
    isLimitedProjectList: boolean;
  }): ReturnType<ProjectListGettableQuery['get']> {
    if (isLimitedProjectList) {
      const ret = await fetchService<{ projects: RawProject[] }>(
        apiEndpoint(`/projects?page=${PROJECT_LIST_PAGE}&per_page=${PROJECT_LIST_LIMIT}`),
        {
          method: 'GET',
          responseType: 'json',
        }
      );
      return ret.projects.map(convertRawProjectToProjectEntity);
    } else {
      const ret = await fetchService<{ projects: ProjectEntity[] }>(apiEndpoint(`project/list`), {
        method: 'GET',
        responseType: 'json',
      });
      return ret.projects;
    }
  }
}

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormRole } from '@c/components/atom/FormRole';
import { regularTextStyle, FONT_SIZE_XSMALL } from '@s/components/atom/Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { focusPreviousNode } from '@aim/shared/src/components/atom/tabControl';

const SinglelineInputElement = styled.input<{
  useBorder: boolean;
  readOnly: boolean;
  disabled: boolean;
}>`
  border: 0;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  ${regularTextStyle};
  transition: all 0.2s;
  border: ${p => (p.useBorder ? '1px solid #DDD' : 'none')};
  cursor: ${p => (p.readOnly || p.disabled ? 'not-allowed' : 'auto')};
  &:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6);
  }
`;

const MultilineInputElement = styled.textarea<{
  useBorder: boolean;
  readOnly: boolean;
  disabled: boolean;
}>`
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  resize: none;
  height: 100%;
  border: ${p => (p.useBorder ? '1px solid #DDD' : 'none')};
  cursor: ${p => (p.readOnly || p.disabled ? 'not-allowed' : 'auto')};
  ${regularTextStyle};
`;

const PlaceholderElement = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  ${regularTextStyle};
  color: #aaa;
  background: transparent;
  transition: all 0.2s;
  padding: 0px 10px;
  border-radius: 4px;
  z-index: 1;
`;

const InputContainerElement = FormRole(
  styled.div`
    position: relative;
    display: inline-block;
  `,
  'textbox'
);

export interface TextInputProps {
  multiline?: {
    autoExpand: boolean;
    height?: number | string;
    width?: number | string;
    maxHeight?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
  };
  value: string;
  className?: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: { value: string; type: 'normal' | 'dynamic' };
  width?: number | string;
  isPassword?: boolean;
  useDefault?: boolean;
  useBorder?: boolean;
  restriction?: RegExp | ((value: string) => boolean);
  onChange?(a: { value: string }): void;
  onBlur?(a: { value: string }): void;
  onKeyUp?(a: {
    value: string;
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;
  }): void;
}

const DEFAULT_ONCHANGE = () => {};
// eslint-disable-next-line  @typescript-eslint/no-unnecessary-type-constraint
const setRef = <T extends any>(ref: React.Ref<T>, value: T) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null) {
    (ref.current as any) = value;
  }
};

const fixPosUnit = (
  value: string | number | undefined,
  defaultValue: string | number = 0
): string => {
  return typeof value === 'string'
    ? value
    : typeof value === 'number'
    ? `${value}px`
    : fixPosUnit(defaultValue);
};

const expandStyle = ({ config }: { config: TextInputProps['multiline'] }) =>
  css`
    width: ${fixPosUnit(config?.width, 200)};
    max-width: ${fixPosUnit(config?.maxWidth, 200)};
    min-height: ${fixPosUnit(config?.height, 200)};
    max-height: ${fixPosUnit(config?.maxHeight, config?.height || 100)};
    min-width: ${fixPosUnit(config?.minWidth, config?.width || 100)};
  `;

const ScalableTextareaContainerElement = styled.span<{
  config: TextInputProps['multiline'];
}>`
  display: inline-block;
  position: relative;
  ${p => expandStyle(p)};
  > span:first-of-type,
  > textarea {
    ${p => expandStyle(p)};
  }
`;
const TextareaExpanderElement = styled.span`
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  ${regularTextStyle};
`;

/**
 * @deprecated please use `@c/shared/components/TextInput.tsx` instead in '@aim/console'.
 */
export const TextInput = compareOnlyProperties(
  React.forwardRef(
    (
      {
        value,
        multiline,
        width = 200,
        placeholder = { value: 'テキストを入力', type: 'normal' },
        useDefault = false,
        useBorder = false,
        readonly = false,
        disabled = false,
        isPassword = false,
        className = '',
        restriction = /.*/,
        onChange = DEFAULT_ONCHANGE,
        onBlur = DEFAULT_ONCHANGE,
        onKeyUp = DEFAULT_ONCHANGE,
      }: TextInputProps,
      ref: React.Ref<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const [valueState, updateValue] = useState({ value });
      let v = valueState.value;
      if (useDefault) {
        const o = onChange;
        onChange = a => {
          o(a);
          updateValue(a);
        };
      } else {
        v = value;
      }

      const placeholderNode =
        placeholder.type === 'dynamic' ? (
          <PlaceholderElement
            aria-hidden={true}
            css={{
              ...(v
                ? {
                    top: -10,
                    left: 5,
                    padding: '0px 10px',
                    border: '1px solid #DDD',
                    background: '#FFF',
                    fontSize: FONT_SIZE_XSMALL,
                    color: '#222',
                  }
                : {}),
            }}
          >
            {placeholder.value}
          </PlaceholderElement>
        ) : null;

      const internalRef = useRef<HTMLElement | null>();
      const rootRef = useRef<HTMLElement | null>();
      const handleChange = (e: { target: { value: string } }) => {
        const value = e.target.value;
        if (typeof restriction === 'function') {
          if (!restriction(value)) {
            return;
          }
        } else if (!restriction.test(value)) {
          return;
        }
        onChange({ value });
      };

      return (
        <InputContainerElement
          ref={rootRef}
          tabIndex={disabled ? -1 : 0}
          aria-multiline={!!multiline}
          aria-placeholder={placeholder.value}
          aria-disabled={disabled}
          aria-readonly={readonly}
          onFocus={e => {
            if (e.relatedTarget === internalRef.current) {
              rootRef.current && !focusPreviousNode(rootRef.current) && e.preventDefault();
              return;
            }
            internalRef.current && internalRef.current.focus();
          }}
          onUserDecision={() => {}}
          css={multiline?.width ? { width: multiline.width } : { width }}
        >
          {placeholderNode}
          {!multiline ? (
            <SinglelineInputElement
              className={className}
              aria-hidden={true}
              useBorder={useBorder}
              type={isPassword ? 'password' : 'text'}
              tabIndex={-1}
              value={v}
              readOnly={readonly}
              disabled={disabled}
              css={{ width }}
              placeholder={placeholder.type === 'normal' ? placeholder.value : ''}
              onKeyUp={event => {
                onKeyUp({ event, value: (event.target as HTMLInputElement).value });
              }}
              ref={el => {
                setRef(ref, el);
                internalRef.current = el;
              }}
              onChange={handleChange}
              onBlur={e => {
                e.stopPropagation();
                onBlur({ value: e.target.value });
              }}
              onFocus={e => e.stopPropagation()}
            />
          ) : (
            <ScalableTextareaContainerElement config={multiline} aria-hidden={true}>
              {multiline.autoExpand ? (
                <TextareaExpanderElement>{v.replace(/\n/g, '\n_')}</TextareaExpanderElement>
              ) : null}
              <MultilineInputElement
                className={className}
                placeholder={placeholder.type === 'normal' ? placeholder.value : ''}
                useBorder={useBorder}
                aria-hidden={true}
                tabIndex={-1}
                value={v}
                readOnly={readonly}
                disabled={disabled}
                ref={el => {
                  setRef(ref, el);
                  internalRef.current = el;
                }}
                onKeyUp={event => {
                  onKeyUp({ event, value: (event.target as HTMLTextAreaElement).value });
                }}
                onChange={handleChange}
                onBlur={e => {
                  e.stopPropagation();
                  onBlur({ value: e.target.value });
                }}
                onFocus={e => e.stopPropagation()}
              />
            </ScalableTextareaContainerElement>
          )}
        </InputContainerElement>
      );
    }
  ),
  'TextInput'
);

/**
 * @fileOverview
 * @author
 */

import { ProjectEntity } from '@c/domain/entities/Project';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { EnvValues } from '@c/domain/values/EnvValues';
import { match } from 'react-router';

export const getEnvSucceeded = (payload: EnvValues) => {
  return {
    type: 'GET_ENV_SUCCEEDED' as const,
    payload,
  };
};

export const getProjectIdSucceeded = (payload: ProjectId) => {
  return {
    type: 'GET_PROJECT_ID_SUCCEEDED' as const,
    payload,
  };
};

export const projectSelected = (payload?: ProjectEntity) => {
  return {
    type: 'PROJECT_SELECTED' as const,
    payload,
  };
};

export const latestUrlChanged = (payload: { match: match }) => {
  return {
    type: 'LATEST_URL_CHANGED' as const,
    payload,
  };
};

/**
 * @fileOverview
 * @name ScenarioEditorRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { CreateInquiryResponse } from '@c/domain/entities/Inquiry';
import { apiEndpoint } from '@c/config';
import { Category } from '@c/domain/entities/Category';
import { AppendonlyRepository } from '@aim/shared/src/Repository';

type ScenarioAppendableRepository = AppendonlyRepository<
  {
    projectId: string;
    inquiry: string;
    category: Category;
  },
  CreateInquiryResponse,
  {
    projectId: string;
    scenarioId: string;
    current: string;
    inquiry: string;
  },
  {}
>;
type P = Params<ScenarioAppendableRepository>;

export class ScenarioEditorRepository implements ScenarioAppendableRepository {
  public constructor() {}

  public async create({ projectId, inquiry, category }: P['create'][0]) {
    const item = await fetchService<CreateInquiryResponse>(
      apiEndpoint(`project/${projectId}/inquiry`),
      {
        method: 'POST',
        responseType: 'json',
        data: { inquiry, categoryId: category.id },
      }
    );

    return item;
  }

  public async update({ projectId, scenarioId, inquiry, current }: P['update'][0]) {
    return fetchService<{}>(apiEndpoint(`project/${projectId}/inquiry/scenario/${scenarioId}`), {
      method: 'PUT',
      responseType: 'json',
      data: { inquiry, current },
    });
  }
}

import React, { useEffect } from 'react';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import styled from '@emotion/styled';
import { Box, Flex } from '@s/components/atom/Box';
import { largeTextStyle } from '@s/components/atom/Text';
import { Button } from '@s/components/atom/Button';
import { useAsyncAction, useRefState } from '@s/reactHooks';
import {
  initializeRecaptchaVerifier,
  MULTI_FACTOR_DISPLAY_NAME,
  sendVerificationCodeInRegister,
} from '@c/modules/account/usecase';
import { GenericLogo } from '@c/components/atom/GenericLogo';
import { PhoneNumberParameter } from '@c/domain/entities/Account';
import { useErrorNotificator } from '@c/components/hooks/useErrorNotificator';
import { storage } from '@s/io/storage';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TextInput } from '@c/shared/components/TextInput';

const PhoneAuthContentWrapperElement = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  > div {
    width: 100%;
    height: 100%;
  }
`;

const LabelElement = styled.div`
  ${largeTextStyle};
  font-weight: bold;
  margin: 10px;
`;

export const PhoneAuth: React.FunctionComponent = compareOnlyProperties(() => {
  const [phoneNumber, setPhoneNumber] = useRefState('');
  const [displayName, setDisplayName] = useRefState('');
  const [recaptchaResolved, setRecaptchaResolved] = useRefState(false);
  const initializeRecaptchaVerifierAction = useAsyncAction(initializeRecaptchaVerifier);
  const registerPhoneNumberAction = useAsyncAction(sendVerificationCodeInRegister);
  const history = useHistory();

  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');

  useErrorNotificator(registerPhoneNumberAction.error);

  useEffect(() => {
    storage('local')
      .get<string | undefined>(MULTI_FACTOR_DISPLAY_NAME)
      .then(displayName => {
        setDisplayName(displayName || '');
      });
    initializeRecaptchaVerifierAction('recaptcha').then(() => {
      setRecaptchaResolved(true);
    });
    return () => {
      setRecaptchaResolved(false);
    };
  }, []);

  return (
    <PhoneAuthContentWrapperElement>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        css={{ position: 'relative', height: '100%' }}
      >
        <Flex justifyContent="center" alignItems="center">
          <Box margin="0 0 80px 0">
            <GenericLogo size={200} />
          </Box>
        </Flex>
        <LabelElement>二要素認証の登録</LabelElement>
        <LabelElement>SMSで認証コードを送信します</LabelElement>
        <TextInput
          width={500}
          border
          placeholder={{
            value: '表示名を入力してください(例: スマートフォン)',
            type: 'dynamic',
          }}
          onChange={e => setDisplayName(e.target.value)}
          css={{ marginBottom: '10px' }}
        />
        <Flex>
          <TextInput
            width={400}
            border
            placeholder={{
              value: '電話番号をハイフン(-)を含めずにご入力ください',
              type: 'dynamic',
            }}
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <Button
            css={{ marginLeft: '10px' }}
            type={'normal'}
            label="送信"
            disabled={!recaptchaResolved.current}
            loading={registerPhoneNumberAction.loading}
            onClick={() => {
              registerPhoneNumberAction(
                new PhoneNumberParameter({
                  phoneNumber: phoneNumber.current,
                  displayName: displayName.current,
                }),
                type !== 'default'
              );
            }}
          />
        </Flex>
        <div id={'recaptcha'} style={{ margin: '20px' }} />
        <Button
          type={'normal'}
          label="スキップ"
          onClick={() => {
            if (type === 'default') {
              history.goBack();
            } else {
              history.push('/login');
            }
          }}
        />
        <div css={{ marginTop: '20px' }}>※SMS送信時に料金が発生しますのでご了承ください</div>
        <div>※日本国内の電話番号のみご利用いただけます</div>
        <div>※IP電話(050から始まる番号)はご利用いただけません</div>
      </Flex>
    </PhoneAuthContentWrapperElement>
  );
}, 'PhoneAuth');

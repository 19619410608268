const pathComponents = location.pathname.split('/').filter(v => !!v);
export const ENV =
  process.env.NODE_ENV === 'production'
    ? (() => {
        const match = /(local|dev|stage)-console/.exec(location.hostname);
        return match ? match[1] : 'prod';
      })()
    : (pathComponents[0] || 'dev').trim();
export const ENV_PREFIX = ENV === 'prod' ? '' : `${ENV}-`;

export const TENANT_NAME =
  process.env.NODE_ENV === 'production'
    ? location.hostname.split(`.${ENV_PREFIX}console.ai-messenger.app`)[0]
    : pathComponents[1];

const NORMALIZE_PATH_REGEX = /\/+/g;
export const normalizePath = (url: string) => url.replace(NORMALIZE_PATH_REGEX, '/');

export const apiEndpoint = (url: string) =>
  process.env.NODE_ENV !== 'production'
    ? normalizePath(`/${ENV_PREFIX}console/${url}`)
    : normalizePath(`/${url}`);

/**
 * @fileOverview
 * @name Environment.ts<platform>
 * @author Taketoshi Aono
 * @license
 */
import { UAParser } from 'ua-parser-js';

export interface Environment {
  isSupportedEnvironment: boolean;
  isSupportedMobileBrowser: boolean;
  isSupportedPcBrowser: boolean;
  platform: UAParser.IResult;
  forceSupportAs(type: 'Android' | 'iOS' | 'PC'): void;
}

const SUPPPORTED_PC_OS_NAMES = new Set(['Windows', 'Mac OS', 'Chromium OS', 'Linux', 'Ubuntu']);
const LOCAL_STORAGE_CHECK_KEY = 'AIM_LOCALSTORAGE_CHECK';

export class WidgetEnvironment implements Environment {
  public isSupportedEnvironment = false;

  public isSupportedMobileBrowser = false;

  public isSupportedPcBrowser = false;

  public platform = new UAParser(navigator.userAgent).getResult();

  private readonly shouldWorkDevices: ('PC' | 'IOS' | 'ANDROID')[];

  public constructor(devices: ('PC' | 'iOS' | 'Android')[]) {
    this.shouldWorkDevices = devices.map(v => v.toUpperCase()) as any;
    this.initialize();
  }

  public forceSupportAs(type: 'PC' | 'iOS' | 'Android'): void {
    switch (type) {
      case 'iOS':
        this.platform = new UAParser(
          'Mozilla/5.0 (iPhone; CPU iPhone OS 12_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Mobile/15E148 Safari/604.1'
        ).getResult();
        break;
      case 'Android':
        this.platform = new UAParser(
          'Mozilla/5.0 (Linux; Android 10; Pixel) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.99 Mobile Safari/537.36'
        ).getResult();
        break;
      case 'PC':
        this.platform = new UAParser(
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36.'
        ).getResult();
        break;
      default:
        break;
    }
    this.initialize();
  }

  private initialize() {
    this.isSupportedPcBrowser = this.validatePcBrowser();
    this.isSupportedMobileBrowser = this.validateAndroidBrowser() || this.validateIosBrowser();
    this.isSupportedEnvironment =
      (this.isSupportedPcBrowser || this.isSupportedMobileBrowser) && this.validateLocalStorage();
  }

  private validateAndroidBrowser(): boolean {
    if (this.shouldWorkDevices.includes('ANDROID') && this.platform.os.name === 'Android') {
      return (
        parseFloat(this.platform.os.version || '5.0') >= 5.0 &&
        (this.platform.browser.name === 'Chrome' ||
          this.platform.browser.name === 'Chrome WebView' ||
          this.platform.browser.name === 'Chrome Headless')
      );
    }

    return false;
  }

  private validateIosBrowser(): boolean {
    if (this.shouldWorkDevices.includes('IOS') && this.platform.os.name === 'iOS') {
      const engine = this.platform.engine;
      return engine.version
        ? engine.name === 'WebKit' && parseInt(engine.version, 10) > 604
        : (this.platform.browser.name === 'Mobile Safari' ||
            this.platform.browser.name === 'Chrome') &&
            parseFloat(this.platform.os.version || '11.0') > 11.0;
    }

    return false;
  }

  private validateLocalStorage(): boolean {
    try {
      localStorage.setItem(LOCAL_STORAGE_CHECK_KEY, '0');
      localStorage.removeItem(LOCAL_STORAGE_CHECK_KEY);
      return true;
    } catch (e) {
      return false;
    }
  }

  private validatePcBrowser(): boolean {
    if (
      this.shouldWorkDevices.includes('PC') &&
      SUPPPORTED_PC_OS_NAMES.has(this.platform.os.name || '')
    ) {
      return (
        this.platform.browser.name === 'Safari' ||
        this.platform.browser.name === 'Chrome' ||
        this.platform.browser.name === 'Firefox' ||
        (this.platform.browser.name === 'IE' && this.platform.browser.major === '11') ||
        this.platform.browser.name === 'Edge'
      );
    }

    return false;
  }
}

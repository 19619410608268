/**
 * @fileOverview
 * @name PreviewCustomerRepository.ts
 * @author Mana Horie
 * @license
 */

import { fetchService } from '@s/io/fetchService';
import { required } from '@s/assertions';
import { CreatableRepository } from '@s/Repository';
import { apiEndpoint } from '@c/config';
import { getAuth } from 'firebase/auth';

export type PreviewCustomerCreateRepository = CreatableRepository<
  { operatorId: string; projectId: string; scenarioVersionId?: string },
  { token: string }
>;
type P = Params<PreviewCustomerCreateRepository>;

export class PreviewCustomerRepository implements PreviewCustomerCreateRepository {
  public async create(data: P['create'][0]): Promise<{ token: string }> {
    const user = getAuth().currentUser;
    const idToken = required(await user?.getIdToken());
    return await fetchService(apiEndpoint(`/operator/${data.operatorId}/preview-customer`), {
      method: 'POST',
      data: {
        projectId: data.projectId,
        scenarioVersionId: data.scenarioVersionId,
      },
      responseType: 'json',
      headers: { authorization: `Bearer ${idToken}` },
    });
  }
}

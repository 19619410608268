/**
 * @fileOverview
 * @name Queue.ts
 * @author Taketoshi Aono
 * @license
 */

export interface Queuable<T> {
  push(v: T): void;
  pop(): T | undefined;
  peek(): T | undefined;
  toArray(): T[];
  length: number;
}

export class Queue<T> implements Queuable<T> {
  public length = 0;

  private readonly st1: T[] = [];
  private readonly st2: T[] = [];

  public *[Symbol.iterator]() {
    for (let i = this.st2.length - 1; i >= 0; i--) {
      yield this.st2[i];
    }
    for (const k of this.st1) {
      yield k;
    }
  }

  public entries(): IterableIterator<[number, T]> {
    let i = 1;
    let j = 0;
    let k = 0;
    return {
      [Symbol.iterator]() {
        return this;
      },
      next: (...args: any[]) => {
        if (this.st2.length >= i) {
          return {
            value: [k++, this.st2[this.st2.length - i++]],
            done: false,
          };
        } else if (this.st2.length <= i && !this.st1.length) {
          return { value: undefined, done: true };
        }

        return this.st1.length > j
          ? { value: [k++, this.st1[j++]], done: false }
          : { value: undefined, done: true };
      },
    };
  }

  public peek(): T | undefined {
    return this.st2.length ? this.st2[this.st2.length - 1] : this.st1[0];
  }

  public pop(): T | undefined {
    if (this.length > 0) {
      this.length--;
    }
    if (this.st2.length) {
      return this.st2.pop();
    }

    while (this.st1.length) {
      this.st2.push(this.st1.pop()!);
    }

    return this.st2.pop();
  }

  public push(v: T): void {
    this.st1.push(v);
    this.length++;
  }

  public toArray(): T[] {
    const ret = [];
    for (let i = this.st2.length - 1; i >= 0; i--) {
      ret.push(this.st2[i]);
    }
    return ret.concat(this.st1);
  }
}

/**
 * @fileOverview
 * @name ConsoleIcon.tsx
 * @author Taketoshi Aono
 * @license
 */

import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

declare const VITE_DERIVERY_URL: string;

const getFontUrl = (fontFileName: string) => {
  if (process.env.NODE_ENV === 'production')
    // NOTE: デプロイ時には配信されているフォントの絶対パスで取得する
    return `${VITE_DERIVERY_URL}/icons/${fontFileName}`;

  return `/icons/${fontFileName}`;
};

export const aimIconCss = css`
  @font-face {
    font-family: 'icons';
    src: url(${getFontUrl('icons.ttf')}) format('truetype'),
    src: url(${getFontUrl('icons.woff')}) format('woff'),
    src: url(${getFontUrl('icons.woff2')}) format('woff2'),
    src: url(${getFontUrl('icons.eot')}) format('embedded-opentype');
    src: url(${getFontUrl('icons.svg')}) format('svg');
  }
`;

export const aimIconBasicStyle = ({
  size,
  css: c,
  color,
}: {
  size: string;
  css?: SerializedStyles;
  color: string;
}) =>
  css`
    text-transform: initial !important;
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    width: ${size};
    height: ${size};
    color: ${color};
    font-size: ${size};
    transition: all 0.3s;
    ${c ? c : ''};
  `;

const IconContainerElement = styled.span<{
  size: string;
  color: string;
  css?: SerializedStyles;
}>`
  ${p => aimIconBasicStyle(p)};
`;

export const iconType = {
  crossbar: 'a',
  refresh: 'b',
  picture: 'c',
  send: 'd',
  chat: 'e',
  llmchat: 'e',
  cloud: 'f',
  sun: 'g',
  rain: 'h',
  trashcan: 'i',
  plus: 'j',
  queryScenarioEditor: 'k',
  dashboard: 'l',
  aiCompass: 'm',
  search: 'n',
  check: 'o',
  edit: 'p',
  forward: 'q',
  user: 'r',
  ascendant: 's',
  descendant: 't',
  arrowdown: 'u',
  arrowup: 'v',
  versions: 'w',
  preview: 'x',
  upload: 'y',
  leftArrow: 'z',
  rightArrow: 'A',
  logout: 'B',
  publish: 'C',
  customize: 'D',
  support: 'E',
  clock: 'F',
  chatOperator: 'G',
  project: 'H',
  assignChat: 'I',
  closeChat: 'J',
  information: 'K',
  customers: 'L',
  botConfig: 'M',
  tag: 'N',
  tagInspector: 'O',
  caution: 'P',
  variables: 'Q',
  normalConnector: 'R',
  branchConnector: 'S',
  addNewScene: 'T',
  existsScene: 'U',
  voice: 'V',
  call: 'W',
  instagram: 'X',
  line: 'Y',
  api: 'Z',
  phone_transfer: '0',
  ivr: '1',
  scenario_jump: '2',
  entity: '3',
  sms: '4',
  mail: '5',
  convert: '6',
  action: '7',
  gear: '8',
  revert: '9',
  phoneNumbers: '\ue001',
  copy: '\ue002',
  bell: '\ue003',
  summary: 'V',
  dialogEngineVoice: 'V', // FIXME: 一旦Voiceと同じアイコンを使う
};

export const Icon = compareOnlyProperties(
  ({
    name,
    additionalCss,
    size = 20,
    color = '#000',
    className = '',
  }: {
    name: keyof typeof iconType | 'back';
    additionalCss?: SerializedStyles;
    size?: string | number;
    color?: string;
    className?: string;
  }) => {
    let style = {} as any;
    if (name === 'back') {
      style = { transform: 'rotate(180deg)' };
      name = 'forward';
    }
    return (
      <IconContainerElement
        size={typeof size === 'number' ? `${size}px` : size}
        color={color}
        css={additionalCss}
        style={style}
        className={className}
      >
        {iconType[name]}
      </IconContainerElement>
    );
  },
  'Icon'
);

/**
 * @fileOverview
 * @name TrashButton.tsx
 * @author Taketoshi Aono
 * @license
 */

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icon, iconType } from '../Icon';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

const style = css`
  background: transparent;
  border: 0;
  padding: 0;
  transition: all 0.2s;
  outline: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 255, 0);
  border-radius: 99999px;
`;
const abledStyle = css`
  cursor: pointer;
  &:focus {
    position: relative;
    z-index: 1;
    > span {
      color: #0099ff !important;
    }
  }
  &:hover {
    > span {
      color: #0066ff;
    }
  }
`;
const IconOnlyButtonContainerElement = styled.button<{ disabled: boolean }>`
  ${style};
  ${p => !p.disabled && abledStyle};
`;
const IconOnlyButtonContainerLinkElement = styled.a<{ disabled: boolean }>`
  ${style};
  ${p => !p.disabled && abledStyle};
`;

export const IconOnlyButton = compareOnlyProperties(
  ({
    name,
    size,
    link,
    className = '',
    color = '#222222',
    disabledColor = '#cccccc',
    type,
    disabled = false,
    onClick,
  }: {
    name: keyof typeof iconType | 'back';
    size: number;
    className?: string;
    color?: string;
    disabledColor?: string;
    link?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    onClick(): void;
  }) => {
    return !link ? (
      <IconOnlyButtonContainerElement
        type={type}
        tabIndex={0}
        onClick={disabled ? () => {} : () => onClick()}
        className={className}
        disabled={disabled}
      >
        <Icon size={size} name={name} color={disabled ? disabledColor : color} />
      </IconOnlyButtonContainerElement>
    ) : (
      <IconOnlyButtonContainerLinkElement
        href={link}
        tabIndex={0}
        onClick={disabled ? () => {} : () => onClick()}
        className={className}
        disabled={disabled}
      >
        <Icon size={size} name={name} color={disabled ? disabledColor : color} />
      </IconOnlyButtonContainerLinkElement>
    );
  },
  'IconOnlyButton'
);

/**
 * @fileOverview
 * @name PreviewRepository.ts
 * @author Mana Horie
 * @license
 */

import { CreatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';

export type PreviewCreatableRepository = CreatableRepository<{ projectId: string }, {}>;
type P = Params<PreviewCreatableRepository>;

export class PreviewRepository implements PreviewCreatableRepository {
  public async create({ projectId }: P['create'][0]): Promise<{}> {
    return fetchService<{}>(apiEndpoint(`project/${projectId}/scenario/preview`), {
      method: 'POST',
      responseType: 'json',
      data: {},
    });
  }
}

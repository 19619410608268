/**
 * @fileOverview
 * @name FirestoreConnection.ts
 * @author Taketoshi Aono
 * @license
 */

import { FirestoreHandleable } from './FirestoreHandler';
import { ENV, staticConfig } from '@c/config';
import { ChatAssignType } from '@c/domain/entities/Chat';
import {
  CollectionReference,
  DocumentData,
  collection,
  collectionGroup,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
  Timestamp,
  Query,
  doc,
  DocumentReference,
} from 'firebase/firestore';

export interface FirestoreConnectable {
  connectToPing({
    tenantId,
    operatorId,
  }: {
    tenantId: string;
    operatorId: string;
  }): DocumentReference<DocumentData>;
  connectToIncidentStore(a: {
    tenantId: string;
    operatorId: string;
  }): CollectionReference<DocumentData>;
  connectToOperatorTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): DocumentReference<DocumentData>;
  connectToCustomerTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): DocumentReference<DocumentData>;
  connectToConversationViews(a: {
    tenantId: string;
    operatorId?: string;
    filterType: ChatAssignType;
    projectId: string;
  }): Query<DocumentData>;
  connectToChatMessages(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): CollectionReference;
  connectToArchives(a: {
    tenantId: string;
    projectId?: string | string[];
    archivedDateRange?: { from: Date; to: Date };
  }): Query<DocumentData>[];
  connectToCustomer(a: { tenantId: string; customerId: string }): DocumentReference<DocumentData>;
  connectToArchivedConversations(a: {
    tenantId: string;
    customerId: string;
    projectId: string | string[];
  }): CollectionReference<DocumentData>[];
  connectToVoiceConversation(a: { tenantId: string; projectId: string }): Query<DocumentData>;
  connectToVoiceConversationEvents(a: {
    tenantId: string;
    voiceCustomerId: string;
    projectId: string;
    conversationId: string;
  }): CollectionReference;
}

export class FirestoreConnector implements FirestoreConnectable {
  public constructor(private readonly firestoreHandler: FirestoreHandleable) {}

  public connectToArchivedConversations({
    customerId,
    projectId,
    tenantId,
  }: Parameters<
    FirestoreConnectable['connectToArchivedConversations']
  >[0]): CollectionReference<DocumentData>[] {
    const projectIds = Array.isArray(projectId) ? projectId : [projectId];
    const db = getFirestore();
    return projectIds.map(projectId => {
      const collectionRef = collection(
        db,
        staticConfig.datasourceEnv,
        tenantId,
        'customers',
        customerId,
        'projects',
        projectId,
        'archives'
      );

      return collectionRef;
    });
  }

  public connectToArchives({
    projectId,
    tenantId,
    archivedDateRange,
  }: Parameters<FirestoreConnectable['connectToArchives']>[0]): Query<DocumentData>[] {
    const projectIds = Array.isArray(projectId) ? projectId : [projectId];
    const ret = [];
    const db = getFirestore();
    const archiveCollectionGroup = collectionGroup(db, 'archives');
    for (let i = 0; i < projectIds.length; i += 10) {
      const query_ = query(
        archiveCollectionGroup,
        where('tenantId', '==', tenantId),
        where('projectId', 'in', projectIds.slice(i, i + 10))
      );

      const filtered = archivedDateRange
        ? query(
            query_,
            where('archivedAt', '>=', Timestamp.fromDate(archivedDateRange.from)),
            where('archivedAt', '<=', Timestamp.fromDate(archivedDateRange.to)),
            orderBy('archivedAt', 'desc')
          )
        : query_;

      ret.push(query(filtered, limit(5000)));
    }
    return ret;
  }

  public connectToChatMessages({
    tenantId,
    customerId,
    projectId,
  }: Parameters<FirestoreConnectable['connectToChatMessages']>[0]): ReturnType<
    FirestoreConnectable['connectToChatMessages']
  > {
    const db = getFirestore();

    const collectionRef = collection(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationEvents'
    );

    return collectionRef;
  }

  public connectToConversationViews({
    tenantId,
    filterType,
    operatorId,
    projectId,
  }: Parameters<FirestoreConnectable['connectToConversationViews']>[0]): ReturnType<
    FirestoreConnectable['connectToConversationViews']
  > {
    const db = getFirestore();
    const collectionRef = collection(db, staticConfig.datasourceEnv, tenantId, 'conversations');

    let filtered =
      filterType === ChatAssignType.SELF
        ? query(collectionRef, where('operatorId', '==', operatorId))
        : filterType === ChatAssignType.NOW_RESPONDING
        ? query(collectionRef, where('operatorChatStarted', '==', true))
        : filterType === ChatAssignType.NOT_RESPONDING
        ? query(collectionRef, where('callingOperator', '==', true))
        : query(collectionRef);

    if (projectId) {
      filtered = query(filtered, where('projectId', '==', projectId));
    }

    return query(filtered, orderBy('updatedAt', 'desc'), limit(1500));
  }

  public connectToCustomer({
    tenantId,
    customerId,
  }: Parameters<FirestoreConnectable['connectToCustomer']>[0]): ReturnType<
    FirestoreConnectable['connectToCustomer']
  > {
    const db = getFirestore();
    const collectionRef = doc(db, staticConfig.datasourceEnv, tenantId, 'customers', customerId);

    return collectionRef;
  }

  public connectToCustomerTyping({
    customerId,
    projectId,
    tenantId,
  }: Parameters<
    FirestoreConnectable['connectToCustomerTyping']
  >[0]): DocumentReference<DocumentData> {
    const db = getFirestore();
    const docRef = doc(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationStatusEvents',
      'customerTyping'
    );

    return docRef;
  }

  public connectToPing({
    operatorId,
    tenantId,
  }: Parameters<FirestoreConnectable['connectToPing']>[0]): ReturnType<
    FirestoreConnectable['connectToPing']
  > {
    const db = getFirestore();
    const docRef = doc(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'operator',
      operatorId,
      'ping',
      `${Date.now()}`
    );

    return docRef;
  }

  public connectToIncidentStore({
    operatorId,
    tenantId,
  }: Parameters<FirestoreConnectable['connectToIncidentStore']>[0]): ReturnType<
    FirestoreConnectable['connectToIncidentStore']
  > {
    const db = getFirestore();
    const collectionRef = collection(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'operator',
      operatorId,
      'operator_incident_state'
    );

    return collectionRef;
  }

  public connectToOperatorTyping({
    customerId,
    projectId,
    tenantId,
  }: Parameters<
    FirestoreConnectable['connectToOperatorTyping']
  >[0]): DocumentReference<DocumentData> {
    const db = getFirestore();
    const docRef = doc(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationStatusEvents',
      'operatorTyping'
    );

    return docRef;
  }

  public connectToVoiceConversation({
    tenantId,
    projectId,
  }: Parameters<FirestoreConnectable['connectToVoiceConversation']>[0]): Query<DocumentData> {
    const db = getFirestore();
    const collectionGroupRef = collectionGroup(db, 'conversations');
    const query_ = query(
      collectionGroupRef,
      where('for_query.env', '==', ENV),
      where('for_query.tenant_id', '==', tenantId),
      where('for_query.project_id', '==', projectId),
      where('is_active', '==', true),
      where('status', '==', '転送済')
    );

    return query_;
  }

  public connectToVoiceConversationEvents({
    tenantId,
    voiceCustomerId,
    projectId,
    conversationId,
  }: Parameters<
    FirestoreConnectable['connectToVoiceConversationEvents']
  >[0]): CollectionReference<DocumentData> {
    const db = getFirestore();
    const collectionRef = collection(
      db,
      staticConfig.datasourceEnv,
      tenantId,
      'customers',
      voiceCustomerId,
      'projects',
      projectId,
      'conversations',
      conversationId,
      'conversation_events'
    );

    return collectionRef;
  }
}

/**
 * @fileOverview
 * @author
 */

import {
  categorySelected,
  fetchMatchingTrainingClustersSucceeded,
  fetchSimilarQueriesSucceeded,
  newScenarioInitialized,
  newAnswerFeedbackStateUpdated,
  newAnswerScenarioEditorClosed,
  linkNewAnswerSucceeded,
  linkExistingQuerySucceeded,
  nowClustering,
  excludeClusterSucceeded,
  aiCompassMatchingTriningCleaned,
  newAnswerSwitchToOpStateUpdated,
} from '@c/modules/aicompassMatching/action';
import { State } from '@c/state';
import produce from 'immer';
import { SceneNodeCache } from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { required } from '@s/assertions';

type ActionTypes = ReturnType<
  | typeof categorySelected
  | typeof fetchMatchingTrainingClustersSucceeded
  | typeof fetchSimilarQueriesSucceeded
  | typeof newScenarioInitialized
  | typeof newAnswerFeedbackStateUpdated
  | typeof newAnswerSwitchToOpStateUpdated
  | typeof newAnswerScenarioEditorClosed
  | typeof linkNewAnswerSucceeded
  | typeof linkExistingQuerySucceeded
  | typeof nowClustering
  | typeof excludeClusterSucceeded
  | typeof aiCompassMatchingTriningCleaned
>;

export default (state: State['aicompass'], action: ActionTypes) => {
  return produce(state, state => {
    switch (action.type) {
      case 'AICOMPASS_MATCHING_CATEGORY_SELECTED':
        state.matchingTraining.selectedCluster = null;
        state.matchingTraining.selectedCategory = action.payload.category;
        return;
      case 'FETCH_MATCHING_TRAINING_CLUSTERS_SUCCEEDED':
        state.matchingTraining.queryClusters = action.payload;
        state.matchingTraining.clusteringInProgress = false;
        return;
      case 'FETCH_SIMILAR_QUERIES_SUCCEEDED':
        state.matchingTraining.selectedCluster = action.payload.cluster;
        state.matchingTraining.similarQueries = action.payload.similarQueries;
        return;
      case 'NEW_SCENARIO_INITIALIZED':
        state.matchingTraining.newAnswer.updated = false;
        state.matchingTraining.newAnswer.selectedScenario = {
          scenes: {
            updated: false,
            feed: false,
            switchToOp: false,
            id: '0',
            dimensions: {},
            isAnalytics: false,
            nodes: new SceneNodeCache([]),
          },
        };
        return;
      case 'NEW_ANSWER_FEEDBACK_STATE_UPDATED':
        required(state.matchingTraining.newAnswer.selectedScenario).scenes.feed = action.payload;
        state.matchingTraining.newAnswer.updated = true;
        state.matchingTraining.newAnswer.saveRequired = true;
        return;
      case 'NEW_ANSWER_SWITCH_TO_OP_STATE_UPDATED':
        required(state.matchingTraining.newAnswer.selectedScenario).scenes.switchToOp =
          action.payload;
        state.matchingTraining.newAnswer.updated = true;
        state.matchingTraining.newAnswer.saveRequired = true;
        return;
      case 'NEW_ANSWER_SCENARIO_EDITOR_CLOSED':
        state.matchingTraining.queryClusters = state.matchingTraining.queryClusters.filter(
          v => v.clusterId !== action.payload
        );
        state.matchingTraining.newAnswer.updated = false;
        state.matchingTraining.newAnswer.selectedScenario = undefined;
        return;
      case 'LINK_NEW_ANSWER_SUCCEEDED':
        state.matchingTraining.newAnswer.updated = false;
        state.matchingTraining.newAnswer.saveRequired = false;
        return;
      case 'LINK_EXISTING_QUERY_SUCCEEDED':
        state.matchingTraining.queryClusters = state.matchingTraining.queryClusters.filter(
          v => v.clusterId !== state.matchingTraining.selectedCluster?.clusterId
        );
        state.matchingTraining.selectedCluster = null;
        return;
      case 'NOW_CLUSTERING':
        state.matchingTraining.clusteringInProgress = true;
        return;
      case 'EXCLUDE_CLUSTER_SUCCEEDED':
        state.matchingTraining.queryClusters = state.matchingTraining.queryClusters.filter(
          v => v.clusterId !== state.matchingTraining.selectedCluster?.clusterId
        );
        state.matchingTraining.selectedCluster = null;
        return;
      case 'AICOMPASS_MATCHING_TRAINING_CLEANED':
        state.matchingTraining.selectedCluster = null;
        state.matchingTraining.similarQueries = null;
        return;
      default:
        return state;
    }
  });
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import * as idbKeyVal from 'idb-keyval';

export type StorageType = 'session' | 'local' | 'idb';

export interface StorageAccessor {
  delete(key: string): Promise<void>;
  set<Value>(key: string, value: Value): Promise<void>;
  get<Value>(key: string): Promise<Value | undefined>;
  get<Value>(key: string, defaultValue?: Value, force?: boolean): Promise<Value>;
}

class StorageAccessorImpl implements StorageAccessor {
  private static cachedResult: { [key: string]: any } = {};

  public constructor(private readonly type: StorageType) {}

  public async delete(key: string) {
    switch (this.type) {
      case 'session': {
        sessionStorage.removeItem(key);
        break;
      }
      case 'local': {
        localStorage.removeItem(key);
        break;
      }
      case 'idb':
        await idbKeyVal.del(key);
        break;
      default:
        const unusedCheck: never = this.type;
        throw new Error('Unknown type.');
    }
    delete StorageAccessorImpl.cachedResult[key];
  }

  public async get<Value>(key: string): Promise<Value | undefined>;
  public async get<Value>(
    key: string,
    defaultValue?: Value,
    isForceUpdateCache?: boolean
  ): Promise<Value> {
    try {
      if (!isForceUpdateCache && StorageAccessorImpl.cachedResult[key]) {
        return StorageAccessorImpl.cachedResult[key];
      }
      let result;
      switch (this.type) {
        case 'session': {
          const v = sessionStorage.getItem(key);

          result = v != null ? JSON.parse(v) : defaultValue;
          break;
        }
        case 'local': {
          const v = localStorage.getItem(key);

          result = v != null ? JSON.parse(v) : defaultValue;
          break;
        }
        case 'idb':
          const ret = await (async () => idbKeyVal.get<Value>(key))();
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          result = ret !== undefined ? ret : defaultValue;
          break;
        default:
          const unusedCheck: never = this.type;
          throw new Error('Unknown type.');
      }

      return (StorageAccessorImpl.cachedResult[key] = result);
    } catch (e) {
      return defaultValue!;
    }
  }

  public async set<Value>(key: string, value: Value): Promise<void> {
    delete StorageAccessorImpl.cachedResult[key];
    try {
      switch (this.type) {
        case 'session': {
          const v = JSON.stringify(value);
          sessionStorage.setItem(key, v);
          break;
        }
        case 'local': {
          const v = JSON.stringify(value);
          localStorage.setItem(key, v);
          break;
        }
        case 'idb':
          await (async () => idbKeyVal.set(key, value))();
          break;
        default:
          const unusedCheck: never = this.type;
          throw new Error('Unknown type.');
      }
      StorageAccessorImpl.cachedResult[key] = value;
    } catch (e) {}
  }
}

export function storage(type: StorageType): StorageAccessor {
  return new StorageAccessorImpl(type);
}

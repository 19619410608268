/**
 * @fileOverview
 * This repository process mainly authorization processes like below.
 * - Check current logged in state.
 * - Login request.
 *
 * @name AccountRepository.tsx
 * @author Taketoshi Aono
 * @license
 */

import { AuthEntity } from '@c/domain/entities/AuthEntity';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint, ENV, TENANT_NAME } from '@c/config';
import { getErrorMessageAndAction } from '@c/io/firebaseErrorHandler';
import { CrashReporter } from '@s/crashreporting/CrashReporter';
import { required } from '@s/assertions';
import { genericError } from '@c/application/GenericError';
import asyncRetry from 'async-retry';
import { createRetryHandler } from '@s/io/createRetryHandler';
import {
  ActionCodeURL,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  linkWithCredential,
  multiFactor,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  updateEmail,
  updatePassword,
  updateProfile,
  UserCredential,
  getMultiFactorResolver,
} from 'firebase/auth';
import { setUserToSentry } from '@s/sentry/config';
export type VerificationId = string;

export interface AccountEditableRepository {
  isLoggedIn(a: { entity: AuthEntity | null; forceUpdate?: boolean }): Promise<AuthEntity | null>;
  loginWithEmailLink(email: string): Promise<void>;
  loginWithPasswordWithoutMultiFactor(payload: {
    id: string;
    password: string;
  }): Promise<AuthEntity>;
  loginWithPassword(payload: {
    id: string;
    password: string;
  }): Promise<[AuthEntity | undefined, MultiFactorInfo[]]>;
  reAuthenticate(payload: { id: string; password: string }): Promise<void>;
  loginWithFacebook(accessToken: string): Promise<AuthEntity>;
  loguot(): Promise<void>;
  issueWithFb(fbAccessToken: string, email: string): Promise<void>;
  issue(payload: { displayName: string; password: string }): Promise<void>;
  updateEmail(a: { email: string }): Promise<void>;
  updatePassword(a: { password: string }): Promise<void>;
  updateDisplayName(a: { displayName: string }): Promise<void>;
  sendRemindPasswordEmail(a: { email: string }): Promise<void>;
  initializeRecaptchaVerifier(a: { containerId: string }): Promise<void>;
  registerPhoneNumber(a: { phoneNumber: string }): Promise<VerificationId>;
  verifyMultiFactorCodeInRegister(a: {
    verificationId: string;
    verificationCode: string;
    displayName: string;
  }): Promise<AuthEntity>;
  verifyMultiFactorCode(a: {
    verificationId: string;
    verificationCode: string;
  }): Promise<AuthEntity>;
  sendVerificationCode(a: { selectedIndex: number }): Promise<VerificationId>;
  clearMultiFactorParameter(): void;
}

const isLoggedInRetryHandler = createRetryHandler<AuthEntity | null>();
export class AccountRepository implements AccountEditableRepository {
  private recaptchaVerifier?: RecaptchaVerifier = undefined;
  private resolver?: MultiFactorResolver = undefined;
  private authUpdatePromise: Promise<AuthEntity | null> | null = null;

  public clearMultiFactorParameter = () => {
    this.recaptchaVerifier = undefined;
    this.resolver = undefined;
  };

  public async initializeRecaptchaVerifier(a: { containerId: string }): Promise<void> {
    return new Promise(async resolve => {
      if (this.recaptchaVerifier) {
        resolve();
      }
      this.recaptchaVerifier = new RecaptchaVerifier(
        a.containerId,
        {
          size: 'normal',
          callback: function () {
            resolve();
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'expired-callback': function () {},
        },
        getAuth()
      );
      await this.recaptchaVerifier.render();
    });
  }

  public async isLoggedIn({ entity }: { entity: AuthEntity | null }): Promise<AuthEntity | null> {
    if (this.authUpdatePromise) {
      return this.authUpdatePromise;
    }
    this.authUpdatePromise = isLoggedInRetryHandler('auth-check', async () => {
      const user = getAuth().currentUser;
      if (user) {
        let idToken = await user.getIdTokenResult();
        if (!idToken.claims?.aim_user_id) {
          idToken = await user.getIdTokenResult(true);
        } else if (entity?.token === idToken.token) {
          return entity;
        }
        const isFacebookProvider = !!user.providerData.find(p => p.providerId === 'facebook.com');
        return {
          token: idToken.token,
          providerId: isFacebookProvider ? 'facebook' : 'password',
          user: {
            displayName: user.displayName ?? '',
            email: user.email ?? '',
          },
          claims: idToken.claims as any,
          visibilityLevel: idToken.claims.visibility_level,
          operatorRoleType: idToken.claims.operator_role_type,
        } as AuthEntity;
      }
    }).finally(() => {
      this.authUpdatePromise = null;
    });
    return this.authUpdatePromise;
  }

  public async issue({
    displayName,
    email,
    password,
  }: {
    displayName: string;
    email: string;
    password: string;
  }): Promise<void> {
    if (!isSignInWithEmailLink(getAuth(), location.href)) {
      throw new Error('メールリンクのURLが期限切れ、または不正です');
    }
    CrashReporter.getInstance().login({ email, displayName });
    return new Promise(async (resolve, reject) => {
      const user = getAuth().currentUser;
      if (user) {
        await asyncRetry(
          async () => {
            await updatePassword(user, password);
          },
          { retries: 2 }
        ).catch(e => {
          reject(e);
        });

        await asyncRetry(
          async () => {
            await signInWithEmailAndPassword(getAuth(), email, password);
          },
          { retries: 2 }
        ).catch(e => {
          reject(e);
        });

        await asyncRetry(
          async () => {
            await updateProfile(getAuth().currentUser!, { displayName });
          },
          { retries: 2 }
        ).catch(e => {
          reject(e);
        });

        const token = await asyncRetry(
          async () => {
            return await getAuth().currentUser!.getIdTokenResult(true);
          },
          { retries: 2 }
        ).catch(e => {
          reject(
            genericError({ message: 'トークンの取得に失敗しました。もう一度試してください。' })
          );
          return null;
        });

        if (token) {
          await asyncRetry(
            async () =>
              fetchService(apiEndpoint(`operator/verify`), {
                method: 'POST',
                responseType: 'json',
                data: { email },
                headers: { authorization: `Bearer ${token.token}` },
              }),
            { retries: 2 }
          ).catch(reject);
          resolve();
        } else {
          reject(
            genericError({ message: 'トークンの取得に失敗しました。もう一度試してください。' })
          );
        }

        await asyncRetry(
          // operator/verify によってサーバーで更新されたIDトークンを再取得
          async () => getAuth().currentUser!.getIdTokenResult(true),
          { retries: 2 }
        ).catch(e => {
          reject(
            genericError({ message: 'トークンの取得に失敗しました。もう一度試してください。' })
          );
        });
      } else {
        reject(genericError({ message: '認証に失敗しました。もう一度試してください。' }));
      }
    });
  }

  public async issueWithFb(fbaccessToken: string, email: string): Promise<void> {
    if (!getAuth().currentUser) {
      throw new Error('Invalid state');
    }
    await new Promise<void>(async (resolve, reject) => {
      let user;
      let providerData = getAuth().currentUser!.providerData.find(
        data => data?.providerId === 'facebook.com'
      );

      if (!providerData) {
        const credential = FacebookAuthProvider.credential(fbaccessToken);
        linkWithCredential(getAuth().currentUser!, credential)
          .then(usercred => {
            user = usercred.user;
            providerData = getAuth().currentUser!.providerData.find(
              data => data?.providerId === 'facebook.com'
            );
          })
          .catch(error => {});
      } else {
        user = getAuth().currentUser!;
      }

      if (user && providerData?.displayName) {
        await updateProfile(user, { displayName: providerData.displayName });
        // sentry login
        setUserToSentry({
          email,
          username: providerData.displayName,
          tenantName: TENANT_NAME ?? '',
        });
        CrashReporter.getInstance().login({
          email,
          displayName: providerData.displayName,
        });
        try {
          const token = await user.getIdTokenResult(true).catch(e => {
            reject(e);
            return null;
          });
          if (token) {
            await fetchService(apiEndpoint(`operator/verify`), {
              method: 'POST',
              responseType: 'json',
              data: { email: user.email },
              headers: { authorization: `Bearer ${token.token}` },
            });
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  public async loginWithEmailLink(email: string): Promise<void> {
    if (getAuth().currentUser) {
      try {
        const idTokenResult = await getAuth().currentUser?.getIdTokenResult();
        if (idTokenResult?.claims.verified) {
          await this.loguot();
        }
      } catch (e: unknown) {
        throw new Error(
          'すでに別ユーザでログインしてるのでログアウト処理を実行しましたが、失敗しました'
        );
      }
    }

    if (!getAuth().currentUser) {
      const actionCodeUrl = ActionCodeURL.parseLink(location.href);
      if (actionCodeUrl?.tenantId) {
        getAuth().tenantId = actionCodeUrl.tenantId;
      }
      await signInWithEmailLink(getAuth(), email, location.href).catch(e => {
        throw new Error(getErrorMessageAndAction(e.code).message);
      });
    }
  }

  public async loginWithFacebook(accessToken: string): Promise<AuthEntity> {
    const oauthCredential = FacebookAuthProvider.credential(accessToken);
    const userCredential: UserCredential = await signInWithCredential(getAuth(), oauthCredential);
    if (userCredential.user) {
      const idToken = await userCredential.user.getIdTokenResult(true);
      return {
        token: idToken.token,
        user: {
          displayName: userCredential.user.displayName ?? '',
          email: userCredential.user.email ?? '',
        },
        fbAccessToken: accessToken,
        providerId: 'facebook',
        visibilityLevel: idToken.claims.visibility_level,
        operatorRoleType: idToken.claims.operator_role_type,
        claims: idToken.claims as any,
      };
    }

    throw new Error('failed to login');
  }

  public async loginWithPassword({
    id,
    password,
  }: {
    id: string;
    password: string;
  }): Promise<[AuthEntity | undefined, MultiFactorInfo[]]> {
    return new Promise(async (resolve, reject) => {
      await signInWithEmailAndPassword(getAuth(), id.toLowerCase(), password)
        .then(async userCredential => {
          if (userCredential.user) {
            let idToken = await userCredential.user.getIdTokenResult();
            if (!idToken.claims?.aim_user_id) {
              idToken = await userCredential.user.getIdTokenResult(true);
            }
            resolve([
              {
                token: idToken.token,
                user: {
                  displayName: userCredential.user.displayName ?? '',
                  email: userCredential.user.email ?? '',
                },
                providerId: 'password',
                visibilityLevel: idToken.claims.visibility_level,
                operatorRoleType: idToken.claims.operator_role_type,
                claims: idToken.claims as any,
              },
              [],
            ]);
          }
        })
        .catch(error => {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.resolver = getMultiFactorResolver(getAuth(), error);
            return resolve([undefined, this.resolver?.hints || []]);
          } else if (error.code === 'auth/too-many-requests') {
            return reject(
              genericError({
                message:
                  'リクエスト回数が多すぎる為、ログインに失敗しました。時間を置いてログインしてください。',
              })
            );
          }
          return reject(genericError({ message: 'ログインに失敗しました' }));
        });
    });
  }

  public async loginWithPasswordWithoutMultiFactor({
    id,
    password,
  }: {
    id: string;
    password: string;
  }): Promise<AuthEntity> {
    const userCredential = await signInWithEmailAndPassword(getAuth(), id.toLowerCase(), password);
    if (userCredential.user) {
      let idToken = await userCredential.user.getIdTokenResult();
      if (!idToken.claims?.aim_user_id) {
        idToken = await userCredential.user.getIdTokenResult(true);
      }
      return {
        token: idToken.token,
        user: {
          displayName: userCredential.user.displayName ?? '',
          email: userCredential.user.email ?? '',
        },
        providerId: 'password',
        visibilityLevel: idToken.claims.visibility_level,
        operatorRoleType: idToken.claims.operator_role_type,
        claims: idToken.claims as any,
      };
    }

    throw new Error('failed to login');
  }

  public async reAuthenticate({ id, password }: { id: string; password: string }): Promise<void> {
    const credential = EmailAuthProvider.credential(id, password);
    try {
      const user = getAuth().currentUser;
      await reauthenticateWithCredential(user!, credential);
    } catch (error) {
      throw new Error('failed to reauthenticate');
    }
  }

  public async loguot(): Promise<void> {
    await getAuth().signOut();
  }

  public async registerPhoneNumber(a: { phoneNumber: string }): Promise<VerificationId> {
    const user = getAuth().currentUser;
    if (this.recaptchaVerifier && user && multiFactor(user)) {
      return new Promise(async (resolve, reject) => {
        multiFactor(user)
          .getSession()
          .then(multiFactorSession => {
            const phoneInfoOptions = {
              phoneNumber: a.phoneNumber,
              session: multiFactorSession,
            };
            const phoneAuthProvider = new PhoneAuthProvider(getAuth());
            phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier!)
              .then(function (verificationId) {
                resolve(verificationId);
              })
              .catch(error => {
                if (error.code === 'auth/second-factor-already-in-use') {
                  reject(
                    genericError({
                      message: '既に登録された電話番号です。ログインからやり直してください。',
                    })
                  );
                } else {
                  reject(
                    genericError({
                      message: '電話番号の登録に失敗しました。ログインからやり直してください。',
                    })
                  );
                }
              });
          });
      });
    }
    throw new Error('電話番号の登録に失敗しました。ログインからやり直してください。');
  }

  public async sendRemindPasswordEmail({ email }: { email: string }): Promise<void> {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email, {
      url: `${location.protocol}//${location.host}/${
        location.hostname === 'localhost' ? `${ENV}/${TENANT_NAME}/` : ''
      }login`,
      handleCodeInApp: true,
    });
  }

  public async sendVerificationCode({
    selectedIndex,
  }: {
    selectedIndex: number;
  }): Promise<VerificationId> {
    if (this.resolver && this.recaptchaVerifier) {
      const phoneInfoOptions = {
        multiFactorHint: this.resolver.hints[selectedIndex],
        session: this.resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(getAuth());
      const verificationId = phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      );
      return verificationId;
    }

    throw new Error('failed to login');
  }

  public async updateDisplayName({ displayName }: { displayName: string }): Promise<void> {
    const auth = getAuth();
    const user = required(auth.currentUser);
    await updateProfile(user, { displayName });
  }

  public async updateEmail({ email }: { email: string }): Promise<void> {
    const auth = getAuth();
    const user = required(auth.currentUser);
    await updateEmail(user, email);
    await sendEmailVerification(user, {
      url: `${location.protocol}//${location.host}/${
        location.hostname === 'localhost' ? `${ENV}/${TENANT_NAME}/` : ''
      }login`,
      handleCodeInApp: true,
    });
  }

  public async updatePassword({ password }: { password: string }): Promise<void> {
    const auth = getAuth();
    const user = required(auth.currentUser);
    await updatePassword(user, password);
  }

  public async verifyMultiFactorCode({
    verificationId,
    verificationCode,
  }: {
    verificationId: string;
    verificationCode: string;
  }): Promise<AuthEntity> {
    if (this.resolver) {
      const res = this.resolver;
      return new Promise(async (resolve, reject) => {
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        res
          .resolveSignIn(multiFactorAssertion)
          .then(async userCredential => {
            if (userCredential.user) {
              const idToken = await userCredential.user.getIdTokenResult(true);
              resolve({
                token: idToken.token,
                user: {
                  displayName: userCredential.user.displayName ?? '',
                  email: userCredential.user.email ?? '',
                },
                providerId: 'password',
                visibilityLevel: idToken.claims.visibility_level,
                operatorRoleType: idToken.claims.operator_role_type,
                claims: idToken.claims as any,
              } as AuthEntity);
            }
          })
          .catch(() => {
            reject(
              genericError({
                message: '認証に失敗しました。ログインからやり直してください。',
              })
            );
          });
      });
    }
    throw new Error('failed to login');
  }

  public async verifyMultiFactorCodeInRegister(a: {
    verificationId: string;
    verificationCode: string;
    displayName: string;
  }): Promise<AuthEntity> {
    try {
      const user = getAuth().currentUser;
      const cred = PhoneAuthProvider.credential(a.verificationId, a.verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      return new Promise(async (resolve, reject) => {
        if (!user) {
          return reject(
            genericError({
              message: 'ユーザ情報の取得に失敗しました。ログインからやり直してください。',
            })
          );
        }
        return multiFactor(user)
          .enroll(multiFactorAssertion, a.displayName)
          .then(async () => {
            const idToken = await user.getIdTokenResult(true);
            resolve({
              token: idToken.token,
              user: {
                displayName: user.displayName ?? '',
                email: user.email ?? '',
              },
              providerId: 'password',
              visibilityLevel: idToken.claims.visibility_level,
              operatorRoleType: idToken.claims.operator_role_type,
              claims: idToken.claims as any,
            });
          })
          .catch(() => {
            reject(
              genericError({
                message: '電話番号の登録に失敗しました。ログインからやり直してください。',
              })
            );
          });
      });
    } catch (e) {
      throw new Error('failed to register phone number');
    }
  }
}

/**
 * @fileOverview
 * @name EventDispacher.ts
 * @author Taketoshi Aono
 * @license
 */

export class EventDispatcher<
  ReceivableEventType extends string,
  SendableEventType extends string = ReceivableEventType
> {
  private readonly eventMap = new Map<string, ((...args: any[]) => void)[]>();

  public dispatch(type: SendableEventType, ...args: any[]) {
    const e = this.eventMap.get(type);
    if (e) {
      e.forEach(h => h(...args));
    }
  }

  public get length(): number {
    return this.eventMap.size;
  }

  public off(type?: ReceivableEventType, handler?: (...args: any[]) => any) {
    if (!type) {
      for (const key of this.eventMap.keys()) {
        this.eventMap.delete(key);
      }
      return;
    }
    if (!handler) {
      this.eventMap.delete(type);
      return;
    }
    const e = this.eventMap.get(type);
    if (e) {
      const i = e.findIndex(v => v === handler);
      if (i > -1) {
        e.splice(0, i);
      }
      if (!e.length) {
        this.eventMap.delete(type);
      }
    }
  }

  public on<Handler extends (...args: any[]) => any>(type: ReceivableEventType, handler?: Handler) {
    if (!this.eventMap.has(type)) {
      this.eventMap.set(type, []);
    }
    if (handler) {
      const e = this.eventMap.get(type)!;
      e.push(handler);
    } else {
      this.eventMap.delete(type);
    }
  }
}

/**
 * @fileOverview
 * @author Kazuki Narizuka
 */

import { fetchService } from '@s/io/fetchService';
import { apiEndpoint, ENV, TENANT_NAME } from '@c/config';
import { required } from '@s/assertions';
import { AppendonlyRepository } from '@s/Repository';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

export type UsersUpdatableRepository = AppendonlyRepository<
  { email: string },
  void,
  { operatorId: string; isDisabled: boolean },
  void
>;
type P = Params<UsersUpdatableRepository>;

export class UsersRepository implements UsersUpdatableRepository {
  public async create(data: P['create'][0]): Promise<void> {
    const user = getAuth().currentUser;
    const idToken = required(await user?.getIdToken());
    await fetchService(apiEndpoint(`/operator/invite`), {
      method: 'POST',
      data,
      responseType: 'json',
      headers: { authorization: `Bearer ${idToken}` },
    });

    await sendSignInLinkToEmail(getAuth(), data.email, {
      url: `${location.protocol}//${location.host}/${
        location.hostname === 'localhost' ? `${ENV}/${TENANT_NAME}/` : ''
      }register`,
      handleCodeInApp: true,
    });
  }

  public async update({ operatorId, isDisabled }: P['update'][0]): Promise<void> {
    const user = getAuth().currentUser;
    if (user) {
      const idToken = required(await user.getIdToken());
      await fetchService(apiEndpoint(`/operator/${operatorId}/disabled`), {
        method: 'PUT',
        data: { disabled: isDisabled },
        responseType: 'json',
        headers: { authorization: `Bearer ${idToken}` },
      });
    }
  }
}

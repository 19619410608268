/**
 * @fileOverview
 * @name OneTurnMessage.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  PlainTextMessageEntity,
  ImageMessageEntity,
  MessageFactory,
  QuickRepliesAttachment,
} from './MessageEntity';
import { v4 } from 'uuid';

export type OneTurnMessageImageType = 'plainImage' | 'lineSticker' | 'facebookSticker';
export type OneTurnMessageImageMetaData = {
  stickerId: number;
  stickerPackageId: number;
  stickerVersion: number;
};

export type Messages = {
  attachment:
    | (ImageMessageEntity['attachment'] & {
        payload: {
          mediaId: string;
          imageType?: OneTurnMessageImageType;
          metadata?: OneTurnMessageImageMetaData;
        };
      })
    | PlainTextMessageEntity['attachment'];
  id: string;
}[];

export type OneTurnMessageMediaType = 'image' | 'plainText';

export type OneTurnMessages = {
  messages: Messages;
} & QuickRepliesAttachment;

export const oneTrunMessages = (props: Partial<OneTurnMessages> = {}): OneTurnMessages => {
  return {
    messages: props.messages ?? [{ attachment: MessageFactory.text('').attachment, id: v4() }],
    quickReplies: props.quickReplies ?? [],
  };
};

import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@s/components/atom/Box';
import { motion } from 'framer-motion';

const LoginTypeSelectButtonElement = styled.button`
  background: none;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    background: #0099ff;
    color: #fff;
  }
  padding: 10px 0px;
`;

export const TypeSelector = motion.custom(
  React.memo(
    forwardRef(
      (
        {
          onSelectIdPassword,
          onLoginWithFacebook,
        }: {
          onSelectIdPassword(): void;
          onLoginWithFacebook(): void;
        },
        ref: React.Ref<any>
      ) => {
        return (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            ref={ref}
            css={{
              transformOrigin: 'center 0px',
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 80,
            }}
          >
            <LoginTypeSelectButtonElement
              onClick={() => {
                onSelectIdPassword();
              }}
            >
              <div>ID・パスワードでログイン</div>
            </LoginTypeSelectButtonElement>
            <Box margin="10px 0 0 0">
              <LoginTypeSelectButtonElement
                onClick={() => {
                  onLoginWithFacebook();
                }}
              >
                <div>Facebookでログイン</div>
              </LoginTypeSelectButtonElement>
            </Box>
          </Flex>
        );
      }
    ),
    () => false
  )
);

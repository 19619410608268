import { initialState, State } from '@c/state';
import produce from 'immer';
import { fetchNotificationsSucceeded } from './action';

type ActionTypes = ReturnType<typeof fetchNotificationsSucceeded>;

export default (
  state: State['notifications'] = initialState.notifications,
  action: ActionTypes
): State['notifications'] => {
  return produce(state, _ => {
    switch (action.type) {
      case 'FETCH_NOTIFICATIONS_SUCCEEDED':
        return action.payload;
      default:
        return;
    }
  });
};

import { apiEndpoint } from '@c/config';
import {
  IgAutoMessage,
  IgAutoMessageDraftState,
  IgAutoMessageType,
} from '@c/domain/entities/IgAutoMessage';
import { fetchService } from '@s/io/fetchService';

export interface IgAutoMessageEditableRepository {
  updatePhrase(payload: {
    entryType: IgAutoMessageType;
    replyMessages: IgAutoMessage[IgAutoMessageType]['replyMessages'];
    projectId: string;
  }): Promise<void>;
  updateDraftState(payload: {
    entryType: IgAutoMessageType;
    draftState: IgAutoMessageDraftState;
    projectId: string;
  }): Promise<void>;
}

type P = Params<IgAutoMessageEditableRepository>;

export class IgAutoMessageRepository implements IgAutoMessageEditableRepository {
  public async updateDraftState({
    entryType,
    draftState,
    projectId,
  }: P['updateDraftState'][0]): ReturnType<IgAutoMessageEditableRepository['updateDraftState']> {
    return fetchService(
      apiEndpoint(`project/${projectId}/instagram/auto-message/${entryType}/promote`),
      {
        method: 'PUT',
        data: { entryType, draftState },
        responseType: 'json',
      }
    );
  }
  public async updatePhrase({
    entryType,
    replyMessages,
    projectId,
  }: P['updatePhrase'][0]): ReturnType<IgAutoMessageEditableRepository['updatePhrase']> {
    return fetchService(apiEndpoint(`project/${projectId}/instagram/auto-message/${entryType}`), {
      method: 'PUT',
      data: { entryType, replyMessages },
      responseType: 'json',
    });
  }
}

/**
 * @fileOverview
 * @author
 */

import { initialState } from '@c/state';
import produce from 'immer';
import { updateFound } from '@c/modules/update/action';

type ActionType = ReturnType<typeof updateFound>;
export default (state = initialState.update, action: ActionType) => {
  return produce(state, state => {
    switch (action.type) {
      case 'UPDATE_FOUND':
        state.isFound = true;
        return;
      default:
        return state;
    }
  });
};

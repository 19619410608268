/**
 * @fileOverview
 * @name AiCompassMatchingQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  MatchingClusters,
  RemoteMatchingCluster,
  SimilarQueries,
  RemoteSimilarQueries,
  MatchingCluster,
} from '@c/domain/entities/MatchingClusters';
import { fetchService } from '@s/io/fetchService';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { Categories } from '@c/domain/entities/Category';
import { GetAndFindableQuery, FindableQuery } from './interface';

export type MatchingClustersGettableQuery = GetAndFindableQuery<
  {
    projectId: ProjectId;
    categories: Categories;
  },
  MatchingClusters,
  {
    clusterId: string;
    projectId: ProjectId;
  },
  SimilarQueries
>;

export class MatchingQuery implements MatchingClustersGettableQuery {
  public async find({
    projectId,
    clusterId,
  }: Parameters<MatchingClustersGettableQuery['find']>[0]): ReturnType<
    MatchingClustersGettableQuery['find']
  > {
    const ret = await fetchService<RemoteSimilarQueries>(
      apiEndpoint(`/project/${projectId}/matching-training/cluster/${clusterId}/search`),
      {
        method: 'POST',
        responseType: 'json',
      }
    );
    return ret.inquiries ?? [];
  }

  public async get({
    projectId,
    categories,
  }: Parameters<MatchingClustersGettableQuery['get']>[0]): ReturnType<
    MatchingClustersGettableQuery['get']
  > {
    const ret = await fetchService<RemoteMatchingCluster>(
      apiEndpoint(`/project/${projectId}/matching-training/cluster/list`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );

    return (ret.clusters as any).map((cluster: MatchingCluster) => {
      const found = categories.find(v => v.id === cluster.categoryId);
      cluster.category = found?.displayName ?? 'カテゴリーなし';
      return cluster;
    });
  }
}

export type MatchingFindableQuery = FindableQuery<
  { projectId: ProjectId; categoryId: number; keyword: string },
  SimilarQueries
>;

export class MatchingSearchQuery implements MatchingFindableQuery {
  public async find({
    projectId,
    categoryId,
    keyword,
  }: Parameters<MatchingFindableQuery['find']>[0]): ReturnType<MatchingFindableQuery['find']> {
    const ret = await fetchService<RemoteSimilarQueries>(
      apiEndpoint(`/project/${projectId}/inquiry/${categoryId}/search`),
      {
        method: 'POST',
        responseType: 'json',
        data: { query: keyword },
      }
    );

    return ret.inquiries ?? [];
  }
}

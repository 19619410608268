/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { blurEffectStyle } from '@s/components/atom/blurEffectStyle';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { xlargeTextStyle } from '@s/components/atom/Text';
import { ConsoleEnvContext } from '../atom/ConsoleEnvContext';
import { Flex } from '@s/components/atom/Box';
import { Loading } from '@s/components/atom/Loading';

const CardContainerElement = styled(motion.section)<{ isTransparent: boolean }>`
  display: inline-block;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  ${p => (p.isTransparent ? blurEffectStyle('#FFF', '#AAA') : 'background: #FFF')};
  box-shadow: 0 0.6px 0.5px -9px rgba(0, 0, 0, 0.013), 0 1.4px 1px -9px rgba(0, 0, 0, 0.019),
    0 2.3px 1.7px -9px rgba(0, 0, 0, 0.023), 0 3.5px 2.5px -9px rgba(0, 0, 0, 0.027),
    0 5px 3.6px -9px rgba(0, 0, 0, 0.03), 0 7.1px 5.1px -9px rgba(0, 0, 0, 0.033),
    0 10.1px 7.3px -9px rgba(0, 0, 0, 0.037), 0 14.6px 10.6px -9px rgba(0, 0, 0, 0.041),
    0 22.5px 16.3px -9px rgba(0, 0, 0, 0.047), 0 40px 29px -9px rgba(0, 0, 0, 0.06);
`;
const CardContentElement = styled.article`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardTitleContainer = styled.div<{ isDisableFlex: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid #eeeeee;
  ${p => (p.isDisableFlex ? 'flex-direction: column' : '')};
`;
export const CardTitleElement = styled.div`
  font-weight: bold;
  color: #222;
  padding: 25px;
  overflow-wrap: break-word;
  ${xlargeTextStyle};
`;

const CardTitleElementContainerElement = styled.div`
  border-bottom: 4px solid #eeeeee;
`;
/**
 * @deprecated please use `Card` component in packages/console/src/shared/components/.
 */
export const Card = compareOnlyProperties(
  React.forwardRef(
    (
      {
        children,
        minWidth = 0,
        minHeight = 0,
        maxHeight = 'auto',
        maxWidth = 'auto',
        width = 'auto',
        height = 'auto',
        title = '',
        description = '',
        titleElement,
        isTransparent = false,
        buttons = null,
        leftButtons = null,
        centerButtons = null,
        loading = false,
      }: {
        children: React.ReactNode;
        title?: string;
        description?: string;
        titleElement?: React.ReactNode;
        minWidth?: string | number;
        minHeight?: string | number;
        maxWidth?: string | number;
        maxHeight?: string | number;
        width?: string | number;
        height?: string | number;
        isTransparent?: boolean;
        buttons?: React.ReactNode;
        leftButtons?: React.ReactNode;
        centerButtons?: React.ReactNode;
        loading?: boolean;
      },
      ref: React.Ref<HTMLElement>
    ) => {
      const environment = useContext(ConsoleEnvContext);
      return (
        <CardContainerElement
          isTransparent={isTransparent}
          initial={{ opacity: 0, translateX: 40 }}
          animate={{ opacity: 1, translateX: 0 }}
          exit={{ opacity: 0, translateX: 40 }}
          transition={{ type: 'tween', duration: 0.3, ease: 'anticipate' }}
          css={{ minWidth, minHeight, width, height, maxWidth, maxHeight }}
          ref={ref}
        >
          <CardContentElement>
            {loading ? <Loading scale={0.5} isBlurEnabled={true} isModalEnabled={true} /> : null}
            {title ? (
              <CardTitleContainer isDisableFlex={environment.isSupportedMobileBrowser}>
                <Flex>
                  <div
                    css={{
                      marginBottom: environment.isSupportedMobileBrowser ? 10 : 0,
                    }}
                  >
                    {leftButtons}
                  </div>
                  <CardTitleElement
                    css={{ maxWidth, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {title}
                  </CardTitleElement>
                  {description ? <div>{description}</div> : <></>}
                  <div
                    css={{
                      marginBottom: environment.isSupportedMobileBrowser ? 10 : 0,
                    }}
                  >
                    {centerButtons}
                  </div>
                </Flex>
                <div
                  css={{
                    marginRight: environment.isSupportedMobileBrowser ? 0 : 40,
                    marginBottom: environment.isSupportedMobileBrowser ? 10 : 0,
                  }}
                >
                  {buttons}
                </div>
              </CardTitleContainer>
            ) : titleElement ? (
              <CardTitleElementContainerElement>{titleElement}</CardTitleElementContainerElement>
            ) : null}
            {children}
            {/* 
              FIXME: children と title 兄弟関係にあるので children で 
              height:100% を設定すると title まで含めた高さを参照してしまう
             */}
          </CardContentElement>
        </CardContainerElement>
      );
    }
  ),
  'Card'
);

/**
 * @fileOverview
 * @author
 */

import { ScenarioEditorCommonState, ScenarioEditorUsingNamespaces } from '@c/state';
import {
  SceneNodes,
  SceneDimensionCache,
  SceneMessageType,
  IntegrationMethod,
  IntegrationHeader,
  SceneDimension,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { SceneErrors } from '@c/domain/values/PromoteErrors';
import { State } from '@c/state';
import { ThunkDeps } from '@c/ThunkDeps';
import { AsyncActionContext } from '@s/reactHooks';
import { SceneType } from '@c/application/value/SceneType';
import { InputStateValues } from '@s/domain/values/InputStateValues';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { ProjectEntity } from '@c/domain/entities/Project';

export type Namespaces = ScenarioEditorUsingNamespaces;
export type C = AsyncActionContext<ThunkDeps, State>;

export const carouselUpdated = (
  namespace: Namespaces,
  payload: { sceneId: string; carousel: Carousel }
) => {
  return {
    type: 'CAROUSEL_UPDATED' as const,
    payload: { namespace, ...payload },
  };
};

export const fetchScenarioSucceeded = (
  namespace: Namespaces,
  payload: {
    scenario: ReadonlyDeep<SceneNodes>;
    scenarioId: string;
    errors: SceneErrors;
  }
) => {
  return {
    type: 'FETCH_SCENARIO_SUCCEEDED' as const,
    payload: { scenario: payload, namespace },
  };
};

export const feedbackUpdated = (namespace: Namespaces, payload: { isEnableFeedback: boolean }) => {
  return {
    type: 'FEEDBACK_STATE_UPDATED' as const,
    payload: { namespace, ...payload },
  };
};

export const customFeedbackUpdated = (namespace: Namespaces, isFeedbackOn: boolean) => {
  return {
    type: 'CUSTOM_FEEDBACK_UPDATED' as const,
    payload: { namespace, isFeedbackOn },
  };
};

export const parameterUpdated = (
  namespace: Namespaces,
  payload: {
    parameterIndex: number;
    parameterId: string;
  }
) => {
  return {
    type: 'PARAMETER_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const parameterDeleted = (
  namespace: Namespaces,
  payload: {
    parameterIndex: number;
  }
) => {
  return {
    type: 'PARAMETER_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const sceneParameterUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    parameter: string;
  }
) => {
  return {
    type: 'SCENE_PARAMETER_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const switchToOpUpdated = (
  namespace: Namespaces,
  payload: { isEnableSwitchToOp: boolean }
) => {
  return {
    type: 'SWITCH_TO_OP_STATE_UPDATED' as const,
    payload: { namespace, ...payload },
  };
};

export const edgeConnected = (
  namespace: Namespaces,
  payload: {
    fromSceneId: string;
    buttonId: string;
    toSceneId: string;
  }
) => {
  return { type: 'EDGE_CONNECTED' as const, payload: { ...payload, namespace } };
};

export const initialEdgeConnected = (namespace: Namespaces, payload: { sceneId: string }) => {
  return {
    type: 'INITIAL_EDGE_CONNECTED' as const,
    payload: { ...payload, namespace },
  };
};

export const edgeDeleted = (
  namespace: Namespaces,
  payload: { sceneId: string; buttonId: string }
) => {
  return { type: 'EDGE_DELETED' as const, payload: { ...payload, namespace } };
};

export const sceneSelected = (namespace: Namespaces, payload: { sceneId: string }) => {
  return { type: 'SCENE_SELECTED' as const, payload: { ...payload, namespace } };
};

export const messageAdded = (namespace: string, payload: { sceneId: string }) => {
  return { type: 'MESSAGE_ADDED' as const, payload: { ...payload, namespace } };
};

export const messageDeleted = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    messageId: string;
  }
) => {
  return {
    type: 'MESSAGE_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const messageUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    messageId: string;
    value: string;
  }
) => {
  return {
    type: 'MESSAGE_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const textInputStateUpdated = (
  namespace: Namespaces,
  { value, sceneId }: { sceneId: string; value: InputStateValues }
) => {
  return {
    type: 'MESSAGE_NODE_INPUT_STATE_UPDATED' as const,
    payload: { value, sceneId, namespace },
  };
};

export const sceneDeleted = (namespace: Namespaces, payload: { sceneId: string }) => {
  return { type: 'SCENE_DELETED' as const, payload: { ...payload, namespace } };
};

export const quickRepliesAdded = (namespace: Namespaces, payload: { sceneId: string }) => {
  return {
    type: 'QUICKREPLIES_ADDED' as const,
    payload: { ...payload, namespace },
  };
};

export const quickRepliesDeleted = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    quickRepliesId: string;
  }
) => {
  return {
    type: 'QUICKREPLIES_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const quickRepliesOrderChanged = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    quickRepliesId: string;
    isDown: boolean;
  }
) => {
  return {
    type: 'QUICKREPLIES_ORDER_CHANGED' as const,
    payload: { ...payload, namespace },
  };
};

export const quickRepliesTextUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    quickRepliesId: string;
    value: string;
  }
) => {
  return {
    type: 'QUICKREPLIES_TEXT_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const sceneAdded = (
  namespace: Namespaces,
  option: { x: number; y: number; type: SceneType; projectType: State['env']['type'] }
) => {
  return { type: 'SCENE_ADDED' as const, payload: { namespace, option } };
};

export const userConfigSaved = (
  namespace: Namespaces,
  payload: ScenarioEditorCommonState['userConfig']
) => {
  return {
    type: 'USER_CONFIG_SAVED' as const,
    payload: { ...payload, namespace },
  };
};

export const queryScenarioEditorUnloaded = (namespace: Namespaces) => {
  return {
    type: 'QUERY_SCENARIO_EDITOR_UNLOADED' as const,
    payload: { namespace },
  };
};

export const dimensionsUpdated = (
  namespace: Namespaces,
  dimensions: ReadonlyDeep<SceneDimensionCache>
) => {
  return {
    type: 'DIMENSIONS_UPDATED' as const,
    payload: { namespace, dimensions },
  };
};

export const dimensionUpdated = (
  namespace: Namespaces,
  { dimension, sceneId }: { dimension: ReadonlyDeep<SceneDimension>; sceneId: string }
) => {
  return {
    type: 'DIMENSION_UPDATED' as const,
    payload: { namespace, dimension, sceneId },
  };
};

export const saveSucceeded = (namespace: Namespaces) => {
  return {
    type: 'SCENARIO_SAVE_SUCCEEDED' as const,
    payload: { namespace },
  };
};

export const messageTypeChanged = (
  namespace: string,
  payload: { type: SceneMessageType; id: string }
) => {
  return {
    type: 'SCENE_MESSAGE_TYPE_CHANGED' as const,
    payload: { ...payload, namespace },
  };
};

export const imageUrlChanged = (namespace: string, payload: { url: string; id: string }) => {
  return {
    type: 'SCENE_MESSAGE_IMAGE_URL_CHANGED' as const,
    payload: { ...payload, namespace },
  };
};

export const saveRequiredUpdated = (
  namespace: Namespaces,
  payload: { isSaveRequired: boolean }
) => {
  return {
    type: 'SAVE_REQUIRED_UPDATED' as const,
    payload: { namespace, ...payload },
  };
};

export const scenarioStateCleaned = (namespace: Namespaces) => {
  return {
    type: 'SCENARIO_EDITOR_STATE_CLEANED' as const,
    payload: { namespace },
  };
};

export const warningDisplayed = (
  namespace: string,
  warningType: keyof ScenarioEditorCommonState['warnings']
) => {
  return {
    type: 'SCENARIO_EDITOR_WARNING_DISPLAYED' as const,
    payload: { namespace, warningType },
  };
};

export const integrationEndpointUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    endpoint: string;
  }
) => {
  return {
    type: 'INTEGRATION_END_POINT_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationMethodUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    method: IntegrationMethod;
  }
) => {
  return {
    type: 'INTEGRATION_METHOD_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationHeaderAdded = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    header: IntegrationHeader;
  }
) => {
  return {
    type: 'INTEGRATION_HEADER_ADDED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationHeaderDeleted = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    headerFieldName: string;
  }
) => {
  return {
    type: 'INTEGRATION_HEADER_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationHeaderUpdated = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    previousHeaderName: string;
    header: IntegrationHeader;
  }
) => {
  return {
    type: 'INTEGRATION_HEADER_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationConditionAdded = (
  namespace: Namespaces,
  payload: { sceneId: string; label: string }
) => {
  return {
    type: 'INTEGRATION_CONDITION_ADDED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationConditionUpdated = (
  namespace: Namespaces,
  payload: { sceneId: string; conditionId: string; condition: string }
) => {
  return {
    type: 'INTEGRATION_CONDITION_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const integrationConditionDeleted = (
  namespace: Namespaces,
  payload: {
    sceneId: string;
    conditionId: string;
  }
) => {
  return {
    type: 'INTEGRATION_CONDITION_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const toAddressUpdated = (
  namespace: Namespaces,
  payload: { sceneId: string; name: string; address: string; index: number }
) => {
  return {
    type: 'MAIL_ACTION_TO_ADDRESS_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const toAddressDeleted = (
  namespace: Namespaces,
  payload: { sceneId: string; index: number }
) => {
  return {
    type: 'MAIL_ACTION_TO_ADDRESS_DELETED' as const,
    payload: { ...payload, namespace },
  };
};

export const toAddressAdded = (namespace: Namespaces, payload: { sceneId: string }) => {
  return {
    type: 'MAIL_ACTION_TO_ADDRESS_ADDED' as const,
    payload: { ...payload, namespace },
  };
};

export const replyToUpdated = (
  namespace: Namespaces,
  payload: { sceneId: string; name: string; address: string }
) => {
  return {
    type: 'MAIL_ACTION_REPLY_TO_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const subjectUpdated = (
  namespace: Namespaces,
  payload: { sceneId: string; value: string }
) => {
  return {
    type: 'MAIL_ACTION_SUBJECT_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const bodyUpdated = (namespace: Namespaces, payload: { sceneId: string; value: string }) => {
  return {
    type: 'MAIL_ACTION_BODY_UPDATED' as const,
    payload: { ...payload, namespace },
  };
};

export const fallbackUsagedUpdated = (
  namespace: Namespaces,
  isEnabled: boolean,
  projectType: ProjectEntity['type'] | ''
) => {
  return {
    type: 'FALLBACK_USAGE_UPDATED' as const,
    payload: { isEnabled, namespace, projectType },
  };
};

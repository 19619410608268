/**
 * @fileOverview
 * @name CategoryQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { Categories } from '@c/domain/entities/Category';
import { fetchService } from '@s/io/fetchService';
import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { GettableQuery } from './interface';

export type CategoryGettableQuery = GettableQuery<{ projectId: ProjectId }, Categories>;

export class CategoryQuery implements CategoryGettableQuery {
  public async get({
    projectId,
  }: Parameters<CategoryGettableQuery['get']>[0]): ReturnType<CategoryGettableQuery['get']> {
    const ret = await fetchService<Categories>(apiEndpoint(`/project/${projectId}/category`), {
      method: 'GET',
      responseType: 'json',
    });
    return ret;
  }
}

import React, { forwardRef } from 'react';
import { Flex, Box } from '@s/components/atom/Box';
import styled from '@emotion/styled';
import { useRefState } from '@s/reactHooks';
import { IconOnlyButton } from '@c/components/atom/IconOnlyButton';
import { InternalLink } from '@c/components/atom/Link';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';
import { Submittable } from '../../../atom/Submittable';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { TextInput } from '@c/shared/components/TextInput';

const loginWithPasswordRootStyle = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform-origin: center 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 80px;
`;
const mobileBoxCss = css`
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ChoseLoginTypeButtonWrapper = styled.button`
  background: none;
  border: 0;
  outline: none;
  color: #0066ff;
  cursor: pointer;
  :hover {
    color: #0099ff;
  }
  :active {
    border: 0;
    background: none;
    outline: none;
  }
`;

export const LoginWithIdPassword = motion.custom(
  compareOnlyProperties(
    forwardRef(
      (
        {
          isSupportedMobileBrowser,
          onLogin,
          onResetLoginType,
        }: {
          isSupportedMobileBrowser: boolean;
          onLogin(a: { id: string; password: string }): void;
          onResetLoginType(): void;
        },
        ref: React.Ref<any>
      ) => {
        const [id, setIdValue] = useRefState('');
        const [password, setPasswordValue] = useRefState('');
        return (
          <Submittable
            ref={ref}
            onSubmit={() => onLogin({ id: id.current, password: password.current })}
            cssProps={loginWithPasswordRootStyle}
          >
            <>
              <Flex
                alignItems="center"
                justifyContent="center"
                css={{
                  marginBottom: 10,
                }}
              >
                <TextInput
                  width={isSupportedMobileBrowser ? '100%' : 500}
                  border
                  placeholder={{ value: 'メールアドレス', type: 'dynamic' }}
                  onChange={e => setIdValue(e.target.value)}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Box margin="0 10px 0 0" css={mobileBoxCss}>
                  <TextInput
                    isPassword
                    width={isSupportedMobileBrowser ? '100%' : 465}
                    border
                    placeholder={{ value: 'パスワード', type: 'dynamic' }}
                    onChange={e => setPasswordValue(e.target.value)}
                  />
                </Box>
                <IconOnlyButton
                  color="#333"
                  name="forward"
                  onClick={() => {
                    onLogin({ id: id.current, password: password.current });
                  }}
                  size={25}
                  className="e2e__login"
                />
              </Flex>
              {!isSupportedMobileBrowser ? (
                <Box margin="10px 0 0 0">
                  <Flex justifyContent="center">
                    <InternalLink to="/reminder">パスワードをお忘れの方はこちら</InternalLink>
                  </Flex>
                  <Flex justifyContent="center">
                    <ChoseLoginTypeButtonWrapper onClick={() => onResetLoginType()}>
                      ログイン方法を選択
                    </ChoseLoginTypeButtonWrapper>
                  </Flex>
                </Box>
              ) : null}
            </>
          </Submittable>
        );
      }
    )
  )
);

/**
 * @fileOverview
 * @name promotionErrorAdapter.ts
 * @author Taketoshi Aono
 * @license
 */

import { PromoteErrors, RemotePromoteErrors, PromoteError } from '@c/domain/values/PromoteErrors';

export const promotionErrorAdapter = (errors: RemotePromoteErrors): PromoteErrors => {
  const e = errors.errors;
  return e.map(({ errors, ...all }) => {
    const ret: PromoteError = { ...all, errors: {} };
    ret.errors = errors.reduce((acc, e) => {
      let error = acc[e.id];
      if (!error) {
        error = acc[e.id] = { size: 0 };
      }
      error[e.code] = e.code;
      return acc;
    }, {} as PromoteError['errors']);
    return ret;
  });
};

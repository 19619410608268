/**
 * @fileOverview
 * @author
 */

import { ProjectId } from '@c/domain/entities/ProjectId';
import { apiEndpoint } from '@c/config';
import { fetchService } from '@s/io/fetchService';
import { GettableQuery } from './interface';
import { BusinessHourSchedule } from '@c/domain/entities/BusinessHour';

type BusinessHourScheduleGettableQueryResponse = {
  schedule: BusinessHourSchedule[];
};
export type BusinessHourScheduleGettableQuery = GettableQuery<
  { projectId: ProjectId; month: number },
  BusinessHourScheduleGettableQueryResponse
>;

type BusinessHourScheduleResponseType = {
  date: string;
  weekday: number;
  public_holiday?: string;
  close?: boolean;
  opens?: string[][];
};

type BusinessHourScheduleRemoteResponseType = {
  schedule: BusinessHourScheduleResponseType[];
};
export class BusinessHourScheduleQuery implements BusinessHourScheduleGettableQuery {
  public async get({
    projectId,
    month,
  }: Parameters<BusinessHourScheduleGettableQuery['get']>[0]): ReturnType<
    BusinessHourScheduleGettableQuery['get']
  > {
    const ret = await fetchService<BusinessHourScheduleRemoteResponseType>(
      apiEndpoint(`/project/${projectId}/biz_hour/schedule?month=${month}`),
      {
        method: 'GET',
      }
    );
    return {
      ...ret,
      schedule: ret.schedule.map((schedule: BusinessHourScheduleResponseType) => {
        return {
          ...schedule,
          publicHoliday: schedule?.public_holiday,
        };
      }),
    };
  }
}

/**
 * @fileOverview
 * @author
 */

import { genericError } from '@c/application/GenericError';

export type RemoteCustomerEntity = {
  /* eslint-disable @typescript-eslint/naming-convention */
  display_name: string;
  note: string;
  preview: boolean;
  developer: boolean;
  disabled: boolean;
  updated_at: number;
  instagram?: {
    id: string;
    name: string;
    follower_count: number;
    profile_pic: string;
  };
  web?: {
    uid: string;
    last_ip_address: string;
    last_user_agent: string;
    additionals?: { [key: string]: string };
  };
  line?: {
    user_id: string;
    display_name: string;
    picture_url: string;
    status_message: string;
    updated_at: number;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
};

export type CustomerEntity = {
  id: string;
  displayName: string;
  note: string;
  preview: boolean;
  developer: boolean;
  disabled: boolean;
  updatedAt: number;
  instagram?: {
    id: string;
    name: string;
    followerCount: number;
    profilePic: string;
  };
  web?: {
    uid: string;
    lastIpAddress: string;
    lastUserAgent: string;
    additionals?: { [key: string]: string };
  };
  line?: {
    userId: string;
    displayName: string;
    pictureUrl: string;
    statusMessage: string;
    updatedAt: number;
  };
};

export const DEFAULT_EMPTY_WEB_CUSTOMER_ENTITY = {
  id: '',
  displayName: '',
  note: '',
  preview: false,
  developer: false,
  disabled: false,
  updatedAt: 0,
  web: {
    uid: '',
    lastIpAddress: '',
    lastUserAgent: '',
    additionals: {},
  },
};

export class CustomerDescriptionParameter {
  public constructor(public displayName: string, public note: string) {}

  public validate() {
    if (!this.displayName) {
      throw genericError({ message: '名前は必須です' });
    }
    if (this.displayName.length > 32) {
      throw genericError({ message: '名前は32文字以内で入力してください' });
    }
    if (this.note.length > 1000) {
      throw genericError({ message: 'メモは1000文字以内で入力してください' });
    }
  }
}

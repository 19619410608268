/**
 * @fileOverview
 * @author
 */

import {
  createScenarioSucceeded,
  selectCategorySucceeded,
  cleanupQueryScenarioEditorSucceeded,
  updateInquirySucceeded,
  deleteScenarioSucceeded,
} from '@c/modules/queryScenarioEditor/action';
import { State, initialState } from '@c/state';
import produce from 'immer';
import { required } from '@s/assertions';

type ActionTypes = ReturnType<
  | typeof createScenarioSucceeded
  | typeof selectCategorySucceeded
  | typeof cleanupQueryScenarioEditorSucceeded
  | typeof updateInquirySucceeded
  | typeof deleteScenarioSucceeded
>;

export default (
  state: State['queryScenarioEditor'] = initialState.queryScenarioEditor,
  action: ActionTypes
) => {
  return produce(state, state => {
    switch (action.type) {
      case 'CREATE_SCENARIO_SUCCEEDED':
        if (!state.scenarioList[state.selectedCategory.id]) {
          state.scenarioList[state.selectedCategory.id] = [];
        }
        required(state.scenarioList[state.selectedCategory.id]).unshift({
          ...action.payload,
          draftState: '',
          order: 0,
          summaryId: '',
        });
        return;
      case 'QUERY_SCENARIO_EDITOR_SELECT_CATEGORY_SUCCEEDED':
        state.selectedCategory = action.payload.category;
        state.selectedScenario = undefined;
        return;
      case 'CLEANUP_QUERY_SCENARIO_EDITOR_SUCCEEDED':
        state.selectedCategory = { id: 1, displayName: '' };
        state.scenarioList = {};
        return;
      case 'UPDATE_INQUIRY_SUCCEEDED': {
        const scenario = state.scenarioList[state.selectedCategory.id];
        if (scenario) {
          const found = scenario.find(v => v.scenarioId === action.payload.scenarioId);
          if (found) {
            found.inquiry = action.payload.inquiry;
          }
        }
        return;
      }
      case 'DELETE_SCENARIO_SUCCEEDED':
        {
          const f = state.scenarioList[state.selectedCategory.id];
          if (f) {
            const index = f.findIndex(v => v.scenarioId === action.payload.scenarioId);
            f.splice(index, 1);
            state.selectedScenario = undefined;
          }
        }
        return;
      default:
        const unusedCheck: never = action;
        return state;
    }
  });
};

/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';

export const DEFAULT_VIEW = document.defaultView || window;

export const getComputedStyle = (element: Element, prop: keyof CSSStyleDeclaration): any => {
  return DEFAULT_VIEW.getComputedStyle(element)[prop];
};

export const forceLayout = (el: Element) => {
  void el.clientLeft;
};

export const setRefedElement = (ref: React.Ref<any> | undefined | null, element: any) => {
  if (!ref) {
    return;
  }
  if (typeof ref === 'function') {
    ref(element);
  } else {
    (ref as any).current = element;
  }
};

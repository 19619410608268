/**
 * @fileOverview
 * @name LruCache.ts
 * @author Taketoshi Aono
 * @license
 */

class LruCacheNode<T> {
  private next: LruCacheNode<T> | null = null;
  private prev: LruCacheNode<T> | null = null;

  public constructor(private readonly key: string, private readonly value: T) {}

  public getKey() {
    return this.key;
  }

  public getNext() {
    return this.next;
  }

  public getPrev() {
    return this.prev;
  }

  public getValue() {
    return this.value;
  }

  public remove() {
    if (this.next) {
      this.next.prev = this.prev;
    }
    if (this.prev) {
      this.prev.next = this.next;
    }
    this.next = this.prev = null;
  }

  public setNext(next: LruCacheNode<T> | null) {
    if (next === this) {
      return;
    }
    if (this.next) {
      this.next.remove();
    }
    if (next) {
      next.prev = this;
    }
    this.next = next;
  }
}

export class LruCache<T> {
  private readonly deleteHandlers: ((key: string, value: T) => void)[] = [];

  private nodeCount = 0;

  private nodeMap: { [key: string]: LruCacheNode<T> | undefined } = {};

  private recent: LruCacheNode<T> | null = null;

  private root: LruCacheNode<T> | null = null;

  public constructor(private readonly limit = 10) {}

  public add({ key, value }: { key: string; value: T }) {
    if (!this.root) {
      this.recent = this.root = new LruCacheNode(key, value);
      this.nodeMap[key] = this.root;
      this.nodeCount++;
    } else if (this.recent) {
      const n = this.nodeMap[key];
      if (n) {
        if (this.recent !== n) {
          const next = n.getNext();
          n.remove();
          if (n === this.root) {
            if (next) {
              this.root = next;
            }
          }
          this.recent.setNext(n);
          this.recent = n;
        }
      } else {
        const recent = new LruCacheNode(key, value);
        this.recent.setNext(recent);
        this.recent = this.nodeMap[key] = recent;
        this.nodeCount++;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.nodeCount > this.limit && this.root) {
      this.nodeCount--;
      const oldRoot = this.root;
      const next = this.root.getNext();
      const key = this.root.getKey();
      delete this.nodeMap[key];
      this.root.remove();
      this.root = next;
      this.deleteHandlers.forEach(h => h(key, oldRoot.getValue()));
    }
  }

  public delete(key?: string): void {
    if (!key) {
      for (const key of Object.keys(this.nodeMap)) {
        const node = this.nodeMap[key]!;
        node.remove();
        this.deleteHandlers.forEach(h => h(key, node.getValue()));
      }
      this.root = this.recent = null;
      this.nodeCount = 0;
      this.nodeMap = {};
      return;
    }
    const node = this.nodeMap[key];
    if (node) {
      if (this.root === node) {
        this.root = node.getNext();
      }
      if (this.recent === node) {
        this.recent = node.getPrev();
      }
      node.remove();
      delete this.nodeMap[key];
      this.deleteHandlers.forEach(h => h(key, node.getValue()));
      this.nodeCount--;
    }
  }

  public get(key: string): T | null {
    const node = this.nodeMap[key];
    if (node) {
      return node.getValue();
    }
    return null;
  }

  public keys(): string[] {
    return Object.keys(this.nodeMap);
  }

  public onDelete(cb: (key: string, value: T) => void) {
    this.deleteHandlers.push(cb);
  }

  public touch(key: string) {
    const n = this.nodeMap[key];
    if (n) {
      this.add({ key, value: n.getValue() });
    }
  }
}

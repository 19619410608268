/**
 * @fileOverview
 * @name SystemMessagesQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { SystemMessages } from '@c/domain/entities/SystemMessages';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { GettableQuery } from './interface';

export type SystemMessageGettableQuery = GettableQuery<{ projectId: string }, SystemMessages>;

export class SystemMessagesQuery implements SystemMessageGettableQuery {
  public async get({
    projectId,
  }: Parameters<SystemMessageGettableQuery['get']>[0]): ReturnType<
    SystemMessageGettableQuery['get']
  > {
    const ret = await fetchService<{ messages: SystemMessages }>(
      apiEndpoint(`project/${projectId}/scenario/system_message`),
      {
        method: 'GET',
        responseType: 'json',
      }
    );

    return ret.messages;
  }
}

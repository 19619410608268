/**
 * @fileOverview
 * @author
 */

import produce from 'immer';
import { ScenarioCommonState } from '@c/state';
import { Namespaces, fetchScenarioListSucceeded } from '@c/modules/scenarioCommon/action';
import { dynamciNamespaceIc } from '../dynamicNamespaceIC';

type ActionType = ReturnType<typeof fetchScenarioListSucceeded>;

const invokeNamespaceIc = dynamciNamespaceIc<ScenarioCommonState>();

export default (namespace: Namespaces) => (state: any, action: ActionType) => {
  if (!(action as any)?.payload?.namespace || action.payload.namespace !== namespace) {
    return state;
  }

  return produce(state, (state: any) => {
    const commonState = invokeNamespaceIc({
      state,
      namespace: action.payload.namespace,
    });
    switch (action.type) {
      case 'FETCH_SCENARIO_LIST_SUCCEEDED':
        const order = action.payload.scenarioList
          .filter(s => s.order !== 0)
          .sort((a, b) => a.order - b.order)
          .map(s => s.scenarioId);
        commonState.scenarioList[action.payload.categoryId] = action.payload.scenarioList.map(
          s => ({
            ...s,
            order: order.indexOf(s.scenarioId) + 1,
          })
        );
        return;
      default:
        return state;
    }
  });
};
